import React from 'react'
import { useForm } from 'react-hook-form'

const TitleForm = ({ initialValues, setShowEdit, handleProfileSubmit }) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })
  const areaCode = watch('area_code')

  const phonePlaceholder = {
    '+1': '555 987 6543',
    '+63': '917 282 2123',
    '+52': '888 351 4603'
  }

  return (<div className='min-w-full'>
    <form className='form' onSubmit={handleSubmit(handleProfileSubmit)}>
      <div className="px-4 py-5 sm:px-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">This information will be used for the Digital Business Card.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Role</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="position"
                  id="position"
                  autoComplete="position"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('position')}
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full text-gray-400 sm:text-sm border-gray-300 rounded-md"
                  {...register('email')}
                  disabled
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="area_code" className="sr-only">
                      Area Code
                    </label>
                    <select
                      id="area_code"
                      name="area_code"
                      autoComplete="area_code"
                      onChange={(e) => setValue('area_code', e.target.value)}
                      defaultValue={areaCode}
                      className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    >
                      <option>+1</option>
                      <option>+63</option>
                      <option>+52</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-gray-300 pl-16 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    placeholder={phonePlaceholder[areaCode]}
                    {...register('phone')}
                  />
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>    
      <div className="pt-5 pr-1">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowEdit(false) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>)
}

export default TitleForm
