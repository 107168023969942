import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import WriterSelect from '../WriterSelect/WriterSelect'

const Writers = ({ ownershipIndex }) => {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `publishers[${ownershipIndex}].ownership_writers`,
    keyName: 'uuid'
  })

  if (fields.length === 0) {
    append({})
  }

  return (<>
    <div className="bg-white rounded-md">
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label htmlFor="collectors" className="block text-sm font-medium text-gray-700">
            Writers<sup className='required-icon'>*</sup>
          </label>
          {fields.map((ownershipWriter, index) =>
            <WriterSelect
              key={ownershipWriter}
              ownershipWriter={ownershipWriter}
              index={index}
              ownershipIndex={ownershipIndex}
              remove={remove}
            />
          )}
          <div className="pt-3">
            <div className="flex">
              <button
                type="button"
                onClick={() => append()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Add Writer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default Writers
