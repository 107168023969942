import React from 'react';
import { Route, Switch } from 'react-router';
import Feed from './routes/Feed/index';
import Stats from './routes/Stats/index';
import Uploading from './routes/Uploading/index';
import AssetSearch from './routes/AssetSearch/index';
import Dataset from './routes/Dataset/index';

export default function AugmentorViews () {
  return (
    <Switch>
      <Route exact path='/augmentor/' component={Feed} />
      <Route exact path='/augmentor/assets/' component={AssetSearch} />
      <Route exact path='/augmentor/dataset/' component={Dataset} />
      <Route path='/augmentor/upload/:id' component={Uploading} />
      <Route path='/augmentor/stats/:id' component={Stats} />
    </Switch>
  )
}
