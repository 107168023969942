import React, { useEffect, useState } from 'react'
import './ListTable.scss'

import Paginator from 'components/Paginator';

const ListTable = ({ currentPage, searchQ, refreshTable, publisherList, pages, getList }) => {

  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)

  const _onNext = (page) => {
    setLoading(true)
    getList(parseInt(page) + 1, searchQ)
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(parseInt(page) - 1, searchQ)
      setCurrPage(parseInt(page) - 1)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (searchQ) {
      getList(currPage, searchQ)
    }
  }, [refreshTable])

  const hasNext = currPage < pages

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {publisherList && publisherList.map(publisher => (
          <li key={publisher.external_id}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-left justify-between">
                <div>
                  <a href={'/publishers/' + publisher.external_id + '/'} className="text-lg font-medium hover:text-blue-500 truncate">{publisher.name}</a>
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {publisher.external_id}
                  </p>
                </div>
              </div>
              <div className="mt-2 md:grid md:grid-cols-3">
                <div className="sm:flex">
                  <p className="flex items-left text-sm text-gray-500">
                    <div className='table-asset-codes'>
                      <span>Pro Affiliation: </span><br />
                      {publisher.pro_affiliation}
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="mt-2 flex items-left text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <div className='table-asset-codes'>
                      <span>IPI Number: </span><br />
                      {publisher.ipi_number}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {pages && (<div className="">
          <Paginator
            getNext={_onNext}
            getPrev={_onPrevious}
            currPage={currPage}
            setCurrPage={setCurrPage}
            hasNext={hasNext}
            totalPages={pages}
          />
        </div>)
      }
    </div>
  )
}

export default ListTable
