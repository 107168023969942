import React, { useState } from 'react'
import './SelectToggle.scss'

const SelectToggle = ({ defaultValue, onChange, className, type, options, isMulti, disabled, utilFunc, utilIndex}) => {
  const [value, setValue] = useState(defaultValue || 'include')

  const clickOption = (option) => {
    if (utilFunc) {
      utilFunc(utilIndex, option.value)
    }
    onChange(option.value)
    setValue(option.value)
  }

  return (<div className={'select-toggle'}>
    <input type='text' hidden />
    <div className='btn-group '>
      {options.map((option, idx) => {
        let klass = ['btn', 'btn-option']
        if (option.value === value || (!value && option.value === 'include')) {
          klass.push('selected')
        }
        return (<div key={idx}
          className={klass.join(' ')}
          value={value}
          onClick={() => clickOption(option)}>
          {option.label}
        </div>)
      })}
    </div>
  </div>)
}

export default SelectToggle
