import React, { useState, useEffect } from 'react'
import InfoBar from 'components/InfoBar'
import ClientTable from './ClientTable'
import StaffTable from './StaffTable'
import PotentialClaimTable from './PotentialClaimTable'
import DisputedClaimTable from './DisputedClaimTable'

const StatsContainer = ({ id, propScope, stats, staff, month, setMonth, year, setYear }) => {
  const [scope, setScope] = useState('exp-read')

  useEffect(() => {
    if (propScope) {
      setScope(propScope)
    }
  }, [propScope])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const clearValues = () => {
    setMonth('')
    setYear('')
  }

  const platforms = [
    { name: 'YouTube', bgColor: 'bg-red-600', total: stats?.per_platform?.youtube || 0 },
    { name: 'SoundExchange', bgColor: 'bg-blue-600', total: stats.per_platform?.soundexchange || 0 },
    { name: 'MRI', bgColor: 'bg-green-600', total: stats.per_platform?.mri || 0 },
    { name: 'MLC', bgColor: 'bg-indigo-600', total: stats.per_platform?.mlc || 0 },
    { name: 'CMRRA', bgColor: 'bg-yellow-600', total: stats.per_platform?.cmrra || 0 }
  ]

  const potentialCategories = [
    { name: 'Claimed', bgColor: 'bg-red-600', total: stats?.per_potential_category?.claimed || 0 },
    { name: 'Released', bgColor: 'bg-blue-600', total: stats?.per_potential_category?.released || 0 },
    { name: 'Misembed', bgColor: 'bg-green-600', total: stats?.per_potential_category?.misembed || 0 },
    { name: 'Added Share', bgColor: 'bg-indigo-600', total: stats?.per_potential_category?.add_share || 0 },
    { name: 'Duplicate', bgColor: 'bg-yellow-600', total: stats?.per_potential_category?.duplicate || 0 }
  ]

  const disputedCategories = [
    { name: 'Reinstated', bgColor: 'bg-red-600', total: stats.per_disputed_category?.reinstate || 0 },
    { name: 'Released', bgColor: 'bg-blue-600', total: stats.per_disputed_category?.release || 0 },
    { name: 'Skipped', bgColor: 'bg-green-600', total: stats.per_disputed_category?.skip || 0 },
    { name: 'Skipped & Removed', bgColor: 'bg-indigo-600', total: stats.per_disputed_category?.skip_remove || 0 }
  ]

  let isAdmin = scope.indexOf('exp-claimad') >= 0 || scope.indexOf('exp-admin') >= 0 || scope.indexOf('exp-dev') >= 0
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  return (<>
    <div className='flex flex-wrap flex-1 min-h-full'>
      <div className="p-6 flex-1">
        <div>
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              {isAdmin && !id
                ? (<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3 truncate">Overall Claim Stats</h2>)
                : (<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3 truncate">{staff?.email}'s Stats</h2>)
              }
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <div>
                <label htmlFor="month" className="block text-sm font-medium text-gray-700">
                  Filter by month
                </label>
                <select
                  id="month"
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                >
                  <option value="">--</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="ml-2">
                <label htmlFor="month" className="block text-sm font-medium text-gray-700">
                  Filter by year
                </label>
                <select
                  id="year"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                >
                  <option value="">--</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </div>
            </div>
          </div>
          {(month && year) && (<InfoBar
              text={"Showing claims for the month of " + months[month-1] + " " + year}
              onClose={() => clearValues()}
              showClose
            />)
          }
        </div>
        <div className="mb-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='claims-per-client'>
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl mb-3 truncate">Manual Claims</h3>
                  </div>
                </div>
              </div>
              <ul role="list" className="mt-3 mb-10 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
                {platforms.map((platform) => (
                  <li key={platform.name} className="col-span-1 flex shadow-sm rounded-md">
                    <div
                      className={classNames(
                        platform.bgColor,
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                      )}
                    >
                      {platform.total}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm">
                        {platform.name}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <ClientTable id={id} month={month} year={year} />
            </div>

            <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='claims-per-client'>
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl mb-3 truncate">Potential Claims</h3>
                  </div>
                </div>
              </div>
              <ul role="list" className="mt-3 mb-10 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
                {potentialCategories.map((category) => (
                  <li key={category.name} className="col-span-1 flex shadow-sm rounded-md">
                    <div
                      className={classNames(
                        category.bgColor,
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                      )}
                    >
                      {category.total}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm">
                        {category.name}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <PotentialClaimTable id={id} month={month} year={year} />
            </div>

            <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='claims-per-client'>
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl mb-3 truncate">Disputed Claims</h3>
                  </div>
                </div>
              </div>
              <ul role="list" className="mt-3 mb-10 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {disputedCategories.map((category) => (
                  <li key={category.name} className="col-span-1 flex shadow-sm rounded-md">
                    <div
                      className={classNames(
                        category.bgColor,
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                      )}
                    >
                      {category.total}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm">
                        {category.name}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <DisputedClaimTable id={id} month={month} year={year} />
            </div>
            
            {isAdmin && !id
              && (<div className='bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='claims-per-staff'>
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="sm:flex sm:space-x-5">
                    <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                      <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl mb-3 truncate">Claims Per Staff</h3>
                    </div>
                  </div>
                </div>
                <StaffTable month={month} year={year} />
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default StatsContainer
