import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { TrashIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import { getAssetReportList as getAssetReportListApi, deleteAssetReport as deleteAssetReportApi } from 'api/download'
import Filters from './Filters'
import ListTable from 'components/ListTable'

function ImportedFiles ({ propScope, propUser }) {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rownum, setRownum] = useState(10)
  const [searchFilter, setSearchFilter] = useState()
  const [dateFilter, setDateFilter] = useState()

  const [scope, setScope] = useState('exp-read')
  const [user, setUser] = useState()

  useEffect(() => {
    if (propScope) {
      setScope(propScope)
    }

    if (propUser) {
      setUser(propUser)
    }
  }, [propScope, propUser])

  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const getList = () => {
    setLoading(true)

    const startDate = dateFilter && dateFilter.startDate ? format(dateFilter.startDate, 'yyyy-MM-dd') : null
    const endDate = dateFilter && dateFilter.endDate ? format(dateFilter.endDate, 'yyyy-MM-dd') : null

    getAssetReportListApi(currentPage, rownum, searchFilter, startDate, endDate).then(res => {
      setTotalPages(res.data.total_pages)
      if (searchFilter) {
        setFileList(
          res.data.results.filter((item) => {
            let formattedItem = item.filename.toLowerCase()
            return formattedItem.indexOf(searchFilter.toLowerCase()) !== -1
          })
        )
      } else {
        setFileList(res.data.results)
      }
      setLoading(false)
    }).catch(err =>{
      console.log(err.response)
    })
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== '' && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleRowChange = (evt) => {
    const rNum = parseInt(evt.target.value)
    setRownum(rNum)
  }

  const handleSearchChange = (q) => {
    setSearchFilter(q)
  }

  const handleDateChange = (data) => {
    setDateFilter(data[0])
  }


  const handleFilter = () => {
    getList()
  }

  const handleReset = () => {
    setDateFilter()
    setSearchFilter()
    setReset(true)
  }

  const deleteAssetReport = (id) => {
    deleteAssetReportApi(id).then(res => {
      if (res.status === 204) {
        toast.success('Successfully deleted asset report!')
        getList()
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to delete asset report.')
    })
  }

  const reportColumns = [
    {
      Header: 'Filename',
      accessor: 'filename'
    },
    {
      Header: 'Upload Date',
      Cell: ({ row }) => (
        <span>{new Date(row.original.created).toLocaleString()}</span>
      )
    },
    {
      Header: 'Report Type',
      accessor: 'export_type'
    },
    {
      Header: '',
      id: 'action',
      Cell: ({ row }) => (<div className='flex'>
        <button
            type="button"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this asset report?')) {
                deleteAssetReport(row.original.pk)
              }
            }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
      )
    }
  ]

  return (
    <div className='divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Imported Files</h3>
        <Filters
          searchChange={handleSearchChange}
          dateChange={handleDateChange}
          handleFilter={handleFilter}
          handleReset={handleReset}
          loading={loading}
        />
      </div>
      <div className='pt-8'>
        <div className='col-md-12'>
          <ListTable
            columns={reportColumns}
            data={fileList}
            getNext={onNext}
            getPrev={onPrevious}
            currPage={currentPage}
            setCurrPage={setCurrentPage}
            hasNext={hasNext}
            pages={totalPages}
            showPagination
          />
        </div>
      </div>
    </div>
  )
}

export default ImportedFiles
