import React, { useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { Form, Col } from 'react-bootstrap'
import { Switch } from '@headlessui/react'
import toast from 'react-hot-toast'

import Icon from 'layouts/components/Icon'
import { transformData } from 'views/Composition/utils'
import ArtistSelectModal from 'layouts/components/ArtistModalSelect/ArtistSelect'
import RenderAlternateTitles from './RenderAlternateTitles'
import { AssignToArtist } from 'layouts/components/ArtistModalSelect/AssignToArtist'
import { editComposition } from 'api/composition'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TitleForm = ({ ownership, initialValues, id, showTitleEditForm, setShowTitleEditForm, setRecord }) => {
  const [selectedArtists, setSelectedArtists] = useState([])
  const [selectedArtistField, setSelectedArtistField] = useState('')
  const [selectArtistModal, setSelectArtistModal] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const relatedArtists = methods.watch('related_artists', '') || []
  const arrangement = methods.watch('is_arrangement')

  const handleEditSubmit = (values) => {
    if (values.related_artists) {
      values.related_artists = values.related_artists.map((artist, idx) => {
        return artist.pk
      })
    }

    editComposition(values, id).then(res => {
      toast.success('Composition saved successfully!')
      setRecord(res.data)
    }).catch(err => {
      console.log(err.response)
      toast.error('Failed to save composition')
    })

    setShowTitleEditForm(false)
  }

  const assignArtist = (artist) => {
    let artists = selectedArtists
    let artistField = selectedArtistField

    if (artists.includes(artist)) {
      artists.splice(artists.indexOf(artist), 1)

      let artistString = ', ' + artist.name

      if (artistField.includes(artistString)) {
        artistField = artistField.replace(artistString, '')
      } else {
        artistField = artistField.replace(artist.name + ', ', '')
      }
    } else {
      artists.push(artist)

      if (artistField.length > 0) {
        artistField = artistField + ', ' + artist.name
      } else {
        artistField = artist.name
      }
    }

    methods.setValue(`artist_field`, artistField)
    methods.setValue(`related_artists`, artists)

    setSelectedArtistField(artistField)
    setSelectedArtists(artists)
  }

  const toggleSelectArtistModal = () => {
    setSelectArtistModal(!selectArtistModal)
  }

  return (<FormProvider {...methods}>
    <div className='space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow'>
      <ArtistSelectModal
        status={selectArtistModal}
        toggleModal={toggleSelectArtistModal}
        assignArtist={assignArtist}
        relatedArtists={relatedArtists}
      />
      <form className='space-y-8' onSubmit={methods.handleSubmit(handleEditSubmit)}>
        <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
          <div className='sm:col-span-3'>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="_title"
                id="_title"
                autoComplete="_title"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...methods.register('_title', { required: true })}
              />
            </div>
          </div>
        </div>
        <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
          <div className="sm:col-span-1">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Arrangement?
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Is this an arrangement?
                </Switch.Description>
              </span>
              <Switch
                checked={arrangement}
                onChange={(e) => methods.setValue('is_arrangement', e)}
                className={classNames(
                  arrangement ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    arrangement ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div className='sm:col-span-12'>
            <RenderAlternateTitles />
          </div>
          {/* <div className='sm:col-span-6'>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Artists
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name='artist_field'
                render={() => (
                  <AssignToArtist
                    className='form-control'
                    value={selectedArtistField}
                    toggleSelectArtistModal={toggleSelectArtistModal}
                  />
                )}
              />
            </div>
          </div> */}
        </div>
        <div className="pt-1">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => { setShowTitleEditForm(!showTitleEditForm)}}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={methods.errors}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </FormProvider>)
}

export default TitleForm