import React, { useState } from 'react'

import './List.scss';

import InfoBar from 'components/InfoBar'
import PublishingListTable from './PublishingListTable'
import Modal from 'react-modal'
import PublishingSearch from './PublishingSearch'
import Icon from 'layouts/components/Icon'
import NewPublishing from './NewPublishing'

import { getPublishingSelectList } from 'api/publishing'

const PublishingSelectModal = ({ status, collectorValue, change, register, control, toggleModal, selectedCollectors, assignPublishing, field, values }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [refreshTable, setRefreshTable] = useState(false)
  const [newPub, setNewPub] = useState(false)
  const [pubType, setPubType] = useState('publisher')

  const onResetSearch = () => {
    getPublishingSelectList({ page: 1, q: ''})
  }

  const doSearch = (searchQ) => {
    if (status) {
      setSearchQ(searchQ)
      setCurrPage(1)
      getPublishingSelectList({ page: 1, q: searchQ })
      setRefreshTable(!refreshTable)
    }
  }

  const toggleNewPub = (modelType) => {
    setNewPub(!newPub)
    setPubType(modelType)
  }

  const saveNewPub = (pubName) => {
    setNewPub(!newPub);
    change(`collectors.create`, 'create')
    change(`collector_field`, pubName)
    toggleModal()
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  }

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
        onClick={() => toggleModal()}>
        Continue
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={'Showing search result for ' + "'" + searchQ + "'"}
          onClose={onResetSearch}
        />
      )}

      <div className="page">
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Publisher/Writer</span><br />
            <span className='form-help'>Select multiple Publishers or Writers and click continue to close this window</span>
          </div>

          <PublishingSearch doSearch={doSearch} />
        </div>

        {collectorValue !== undefined && Array.isArray(collectorValue)
          ? (<div>{collectorValue.map((pub, idx) => {
            return (
              <span onClick={() => assignPublishing(pub)} key={idx} className='mr-1 text-white text-sm cursor-pointer rounded-md p-1 bg-red-600'>{pub.pub_name}</span>
            )
          })}
          </div>)
          : null
        }
        <br />

        {newPub
          ? <NewPublishing
            pubType={pubType}
            toggleNewPub={toggleNewPub}
            saveNewPub={saveNewPub}
            collectorValue={collectorValue}
            register={register}
            control={control}
            change={change} />
          : <div>
            <div className='float-right'>
              <button className='btn btn-xs btn-hover btn-hover-primary' type='button'
                onClick={() => toggleNewPub('publisher')}>
                <Icon name='add' spaceAfter />Add Publisher
              </button>&nbsp;&nbsp;
              <button className='btn btn-xs btn-hover btn-hover-primary' type='button'
                onClick={() => toggleNewPub('writer')}>
                <Icon name='add' spaceAfter />Add Writer
              </button>
            </div>
            <br /><br />
            <div className='page-body no-padding'>
              <PublishingListTable
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                refreshTable={refreshTable}
                collectorValue={collectorValue}
                selectedCollectors={selectedCollectors}
                assignPublishing={assignPublishing}
              />
            </div>
          </div>
        }
      </div>

      {!newPub
        ? <button type='button'
          className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
          onClick={() => toggleModal()}>
          Continue
        </button>
        : null
      }
    </ Modal>
  )
}

export default PublishingSelectModal
