import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import PermissionForm from '../../components/PermissionForm'
import './components/Detail.scss'

import { fetch } from 'api/users'

const DetailView = () => {
  const { id } = useParams()

  const [record, setRecord] = useState(null)

  useEffect(() => {
    fetch(id).then((res) => {
      if (res.status === 200) {
        setRecord(res.data)
      } else {
        toast.error('Unable to fetch user')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch user')
    })
  }, [])

  if (record === undefined || record === null) {
    return null
  }

  const publishers = record.publishers
  let name = record.first_name || record.last_name ? record.first_name + ' ' + record.last_name : record.email

  return (
    <>
      <div className="flex flex-wrap flex-1 min-h-full">
        <div className="p-6 flex-1">
          {/* Page header */}
          <div className="px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="https://picsum.photos/150/?blur"
                    alt="Avatar"
                  />
                  <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {record.first_name || record.last_name
                    ? <React.Fragment>
                        <span>{record.first_name + ' ' + record.last_name} ({record.email})</span>
                      </React.Fragment>
                    : <span>{record.email}</span>
                  }
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Last login:{' '}
                  <span className='text-gray-900'>
                    {record.last_login
                    ? <Moment date={record.last_login} />
                    : 'N/A'}
                  </span>
                </p>
              </div>
            </div>
            {/* <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Disqualify
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Disable Account
              </button>
            </div> */}
          </div>

          <div className='flex'>
            <div className='flex-1 px-3'>
              <PermissionForm record={record} />
            </div>
            <div className='flex-1 px-3'>
              <div className='bg-white mt-5 p-5 space-y-8 rounded-md shadow'>
                <div className='divide-y divide-gray-200 space-y-8 sm:space-y-5'>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Associated Publishers</h3>
                  </div>
                  <div className="pt-5 grid grid-cols-1 gap-4 sm:grid-cols-1">
                    {publishers?.map((pub, idx) => (
                      <div
                        key={idx}
                        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                      >
                        <div className="flex-shrink-0">
                          <img className="h-10 w-10 rounded-full" src='https://picsum.photos/150/?blur' alt="Pub Avatar" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-sm font-medium text-gray-900">{pub.name}</p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailView
