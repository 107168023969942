import React, { useState, useEffect } from 'react'
import { RadioGroup, Switch } from '@headlessui/react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'
import { CheckCircleIcon } from '@heroicons/react/solid'

import InfoBar from 'components/InfoBar'
import ProgressBar from 'components/ProgressBar'
import FileInput from './FileInput'
import { transformData } from 'views/Upload/utils'

import { getPresignedUrl, notifyUploadComplete } from 'api/ddex'

const deliverToOptions = [
  { id: 'expdata', title: 'Exploration Data', description: 'Deliver to Exploration Data only.' },
  { id: 'ddex', title: 'YouTube via DDEX', description: 'Deliver to YouTube in DDEX format only.' },
  { id: 'both', title: 'Both', description: 'Deliver to both Exploration Data and YouTube.' }
]

const deliveryTypeOptions = [
  { id: 'single', title: 'Single' },
  { id: 'album', title: 'Album' },
  { id: 'update', title: 'Update' },
  { id: 'takedown', title: 'Takedown' }
]

const UploadForm = ({ datafeed }) => {
  const [saving, setSaving] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const [saveFile, setSaveFile] = useState(null); // File state
  const [deliverTo, setDeliverTo] = useState(deliverToOptions[0]); // Radio group for delivery
  const [contentType, setContentType] = useState([]); // Checkboxes
  const [deliveryType, setDeliveryType] = useState('single'); // Delivery type
  const [test, setTest] = useState(false) // Is this a test delivery?

  const history = useHistory()

  let initialValues = {}

  useEffect(() => {
    if (datafeed) {
      let datafeedCopy = Object.assign({}, datafeed)
      initialValues = transformData(datafeedCopy)
    }
    reset(initialValues)
  }, [datafeed])

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })


  async function uploadFile(formProps) {
    setSaving(true);
    // Prepare form values
    const formValues = {
      ...formProps,
      filename: saveFile.name,
      file_type: saveFile.type,
      is_test: test,
    };

    // Append radio values
    formValues['deliver_to'] = deliverTo?.id;

    // Append checkboxes for content type
    var contentTypeValues = []
    if (contentType.includes('soundRecording')) {
      contentTypeValues.push('sound_recording');
    }
    if (contentType.includes('artTrack')) {
      contentTypeValues.push('art_track');
    }
    formValues['content_type'] = contentTypeValues;

    // Append radio for delivery type
    formValues['delivery_type'] = deliveryType;

    try {
      // Step 1: Get the presigned URL
      const presignedUrlResponse = await getPresignedUrl(formValues);
      if (presignedUrlResponse.status !== 200) {
        throw new Error('Failed to get presigned URL');
      }

      const { presigned_post, feed_id } = presignedUrlResponse.data;

      // Step 2: Upload to S3 with Progress Tracking
      const formData = new FormData();
      Object.entries(presigned_post.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', saveFile);

      await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', presigned_post.url, true);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = Math.round((event.loaded / event.total) * 100);
            setPercentage(percentComplete);
          }
        };

        xhr.onload = function() {
          if (xhr.status === 204) { // 204 indicates a successful S3 upload
            resolve(); // Upload complete, resolve the promise
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };

        xhr.onerror = function() {
          reject(new Error('Upload failed due to a network error'));
        };

        xhr.send(formData);
      });

      // Step 3: Notify backend of completion
      const notifyResponse = await notifyUploadComplete({ feed_id });
      if (notifyResponse.status !== 200) {
        throw new Error('Failed to notify backend of upload completion');
      }

      setPercentage(100);
      history.push('/ddex/upload/' + notifyResponse.data.pk);

    } catch (err) {
      console.error(err.response);
      setPercentage(100);
      setSaving(false);
      toast.error('An error occurred in processing your file.');
    }
  }

  const cleanForm = () => {
    setSaveFile(null)
    reset({
      'file': null,
      'deliver_to': '',
      'content_type': '',
      'delivery_type': '',
      'is_test': false
    })
  }

  return (<>
    {datafeed && <InfoBar
      text={`Editing information for ingest file: ${datafeed.filename}`}
      onClose={cleanForm}
      />}
    <Form className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6" onSubmit={handleSubmit(uploadFile)}>
      <div className="space-y-8 pb-5 divide-y divide-gray-200">
        <div>
          <div className="my-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              {saving
                ? (<ProgressBar progressText='Uploading your file...' percentage={percentage} />)
                : (<Controller
                  control={control}
                  name={`file`}
                  render={() => (
                    <FileInput file={saveFile} setFile={setSaveFile} />
                  )}
                />)
              }
            </div>
          </div>
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Deliver to</legend>
            <RadioGroup
              value={deliverTo}
              onChange={setDeliverTo}
              className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
            >
              {deliverToOptions.map((deliveryOption) => (
                <RadioGroup.Option
                  key={deliveryOption.id}
                  value={deliveryOption}
                  className={({ active, checked }) =>
                    `group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none 
                    ${checked ? 'border-red-600 ring-2 ring-red-600' : ''}
                    ${active ? 'border-red-600' : ''}`
                  }
                >
                  {({ checked }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <span className="block text-sm font-medium text-gray-900">{deliveryOption.title}</span>
                          <span className="mt-1 flex items-center text-sm text-gray-500">{deliveryOption.description}</span>
                        </span>
                      </span>
                      {checked && (
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-red-600"
                        />
                      )}
                      <span
                        aria-hidden="true"
                        className={`pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent 
                        ${checked ? 'border-red-600' : ''}`}
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </fieldset>

          <fieldset className='mt-5'>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Content Type</legend>
            <div className="mt-3 space-y-5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="soundRecording"
                    name="soundRecording"
                    type="checkbox"
                    aria-describedby="sound-recording-description"
                    onChange={(e) => setContentType(e.target.checked ? [...contentType, 'soundRecording'] : contentType.filter(c => c !== 'soundRecording'))}
                    className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="soundRecording" className="font-medium text-gray-900">
                    Sound Recording
                  </label>{': '}
                  <span id="sound-recording-description" className="text-gray-500">
                    <span className="sr-only">Sound Recording: </span>deliver content for YouTube SR.
                  </span>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="artTrack"
                    name="artTrack"
                    type="checkbox"
                    aria-describedby="art-track-description"
                    onChange={(e) => setContentType(e.target.checked ? [...contentType, 'artTrack'] : contentType.filter(c => c !== 'artTrack'))}
                    className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="artTrack" className="font-medium text-gray-900">
                    Art Track
                  </label>{': '}
                  <span id="art-track-description" className="text-gray-500">
                    <span className="sr-only">Art Track: </span>deliver content for YouTube AT.
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
          
          <fieldset className='mt-5'>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Delivery Type</legend>
            <div className="mt-3 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {deliveryTypeOptions.map((deliveryType) => (
                <div key={deliveryType.id} className="flex items-center">
                  <input
                    id={deliveryType.id}
                    name="delivery-type"
                    type="radio"
                    defaultChecked={deliveryType.id === 'single'}
                    onChange={(e) => setDeliveryType(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                  />
                  <label htmlFor={deliveryType.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {deliveryType.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>

          <Switch.Group as="div" className="mt-5 flex items-center justify-between">
            <span className="flex flex-grow flex-col">
              <Switch.Label as="span" className="text-sm/6 font-medium text-gray-900">
                Is this a test delivery to YouTube?
              </Switch.Label>
              <Switch.Description as="span" className="text-sm text-gray-500">
                Delivery will be sent to YouTube for verification but assets and resources will not be ingested in the system.
              </Switch.Description>
            </span>
            <Switch
              checked={test}
              onChange={setTest}
              className={`group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 data-[checked]:bg-red-600 ${
                test ? "bg-red-600" : "bg-gray-200"
              }`}
            >
              <span
                aria-hidden="true"
                className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                  test ? "translate-x-5" : "translate-x-0"
                }`}
              />
            </Switch>
          </Switch.Group>
        </div>
      </div>

      {!saving && (<div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => cleanForm()}
              disabled={saving}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>)
      }
    </Form>
  </>)
}

export default (UploadForm)
