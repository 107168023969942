import React from 'react'
import { DownloadIcon } from '@heroicons/react/outline'

const RecordingTable = ({ setToggleExport, toggleExport, recordings, selected, selectAll, toggleRow, toggleSelectAll }) => {
  return (<>
    <div className='tab-actions _right'>
      <button type='button' onClick={() => setToggleExport(!toggleExport)} className='ml-3 inline-flex items-center px-4 py-2 border border-transparent
        shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
        Export <DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
      </button>
    </div>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    IDs
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Record Label
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {recordings.map((item, recordingIdx) => (
                  <tr key={item.external_id} className={recordingIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-3 py-4 text-sm font-medium">
                      <a className="hover:text-blue-500" href={'/recordings/' + item.external_id + '/'}>{item.external_id}</a>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <a className="hover:text-blue-500" href={'/recordings/' + item.external_id + '/'}>{item.title}</a>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <span>ISRC:&nbsp;{item.isrcs}</span><br />
                      <span>YT SR ID:&nbsp;{item.yt_sr_ids}</span>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.new_record_label &&
                        (<a href={'/record-labels/' + item.new_record_label.external_id + '/'}>
                          {item.new_record_label.name}
                        </a>)
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RecordingTable