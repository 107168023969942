import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import AdminForm from './components/AdminForm'
import toast from 'react-hot-toast'

import { AuthContext } from 'stores/auth'
import { fetch as fetchComposition } from 'api/composition'

const AdminFormView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const authStore = useContext(AuthContext)

  const [composition, setComposition] = useState()
  const [record, setRecord] = useState()

  useEffect(() => {
    if (query.composition) {
      fetchComposition(query.composition).then(res => {
        if (res.status === 200) {
          setComposition(res.data)
        } else {
          toast.error('Unable to fetch composition')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch composition')
      })
    }
  }, [query.composition])

  if (authStore.user === undefined) {
    return null
  }

  return (<>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          <AdminForm
            record={record}
            composition={composition}
            user={authStore.user}
          />
        </div>
      </div>
    </>
  )
}

export default AdminFormView;
