import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'

import './components/Conflict.scss'
import InfoBar from 'components/InfoBar'
import Heading from './components/Heading'

import { fetchConflictFeed } from 'api/conflict'
import ListTable from './components/ListTable'

const DetailView = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [filename, setFilename] = useState()
  const [filteredConflicts, setFilteredConflicts] = useState()
  const [totalPages, setTotalPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [rownum, setRowNum] = useState(10)

  const hasNext = currPage < totalPages

  const getList = (page, rNum, filter) => {
    setSearchQ(filter)
    fetchConflictFeed(id, page, rNum, filter).then(res => {
      if (res.status === 200) {
        setFilteredConflicts(res.data.results)
        setTotalPages(res.data.total_pages)
        setFilename(res.data.filename)
      } else {
        toast.error('Unable to fetch conflicts')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch conflicts')
    })
  }

  useEffect(() => {
    if (query.q) {
      setSearchQ(query.q)
      getList(currPage, rownum, query.q)
    } else {
      getList(1, rownum, '')
    }

  }, [location.search])

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
    }

  const _doSearch = (searchValue) => {
    let query = {
      page: 1,
      q: searchValue
    }
  
    if (searchValue === undefined || searchValue === '') {
      query = { page: 1 }
    }
    history.push({
      pathname: `/conflict-resolution/${id}`,
      search: `?${objectToQueryString(query)}`
    })
  }

  if (filteredConflicts === undefined || filteredConflicts === null) {
    return null
  }

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {!searchFirst &&
          (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => history.push(`/conflict-resolution/${id}`)}
            showClose
          />)
        }
        <Heading
          filename={filename}
          searchFirst={searchFirst}
          handleSearch={_doSearch}
        />
        <div className='py-4'>
          <ListTable
            currentPage={currPage}
            searchQ={searchQ}
            refreshTable={refreshTable}
            sort={query.sort}
            conflictList={filteredConflicts}
            pages={totalPages}
            getList={getList}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailView
