import React from 'react'
import { DownloadIcon } from '@heroicons/react/solid'

export default function Heading ({ feedName, feedLink }) {

  return (<>
    <div>
      <div className="mb-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{feedName}</h2>
        </div>
        <div className="py-3 flex justify-end">
          <a 
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none cursor-pointer"
              href={feedLink}
              target='_blank'
            >
            Download Output<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  </>)
}
