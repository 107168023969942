import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import ListTable from 'components/ListTable'
import ReactTable from 'layouts/components/ReactTable'
import toast from 'react-hot-toast'

import '../styles/DuplicateForm.scss'
import { adminCreate } from 'api/share'

const DuplicateConfirm = ({ originalFormValues, editingFormValues, dupes }) => {
  const history = useHistory()

  const formatData = (values) => {
    values['bypass_duplicate'] = true
    adminCreate(values).then(res => {
      if (res.status === 200 || res.status === 201) {
        history.push('/shares/' + res.data.external_id + '/')
        toast.success('Successfully create share!')
      } else {
        toast.error('Unable to create share')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create share')
    })
  }

  const handleCancel = (editingFormValues) => {
    localStorage.setItem('review', JSON.stringify(editingFormValues))
    history.goBack()
  }

  if (dupes === undefined) {
    return null
  }

  const loading = dupes === undefined

  let shareColumns = [{
    Header: `ID`,
    id: 'pk',
    maxWidth: 100,
    Cell: ({ row }) => (
      <a className='table-main-link' href={'/compositions/' + row.original.external_id + '/'}>{row.original.external_id}</a>
    )
  }, {
    Header: `Title`,
    id: '_title',
    Cell: ({ row }) => (
      <a className='table-main-link' href={'/compositions/' + row.original.external_id + '/'}>{row.original._title}</a>
    )
  }, {
    Header: `Writer`,
    id: 'writers',
    Cell: ({ row }) => (
      <div>{row.original.writers.map((writer, idx) => {
        return (
          <a className='table-writer' key={writer['pk']} href={'/writers/' + writer['external_id'] + '/'}>{writer['name']}</a>
        )
      })}</div>
    )
  }, {
    Header: `Collector`,
    id: 'publishers',
    Cell: ({ row }) => (
      <div>{row.original.publishers.map((publisher, idx) => {
        return (
          <a className='table-collector' key={publisher['pk']} href={`/${publisher.set_type}s/` + `${publisher['external_id']}`}>{publisher['name']}</a>
        )
      })}</div>
    )
  }]

  return (
    <div className='share-form' id='share-form-wrap'>
      <ListTable
        columns={shareColumns}
        data={dupes}
      />
      <div className="pt-5">
        <div className="flex justify-end">

          <button
            type="button"
            onClick={() => handleCancel(editingFormValues)}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => formatData(originalFormValues)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save Anyway
          </button>
        </div>
      </div>
    </div>
  )
}

export default DuplicateConfirm
