import React, { useEffect, useState } from 'react'
import Paginator from 'components/Paginator'

import './ListTable.scss'

const ListTable = ({ currentPage, searchQ, refreshTable, sort, propSelected, propSelectAll, toggleRow,
  toggleSelectAll, recordingList, pages, getList }) => {

  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)
  const [rownum, setRownum] = useState(20)

  useEffect(() => {
    setLoading(true)
    if (searchQ) {
      getList(currPage, searchQ)
    }
  }, [refreshTable])

  const _onNext = (page) => {
    setLoading(true)
    getList(parseInt(page) + 1, searchQ)
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(parseInt(page) - 1, searchQ)
      setCurrPage(parseInt(page) - 1)
    }
  }

  if (recordingList === undefined || recordingList === null) {
    return null
  }

  const hasNext = currPage < pages

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {recordingList.map((recording) => {

          return (<li key={recording.external_id}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div>
                  <div className='pretty p-default p-curve'>
                    <input
                      type='checkbox'
                      checked={propSelected[recording.pk] === true}
                      onChange={() => toggleRow(recording.pk)}
                    />
                    <div className='state'>
                      <label />
                    </div>
                  </div>
                  <a href={`/recordings/` + `${recording.external_id}/`} className="text-lg font-medium hover:text-blue-500 truncate">{recording.title}</a>
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {recording.external_id}
                  </p>
                </div>
              </div>
              <div className="mt-2 md:grid md:grid-cols-3">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    <div className='table-asset-codes'>
                      <span>Artists: </span><br />
                      {recording.related_artists?.map((artist, idx) => {
                        return (
                          <a className="hover:text-blue-500" key={idx} href={`/artists/` + `${artist['external_id']}`}>
                            {artist['name']}<br />
                          </a>
                        )
                      })}
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <div className='table-asset-codes'>
                      <span><small>ISRC: </small>{recording.isrcs ? recording.isrcs : '---'}</span><br />
                      <span><small>YT SR ID: </small>{recording.yt_sr_ids ? recording.yt_sr_ids : '---'}</span>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </li>)
        })}
      </ul>

      <div className="">
        <Paginator
          getNext={_onNext}
          getPrev={_onPrevious}
          currPage={currPage}
          setCurrPage={setCurrPage}
          hasNext={hasNext}
          totalPages={pages}
        />
      </div>
    </div>
  )
}

export default ListTable
