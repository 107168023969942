import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import ClaimDetail from './components/ClaimDetail'

import { fetch as fetchClaim } from 'api/claim'

const DetailView = () => {
  const { id } = useParams()

  const [claimFile, setClaimFile] = useState()

  const getClaim = () => {
    fetchClaim(id).then(res => {
      if (res.status === 200) {
        setClaimFile(res.data)
      } else {
        toast.error('Unable to fetch claim submission')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch claim submission')
    })
  }

  useEffect(() => {
    if (claimFile === undefined || claimFile.pk !== id) {
      getClaim(id)
    }
  })

  if (!claimFile) {
    return null
  }

  return (<>
    <ClaimDetail claimFile={claimFile} getClaim={getClaim} />
  </>)
}

export default DetailView
