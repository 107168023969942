import React from 'react'

import ListSearch from 'components/ListSearch'

export default function Heading (props) {
  const { searchFirst, handleSearch } = props
  return (<>
    <div>
      <div className="mb-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Users</h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <div>
            {searchFirst &&
              (<ListSearch
                filterList={handleSearch}
                placeholder='Find a user'
              />)
            }
          </div>
        </div>
      </div>

    </div>
  </>)
}
