export const transformData = (payload) => {
  if (payload['pro_affiliation'] && payload['pro_affiliation'].value === undefined) {
    let proAffiliation = { value: payload['pro_affiliation'], label: payload['pro_affiliation'] }
    payload['pro_affiliation'] = proAffiliation
  }

  let pubField = ''
  if (payload['pub_affiliations']) {
    payload['pub_affiliations'].map((val, idx) => (
      (pubField.length > 0) ? (
        pubField = pubField + ', ' + val.name ? val.name : val.pub_name
      ) : (
        pubField = val.name ? val.name : val.pub_name
      )
    ))

    let pubAffiliations = []
    payload['pub_affiliations'].map((val, idx) => {
      pubAffiliations.push({
        id: val.pk,
        ipi_number: val.ipi_number,
        pk: val.pk,
        pro_affiliation: val.pro_affiliation,
        pub_name: val.name ? val.name : val.pub_name
      })
    })
    payload['pub_affiliations'] = pubAffiliations
  }

  payload['publisher_field'] = pubField
  return payload
}
