import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import { fetch } from 'api/recordings'
import Detail from './components/Detail'

const DetailView = () => {
  const { id } = useParams()

  const [record, setRecord] = useState()

  useEffect(() => {
    if (record === undefined || record.pk !== id) {
      fetch(id).then(res => {
        if (res.status === 200) {
          setRecord(res.data)
        } else {
          toast.error('Unable to fetch recording')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch recording')
      })
    }
  }, [])

  if (record === undefined) {
    return null
  }

  return (<div>
    <Detail propsRecord={record} />
  </div>)
}

export default DetailView
