import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { AuthContext } from 'stores/auth'
import ListSearch from 'components/ListSearch'
import { PlusCircleIcon, CloudUploadIcon } from '@heroicons/react/outline'

export default function Heading (props) {
  const { searchFirst, handleSearch } = props
  const history = useHistory()
  const authStore = useContext(AuthContext)

  let user = authStore.user
  let scope  = user?.profile?.scope || authStore.scope

  return (<>
    <div>
      <div className="mb-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Conflicts</h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <div>
            {searchFirst &&
              (<ListSearch
                filterList={handleSearch}
                placeholder='Find a title or client'
              />)
            }
          </div>
          {scope.indexOf('exp-conflict') >= 0
            && (<>
              <Link to='/conflict-resolution/add/'>
                <button
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Add<PlusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </Link>
              <Link to='/conflict-resolution/'>
              <button
                type="button"
                className="ml-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Upload<CloudUploadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            </Link>
            </>)
          }
        </div>
      </div>
    </div>
  </>)
}
