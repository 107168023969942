import React, { useState } from 'react'
import './List.scss'

import InfoBar from 'components/InfoBar'
import Icon from 'layouts/components/Icon'
import WriterOwnerListTable from './WriterOwnerListTable'
import Modal from 'react-modal'
import WriterOwnerSearch from './WriterOwnerSearch'
import NewWriterOwner from './NewWriterOwner'

import { getWriterSelectList } from 'api/writers'

const WriterOwnerSelectModal = ({ status, ownerValue, pub, index, change, toggleModal, selectedWriters, assignWriter }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [refreshTable, setRefreshTable] = useState(false)
  const [newWriter, setNewWriter] = useState(false)

  const onResetSearch = () => {
    getWriterSelectList({ page: 1, q: ''})
  }

  const _doSearch = (searchQ) => {
    if (status) {
      setSearchQ(searchQ)
      setCurrPage(1)
      getWriterSelectList({ page: 1, q: searchQ })
      setRefreshTable(!refreshTable)
    }
  }

  const toggleNewWriter = () => {
    setNewWriter(!newWriter)
  }

  const saveNewWriter = (writerName) => {
    setNewWriter(!newWriter)
    change(`${pub}.create`, 'create')
    change(`${pub}.owner_field`, writerName)
    toggleModal()
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  }
  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
        onClick={() => toggleModal()}>
        Continue
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={'Showing search result for ' + "'" + searchQ + "'"}
          onClose={onResetSearch}
        />
      )}

      <div className="page">
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Writer</span><br />
            <span className='form-help'>Select multiple Writers and click continue to close this window</span>
          </div>

          <WriterOwnerSearch doSearch={_doSearch} />
        </div>

        {ownerValue !== undefined
          ? (<div>{ownerValue.map((writer, idx) => {
            return (
              <span key={idx} className='mr-1 text-white text-sm cursor-pointer rounded-md p-1 bg-red-600'>{writer.pub_name}</span>
            )
          })}
          </div>)
          : null
        }

        <br />

        {newWriter
          ? <NewWriterOwner
            toggleNewWriter={toggleNewWriter}
            saveNewWriter={saveNewWriter}
            pub={pub}
            ownerValue={ownerValue}
            index={index} />
          : <div>
            <div className='float-right'>
              <button className='btn btn-xs btn-hover btn-hover-primary' type='button'
                onClick={() => toggleNewWriter()}>
                <Icon name='add' spaceAfter />Add Writer
              </button>
            </div>
            <br /><br />
            <div className='page-body no-padding'>
              <WriterOwnerListTable
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                refreshTable={refreshTable}
                selectedWriters={selectedWriters}
                ownerValue={ownerValue}
                assignWriter={assignWriter}
              />
            </div>
          </div>
        }
      </div>
      {!newWriter
        ? <button type='button'
          className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
          onClick={() => toggleModal()}>
          Continue
        </button>
        : null
      }
    </ Modal>
  )
}

export default WriterOwnerSelectModal
