import React from 'react'
import { Route, Switch } from 'react-router'
import BulkDeleteView from './routes/BulkDelete'
import UndoDeleteView from './routes/Undo'

export default function DeleteViews () {
  return (
    <Switch>
      <Route exact path='/delete' component={BulkDeleteView} />
      <Route path='/delete/undo' component={UndoDeleteView} />
    </Switch>
  )
}
