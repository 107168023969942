import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import RenderAlternateTitles from 'views/Share/components/GeneralInfo/RenderAlternateTitles'
import { AssignToComposition } from 'layouts/components/CompositionModalSelect/AssignToComposition'
import CompositionSelectModal from 'layouts/components/CompositionModalSelect/CompositionSelectModal'
import ExplorationControl from 'components/ExplorationControl'

const TitleForm = ({ initialValues, showTitleEditForm, setShowTitleEditForm }) => {
  const [selectCompositionModal, setSelectCompositionModal] = useState(false)
  const [selectedCompositionField, setSelectedCompositionField] = useState(initialValues.composition_field)
  const [selectedComposition, setSelectedComposition] = useState(null)

  const { register, errors, control, setValue, watch } = useFormContext()

  var controlled = watch('controlled')

  const assignComposition = (composition) => {
    if (composition) {
      setValue('composition_field', composition._title)
      setValue('composition', composition.pk)
      setSelectedComposition(composition)
      setSelectedCompositionField(composition._title)
      setSelectCompositionModal(false)
    } else {
      setValue('composition_field', ' ')
      setValue('composition', null)
      setSelectedComposition(null)
      setSelectedCompositionField(' ')
      setSelectCompositionModal(false)
    }
  }

  const toggleSelectCompositionModal = () => {
    setSelectCompositionModal(!selectCompositionModal)
  }

  return (<div className='space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow'>
    <CompositionSelectModal
      status={selectCompositionModal}
      toggleModal={toggleSelectCompositionModal}
      assignComposition={assignComposition}
    />
    <div className='space-y-8'>
      <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12'>
        <div className='sm:col-span-8'>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="title"
              id="title"
              autoComplete="title"
              className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
              {...register('title', { required: true })}
            />
          </div>
        </div>
        <div className='sm:col-span-12'>
          <RenderAlternateTitles />
        </div>
        <div className='sm:col-span-12'>
          <label htmlFor="composition" className="block text-sm font-medium text-gray-700">
            Related Composition
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              name='composition_field'
              render={() => (
                <AssignToComposition
                  className='form-control'
                  value={selectedCompositionField}
                  toggleSelectCompositionModal={toggleSelectCompositionModal}
                />
              )}
            />
          </div>
        </div>
      </div>
      <ExplorationControl
        id={'sharePlatforms'}
        initialValues={initialValues}
        controlled={controlled}
        type={'share'} />

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowTitleEditForm(!showTitleEditForm) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>)
}

export default TitleForm
