import axios from 'axios'

const buildUrl = (url) => {
  let final = process.env.REACT_APP_API_ROOT
  if (!url.startsWith('/')) {
    final += '/'
  }
  final += url
  return final
}

const _getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

const getFormData = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

export const _getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

const PrivateAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url),
      headers: {
        'Authorization': 'JWT ' + _getAccessToken()
      },
      params: query
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'JWT ' + _getAccessToken()
      }
    })
  },

  postfile: function (url, payload) {
    const formData = getFormData(payload)
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: formData,
      headers: {
        'Authorization': 'JWT ' + _getAccessToken()
      }
    })
  },

  put: (url, payload) => {
    return axios({
      method: 'put',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'JWT ' + _getAccessToken()
      }
    })
  },

  delete: (url, payload) => {
    return axios({
      method: 'delete',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'JWT ' + _getAccessToken()
      }
    })
  }
}

export default PrivateAPI
