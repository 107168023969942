import React, { useState, useContext } from "react"
import { useLocation } from "react-router-dom"
import { ViewListIcon, SearchIcon, UploadIcon } from "@heroicons/react/outline"
import queryString from "query-string"

import ImportedFiles from "./components/ImportedFiles"
import "./components/Form.scss"
import UploadForm from "./components/UploadForm"
import { AuthContext } from "stores/auth"

const AugmentorUploadView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [datafeed, setDatafeed] = useState(null)
  const [viewImportedFiles, setViewImportedFiles] = useState(
    Boolean(query.show)
  )
  const authStore = useContext(AuthContext)

  let user = authStore.user
  let scope = user?.profile?.scope || authStore.scope

  if (!scope || !user) {
    return null
  }

  return (
    <>
      <div className="flex flex-1 min-h-full" id="augmentor">
        <div className="p-6 flex-1">
          <div className="mx-auto max-w-2xl mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                DDEX
              </h2>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <button
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                onClick={() => {
                  setViewImportedFiles(!viewImportedFiles)
                }}
              >
                {viewImportedFiles ? (
                  <>
                    Hide Uploads
                    <ViewListIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </>
                ) : (
                  <>
                    View Uploads
                    <ViewListIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-2xl">
            <UploadForm datafeed={datafeed} />
          </div>
          <div className="mt-6">
            {viewImportedFiles && (
              <ImportedFiles propScope={scope} propUser={user} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AugmentorUploadView
