/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { TrashIcon } from '@heroicons/react/outline'

import { deleteClaimSheet as deleteClaimSheetApi } from 'api/claim'
import ManualClaims from './ManualClaims'
import PotentialClaims from './PotentialClaims'
import DisputedClaims from './DisputedClaims'

export default function ClaimSheet ({ item, getClaim, setCurrNav, firstSheet, secondSheet }) {
    const [sheetNav, setSheetNav] = useState('Manual Claims')
    const navigation = ['Manual Claims', 'Potential Claims', 'Disputed Claims']

    const deleteClaimSheet = (id) => {
      deleteClaimSheetApi(id).then(res => {
        if (res.status === 204) {
          toast.success('Successfully deleted sheet!')
          getClaim()
          if (item.sheet_name === firstSheet.sheet_name) {
            setCurrNav(secondSheet.sheet_name)
          } else {
            setCurrNav(firstSheet.sheet_name)
          }
        }
      }).catch(err => {
        console.log(err.response)
        toast.error('Unable to delete sheet.')
      })
    }

    return (
        <div>
            <div className="py-6 sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 mb-3 truncate">{item.sheet_name}</h2>
                </div>
              </div>
              <div className="mt-5 flex justify-center sm:mt-0">
                <button
                  type='button'
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this sheet?')) {
                      deleteClaimSheet(item.pk)
                    }
                  }}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                  Delete Sheet
                  <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </div>
            </div>
            <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
              <div className="ml-3">
                {navigation.map((item) => (
                  <button
                    type='button'
                    key={item}
                    className={item === sheetNav 
                      ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                      : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                    }
                    onClick={() => setSheetNav(item)}
                    aria-current={item === sheetNav ? 'page' : undefined}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </nav>
            <hr />
            <div className='mt-5 bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='nav-tabContent'>
              {sheetNav === 'Disputed Claims'
                ? (<DisputedClaims item={item} />)
                : sheetNav === 'Potential Claims'
                ? (<PotentialClaims item={item} />)
                : (<ManualClaims item={item} getClaim={getClaim} />)
              }
            </div>
        </div>
    )
}
