import React from 'react'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'

import ActionFeed from 'components/ActionFeed'
import NoteBox from 'components/NoteBox'
import CodeCopy from 'components/CodeCopy'

const GeneralInfoTable = ({ record, _toggleEdit }) => {
  return (
    <>
      <div className='mb-5 px-4 py-4 table-description'>
        <div className='flex'>
          <div className='flex-1'>
            <div className="mb-5">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg mx-2 my-2">
                <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
                  <div className="px-4 py-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Writer Information</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Related codes and other information.
                    </p>
                  </div>
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={() => { _toggleEdit('writer') }}
                    >
                      Edit<PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">IPI Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <CodeCopy copyText={record.ipi_number} />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Pro Affiliation</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <CodeCopy copyText={record.pro_affiliation} />
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">ISNI</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <CodeCopy copyText={record.isni} /> 
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>     
            </div>
            <br />
            <>
              <div className="px-4 py-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Activities</h3>
              </div>
              <ActionFeed
                model={'writer'}
                pk={record.external_id} />
            </>
          </div>
          <div className='flex-1'>
            <div className="px-4 py-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
            </div>
            <NoteBox model={'writer'} pk={record.external_id} />
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralInfoTable
