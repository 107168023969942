import React, { useState } from 'react'
import toast from 'react-hot-toast'

import './List.scss';

import InfoBar from 'layouts/CoreLayout/components/InfoBar'
import Modal from 'react-modal'
import LabelSearch from './LabelSearch'

import { getList } from 'api/recordLabel'
import LabelListTable from './LabelListTable';
import LabelForm from './LabelForm';

const LabelSelectModal = ({ status, toggleModal, assignLabel }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()
  const [showForm, setShowForm] = useState(false)
  const [customStyles, setCustomStyles] = useState({
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  })

  const resetSearch = () => {
    getList({ page: 1, q: '' }).then(res => {
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch record labels')
      }
    })
    setSearchQ('')
  }

  const doSearch = (sQ) => {
    if (status) {
      setSearchQ(sQ)
      setCurrPage(1)
      getList({ page: 1, q: sQ }).then(res => {
        if (res.status === 200) {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        } else {
          toast.error('Unable to fetch record labels')
        }
      })
    }
  }

  const triggerForm = () => {
    setCustomStyles({
      content : {
        width                 : '60%',
        top                   : '53%',
        left                  : '58%',
        right                 : 'auto',
        bottom                : '-43%',
        marginRight           : '-20%',
        transform             : 'translate(-50%, -50%)',
        overflowY             : 'auto',
        maxHeight             : '40vh'
      }
    })
    setShowForm(true)
  }

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      {!showForm && (<div className="mt-5 mb-5 flex justify-end sm:mt-0">
          <button
            type='button'
            onClick={() => assignLabel(null)}
            className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
            Clear Record Label
          </button>
        </div>)
      }

      {searchQ === '' ? null : (
        <InfoBar
          text={`Showing search result for '` + searchQ + "'"}
          onClose={() => resetSearch()}
        />
      )}

      <div className='page'>
        {!showForm && (<div className='page-header'>
            <div className='page-title'>
              <span className='main'>Select Record Label</span>
            </div>
            <LabelSearch sQ={searchQ} doSearch={doSearch} />
          </div>)
        }
        <div className='page-body no-padding'>
          {!showForm && (<div className="mt-5 flex justify-end sm:mt-0">
              <button
                type='button'
                onClick={() => triggerForm()}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Create New
              </button>
            </div>)
          }
          {showForm
            ? (<LabelForm
                setShowForm={setShowForm}
                setCustomStyles={setCustomStyles}
                assignLabel={assignLabel}
                toggleModal={toggleModal}
              />)
            : (<LabelListTable
                propList={list}
                propPages={totalPages}
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                assignLabel={assignLabel}
            />)
          }
        </div>
      </div>
    </ Modal>
  )
}

export default LabelSelectModal