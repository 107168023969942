import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { getList as getListApi } from 'api/upload'
import { exportImport } from 'api/download'
import Filters from './Filters'
import FileTable from './FileTable'
import BulkOptions from 'components/BulkOptions'

function ImportedFiles ({ setDatafeed }) {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rownum, setRownum] = useState(10)
  const [searchFilter, setSearchFilter] = useState()
  const [dateFilter, setDateFilter] = useState()
  const [platformFilter, setPlatformFilter] = useState()
  const [platformReady, setPlatformReady] = useState(false)
  const [isUpdate, setIsUpdate] = useState()
  const [ingestType, setIngestType] = useState()
  const [modelType, setModelType] = useState()
  const [modelPk, setModelPk] = useState()
  const [toggleExport, setToggleExport] = useState(false)
  const [bulkItems, setBulkItems] = useState()

  const _toggleExport = (type, model, pk) => {
    console.log(type, model, pk)
    setIngestType(type)
    setModelType(model)
    setModelPk(pk)
    setToggleExport(!toggleExport)
  }

  const history = useHistory()

  const getExport = (thype) => {
    var payload = {}
    payload['typer'] = thype
    payload['model_type'] = modelType
    
    exportImport(modelPk, payload).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date + '/')
      }
    })
  }

  const shareBulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExport,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  const recordingBulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: getExport,
      params: 'exprecordings'
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: getExport,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: getExport,
          params: 'scddex'
        }
      ]
    }
  ]

  useEffect(() => {
    if (ingestType === 'recordings') {
      setBulkItems(recordingBulkItems)
    } else {
      setBulkItems(shareBulkItems)
    }
  }, [ingestType])


  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const getList = () => {
    setLoading(true)

    const startDate = dateFilter && dateFilter.startDate ? format(dateFilter.startDate, 'yyyy-MM-dd') : null
    const endDate = dateFilter && dateFilter.endDate ? format(dateFilter.endDate, 'yyyy-MM-dd') : null

    toast.promise(
      getListApi(currentPage, rownum, searchFilter, startDate, endDate, platformFilter, platformReady, isUpdate).then(res => {
        setTotalPages(res.data.total_pages)
        if (searchFilter) {
          setFileList(
            res.data.results.filter((item) => {
              let formattedItem = item.filename.toLowerCase()
              return formattedItem.indexOf(searchFilter.toLowerCase()) !== -1
            })
          )
        } else {
          setFileList(res.data.results)
        }
        setLoading(false)
      }),
      {
        loading: 'Fetching uploaded files...',
        success: <b>Successfully loaded files!</b>,
        error: <b>Unable to fetch files.</b>,
      }
    )
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== '' && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleRowChange = (evt) => {
    const rNum = parseInt(evt.target.value)
    setRownum(rNum)
  }

  const handleSearchChange = (q) => {
    setSearchFilter(q)
  }

  const handleDateChange = (data) => {
    setDateFilter(data[0])
  }

  const handlePlatformChange = (data) => {
    setPlatformFilter(data)
  }

  const handleReadyChange = (data) => {
    setPlatformReady(data)
  }

  const handleUpdateChange = (data) => {
    setIsUpdate(data)
  }

  const handleFilter = () => {
    getList()
  }

  const handleReset = () => {
    setDateFilter()
    setSearchFilter()
    setPlatformFilter()
    setPlatformReady(false)
    setIsUpdate()
    setReset(true)
  }

  return (
    <div className='divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Imported Files</h3>
        <Filters
          searchChange={handleSearchChange}
          dateChange={handleDateChange}
          platformChange={handlePlatformChange}
          readyChange={handleReadyChange}
          updateChange={handleUpdateChange}
          handleFilter={handleFilter}
          handleReset={handleReset}
          loading={loading}
        />
      </div>
      <div className='flex flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          {fileList && <FileTable
            fileList={fileList}
            _toggleExport={_toggleExport}
            setDatafeed={setDatafeed}
            getNext={onNext}
            getPrev={onPrevious}
            currentPage={currentPage}
            totalPages={totalPages}
            hasNext={hasNext}
          />}
        </div>
        { toggleExport &&
            (<div className="flex-grow-0 flex-shrink-0">
              <BulkOptions items={bulkItems} onClose={setToggleExport} />
            </div>)
          }
      </div>
    </div>
  )
}

export default ImportedFiles
