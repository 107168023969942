import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import ReactTooltip from 'react-tooltip'

import PublisherRoles from 'views/Share/components/PublisherRoles'
import Icon from 'layouts/components/Icon'

import PublishingSelectModal from '../PublishingSelect/PublishingSelect'
import { AssignToCollector } from './AssignToCollector'

const CollectorSelect = () => {
  const { watch, control, setValue, getValues } = useFormContext()

  const collectorValue = watch('collectors')
  const formValues = getValues()

  const [selectedCollectors, setSelectedCollectors] = useState(formValues && formValues.collectors ? formValues.collectors : [])
  const [selectedCollectorField, setSelectedCollectorField] = useState(formValues && formValues.collector_field ? formValues.collector_field : '')
  const [selectCollectorModal, setSelectCollectorModal] = useState(false)

  const toggleSelectCollectorModal = () => {
    setSelectCollectorModal(!selectCollectorModal)
  }

  const assignCollector = (pub) => {
    let collector = selectedCollectors
    let collectorField = selectedCollectorField

    if (collector.includes(pub)) {
      collector.splice(collector.indexOf(pub), 1)

      let collectorString = ', ' + pub.pub_name

      if (collectorField.includes(collectorString)) {
        collectorField = collectorField.replace(collectorString, '')
      } else {
        collectorField = collectorField.replace(pub.pub_name + ', ', '')
      }
    } else {
      collector.push(pub)

      if (collectorField.length > 0) {
        collectorField = collectorField + ', ' + pub.pub_name
      } else {
        collectorField = pub.pub_name
      }
    }

    setValue(`collector_field`, collectorField)
    setValue(`collectors`, collector)

    setSelectedCollectorField(collectorField)
    setSelectedCollectors(collector)
  }

  return (
    <>
      <PublishingSelectModal
        status={selectCollectorModal}
        toggleModal={toggleSelectCollectorModal}
        assignPublishing={assignCollector}
        collectorValue={collectorValue}
        change={setValue}
        selectedCollectors={selectedCollectors}
        field={'collectors'}
        values={formValues}
      />
      <div className="bg-white rounded-md">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="collectors" className="block text-sm font-medium text-gray-700">
              Collector<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name='collector_field'
                render={() => (
                  <AssignToCollector
                    className='form-control'
                    value={selectedCollectorField}
                    toggleSelectCollectorModal={toggleSelectCollectorModal}
                  />
                )}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="collector-role-code" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`collector_role_code`}
                render={() => (
                  <PublisherRoles
                    name={`collector_role_code`}
                    defaultValue={formValues.collector_role_code}
                    control={control}
                    change={setValue}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectorSelect
