import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/outline'

const ExplorationDetails = ({ record, expPlatforms, controlled }) => {
  return (<>
      <div>
        <div className="mb-2">
          {controlled === undefined &&
            (<div className="mb-3 rounded-md bg-blue-50 p-4 shadow">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">
                    This has <strong>{record.reviewed ? 'been reviewed' : 'not been reviewed' }</strong> by Exploration.
                  </p>
                </div>
              </div>
            </div>)
          }
          <div className="mb-3 rounded-md bg-blue-50 p-4 shadow">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  {expPlatforms
                    ? (<>
                      {expPlatforms.length
                        ? (<span>This is controlled by Exploration on these platforms: <strong>{expPlatforms.join(',')}</strong>.</span>)
                        : (<span>This is <strong>not controlled</strong> by Exploration on any platforms.</span>)
                      }
                    </>)
                    : (<>
                      {controlled
                        ? (<span>This is <strong>controlled</strong> by Exploration.</span>)
                        : (<span>This is <strong>not controlled</strong> by Exploration.</span>)
                      }
                    </>)
                  }
                </p>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  )
}


export default ExplorationDetails