import React, { useState } from 'react'
import { getBillboardCharts as getChartsApi } from 'api/tools'
import moment from 'moment'

import InfoBar from 'components/InfoBar'
import 'react-day-picker/lib/style.css'
import 'layouts/components/Datepicker.scss'

const BillboardView = () => {
  const [selectedDate, setSelectedDate] = useState()
  const [downloaded, setDownloaded] = useState(false)

  const getBillboardCharts = (selectedDate) => {
    let finalDate = moment(selectedDate).format('YYYY-MM-DD')
    getChartsApi(finalDate).then(res => {
      setDownloaded(true)
      setSelectedDate()
    })
  }

  const handleDateChange = (newValue) => {
    setDownloaded(false)
    setSelectedDate(newValue.target.value)
  }

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          {downloaded && (
            <InfoBar
              text={'We are processing your export. You will get an email once the file is ready.'}
            />
          )}
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Billboard Charts</h2>
              <p className='mt-1 text-sm text-gray-500'>
                Get the latest information from the various Billboard Weekly Charts organized and delivered to your email.
              </p>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-white shadow sm:rounded-md p-6">
                <div className="sm:col-span-6">
                  <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                    Date
                  </label>
                  <div className="mt-1 sm:flex sm:items-center">
                    <input
                      type='date'
                      name='date'
                      className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                      value={selectedDate}
                      onChange={(e) => handleDateChange(e)}
                    />
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3"
                      onClick={() => getBillboardCharts(selectedDate)}
                    >
                      Download Charts
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BillboardView
