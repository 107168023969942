import React, { useState } from 'react'

const ArtistForm = ({ setShowForm, setCustomStyles, assignArtist, toggleModal }) => {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()

    const closeForm = () => {
        setCustomStyles({
            content : {
              width                 : '60%',
              top                   : '53%',
              left                  : '58%',
              right                 : 'auto',
              bottom                : '-43%',
              marginRight           : '-20%',
              transform             : 'translate(-50%, -50%)',
              overflowY             : 'auto',
              maxHeight             : '80vh'
            }
        })
        setShowForm(false)
    }

    const saveNewArtist = () => {
        let artist = {}
        artist['new'] = true
        artist['last_name'] = lastName
        if (firstName) {                                                                                                                    
            artist['first_name'] = firstName
        }
        assignArtist(artist)
        toggleModal()
    }

    return (
        <div className="mt-5 space-y-8">
            <div>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Basic information about the Artist. (* required)
                    </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                            Last Name<sup className='required-icon'>*</sup>
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={() => closeForm()}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={() => saveNewArtist()}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    Continue
                </button>
            </div>
        </div>
    )
}

export default ArtistForm
