import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';

import GoogleButton from 'components/Buttons/GoogleButton';
import { useForm } from 'react-hook-form';
import { login } from 'api/expauth';
import toast from 'react-hot-toast'

function LogIn() {
  const authStore = useContext(AuthContext);

  const handleLogin = () => {
    console.log('Handle Login');
  };

  const { register, handleSubmit } = useForm()

  const onSubmit = handleSubmit((data) => {
    data['username'] = data['email']
    login(data)
    .then(res=>{
      authStore.setLoggedIn(true)
      authStore.setUser(res.data.user)
      localStorage.setItem("accessToken", res.data.access)
    })
    .catch(err=>{
      console.log(err.response)
      toast.error('Unable to login')
    })
  })


  return useObserver(() => {
    if (authStore.loggedIn) {
      return (<Redirect to='/' />)
    } else {
      return (<div className="min-h-full min-w-full flex">
        <div className="flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex items-center">
                <img
                  className="h-10 w-auto inline-block"
                  src="/images/icon-logo.png"
                  alt="Exploration"
                />
                <h2 className="text-3xl font-extrabold text-gray-900 inline-block align-middle mx-5">Exploration Data</h2>
              </div>

              {/* <div className="mt-6">
                <form onSubmit={onSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                    <div className="mt-1">
                      <input
                      {...register('email')}
                      id="email" name="email" type="email" autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <div className="mt-1">
                      <input
                      {...register('password')}
                      id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link to="#" className="font-medium text-red-600 hover:text-red-500">Forgot your password?</Link>
                    </div>
                  </div>

                  <div>
                    <button type="submit" onClick={() => handleLogin()} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-800 hover:from-red-800 hover:to-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Sign in</button>
                  </div>
                </form>
              </div>
              <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-100 text-gray-500"> OR </span>
                </div>
              </div> */}

              <div className="mt-10">
                <GoogleButton />
              </div>
              <p className="mt-2 text-sm">
                <span className="font-medium text-gray-400 hover:text-gray-500">
                  By signing in, you agree to Exploration's Terms & Conditions and Privacy Policy.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://source.unsplash.com/dX76Mts-LVM"
            alt=""
          />
        </div>
      </div>)
    }
  })
}

export default LogIn;