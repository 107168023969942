import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Creatable from 'layouts/components/Creatable'
import AssignToPublisher from 'views/Writer/components/AssignToPublisher'
import PublisherSelectModal from 'views/Writer/components/PublisherSelect/PublisherSelect'

import Icon from 'layouts/components/Icon'
import ReactTooltip from 'react-tooltip'

import { create } from 'api/writers'
import { checkProperties } from 'views/Recording/utils'

const WriterForm = () => {
  const history = useHistory()

  const [selectPublisherModal, setSelectPublisherModal] = useState(false)
  const [selectedPublishers, setSelectedPublishers] = useState([])
  const [selectedPublisherField, setSelectedPublisherField] = useState('')

  const { register, handleSubmit, formState: { errors }, watch, control, setValue } = useForm({
    mode: 'onChange'
  })

  const pubAffiliations = watch('pub_affiliations')

  const assignPublisher = (pub) => {
    let owner = selectedPublishers
    let ownerField = selectedPublisherField

    if (owner.includes(pub)) {
      owner.splice(owner.indexOf(pub), 1)

      let ownerString = ', ' + pub.pub_name

      if (ownerField.includes(ownerString)) {
        ownerField = ownerField.replace(ownerString, '')
      } else {
        ownerField = ownerField.replace(pub.pub_name + ', ', '')
      }
    } else {
      owner.push(pub)

      if (ownerField.length > 0) {
        ownerField = ownerField + ', ' + pub.pub_name
      } else {
        ownerField = pub.pub_name
      }
    }

    setValue('publisher_field', ownerField)
    setValue('pub_affiliations', owner)

    setSelectedPublisherField(ownerField)
    setSelectedPublishers(owner)
  }

  const toggleSelectPublisherModal = () => {
    setSelectPublisherModal(!selectPublisherModal)
  }

  const formatData = (values) => {
    create({ ...values }).then(res => {
      if (res.status === 201) {
        history.push('/writers/' + res.data.external_id + '/')
        toast.success('Writer created successfully!')
      } else {
        toast.error('Unable to create writer')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create writer')
    })
  }

  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'PRS', label: 'PRS' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'SOCAN', label: 'SOCAN' },
    { value: 'Other', label: 'Other' },
  ]

  return (
    <>
      <PublisherSelectModal
        status={selectPublisherModal}
        toggleModal={toggleSelectPublisherModal}
        assignPublisher={assignPublisher}
        selectedPublishers={selectedPublishers}
        pubAffiliations={pubAffiliations}
        change={setValue}
      />
      <form className="space-y-8" onSubmit={handleSubmit(formatData)} id='newPublisher'>
        <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Basic information about the Writer. (* required)
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
              <div className="sm:col-span-2">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="title"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('title')}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="first-name"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('first_name')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="middle" className="block text-sm font-medium text-gray-700">
                  Middle
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="middle"
                    id="middle"
                    autoComplete="middle"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('middle')}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last Name<sup className='required-icon'>*</sup>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="last-name"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('last_name', { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="suffix" className="block text-sm font-medium text-gray-700">
                  Suffix
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="suffix"
                    id="suffix"
                    autoComplete="suffix"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('suffix')}
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="pub-affiliation" className="block text-sm font-medium text-gray-700">
                  Pub Affiliation
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name='publisher_field'
                    render={(props) => (
                      <AssignToPublisher
                        className='form-control'
                        value={selectedPublisherField}
                        toggleSelectPublisherModal={toggleSelectPublisherModal}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="pro-affiliation" className="block text-sm font-medium text-gray-700">
                  PRO Affiliation
                </label>
                <div className="mt-1">
                    <Controller
                        control={control}
                        name='pro_affiliation'
                        render={(props) => (
                        <Creatable
                            {...props}
                            options={proOptions}
                            onChange={(e) => {
                            setValue('pro_affiliation', e)
                            }}
                        />
                        )}
                    />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                  IPI Number
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="ipi-number"
                    id="ipi-number"
                    autoComplete="ipi-number"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('ipi_number')}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="isni" className="block text-sm font-medium text-gray-700">
                  ISNI
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="isni"
                    id="isni"
                    autoComplete="isni"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('isni')}
                  />
                </div>
              </div>

              <div className="sm:col-span-12">
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Notes
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name='note'
                    render={(props) => (
                      <ReactQuill
                      {...props}
                      theme='snow'
                      placeholder='Add a note...'
                      onChange={(e) => {
                          setValue('note', e)
                      }}
                      />
                    )}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Any additional information for the publisher. (Ex: Agreement with Only Solutions Entertainment.)</p>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={checkProperties(errors)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default WriterForm
