import React from 'react'
import Moment from 'react-moment'
import CodeCopy from 'components/CodeCopy'

const ReleaseTable = ({ releases }) => {
  if (releases === undefined || releases === null) {
    return null
  }

  return (
    <div className="bg-white overflow-hidden sm:rounded-md mx-2">
      <div className="px-4 py-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Releases</h3>
      </div>
      <ul className="divide-y divide-gray-200">
        {releases.map((release) => {
          return (<li className="bg-white shadow overflow-hidden sm:rounded-lg px-5 py-5 mx-2 my-2" key={release.external_id}>
            <div className="flex items-center justify-between">
              <div>
                <span className="text-lg font-semibold truncate">{release.album_title}</span>
              </div>
            </div>
            <div className="mt-2 md:grid md:grid-cols-3">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <div className='table-asset-codes'>
                    <span>Release Date: </span><br />
                    <span><Moment format='MM/DD/YYYY'>{release.release_date}</Moment></span>
                  </div>
                </p>
              </div>
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <div className='table-asset-codes'>
                    <span>UPC: </span><br />
                    <span><CodeCopy copyText={release.upc} /></span>
                  </div>
                </p>
              </div>
            </div>
          </li>)
        })}
      </ul>
    </div>
  )
}

export default ReleaseTable
