import React from 'react'

import { territoryOptions } from 'views/Share/territories'
import RoleCode from 'layouts/components/RoleCode'
import ListTable from 'components/ListTable'

const ReferenceView = () => {
  const territoryColumns = [{
    Header: () => (<h6 style={{ textAlign:'left' }}>Territory</h6>),
    id: 'territory',
    sortable: false,
    accessor: 'label'
  }, {
    Header: () => (<h6 style={{ textAlign:'left' }}>Code</h6>),
    id: 'code',
    sortable: false,
    Cell: ({ row }) => (
      <RoleCode roleCode={row.original.value} asBadge />
    )
  }]

  const tableStyle = {
    border: 'none',
    boxShadow: 'none'
  }

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">References</h2>
            </div>
          </div>
          <div className='py-4'>
            <ListTable
              data={territoryOptions}
              columns={territoryColumns}
              minRows={0}
              defaultPageSize={10}
              style={tableStyle}
              loadingText={'Loading territory codes...'}
              noDataText={'No territory codes available.'}
            />
          </div>
        </div>
      </div>    
    </>
  )
}

export default ReferenceView
