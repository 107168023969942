import React from 'react'
import { Route, Switch } from 'react-router'
import List from './routes/List/index'
import Detail from './routes/Detail/index'

export default function DuplicateViews () {
  return (
    <Switch>
      <Route exact path='/duplicates' component={List} />
      <Route path='/duplicates/:id' component={Detail} />
    </Switch>
  )
}
