import React from 'react'
import WriterForm from './WriterForm'

const WriterFieldArray = ({ includeSelect, formatLabel, errors, userTerritoryPresets, fields, remove }) => {
  const hasError = errors && errors.ownerships

  return (<>
    {fields.map((ownership, index) => {
      return (
        <WriterForm
          remove={remove}
          ownership={ownership}
          index={index}
          includeSelect={includeSelect}
          formatLabel={formatLabel}
          userTerritoryPresets={userTerritoryPresets}
          errors={hasError ? errors.ownerships[index] : null}
        />)
    })}
  </>)
}

export default WriterFieldArray
