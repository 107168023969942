import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useForm, Controller } from 'react-hook-form'

import SelectField from 'layouts/components/Select'
import './styles/PublisherPanel.scss'
import { createPublisher } from 'api/publishers'

const PublisherPanel = ({ hidePublisherForm, toggleSaving, isOpen, publisherName }) => {
  const initialValues = {
    'name': publisherName
  }

  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'Other', label: 'Other' }
  ]

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const handleChange = (state) => {
    if (!state.isOpen) {
      hidePublisherForm()
    }
  }

  const onFormSubmit = (values) => {
    toggleSaving()
    createPublisher(values).then(res => {
      if (res.data === 201) {
        console.log('created')
      } else {
        console.log('error in creating')
      }
    })
  }

  const customStyles = {
    bmOverlay: {
      background: 'none'
    }
  }

  if (publisherName === '' || publisherName === undefined) {
    return null
  }

  return (
    <Menu
      className='side-panel'
      isOpen={isOpen}
      onStateChange={handleChange}
      pageWrapId={'share-form-wrap'}
      width='40%'
      styles={customStyles}
      noOverlay
      disableCloseOnEsc
      disableAutoFocus
      right>
      <div className='publisher-panel'>
        <form className='pub-form' onSubmit={handleSubmit(onFormSubmit)}>
          <div className='row table-form-header'>
            <div className='col-md-12'>
              <h5>New Collector</h5>
              <button
                type='submit'
                className='btn btn-primary btn-wide btn-sm btn-flat'>
                Save and Continue
              </button>
              <button
                type='button'
                className='btn btn-default btn-wide btn-sm btn-flat'
                onClick={() => {
                  hidePublisherForm()
                }}>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Name<sup className='required-icon'>*</sup></label>
            <input
              name='name'
              type='text'
              ref={register({ required: true })} />
            {errors && errors.name && (<div className="_form-feedback" type="invalid">
              {errors.name.type === 'required'
                ? 'This field is required'
                : errors.name.message
            }
            </div>)}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">IPI Number</label>
            <input
              name='ipi_number'
              type='text'
              ref={register} />
              {errors && errors.ipi_number && (<div className="_form-feedback" type="invalid">
                {errors.ipi_number.message}
              </div>)}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Pro Affiliation</label>
            <Controller
              control={control}
              name='pro_affiliation'
              render={() => (
                <SelectField
                  options={proOptions}
                  onChange={(e) => {
                    setValue('pro_affiliation', e)
                  }}
                />
              )}
            />
          </div>
        </form>
      </div>
    </Menu>
  )
}

export default PublisherPanel
