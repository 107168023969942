import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import AssignToLabel from 'layouts/components/LabelModalSelect/AssignToLabel'
import LabelSelectModal from 'layouts/components/LabelModalSelect/LabelSelectModal'
import { edit } from 'api/artist'
import { transformData } from 'views/Artist/utils'

const TitleForm = ({ initialValues, id, setRecord, showTitleEditForm, setShowTitleEditForm }) => {
  const [selectLabelModal, setSelectLabelModal] = useState(false)
  const [selectedLabelField, setSelectedLabelField] = useState('')

  const { register, handleSubmit, setValue, errors } = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const assignLabel = (label) => {
    if (label) {
      setValue('record_label_affiliation', label.pk)
      setSelectedLabelField(label.name)
    } else {
      setValue('record_label_affiliation', null)
      setSelectedLabelField(' ')
    }
    setSelectLabelModal(false)
  }

  const toggleSelectLabelModal = () => {
    setSelectLabelModal(!selectLabelModal)
  }

  const _handleArtistSubmit = (values) => {
    edit(values, id).then(res => {
      if (res.status === 200) {
        toast.success('Artist updated successfully')
        setRecord(res.data)
        setShowTitleEditForm(false)
      } else {
        toast.error('Unable to update artist')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update artist')
    })
  }

  const formatData = (values) => {
    let data = { ...values }
    _handleArtistSubmit(data)
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow" onSubmit={handleSubmit(formatData)} id='artistDetail'>
      <LabelSelectModal
        status={selectLabelModal}
        toggleModal={toggleSelectLabelModal}
        assignLabel={assignLabel}
      />
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="first_name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('first_name')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="last_name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('last_name', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="record_label_affiliation" className="block text-sm font-medium text-gray-700">
                Record Label Affiliation
              </label>
              <div className="mt-1">
                <AssignToLabel
                  className='form-control'
                  value={selectedLabelField}
                  toggleSelectLabelModal={toggleSelectLabelModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowTitleEditForm(!showTitleEditForm) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default TitleForm
