import { useForm } from "react-hook-form"
import { validateRoyaltyReport } from "api/asset"
import { useState } from "react"
import Icon from "components/Icon"
import classNames from "classnames"

const RoyaltyValidatorView = () => {
  const { register, handleSubmit, watch, reset } = useForm()
  const [isValidating, setIsValidating] = useState(false)
  const [responseData, setResponseData] = useState()

  const fileUpload = watch("file")

  const onSubmit = (data) => {
    setIsValidating(true)
    const formData = new FormData()
    formData.append("file", data.file[0])
    validateRoyaltyReport(formData)
      .then((res) => {
        setResponseData(res.data)
        console.log(res.data)
      })
      .catch((res) => {
        setResponseData(res.response.data)
      })
      .finally(() => setIsValidating(false))
  }

  function underscoreToCapital(str) {
    return str
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" ") // Join the words with spaces
  }

  return (
    <div className="mx-auto max-w-2xl space-y-4">
      <h2 className="text-2xl mb-2 font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        Royalty Validator
      </h2>
      <form
        className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            {!fileUpload ? (
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <Icon name="file-csv" size="50px" color="gray" />
            )}

            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file"
                className={classNames(
                  "relative cursor-pointer bg-white rounded-md font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 text-red-600 hover:text-red-500 focus-within:ring-red-500"
                )}
              >
                <span>
                  {fileUpload?.[0]?.name ?? "Click here to upload a csv file"}
                </span>
                <input
                  id="file"
                  name="file"
                  {...register("file")}
                  type="file"
                  className="sr-only"
                  accept=".csv"
                />
              </label>
              {/* <p className="pl-1">or drag and drop</p> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={() => {
              reset()
            }}
            disabled={isValidating}
            className="bg-white py-2 px-4 my-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Canel
          </button>
          <button
            type="submit"
            disabled={isValidating}
            className="text-center my-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Validate
          </button>
        </div>
      </form>
      {!!responseData && (
        <div className="bg-white shadow sm:rounded-md p-6">
          <h1 className="text-2xl font-bold text-gray-600">Results</h1>
          <div>
            {Object.entries(responseData).map(
              ([key, value]) =>
                key !== "rows" && (
                  <div className="flex">
                    <div className="w-32">{underscoreToCapital(key)}</div>:
                    <div
                      className={classNames(
                        "ml-4 font-semibold",
                        !value ? "text-red-500" : "text-gray-700"
                      )}
                    >
                      {value ?? "Missing header"}
                    </div>
                  </div>
                )
            )}
          </div>
          {responseData?.rows && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              {responseData.rows.map((el) => (
                <div className="flex">
                  <div className="w-52">{underscoreToCapital(el.label)}</div>:
                  <div
                    className={classNames("ml-4 font-semibold text-red-500")}
                  >
                    {el.value}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RoyaltyValidatorView
