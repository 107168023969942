import React, { useState } from 'react'
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Label = styled.label`
  font-size: 12px;
  margin-bottom: 5px;
`

const SearchBox = styled.div`
  .btn {
    margin: 0 !important;
  }
`

const DateBox = styled.div`
  margin-top: 10px;
`

const StyledDateRange = styled(DateRange)`
  width: 100%;
`

const Wrapper = styled.div`
  padding-bottom: 80px;
`

function Filters ({ searchChange, dateChange, claimStaffChange, handleFilter, handleReset, loading }) {
  const [searchFilter, setSearchFilter] = useState()
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),  
      key: 'selection'
    }
  ])
  const [claimStaff, setClaimStaff] = useState('')

  const handleSearchFilter = (evt) => {
    setSearchFilter(evt.target.value)
    searchChange(evt.target.value)
  }

  const handleDateChange = (data) => {
    setDateFilter(data)
    dateChange(data)
  }

  const handleClaimStaffChange = (evt) => {
    setClaimStaff(evt.target.value)
    claimStaffChange(evt.target.value)
  }

  const _handleReset = () => {
    setDateFilter([{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }])
    setSearchFilter('')
    setClaimStaff('')
    handleReset()
  }

  return (<Wrapper>
    <Form>
      <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <DateBox>
            <label htmlFor="uploadDate" className="block text-sm font-medium text-gray-700">
              Date of Submission
            </label>
            <input type='hidden' name='startDate' />
            <input type='hidden' name='endDate' />
            <StyledDateRange
              className='mt-1'
              editableDateInputs
              onChange={item => handleDateChange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </DateBox>
        </div>
        <div className='sm:col-span-3 space-y-6 sm:space-y-5'>
          <div className='sm:col-span-3'>
            <label htmlFor="filename" className="block text-sm font-medium text-gray-700">
                Search by filename
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='filename'
                className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                value={searchFilter}
                onChange={handleSearchFilter}
              />
            </div>
          </div>

          <div className='sm:col-span-3'>
            <label htmlFor="filename" className="block text-sm font-medium text-gray-700">
                Search by uploader
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='uploader'
                className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                value={claimStaff}
                onChange={handleClaimStaffChange}
              />
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => _handleReset()}
                disabled={loading}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Reset
              </button>
              <button
                onClick={() => handleFilter()}
                disabled={loading}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </Wrapper>)
}

export default Filters
