import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'

import './components/List.scss'
import InfoBar from 'components/InfoBar'
import ListTable from 'components/ListTable'
import Heading from './components/Heading'

import { fetchDuplicates } from 'api/composition'

const ListView = () => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [filteredDuplicates, setFilteredDuplicates] = useState()
  const [totalPages, setTotalPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [rownum, setRowNum] = useState(20)

  const hasNext = currPage < totalPages

  const getList = (page, rNum, filter) => {
    setSearchQ(filter)
    fetchDuplicates(page, rNum, filter).then(res => {
      if (res.status === 200) {
        setFilteredDuplicates(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch duplicates')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch duplicates')
    })
  }

  useEffect(() => {
    if (query.q) {
      setSearchQ(query.q)
      getList(query.page, rownum, query.q)
    } else {
      setCurrPage(1)
      getList(1, rownum, '')
    }

  }, [location.search])

  const _onNext = (page) => {
    getList(page + 1, rownum)
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      getList(page - 1, rownum)
      setCurrPage(page - 1)
    }
  }

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
    }

  const _doSearch = (searchValue) => {
    let query = {
      page: 1,
      q: searchValue
    }
  
    if (searchValue === undefined || searchValue === '') {
      query = { page: 1 }
    }
    history.push({
      pathname: `/duplicates`,
      search: `?${objectToQueryString(query)}`
    })
  }

  if (filteredDuplicates === undefined || filteredDuplicates === null) {
    return null
  }

  const dupeColumns = [{
    Header: 'ID',
    id: 'id',
    sortable: false,
    width: 100,
    Cell: ({ row }) => (
      <div>
        <Link to={'/duplicates/' + row.original['pk'] + '/'}><strong>{row.original['composition']['external_id']}</strong></Link>
      </div>
    )
  }, {
    Header: 'Title',
    id: '_title',
    sortable: false,
    width: 250,
    Cell: ({ row }) => (
      <div>
        <Link to={'/duplicates/' + row.original['pk'] + '/'}><strong>{row.original['composition']['_title']}</strong></Link>
      </div>
    )
  }, {
    Header: 'Potential Duplicate ID',
    id: 'duplicate_id',
    sortable: false,
    width: 100,
    Cell: ({ row }) => (
      <div>
        <Link to={'/duplicates/' + row.original['pk'] + '/'}><strong>{row.original['duplicate']['external_id']}</strong></Link>
      </div>
    )
  }, {
    Header: 'Potential Duplicate Title',
    id: 'duplicate_title',
    sortable: false,
    width: 250,
    Cell: ({ row }) => (
      <div>
        <Link to={'/duplicates/' + row.original['pk'] + '/'}><strong>{row.original['duplicate']['_title']}</strong></Link>
      </div>
    )
  }, {
    Header: 'Match Score',
    id: 'score',
    sortable: true,
    Cell: ({ row }) => (
      <div>{row.original['fuzzy_ratio']}</div>
    )
  }]

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {!searchFirst &&
          (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => history.push('/duplicates')}
            showClose
          />)
        }
        <Heading
          searchFirst={searchFirst}
          handleSearch={_doSearch}
        />
        <div className='py-4'>
          <ListTable
            columns={dupeColumns}
            data={filteredDuplicates}
            getNext={_onNext}
            getPrev={_onPrevious}
            currPage={currPage}
            setCurrPage={setCurrPage}
            pages={totalPages}
            hasNext={hasNext}
            showPagination
          />
        </div>
      </div>
    </div>
  )
}

export default ListView
