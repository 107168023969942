import React from 'react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import './Icon.scss'

const Icon = ({ name, klass, className, spaceAfter, spaceBefore, tooltip, ios, src }) => {
  const _id = _.uniqueId('icon__')

  let ionKlassName = ['icon', klass, className]
  if (ios) {
    ionKlassName.push('ion-ios-' + name)
  } else {
    ionKlassName.push('ion-md-' + name)
  }

  let imgKlassName = ['icon', 'img-icon']

  if (spaceAfter) {
    ionKlassName.push('space-after')
    imgKlassName.push('space-after')
  }

  if (spaceBefore) {
    ionKlassName.push('space-before')
    imgKlassName.push('space-before')
  }
  return (
    <React.Fragment>
      {name
        ? <i className={ionKlassName.join(' ')} data-tip={tooltip} data-for={_id}>
          <ReactTooltip id={_id} />
        </i>
        : <img className={imgKlassName.join(' ')} alt='icon' src={'/images/' + src} /> }
    </React.Fragment>
  )
}

export default Icon
