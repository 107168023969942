import React, { useState, useEffect } from "react"
import { DownloadIcon, ChartBarIcon } from "@heroicons/react/outline"
import ReactTooltip from "react-tooltip"

import { getDDEXFeedList } from "api/ddex"
import ListTable from "components/ListTable"

const statusList = {
  'pending': 'Pending',
  'processing': 'Processing',
  'delivering_to_yt': 'Delivering to YT',
  'delivering_to_expdata': 'Delivering to Data',
  'delivered_to_yt': 'Delivered to YT',
  'delivered_to_expdata': 'Delivered to Data',
  'delivered_to_both': 'Delivered to YT and Data'
}

function ImportedFiles() {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rownum, setRownum] = useState(10)

  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const isNotEmpty = (obj) => {
    return obj && Object.keys(obj).length > 0
  }

  const getList = () => {
    setLoading(true)
    getDDEXFeedList(currentPage, rownum)
      .then((res) => {
        console.log(res.data)
        setTotalPages(res.data.total_pages)
        setFileList(res.data.results)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== "" && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleRowChange = (evt) => {
    const rNum = parseInt(evt.target.value)
    setRownum(rNum)
  }

  const assetColumns = [
    {
      Header: "Delivery ID",
      id: "delivery_id",
      Cell: ({ row }) => {
        const { delivery_id } = row.original;
        
        return (<label className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
          {delivery_id}
        </label>);
      },
    },
    {
      Header: "Filename",
      Cell: ({ row }) => {
        const names = row.original.filename
          .split(" ")
          .map((name) => name.trim())
        // Group names to keep this to four strings per row
        const groupedNames = []
        for (let i = 0; i < names.length; i += 4) {
          groupedNames.push(names.slice(i, i + 4).join(" "))
        }
        return (
          <>
            {row.original.status === "processed" && row.original.output_link ? (
              <a
                className="font-bold hover:text-blue-500"
                href={row.original.output_link}
                target="_blank"
              >
                {groupedNames.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </a>
            ) : (
              <span>
                {groupedNames.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            )}
          </>
        )
      },
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const formattedDate = new Date(row.original.created).toLocaleString()
        const [date, time] = formattedDate.split(", ")
        return (
          <span>
            {date}
            <br />
            {time}
          </span>
        )
      },
    },
    {
      Header: "Date of Completion",
      Cell: ({ row }) => {
        const formattedDate = new Date(
          row.original.time_completed
        ).toLocaleString()
        const [date, time] = formattedDate.split(", ")
        return (
          <>
            {row.original.time_completed && (
              <span>
                {date}
                <br />
                {time}
              </span>
            )}
          </>
        )
      },
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => {
        const { status } = row.original;
        
        return (
          <div className="flex">
            {["processed", "delivered_to_yt", "delivered_to_expdata", "delivered_to_both"].includes(status) ? (
              <label className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {statusList[status]}
              </label>
            ) : ["invalid"].includes(status) ? (
              <label className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                Invalid
              </label>
            ) : (
              <label className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                {statusList[status]}
              </label>
            )}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => (
        <div className="flex">
          {["delivered_to_yt", "delivered_to_both"].includes(row.original.status)  ? (
            <>
              <a
                data-tip
                data-for="download"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer"
                href={row.original.download_url}
                target="_blank"
              >
                <DownloadIcon className="h-4 w-4" aria-hidden="true" />
              </a>
              <ReactTooltip id="download" type="dark">
                <span>Download DDEX Files</span>
              </ReactTooltip>
            </>
          ) : null}
        </div>
      ),
    },
  ]

  return (
    <div className="divide-y divide-gray-200 bg-white shadow sm:rounded-md">
      <ListTable
        columns={assetColumns}
        data={fileList}
        getNext={onNext}
        getPrev={onPrevious}
        currPage={currentPage}
        setCurrPage={setCurrentPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default ImportedFiles
