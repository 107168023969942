import React, { useState, useEffect } from 'react'
import ListTable from 'components/ListTable'
import toast from 'react-hot-toast'
import Moment from 'react-moment'

import Icon from 'layouts/components/Icon'
import ListSearch from 'components/ListSearch'
import { getList as fetchFeed } from 'api/download'

const ExportList = () => {
  const [feedList, setFeedList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currPage, setCurrPage] = useState(1)
  const [loading, setLoading] = useState()
  const [rownum, setRowNum] = useState(10)

  const getList = (page, rNum, filter) => {
    fetchFeed(page, rNum, filter).then(res => {
      if (res.status === 200) {
        setFeedList(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch export files')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch export files')
    })
  }

  useEffect(() => {
    getList(1, rownum)
  }, [])

  const onPageChange = (evt) => {
    setCurrPage(evt.target.value)

    if (evt.target.value !== '' && parseInt(evt.target.value) !== currPage) {
      getList(evt.target.value, rownum)
    }
  }

  const _onNext = (page) => {
    setLoading(true)
    getList(page + 1, rownum)
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(page - 1, rownum)
      setCurrPage(page - 1)
    }
  }

  const filterFeed = (filter) => {
    getList(1, rownum, filter)
  }

  const handleRowChange = (event) => {
    setRowNum(parseInt(event.target.value))
    setLoading(true)
    getList(currPage, parseInt(event.target.value))
  }

  const feedHasNext = currPage < totalPages

  if (feedList === undefined || feedList === null) {
    return null
  }

  const feedColumns = [{
    Header: 'Filename',
    accessor: 'filename'
  }, {
    Header: 'Upload Date',
    id: 'created',
    Cell: ({ row }) => (
      <Moment format='MM/DD/YYYY HH:mm'>{row.original.created}</Moment>
    )
  }, {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }) => (<div>
      {row.original.filename === 'N/A'
        ? (<label className='px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'>PROCESSING</label>)
        : row.original.file_url === 'expired'
        ? (<label className='px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'>EXPIRED LINK</label>)
        : (<a className='px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
          href={row.original.file_url}>
          <Icon name='download' spaceAfter />Download
        </a>)}
    </div>
    )
  }]
  
  return (
    <div>
      <div className='box'>
        <div className='row box-header'>
          <div className='col'>
            <span className='box-title'>Exports</span>
          </div>
          <div className='box-tools col-md-auto'>
            <ListSearch
              filterList={filterFeed}
              placeholder='Find export file'
            />
          </div>
        </div>

        <div className='box'>
          <ListTable
            manual
            data={feedList}
            currPage={currPage}
            setCurrPage={setCurrPage}
            columns={feedColumns}
            sortable={false}
            minRows={0}
            showPagination={false}
            defaultPageSize={1}
            className='table expdata-table'
            noDataText={feedList === undefined
              ? 'Loading exports...'
              : 'No exports available.'}
          />
        </div>
      </div>
    </div>
  )
}

export default ExportList
