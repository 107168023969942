import React from 'react'

import { useForm, useFieldArray } from 'react-hook-form'
import toast from 'react-hot-toast'

import { transformData } from 'views/Publisher/utils'
import { edit } from 'api/publishers'
import RenderPublishers from 'views/Publisher/routes/Form/components/RenderPublishers'

const PublisherForm = ({ initialValues, setRecord, setShowPublisherForm, showPublisherForm }) => {
  const { register, handleSubmit, errors, setValue, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "publishers",
    keyName: "uuid"
  })

  const selectedPublishers = watch('publishers')

  const handlePublisherSubmit = (values) => {
    edit(values).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
        setShowPublisherForm (false)
        toast.success('Successfully updated publisher!')
      } else {
        toast.error('Unable to update publisher')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update publisher')
    })
  }

  return (<form className='px-5 py-4' onSubmit={handleSubmit(handlePublisherSubmit)}>
    <div className="py-2">
      <label className="block text-lg font-medium text-gray-700">Publisher Relations</label>
      <div className="col" xs={12} md={12}>
        <RenderPublishers
          selectedPublishers={selectedPublishers}
          fields={fields}
          append={append}
          remove={remove}
          register={register}
          control={control}
          change={setValue} />
      </div>
    </div>
    <div className="pt-5">
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => { setShowPublisherForm(!showPublisherForm) }}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={errors}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Save
        </button>
      </div>
    </div>
  </form>)
}

export default PublisherForm
