import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import {
  HomeIcon,
  PlusCircleIcon,
  DuplicateIcon,
  CashIcon,
  ChartPieIcon,
  XIcon,
  ArchiveIcon,
  UsersIcon,
  PresentationChartBarIcon,
  DocumentSearchIcon,
  UploadIcon,
  DownloadIcon,
  LightningBoltIcon,
  CogIcon
} from '@heroicons/react/outline'

import ProfileImage from 'components/ProfileImage';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Menu = ({ sidebarOpen, setSidebarOpen, user, propScope }) => {
  const [authScope, setAuthScope] = useState('exp-read')

  useEffect(() => {
    if (propScope) {
      setAuthScope(propScope)
    }
  }, [propScope])

  let navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, scope: 1 },
    { name: 'Create Assets', href: '/create-assets', icon: PlusCircleIcon, scope: 1 },
    { name: 'Import Data', href: '/upload', icon: UploadIcon, scope: 1 },
    { name: 'Export Data', href: '/download', icon: DownloadIcon, scope: 1 },
    { name: 'Duplicates', href: '/duplicates', icon: DuplicateIcon, scope: 1 },
    { name: 'Conflicts', href: '/conflicts', icon: CashIcon, scope: 1 },
    { name: 'Conflict Resolution', href: '/conflict-resolution', icon: ChartPieIcon, scope: 1 },
    { name: 'Claiming', href: '/claiming', icon: ArchiveIcon, scope: 3 },
    { name: 'Tools', href: '/tools', icon: CogIcon, scope: 1 },
    { name: 'Users', href: '/users', icon: UsersIcon, scope: 4 },
    { name: 'Errors', href: '/errors', icon: LightningBoltIcon, scope: 5 }
  ]

  const location = useLocation();
  const activeNav = location.pathname

  const isActive = (pathname, link) => {
    pathname = pathname.split('/').filter((el) => { return el !== '' });
    link = link.split('/').filter((el) => { return el !== '' });
    return pathname[0] === link[0];
  };

  const menuLogic = (items, activeNav, scope) => {
    return (
      <div className="px-2 space-y-1">
        {items && items.map(function (mainMenu, key) {
          return (
            mainMenu.scope <= scope
              && (<a
                key={key}
                href={mainMenu.href}
                className={classNames(
                  isActive(activeNav, mainMenu.href)
                    ? 'bg-red-700 text-white font-bold'
                    : 'text-white hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-sm rounded-md'
                )}
              >
                <mainMenu.icon
                  className={classNames(
                    isActive(activeNav, mainMenu.href) ? 'text-white' : 'text-white group-hover:text-gray-500',
                    'mr-3 h-4 w-4'
                  )}
                  aria-hidden="true"
                />
                {mainMenu.name}
              </a>)
            )
        })}
      </div>
    );
  };

  let scope = 1

  if (authScope !== undefined && authScope !== null) {
    if (authScope.indexOf('exp-read') >= 0) {
      scope = 1
    }

    if (authScope.indexOf('exp-write') >= 0) {
      scope = 2
    }

    if (authScope.indexOf('exp-claim') >= 0 || authScope.indexOf('exp-claimad') >= 0) {
      scope = 3
    }

    if (authScope.indexOf('exp-admin') >= 0) {
      scope = 4
    }

    if (authScope.indexOf('exp-dev') >= 0) {
      scope = 5
    }
  }

  return (<>
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 lg:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-15 w-auto"
                  src="/images/new-exploration-logo.png"
                  alt="ExplorationData"
                />
              </div>
              <nav aria-label="Sidebar" className="mt-5">
                {menuLogic(navigation, activeNav, scope)}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <a href="#" className="flex-shrink-0 group block">
                <div className="flex items-center">
                  <div>
                    <ProfileImage
                      email={user?.email}
                      className="inline-block h-10 w-10 rounded-full"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{user?.first_name + " " + user?.last_name}</p>
                    <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{user?.email}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>

    {/* Static sidebar for desktop */}
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-red-600">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-15 w-auto"
                src="/images/new-exploration-logo.png"
                alt="ExplorationData"
              />
            </div>
            <nav className="mt-5 flex-1" aria-label="Sidebar">
              {menuLogic(navigation, activeNav, scope)}
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-red-500 p-4 hover:bg-red-700">
            <a href="/users/profile" className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <ProfileImage
                    email={user?.email}
                    className="inline-block h-9 w-9 rounded-full"
                  />
                </div>
                <div className="ml-3 truncate">
                  <p className="text-sm font-medium text-white truncate">{user?.first_name + " " + user?.last_name}</p>
                  <p className="text-xs font-medium text-white truncate">{user?.email}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default Menu;
