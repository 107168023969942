import React, { useState } from 'react'

import TitleBox from './TitleBox'
import Codes from './Codes'
import ShareTable from './ShareTable'
import RecordingTable from './RecordingTable'

const CompositionDetail = ({ divId, entry }) => {
  var adminshares = entry.shares
  var recordings = entry.recordings
  var ownership = parseFloat(entry.ownership).toFixed(2)

  const [currNav, setCurrNav] = useState(`#nav-shares${divId}`)

  if (entry === undefined) {
    return null
  }

  var ownerships = []
  for (var i = 0; i < adminshares.length; i++) {
    for (var j = 0; j < adminshares[i].ownerships.length; j++) {
      ownerships.push(adminshares[i].ownerships[j])
    }
  }

  let codes = entry.codes

  const navigation = [
    { name: 'Details', href: `#nav-details${divId}` } ,
    { name: 'Shares', href: `#nav-shares${divId}` },
    { name: 'Recordings', href: `#nav-recordings${divId}` }
  ]

  return (
    <>
      <div className='box box-default'>
        <div className='box-body'>
          <div className='composition-box'>
            <TitleBox
              record={entry}
              ownership={ownership}
              />
          </div>
        </div>
      </div>

      <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
        <div className="ml-3">
          {navigation.map((item) => (
            <button
              type='button'
              key={item.name}
              className={item.href === currNav 
                ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
              }
              onClick={() => setCurrNav(item.href)}
              aria-current={item.href === currNav ? 'page' : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </nav>
      <hr />
      <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content' id='nav-tabContent'>
        {currNav === `#nav-details${divId}` &&
          (<div className='tab-pane tab-pad fade'
            id='nav-details' role='tabpanel' aria-labelledby='nav-details-tab'>
            <Codes codes={codes} />
          </div>)       
        }
        {currNav === `#nav-shares${divId}` &&
          (<div className='tab-pane tab-pad fade active show'id='nav-shares' role='tabpanel' aria-labelledby='nav-shares-tab'>
              <ShareTable adminshares={adminshares} />
          </div>)
        }
        {currNav === `#nav-recordings${divId}` &&
          (<div className='tab-pane tab-pad fade' id='nav-recordings' role='tabpanel' aria-labelledby='nav-recordings-tab'>
            <RecordingTable recordings={recordings} />
          </div>)
        }
      </div>
    </>
  )
}

export default CompositionDetail
