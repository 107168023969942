import React, { useState, useEffect } from 'react'
import ListTable from 'components/ListTable'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import toast from 'react-hot-toast'

import Heading from './components/Heading'
import InfoBar from 'components/InfoBar'

// import AssignPublisher from './AssignPublisher'
// import AssignUserType from './AssignUserType'

import { getList } from 'api/users'

const ListView = () => {
  const [users, setUsers] = useState(null)
  const [filteredUsers, setFilteredUsers] = useState(null)
  const [searchQ, setSearchQ] = useState('')

  useEffect(() => {
    getList().then((res) => {
      if (res.status === 200) {
        setUsers(res.data)
        setFilteredUsers(res.data)
      } else {
        toast.error('Unable to fetch users')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch users')
    })
  }, [])

  const filterUsers = (userFilter) => {
    setSearchQ(userFilter)
    if (users === null) {
      return null
    }

    let newUsers = users.filter((item) => {
      let formattedItem = item.last_name.toLowerCase()
      if (item.first_name) {
        formattedItem = formattedItem + item.first_name.toLowerCase()
      }
      if (item.email) {
        formattedItem = formattedItem + item.email.toLowerCase()
      }
      return formattedItem.indexOf(
        userFilter.toLowerCase()) !== -1
    })
    setFilteredUsers(newUsers)
  }

  const onCellClick = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        if (column['Header'] === 'Publisher') {
        }
      }
    }
  }

  if (users === null || filteredUsers === null) {
    return null
  }

  const columns = [{
    Header: 'Name',
    id: 'name',
    accessor: item => (
      <Link className='hover:text-blue-500' to={'/users/' + item['pk']}>{item['first_name'] + ' ' + item['last_name']}</Link>)
  }, {
    Header: 'Email Address',
    id: 'email',
    accessor: item => (
      <Link className='hover:text-blue-500' to={'/users/' + item['pk']}>{item['email']}</Link>)
  }, {
    Header: 'Verified',
    accessor: 'is_active',
    Cell: ({ row }) => {
      return (row.original.is_active ? <span className='icon-success'><ion-icon name='checkmark-circle' /></span> : null)
    }
  }, {
    Header: 'Scope',
    accessor: 'scope',
    Cell: ({ row }) => {
      return row.original.profile.scope.split(' ').map((scope, idx) => {
        return (<div className='badge badge-default' key={'scope__' + idx}>{scope}</div>)
      })
    }
  }, {
    Header: 'Last Login',
    id: 'last_login',
    accessor: (item) => {
      if (item.last_login) {
        return <Moment date={item.last_login} fromNow />
      } else {
        return <span>N/A</span>
      }
    }
  }]

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          {!searchFirst &&
            (<InfoBar
              text={"Showing search result for '" + searchQ + "'"}
              onClose={() => filterUsers('')}
            />)
          }
          <Heading
            searchFirst={searchFirst}
            handleSearch={filterUsers}
          />
          <div className='py-4'>
            <ListTable
              data={filteredUsers}
              columns={columns}
              sortable={false}
              minRows={0}
              defaultPageSize={10}
              className='table expdata-table'
              noDataText=' '
              defaultSorted={[{
                id   : 'email',
                desc : false
              }]}
              getTdProps={onCellClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ListView
