import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'


import PublisherRoles from 'views/Share/components/PublisherRoles'
import PublisherSelectModal from '../PublisherSelect/PublisherSelect'
import { AssignToAdministrator } from './AssignToAdministrator'

const AdministratorSelect = ({ admin, index, remove }) => {
  const { control, setValue, watch } = useFormContext()
  var administrators = watch('administrators')

  const [selectedAdmin, setSelectedAdmin] = useState(admin.administrator_pk ? admin.administrator_pk : null)
  const [selectedAdminField, setSelectedAdminField] = useState(admin.administrator_field ? admin.administrator_field : '')
  const [selectAdminModal, setSelectAdminModal] = useState(false)

  const toggleSelectAdminModal = () => {
    setSelectAdminModal(!selectAdminModal)
  }

  const assignAdministrator = (pub) => {
    setValue(`administrators[${index}].administrator_field`, pub.pub_name)
    setValue(`administrators[${index}].administrator`, pub)
    setSelectedAdmin(pub)
    setSelectedAdminField(pub.pub_name)
    setSelectAdminModal(false)
  }

  return (
    <>
      <PublisherSelectModal
        status={selectAdminModal}
        toggleModal={toggleSelectAdminModal}
        assignPublisher={assignAdministrator}
        pubValue={administrators}
        pub={admin}
        change={setValue}
        index={index}
      />
      <div className="bg-white rounded-md">
        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="administrator" className="block text-sm font-medium text-gray-700">
              Administrator {index + 1}<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`administrators[${index}].admin_field`}
                render={() => (
                  <AssignToAdministrator
                    className='form-control'
                    value={selectedAdminField}
                    toggleSelectAdministratorModal={toggleSelectAdminModal}
                  />
                )}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="admin-role-code" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`administrators[${index}].administrator_role_code`}
                render={() => (
                  <PublisherRoles
                    name={`administrators[${index}].administrator_role_code`}
                    defaultValue={admin.administrator_role_code}
                    control={control}
                    change={setValue}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {index !== null ? <button
          className='btn btn-link btn-remove'
          type='button'
          onClick={() => remove(index)}>
          Remove
        </button> : null }
      </div>
      
    </>
  )
}

export default AdministratorSelect
