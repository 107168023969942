import React from 'react'
import { InformationCircleIcon, XIcon } from '@heroicons/react/solid'

export const InfoBar = ({ text, onClose, showClose }) => (
  <div className="mb-5 rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-blue-800">{text}</p>
      </div>
      {showClose && (<div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex bg-blue-50 rounded-md p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>)
      }
    </div>
  </div>
)

export default InfoBar
