import React from 'react'
import { Controller } from 'react-hook-form'
import SelectField from 'layouts/components/Select'
import RoleCode from 'layouts/components/RoleCode'

const options = [
  { value: '', label: '----' },
  { value: 'AM', label: 'Administrator' },
  { value: 'E', label: 'Original Publisher' },
  { value: 'PY', label: 'Payee' },
  { value: 'PA', label: 'Publisher Income Participant' },
  { value: 'SE', label: 'Sub-Publisher' },
  { value: 'ES', label: 'Substitute Publisher' },
  { value: 'W', label: 'Writer' }
];

const formatLabel = (option, elem) => {
  return (
    <div className='select-option'>
      <span className='select-title'>{option.label}<RoleCode roleCode={option.value} asBadge /></span>
    </div>
  )
}

const PublisherRoles = ({ name, control, change, ...props }) => {
  var selectName = name === undefined ? 'publisher_role_code' : name;

  return (
    <SelectField
      {...props}
      options={options}
      formatLabel={formatLabel}
      onChange={(e) => {
        change(selectName, e)
      }}
    />
  )
}

export default PublisherRoles
