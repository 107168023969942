import PrivateAPI from './private'

export const getList = (page, rownum, term, startDate, endDate, platform, platformReady, isUpdate) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  if (platform !== undefined && platform !== '') {
    params['platform'] = platform
  }

  if (platformReady !== undefined && platformReady !== '') {
    params['platformReady'] = platformReady
  }

  if (isUpdate !== undefined && isUpdate !== '') {
    params['isUpdate'] = isUpdate
  }

  if (startDate !== undefined && startDate !== null) {
    params['startDate'] = startDate
  }

  if (endDate !== undefined && endDate !== null) {
    params['endDate'] = endDate
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/datafeed/upload/', params)
}

export const fetch = (pk, type) => {
  return PrivateAPI.get('api/datafeed/' + type + '/' + pk + '/')
}

export const onSubmit = (payload) => {
  return PrivateAPI.postfile('api/datafeed/upload/', payload)
}

export const onEdit = (pk, payload) => {
  return PrivateAPI.put('api/datafeed/upload/' + pk + '/', payload)
}

export const importData = (pk, type) => {
  return PrivateAPI.post('api/datafeed/' + type + '/' + pk + '/', {})
}

export const getShares = (pk) => {
  var params = {
    'data_feed_id': pk
  }

  return PrivateAPI.get('api/compositions/shares/', params)
}

export const getAliases = (type) => {
  var params = {
    'file_type': type
  }

  return PrivateAPI.post('api/datafeed/aliases/', params)
}

export const sendAlias = (payload) => {
  return PrivateAPI.post('api/datafeed/aliases/add/', payload)
}

export const editAlias = (pk, payload) => {
  return PrivateAPI.put('api/datafeed/aliases/' + pk + '/', payload)
}

export const deleteAlias = (pk) => {
  return PrivateAPI.delete('api/datafeed/aliases/' + pk + '/')
}

export const googleUpload = (payload) => {
  return PrivateAPI.post('api/datafeed/google/', payload)
}

export const getErrorList = (page, rownum, filter) => {
  var params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/datafeed/upload/errors/' + page + '/' + rownum + '/', params)
}

export const getStats = () => {
  return PrivateAPI.get('api/datafeed/stats/')
}