import React, { useState, useEffect } from 'react'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import PossibleMatches from './PossibleMatches'
import RowDetails from './RowDetails'

import { getBlankRows } from 'api/asset'

export default function ReviewMatches ({ feedId, fetchAugmentorFeed }) {
    const [currentRow, setCurrentRow] = useState()
    const [currPage, setCurrPage] = useState(1)
    const [totalAssets, setTotalAssets] = useState(0)

    const fetchBlankRows = () => {
        toast.loading('Fetching blank rows...')
        getBlankRows(feedId, currPage).then(res => {
            if (res.status === 200) {
                setCurrentRow(res.data.results)
                setTotalAssets(res.data.total_count)
                toast.dismiss()
            } else {
                toast.error('Unable to fetch blank rows')
            }
        }).catch(err=>{
          console.log(err.response)
          toast.dismiss()
          toast.error('Unable to fetch blank rows')
        })
    }

    useEffect(() => {
        fetchBlankRows()
    }, [currPage])

    const hasNext = currPage < totalAssets

    if (totalAssets === 0) {
        return null
    } 

    return (<>
        <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
                <h3 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">TitleSync</h3>
            </div>
            <span className="py-3 flex justify-end">
                <span className="mr-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">{currPage} of {totalAssets}</span>
                <button
                    type="button"
                    onClick={() => setCurrPage(currPage - 1)}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    onClick={() => setCurrPage(currPage + 1)}
                    className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </span>
        </div>

        <div className="flex space-x-4">
            {/* Row Details */}
            <div className='px-4 py-5 sm:p-6 w-1/3'>
                {currentRow && <RowDetails currentRow={currentRow} />}
            </div>

            {/* Possible Matches */}
            <div className='rounded-lg bg-white px-4 py-5 shadow sm:p-6 w-2/3'>
                <div className='mt-2'>
                {currentRow && <PossibleMatches
                    rowId={currentRow.pk}
                    hasNext={hasNext}
                    currPage={currPage}
                    setCurrPage={setCurrPage}
                    fetchAugmentorFeed={fetchAugmentorFeed}
                />}
                </div>
            </div>
        </div>

    </>)
}
