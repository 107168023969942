import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import AdminDetail from './components/AdminDetail'

import { adminFetchE } from 'api/share'
import { AuthContext } from 'stores/auth'

const AdminDetailView = () => {
  const { id } = useParams()
  const authStore = useContext(AuthContext)

  const [record, setRecord] = useState()
  const [composition, setComposition] = useState()

  useEffect(() => {
    if (record === undefined || record.external_id !== id) {
      adminFetchE(id).then(res => {
        if (res.status === 200) {
          setRecord(res.data)
        } else {
          toast.error('Unable to fetch share')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch share')
      })
    }
  }, [])

  if (record === undefined) {
    return null
  }

  return (
    <AdminDetail
      record={record}
      setRecord={setRecord}
      composition={composition}
      authStore={authStore}
      user={authStore.user}
    />
  )
}

export default AdminDetailView
