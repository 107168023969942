import React from 'react'

export const ProgressBar = ({ progressText, percentage }) => (
  <div>
    <h4 className="sr-only">Status</h4>
    <p className="text-sm font-medium text-gray-900">{progressText}</p>
    <div aria-hidden="true" className="mt-6">
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div style={{ width: `${percentage}%` }} className="h-2 rounded-full bg-red-600" />
      </div>
    </div>
  </div>
)

export default ProgressBar
