import React, { useRef } from 'react'

const AdvancedSearch = ({ filterList, options, placeholder, toggleAdvancedSearch }) => {
  const searchField = useRef(null)
  const filterType = useRef(null)
  const filterField = useRef(null)

  const handleAdvancedSearch = () => {
    if (options) {
      filterList(searchField.current.value, filterType.current.value, filterField.current.value)
    } else {
      filterList(searchField.current.value)
    }
  }

  return (<aside className="w-96 bg-white border-l border-gray-200 overflow-y-auto h-full">
    <div className="overflow-hidden divide-y divide-gray-200">
      <div className="p-6">
        <div className="pb-2 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Advanced Search</h3>
        </div>
        <div className='py-4'>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4">
            <div className="">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                {placeholder || 'Search'}
              </label>
              <div className="mt-1">
                <textarea
                  type='text'
                  name="query"
                  ref={searchField}
                  rows={3}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>

            {options && <div className="">
              <fieldset className="">
                <legend className="block text-sm font-medium text-gray-700">Filter</legend>
                <div className="mt-1 rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="filterType" className="sr-only">
                      Filter Type
                    </label>
                    <select
                      id="filterType"
                      name="filterType"
                      ref={filterType}
                      className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    >
                      <option value='-1'>Select...</option>
                      {Object.keys(options).map((key, index) => (
                        <option key={index} value={key}>{options[key]}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="filterField" className="sr-only">
                      Filter Field
                    </label>
                    <input
                      type="text"
                      name="filterField"
                      id="filterField"
                      ref={filterField}
                      className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                      placeholder=""
                    />
                  </div>
                </div>
              </fieldset>
            </div>}

            <div className="flex justify-end">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={() => toggleAdvancedSearch()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => handleAdvancedSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </aside>)

}

export default AdvancedSearch
