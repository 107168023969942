import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import CodeCopy from 'components/CodeCopy'

const MultipleValueField = ({ idx, label, codes, showForm }) => {
  const { register, control } = useFormContext()
  let name = label['key']

  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    keyName: 'uuid'
  })

  return (<>
    {name === 'related_asset_ids'
        ? (<>
            {!showForm ? codes[label['key']].map((code, idx) => {
                return (
                <span key={idx}>
                    <CodeCopy copyText={code} />
                    {/* <span className="px-3 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" href={`/shares/` + `${code['external_id']}`}>{code}</span> */}
                </span>
                )
            }) : <span className='mb-3'>
                {fields.map((field, idx)=>{
                return (<div key={field.uuid} style={{marginBottom: "0.5rem"}}>
                    <small style={{display: "block"}}>
                        <strong>Related Asset ID {idx + 1}</strong>
                    </small>
                    <input
                        className='shadow-sm focus:ring-red-500 focus:border-red-500 block min-w-full sm:text-sm border-gray-300 rounded-md'
                        type='text'
                        {...register(`related_asset_ids[${idx}]`)}
                    />
                    <button
                        className='btn btn-link btn-remove'
                        type='button'
                        onClick={() => remove(idx)}>
                        Remove
                    </button>
                </div>)
                })}
                <button
                    type="button"
                    onClick={() => append()}
                    className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                >Add</button>
            </span>}
        </>)
        : (<>
        {!showForm ? codes[label['key']].map((code, idx) => {
            return (
            <span key={idx}>
                <CodeCopy copyText={code[label['key']]} />
                {/* <a className="px-3 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" href={`/shares/` + `${code['external_id']}`}>{code[label['key']]}</a> */}
            </span>
            )
        }) : <span className='mb-3'>
                {fields.map((field, idx)=>{
                    return (<div key={field.uuid} style={{marginBottom: "0.5rem"}}>
                        <small style={{display: "block"}}>
                            <strong>Share ID:</strong> <a href={`/shares/` + `${field.external_id}`}>{field.external_id}</a>
                        </small>
                        <input
                            type='hidden'
                            {...register(`${name}[${idx}].external_id`)}
                        />
                        <input
                            className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                            type='text'
                            {...register(`${name}[${idx}].${label['key']}`)}
                        />
                    </div>)
                })}
            </span>}
        </>)
    }
    </>
  )
}

export default MultipleValueField