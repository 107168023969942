import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'

import InfoBar from 'components/InfoBar'
import Select from 'components/Select'
import FileInput from './FileInput'

import { uploadAssetReport } from 'api/download'

const UploadForm = ({ datafeed }) => {
  const [saving, setSaving] = useState(false)
  const [saveFile, setSaveFile] = useState()
  const history = useHistory()

  let initialValues = {}

  const { register, handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const submitForm = (formProps) => {
    setSaving(true)
    toast.loading('Uploading asset report...')
    let formData = Object.assign({}, formProps)

    formData['file'] = saveFile
    if (formData['file'] === undefined) {
      delete formData['file']
    }

    uploadAssetReport(formData).then(res => {
      if (res.status === 200) {
        history.push('/upload/' + res.data.pk)
      } else {
        toast.error('An error occured in processing your file.')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('An error occured in processing your file.')
    })
    setSaving(false)
  }

  const cleanForm = () => {
    reset({
      'client': null,
      'report_type': null,
      'asset_count': null
    })
  }

  const clientOptions = [
    { value: null, label: '---' },
    { value: '4af5ae733243428d', label: 'Abkco' },
    { value: '427fc0e4ebe24f15', label: 'ARESA' },
    { value: 'f3aa9ef426e547e8', label: 'Arts & Crafts Music Inc.' },
    { value: '938b40d4127449e0', label: 'BMG' },
    { value: 'a4336f7c026e4f36', label: 'Cabin 24 Records' },
    { value: '956ebc6834cc4217', label: 'Crisma' },
    { value: '6a4f817d478f4373', label: 'PEN MUSIC GROUP INC'},
    { value: 'f7f54cb831824c66', label: 'Red Bull Media House NA' },
    { value: '45ac008e7d444e9d', label: 'Tommy Boy' },
    { value: 'bfdf10b21c674fdc', label: 'Ultra International Music Publishing' },
    { value: '9595187d02904701', label: 'Warp Publishing' }
  ]

  const reportOptions = [
    { value: 'pub', label: 'Publishing' },
    { value: 'sr', label: 'Sound Recording' }
  ]

  return (<>
    {datafeed && <InfoBar
      text={`Editing information for ingest file: ${datafeed.filename}`}
      onClose={cleanForm}
      />}
    <Form className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6" onSubmit={handleSubmit(submitForm)}>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <Controller
                control={control}
                name={`file`}
                render={() => (
                  <FileInput saveFile={setSaveFile} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Report Information</h3>
          </div>
          <div className="mt-6 mb-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="filename" className="block text-sm font-medium text-gray-700">
                Filename
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="filename"
                  id="filename"
                  autoComplete="filename"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('filename')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="file_url" className="block text-sm font-medium text-gray-700">
                File URL
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="file_url"
                  id="file_url"
                  autoComplete="file_url"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('file_url')}
                />
              </div>
            </div>
                  
            {/* <div className="sm:col-span-3">
              <label htmlFor="client_id" className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <div className="mt-1">
                <Select
                  id="client_id"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="client_id"
                  options={clientOptions}
                  register={register}
                />
              </div>
            </div> */}

            <div className="sm:col-span-3">
              <label htmlFor="client_email" className="block text-sm font-medium text-gray-700">
                Client Email
              </label>
              <div className="mt-1">
              <input
                  type="email"
                  name="client_email"
                  id="client_email"
                  autoComplete="client_email"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('client_email')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="client_id" className="block text-sm font-medium text-gray-700">
                Client ID
              </label>
              <div className="mt-1">
              <input
                  type="text"
                  name="client_id"
                  id="client_id"
                  autoComplete="client_id"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('client_id')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="report_type" className="block text-sm font-medium text-gray-700">
                Report Type
              </label>
              <div className="mt-1">
                <Select
                  id="report_type"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="report_type"
                  options={reportOptions}
                  register={register}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="asset_count" className="block text-sm font-medium text-gray-700">
                Asset Count
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="asset_count"
                  id="asset_count"
                  autoComplete="asset_count"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('asset_count')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => cleanForm()}
            disabled={saving}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </Form>
  </>)
}

export default (UploadForm)