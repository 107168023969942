import PrivateAPI from "./private"

export const uploadAugmentorFeedFile = (payload) => {
  return PrivateAPI.postfile("api/assets/", payload)
}

export const getPresignedUrl = (payload) => {
  return PrivateAPI.post("api/assets/presigned-url/", payload)
}

export const notifyUploadComplete = (payload) => {
  return PrivateAPI.post("api/assets/upload-complete/", payload)
}

export const getFeedPresignedUrl = (payload) => {
  return PrivateAPI.post('api/assets/feed/presigned-url/', payload)
}

export const getAugmentorFeedDetail = (id) => {
  return PrivateAPI.get(`api/assets/${id}/`)
}

export const notifyFeedUploadComplete = (payload) => {
  return PrivateAPI.post('api/assets/feed/upload-complete/', payload)
}

export const getAugmentorAssetFeedList = (page, rownum) => {
  var params = {}

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/assets/feed/list/', params)
}

export const getAugmentorFeedList = (page, rownum) => {
  var params = {}

  params["page"] = page
  params["rownum"] = rownum

  return PrivateAPI.get("api/assets/", params)
}

export const getAugmentorFeedRows = (id, page, rownum) => {
  var params = {}

  params["page"] = page
  params["rownum"] = rownum

  return PrivateAPI.get(`api/assets/${id}/`, params)
}

export const uploadAugmentorAssetFeedFile = (payload) => {
  return PrivateAPI.postfile("api/assets/asset-feed/", payload)
}

export const fetchAssets = (page, rownum, term) => {
  var params = {}
  if (term !== undefined && term !== "") {
    params["_filter"] = term
  }

  params["page"] = page
  params["rownum"] = rownum

  return PrivateAPI.get("api/assets/asset-search/", params)
}

export const validateRoyaltyReport = (payload) => {
  return PrivateAPI.post("api/assets/royalties-validator/", payload)
}

export const getBlankRows = (feedId, page) => {
  var params = {}

  params['feed_id'] = feedId
  params['page'] = page

  return PrivateAPI.get('api/assets/feed/row/', params)
}

export const getPossibleMatches = (id) => {
  var params = {}
  
  params['augmentor_row_id'] = id

  return PrivateAPI.get('api/assets/title-sync/', params)
}

export const approveMatch = (rowId, shareId) => {
  var payload = {}

  payload['augmentor_row_id'] = rowId
  payload['share_id'] = shareId

  return PrivateAPI.post('api/assets/title-sync/', payload)
}