import React, { useRef, useEffect, useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'

import './LabelSearch.scss'

function LabelSearch ({ sQ, doSearch, placeholder }) {
  const search = useRef(null)
  const [searching, setSearching] = useState()

  useEffect(() => {
    search.current.value = sQ || ''
  }, [sQ])

  const _doSearch = () => {
    setSearching(true)
    doSearch(search.current.value)
    setSearching(false)
  }

  const _keyUp = (event) => {
    if (event.keyCode === 13) {
      _doSearch()
    }
  }

  return (
    <div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          className="focus:ring-red-500 focus:border-red-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder === undefined ? 'Search for record label' : placeholder}
          ref={search}
          onKeyUp={(event) => _keyUp(event)}
          defaultValue={sQ || ''} />
      </div>
    </div>
  )
}

export default LabelSearch