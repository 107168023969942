import React from 'react'
import { Link } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/outline'

import { option } from 'views/Publisher/utils'

const RelatedPublisherTable = ({ publishers, setShowPublisherForm, showPublisherForm }) => {
  return (<>
    <div>
      <div className="px-4 py-4 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Related Publishers</h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <button
            type="button"
            onClick={() => { setShowPublisherForm(!showPublisherForm) }}
            className="inline-flex items-center px-4 py-2 border border-transparent
            shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            Edit<PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Platform
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Account Number
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {publishers.map((item, pubIdx) => (
                  <tr key={item.external_id} className={pubIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-3 py-4 text-sm font-medium">
                      <Link className="hover:text-blue-500" to={`/publishers/${item.publisher.external_id}/`}>{item.publisher.name}</Link>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {option[item.platform]}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.account_number}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RelatedPublisherTable