import React from 'react'
import { TrashIcon } from '@heroicons/react/outline'

var moment = require('moment')

const TitleBox = ({ record, ownership, handleResolveDupe }) => {
  let newArtists = record.related_artists

  return (<>
    <div className="mb-5 rounded-lg bg-white overflow-hidden shadow min-w-full">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3 truncate">
                  {ownership > 100 ? (
                    <span className="text-red-600">{record._title}</span>
                    ) : (
                      <span>{record._title}</span>
                    )
                  }
                </h2>
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {record.external_id}
                </p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <button
                type='button'
                onClick={(e) => {
                  if (window.confirm('Delete this composition?\nWARNING: ' +
                  'Deleting this composition will delete all linked Shares to this composition. Any attached Recording will be merged to the other Composition in this page.')) {
                    handleResolveDupe(record.pk)
                  }
                }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Delete
                <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-white grid grid-cols-3 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          <div key='artists' className="px-6 py-5 text-sm text-center">
            <span className="text-gray-600">Sung/Performed by</span>&nbsp;
            <span className="text-gray-900 font-medium">
              {newArtists && newArtists.map((artist, idx) => {
                return (
                  <a key={idx} href={`/artists/` + `${artist['external_id']}`} className='value'>
                    <span className="text-gray-900 hover:text-blue-500">{artist['name']}</span>
                    {idx < newArtists.length - 1
                      ? (<span>,&nbsp;</span>)
                      : (<span>&nbsp;</span>)
                    }
                  </a>
                )
              })}
            </span> 
          </div>
          <div key='sync' className="px-6 py-5 text-sm text-center">
            {ownership > 100 ? (
              <div>
                <span className="text-gray-600">
                  Total Synchronization Ownership in the United States is&nbsp;
                </span>
                <span className="text-red-600 font-medium">
                  {ownership.toString().split('.')[1] !== undefined &&
                      ownership.toString().split('.')[1].length > 4
                    ? (parseFloat(ownership).toFixed(2))
                    : ownership
                  }%
                </span>
              </div>
              ) : (
                <div>
                  <span className="text-gray-600">
                    Total Synchronization Ownership in the United States is&nbsp;
                  </span>
                  <span className="text-gray-900 font-medium">
                    {ownership.toString().split('.')[1] !== undefined &&
                        ownership.toString().split('.')[1].length > 4
                      ? (parseFloat(ownership).toFixed(2))
                      : ownership
                    }%
                  </span>
              </div>
              )
            }
          </div>
          <div key='modified' className="px-6 py-5 text-sm text-center">
            {record.modified_email === 'Unknown'
              ? (<div>
                <span className="text-gray-600">
                  Last edited at&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
              : (<div>
                <span className="text-gray-600">
                  Last edited by&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {record.modified_email} at {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TitleBox
