import React from 'react'
import { Controller } from 'react-hook-form'

import SelectField from 'layouts/components/Select'

const NewWriterOwner = ({ pub, values, index, register, control, change, saveNewWriter, toggleNewWriter }) => {
  var fieldName = pub + 'owners[0]'

  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <>
      <div className="p-6">
        <div className='box box-white'>
          <div className='box-form-header'>New Writer</div>
          <div className="py-2">
            <div className="col" xs={12} md={2}>
              <div controlId='newWriterTitle'>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  name={`${fieldName}.title`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={3}>
              <div controlId='newWriterFn'>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  name={`${fieldName}.first_name`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={2}>
              <div controlId='newWriterMiddle'>
                <label className="block text-sm font-medium text-gray-700">Middle</label>
                <input
                  name={`${fieldName}.middle_name`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={3}>
              <div controlId='newWriterLn'>
                <label className="block text-sm font-medium text-gray-700">Last Name<sup className='required-icon'>*</sup></label>
                <input
                  name={`${fieldName}.last_name`}
                  type='text'
                  ref={register({ required: true })} />
              </div>
            </div>
            <div className="col" xs={12} md={2}>
              <div controlId='newWriterSuffix'>
                <label className="block text-sm font-medium text-gray-700">Suffix</label>
                <input
                  name={`${fieldName}.suffix`}
                  type='text'
                  ref={register} />
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="col" xs={12} md={4}>
              <div controlId='newWriterIpi'>
                <label className="block text-sm font-medium text-gray-700">IPI Number</label>
                <input
                  name={`${fieldName}.ipi_number`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={4}>
              <div controlId='newWriterIsni'>
                <label className="block text-sm font-medium text-gray-700">ISNI</label>
                <input
                  name={`${fieldName}.isni`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={4}>
              <div controlId='newPubPro'>
                <label className="block text-sm font-medium text-gray-700">Pro Affiliation</label>
                <Controller
                  control={control}
                  name={`${fieldName}.pro_affiliation`}
                  render={() => (
                    <SelectField
                      options={proOptions}
                      onChange={(e) => {
                        change(`${fieldName}.pro_affiliation`, e)
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='box'>
          <div className='row'>
            <div className='col form-actions'>
              <button
                className='btn btn-primary btn-wide btn-submit btn-flat'
                type='submit'
                onClick={() => saveNewWriter(values['writers'][index]['owners'][0].first_name + ' ' + values['writers'][index]['owners'][0].last_name)}>Save</button>
              <button
                className='btn btn-blue-gray btn-wide btn-submit btn-flat'
                type='button'
                onClick={() => toggleNewWriter()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewWriterOwner
