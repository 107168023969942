import React, { useState } from 'react'
import Modal from 'react-modal'

import './List.scss';
import InfoBar from 'components/InfoBar'
import Icon from 'layouts/components/Icon'
import WriterListTable from './WriterListTable'
import WriterSearch from './WriterSearch'
import NewWriter from './NewWriter'

import { getWriterSelectList } from 'api/writers'

const WriterSelectModal = ({ status, setSelectedWriterField, register, values, setValue, index, ownershipIndex, toggleModal, selectedWriters, assignWriter }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [refreshTable, setRefreshTable] = useState(false)
  const [newWriter, setNewWriter] = useState(false)

  console.log(ownershipIndex, index)

  const onResetSearch = () => {
    getWriterSelectList({ page: 1, q: ''})
  }

  const _doSearch = (searchQ) => {
    if (status) {
      setSearchQ(searchQ)
      setCurrPage(1)
      getWriterSelectList({ page: 1, q: searchQ })
      setRefreshTable(!refreshTable)
    }
  }

  const toggleNewWriter = () => {
    setNewWriter(!newWriter)
  }

  const saveNewWriter = (writerValues) => {
    let writerNameKeys = ['title', 'first_name', 'middle_name', 'last_name', 'suffix']
    let writerName = ''
    writerNameKeys.forEach(key => {
      if (writerValues[key]) {
        writerName = writerName + ' ' + writerValues[key]
      }
    })

    setValue(`publishers[${ownershipIndex}].ownership_writers[${index}].create`, 'create')
    setValue(`publishers[${ownershipIndex}].ownership_writers[${index}].writer_field`, writerName)
    setValue(`publishers[${ownershipIndex}].ownership_writers[${index}].writer`, writerValues)
    setSelectedWriterField(writerName)
    toggleModal()
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  }

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
        onClick={() => toggleModal()}>
        Continue
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={'Showing search result for ' + "'" + searchQ + "'"}
          onClose={onResetSearch}
        />
      )}

      <div className="page">
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Writer</span>
          </div>

          <WriterSearch doSearch={_doSearch} />
        </div>

        {newWriter
          ? <NewWriter
            toggleNewWriter={toggleNewWriter}
            saveNewWriter={saveNewWriter}
            register={register}
            values={values}
            setValue={setValue}
            index={index}
            ownershipIndex={ownershipIndex} />
          : <div>
            <div className='float-right'>
              <button className='btn btn-xs btn-hover btn-hover-primary' type='button'
                onClick={() => toggleNewWriter()}>
                <Icon name='add' spaceAfter />Add Writer
              </button>
            </div>
            <br /><br />
            <div className='page-body no-padding'>
              <WriterListTable
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                refreshTable={refreshTable}
                selectedWriters={selectedWriters}
                assignWriter={assignWriter}
              />
            </div>
          </div>
        }
      </div>
      {!newWriter
        ? <button type='button'
          className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
          onClick={() => toggleModal()}>
          Continue
        </button>
        : null
      }
    </ Modal>
  )
}

export default WriterSelectModal
