import React from 'react'
import { Route, Switch } from 'react-router'
import AdminList from './routes/AdminList/index'
import AdminForm from './routes/AdminForm/index'
import AdminFormDuplicate from './routes/AdminFormDuplicate/index'
import AdminBulk from './routes/AdminBulk/index'
import AdminDetail from './routes/AdminDetail/index'

export default function ShareViews () {
  return (
    <Switch>
      <Route exact path='/shares' component={AdminList} />
      <Route path='/shares/add' component={AdminForm} />
      <Route path='/shares/potential-duplicate' component={AdminFormDuplicate} />
      <Route path='/shares/update' component={AdminBulk} />
      <Route path='/shares/:id' component={AdminDetail} />
    </Switch>
  )
}