import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import Creatable from 'layouts/components/Creatable'

const NewWriter = ({ control, saveNewWriter, toggleNewWriter }) => {
  const [writerValues, setWriterValues] = useState()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWriterValues(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleSelect = (e) => {
    if (e && e.value) {
      setWriterValues(prevState => ({
          ...prevState,
          ['pro_affiliation']: e.value
      }));
    }
  };

  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">New Writer</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about the Writer. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-2">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="title"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.title}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="first_name"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.first_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
                Middle
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  autoComplete="middle_name"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.middle_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Last Name<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="last_name"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="suffix" className="block text-sm font-medium text-gray-700">
                Suffix
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="suffix"
                  id="suffix"
                  autoComplete="suffix"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.suffix}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="pro_affiliation" className="block text-sm font-medium text-gray-700">
                PRO Affiliation
              </label>
              <div className="mt-1">
                  <Controller
                      control={control}
                      name='pro_affiliation'
                      value={writerValues?.pro_affiliation}
                      render={(props) => (
                        <Creatable
                            {...props}
                            options={proOptions}
                            onChange={handleSelect}
                        />
                      )}
                  />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="ipi_number" className="block text-sm font-medium text-gray-700">
                IPI Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="ipi_number"
                  id="ipi_number"
                  autoComplete="ipi_number"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.ipi_number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="isni" className="block text-sm font-medium text-gray-700">
                ISNI
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="isni"
                  id="isni"
                  autoComplete="isni"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={writerValues?.isni}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => toggleNewWriter()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => saveNewWriter(writerValues)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default NewWriter
