import React from 'react'

const PublisherCard = ({ pubData }) => {
  return (<>
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Publisher Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.publisherName}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Chain ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.chainId}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Chain Parent ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.chainParentId}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Parent Publishers</dt>
                <dd className="mt-1 text-sm text-gray-900">
                    {pubData.parentPublishers.map(function (parentPub, pubIdx) {
                        return (<span key={'parentPub-' + pubIdx}>
                            {parentPub.name}<br />
                        </span>)
                    })}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Administrators</dt>
                <dd className="mt-1 text-sm text-gray-900">
                    {pubData.administrators.map(function (admin, adminIdx) {
                        return (<span key={'admin-' + adminIdx}>
                            {admin.name}<br />
                        </span>)
                    })}
                </dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">MLC Pub Number</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.mlcPublisherNumber}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Publisher ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.mlcPublisherNumber}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Publisher IPI</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.publisherIpiNumber}</dd>
            </div>

            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Publisher Role Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.publisherRoleCode}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Collection Share</dt>
                <dd className="mt-1 text-sm text-gray-900">{pubData.collectionShare}</dd>
            </div>
        </dl>
    </div>
  </>)
}

export default PublisherCard