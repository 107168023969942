import React from 'react'
import { useFormContext } from 'react-hook-form'

import ReactTooltip from 'react-tooltip'
import './OwnershipTable.scss'
import RoleCode from 'layouts/components/RoleCode'

const StaticOwnershipTable = ({ expPlatforms, ownerships, showPercentageForm, withAdmin }) => {
  const { register } = useFormContext()
  const option = {}
  option['AD'] = 'Adaptor'
  option['AM'] = 'Administrator'
  option['AR'] = 'Arranger'
  option['PR'] = 'Associated Performer'
  option['A'] = 'Author'
  option['C'] = 'Composer'
  option['CA'] = 'Composer/Author'
  option['E'] = 'Original Publisher'
  option['PA'] = 'Publisher Income Participant'
  option['SR'] = 'Sub-Arranger'
  option['SA'] = 'Sub-Author'
  option['SE'] = 'Sub-Publisher'
  option['ES'] = 'Substitute Publisher'
  option['TR'] = 'Translator'
  option['W'] = 'Writer'
  option['PY'] = 'Payee'

  return (
    ownerships ? ownerships.map((ownership, idx) => {
      return (
        <tr className={withAdmin ? 'bg-gray-50' : 'bg-white'} key={'A' + idx + ownership.pk}>
          <td />
          {withAdmin ? <td /> : null }
          <td className="px-3 py-4 text-sm text-gray-500">
            {
              ownership.owners.map((owner, index) => (
                <span key={index}>
                  <a className="block mb-1 hover:text-blue-500"  href={`/${ownership.owner_set_type}s/` +
                    `${owner.external_id}`
                  }>{owner.name}{' '}</a>
                </span>
                )
              )
            }
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">
            <span data-tip data-for='ownerRoleCode' className='form-tip text-gray-500'>
              {ownership.owner_role_code}
            </span>
            <ReactTooltip id='ownerRoleCode' type='dark' place='top'>
              <span>{option[ownership.owner_role_code]}</span>
            </ReactTooltip>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{ownership.owner_role_code === 'W' ||
            ownership.owner_role_code === 'C' ||
            ownership.owner_role_code === 'A'
          ? null
          : ownership.writers.map((obj, idx) => {
            return (
              <span key={'A' + idx + obj.pk}>
                <span>
                  <a className="block mb-1 hover:text-blue-500"  href={'/writers/' + obj.external_id + '/'}>
                    {obj.name}
                    {obj.writer_role_code !== null
                      ? <RoleCode key={obj.name} roleCode={obj.writer_role_code} asBadge />
                      : null}
                  </a>
                </span>
              </span>
            )
          })}</td>
          <input type='hidden' {...register(`ownerships[${idx}].pk`)} />
          <td className="px-3 py-4 text-sm text-gray-500">{ showPercentageForm
            ? (<input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register(`ownerships[${idx}].sr_share`, { min: 0, max: 100 })} />)
            : ownership.sr_share
          }</td>
          <td className="px-3 py-4 text-sm text-gray-500">
            {ownership?.sr_include_exclude === 'include' ? ownership?.sr_share_territory?.replace(/,/g, ' ') : 'WW (ex.' + ownership?.sr_share_territory?.replace(/,/g, ' ') + ')' }
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{ showPercentageForm
            ? (<input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='-ml-5 shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register(`ownerships[${idx}].mr_share`, { min: 0, max: 100 })} />)
            : ownership.mr_share
          }</td>
          <td className="px-3 py-4 text-sm text-gray-500">
            {ownership?.mr_include_exclude === 'include' ? ownership?.mr_share_territory?.replace(/,/g, ' ') : 'WW (ex.' + ownership?.mr_share_territory?.replace(/,/g, ' ') + ')' }
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{ showPercentageForm
            ? (<input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register(`ownerships[${idx}].pr_share`, { min: 0, max: 100 })} />)
            : ownership.pr_share
          }</td>
          <td className="px-3 py-4 text-sm text-gray-500">
            {ownership?.pr_include_exclude === 'include' ? ownership?.pr_share_territory?.replace(/,/g, ' ') : 'WW (ex.' + ownership?.pr_share_territory?.replace(/,/g, ' ') + ')' }
          </td>
          {expPlatforms.includes('LyricFind') &&
            (<td className="px-3 py-4 text-sm text-gray-500">{ showPercentageForm
              ? (<input
                type='number'
                step='.01'
                min='0'
                max='100'
                className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                {...register(`ownerships[${idx}].lr_share`, { min: 0, max: 100 })} />)
              : ownership.lr_share
            }</td>)
          }
          {expPlatforms?.includes('LyricFind') &&
            (<td className="px-3 py-4 text-sm text-gray-500">{ownership?.lr_include_exclude === 'include' ? ownership.lr_share_territory?.replace(/,/g, ' ') : 'WW (ex.' + ownership.lr_share_territory?.replace(/,/g, ' ') + ')' }</td>)
          }
          {/* <td /> */}
        </tr>
      )
    }) : null
  )
}

export default StaticOwnershipTable
