import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { getStats } from 'api/upload'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FeedStats = ({ showStats }) => {
    const [stats, setStats] = useState()
    const [latestDate, setLatestDate] = useState('2022-06-22')

    useEffect(() => {
        getStats().then(res => {
            if (res.status === 200) {
                setStats(res.data.stats)
                setLatestDate(res.data.latestDate)
            } else {
                toast.error('Unable to fetch stats')
            }
        }).catch(err => {
            console.log(err)
            toast.error('Unable to fetch stats')
        })
    }, [showStats])

    if (!showStats) {
        return null
    }

    return (
        <div>
        <h3 className="mt-5 text-lg leading-6 font-medium text-black">Last 6 months of 2021 vs. First 6 months of 2022 ({latestDate})</h3>
        <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
            {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-red-600">
                    {item.stat}
                    <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
                </div>

                <div
                    className={classNames(
                    item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}
                >
                    {item.changeType === 'increase' ? (
                    <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                    />
                    ) : (
                    <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                    )}

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change.toFixed(2)}%
                </div>
                </dd>
            </div>
            ))}
        </dl>
        </div>
    )
}

export default FeedStats
