/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import Icon from 'layouts/components/Icon'

import 'views/Share/components/styles/AdminForm.scss'

import GeneralInfo from 'views/Share/components/GeneralInfo/GeneralInfo'
import Collectors from 'views/Share/components/Collectors/Collectors'
import Ownership from 'views/Share/components/Ownership/Ownership'
import { transformDuplicate } from 'views/Share/utils'

import { _bulkUpdate } from 'api/share'

const AdminBulk = ({ user, composition, shareUpdates, shareIds }) => {
  const history = useHistory()

  const [record, setRecord] = useState()
  const [pubRecord, setPubRecord] = useState()

  const [showGenInfo, setShowGenInfo] = useState(true)
  const [showCollectors, setShowCollectors] = useState(true)
  const [showOwnerships, setShowOwnerships] = useState(true)
  const [publisherIsSaving, setPublisherIsSaving] = useState(false)

  let initialValues = {}

  if (record !== undefined) {
    initialValues = transformDuplicate(record)
  } else {
    if (composition !== undefined) {
      let defaultTitle = composition._title
      let defaultComposition = composition._title
      initialValues = {
        'composition': defaultComposition,
        'title': defaultTitle,
        'publishers': [
          {
            'owners': [{}],
            'ownership_writers': [{}],
            'sr_include_exclude': 'include',
            'mr_include_exclude': 'include',
            'pr_include_exclude': 'include'
          }
        ]
      }
    } else {
      initialValues = {
        'publishers': [
          {
            'owners': [{}],
            'ownership_writers': [{}],
            'sr_include_exclude': 'include',
            'mr_include_exclude': 'include',
            'pr_include_exclude': 'include'
          }
        ]
      }
    }
  }

  const { handleSubmit, errors} = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  useEffect(() => {
    window.$('[data-toggle=\'tooltip\']').tooltip()
    if (publisherIsSaving && pubRecord !== undefined) {
      setPublisherIsSaving(false)
    }
  }, [])


  const formatData = (values) => {
    const blankPub = {
      'owners': [{}],
      'ownership_writers': [{}],
      'sr_include_exclude': 'include',
      'mr_include_exclude': 'include',
      'pr_include_exclude': 'include'
    }

    let data = Object.assign({}, values)
    const shareTypes = ['sr', 'mr', 'pr']

    if (JSON.stringify(blankPub) === JSON.stringify(data.publishers[0])) {
      delete data.publishers
    }

    data.share_ids = shareIds

    if (data.collectors !== undefined) {
      data.collectors = data.collectors.map((collector, idx) => {
        if (collector !== undefined) {
          return collector.pk
        }
      })
      if (data.collector_role_code !== undefined) {
        data.collector_role_code = data.collector_role_code.value
      }
    }

    if (data.publishers !== undefined) {
      data.publishers = data.publishers.map((publisher) => {
        let pub = Object.assign({}, publisher)
        if (pub.owners !== undefined) {
          pub.owners = pub.owners.map((owner) => {
            if (owner.pk !== undefined) {
              return owner.pk
            }
          })
          if (pub.owner_role_code) {
            pub.owner_role_code = pub.owner_role_code.value
          }
        }
        if (pub.ownership_writers !== undefined) {
          pub.ownership_writers = pub.ownership_writers.map((writer) => {
            if (writer.writer.pk !== undefined) {
              return { writer: writer.writer.pk, writer_role_code: writer.writer_role_code.value }
            }
          })
        }

        shareTypes.map((type) => {
          if (Array.isArray(pub[type + '_share_territory'])) {
            if (pub[type + '_share_territory'] !== undefined) {
              pub[type + '_share_territory'] = (pub[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
            }
          } else {
            if (pub[type + '_share_territory'] !== undefined) {
              pub[type + '_share_territory'] = pub[type + '_share_territory']['value']
            }
          }
        })
        return pub
      })
    }

    if (data.writers !== undefined) {
      data.writers = data.writers.map((w, idx) => {
        let writer = Object.assign({}, w)
        writer.owners = writer.owners.map((owner, idx) => {
          if (owner.pk !== undefined) {
            return owner.pk
          }
        })
        if (writer.owner_role_code !== undefined) {
          writer.owner_role_code = writer.owner_role_code.value
        }
        shareTypes.map((type) => {
          if (Array.isArray(writer[type + '_share_territory'])) {
            if (writer[type + '_share_territory']) {
              writer[type + '_share_territory'] = (writer[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
            }
          } else {
            if (writer[type + '_share_territory'] !== undefined) {
              writer[type + '_share_territory'] = writer[type + '_share_territory']['value']
            }
          }
        })
        return writer
      })
    }

    _bulkUpdate(data).then(res => {
      if (res.status === 200) {
        toast.success('Successfully updated shares!')
        history.push('/shares/')
      }
    })
  }

  const toggleGenInfo = () => {
    setShowGenInfo(!showGenInfo)
  }

  const toggleCollectors = () => {
    setShowCollectors(!showCollectors)
  }

  const toggleOwnerships = () => {
    setShowOwnerships(!showOwnerships)
  }

  if ( initialValues === undefined) {
    return null
  }

  let userTerritoryPresets = []

  if (user) {
    userTerritoryPresets = user.territory_presets
  }

  return (
    <div className='share-form' id='share-form-wrap'>
      <div className='form-wrapper'>
        <form onSubmit={handleSubmit(formatData)} className='exp-form'>
          <div className="p-6">
            <div className='page-header'>
              <div className='page-nav'>
                <a className='btn btn-light btn-icon' href='/shares/'>
                  <Icon name='menu' />
                </a>
                <div className='page-title'>
                  <span className='main'>Update All Shares</span>
                </div>
              </div>
              <div className='page-tools'>
                <button
                  className='btn btn-sm btn-primary'
                  type='submit'
                  disabled={errors}>Save</button>
                <button className='btn btn-sm btn-blue-gray'
                  type='button'
                  onClick={() => history.goBack()}>Cancel</button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div>{shareUpdates.map((share, idx) => {
                return (
                  <div key={'share-' + idx + share['pk']} className='box box-white'>
                    <span className='title'>{share.title}</span>
                    <div className='table-asset-codes'>
                      <span>
                        <small>Collectors: </small>
                        {share.collectors.map((collector, idx) => {
                          return (
                            <span>
                              <span>{collector['name']}</span>
                              {share.collectors.length === (idx + 1) ? '' : <span key={'A' + collector['pk']}>,&nbsp;</span>}
                            </span>
                          )
                        })}
                      </span><br />
                      <span>
                        <small>Writers: </small>
                        {share.writers.map((writer, idx) => {
                          return (
                            <span>
                              <span>{writer['name']}</span>
                              {share.writers.length === (idx + 1) ? '' : <span key={'A' + writer['pk']}>,&nbsp;</span>}
                            </span>
                          )
                        })}
                      </span><br />
                      <span><small>ISWC: </small>{share.iswc ? share.iswc : '---'}</span><br />
                      <span><small>HFA Code: </small>{share.hfa_code ? share.hfa_code : '---'}</span><br />
                      <span><small>Custom ID: </small>{share.custom_id ? share.custom_id : '---'}</span>
                    </div>
                  </div>
                )
              })}</div>
            </div>
            <div className='col-md-6'>
              <div className='form-body'>
                {showGenInfo
                  ? (<button type='button' className='btn btn-link'
                    onClick={() => toggleGenInfo()}>
                    <Icon name='close' spaceAfter />Skip Update for General Info
                  </button>)
                  : (<div>
                    <button type='button' className='btn btn-link'
                      onClick={() => toggleGenInfo()}>
                      <Icon name='create' spaceAfter />Update General Info
                    </button>
                    <br /><br />
                  </div>)
                }

                {showGenInfo
                  && <GeneralInfo initialValues={initialValues} />}

                {showCollectors
                  ? (<button type='button' className='btn btn-link'
                    onClick={() => toggleCollectors()}>
                    <Icon name='close' spaceAfter />Skip Update for Collectors
                  </button>)
                  : (<div>
                    <button type='button' className='btn btn-link'
                      onClick={() => toggleCollectors()}>
                      <Icon name='create' spaceAfter />Update Collectors
                    </button>
                    <br /><br />
                  </div>)
                }

                {showCollectors
                  && <div className="col"lectors initialValues={initialValues} />}

                {showOwnerships
                  ? (<button type='button' className='btn btn-link'
                    onClick={() => toggleOwnerships()}>
                    <Icon name='close' spaceAfter />Skip Update for Ownerships
                  </button>)
                  : (<div>
                    <button type='button' className='btn btn-link'
                      onClick={() => toggleOwnerships()}>
                      <Icon name='create' spaceAfter />Update Ownerships
                    </button>
                    <br /><br />
                  </div>)
                }
                {showOwnerships
                  && <Ownership
                  initialValues={initialValues}
                  userTerritoryPresets={userTerritoryPresets}
                />}

                <div className='box'>
                  <div className='row'>
                    <div className='col form-actions'>
                      <button
                        className='btn btn-primary btn-wide btn-submit btn-flat'
                        type='submit'
                        disabled={errors}>Save</button>
                      <button className='btn btn-blue-gray btn-wide btn-submit btn-flat'
                        type='button'
                        onClick={() => history.goBack()}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminBulk
