import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, useFieldArray, Controller } from 'react-hook-form'

import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Creatable from 'layouts/components/Creatable'
import RenderPublishers from './RenderPublishers'

import { create } from 'api/publishers'

const PublisherForm = () => {
  const history = useHistory()

  const { register, handleSubmit, watch, control, setValue } = useForm({
    mode: 'onChange'
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'publishers',
    keyName: 'uuid'
  })

  const proOptions = [
    { value: '', label: '----' },
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'PRS', label: 'PRS' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'SOCAN', label: 'SOCAN' },
    { value: 'Other', label: 'Other' }
  ]

  const selectedPublishers = watch('publishers')

  const onSubmit = (values) => {
    create(values).then(res => {
      if (res.status === 201) {
        history.push('/publishers/' + res.data.external_id + '/')
        toast.success('Successfully created publisher!')
      } else {
        toast.error('Unable to create publisher')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create publisher')
    })
  }

  return (
    <form className="space-y-8" onSubmit={handleSubmit(onSubmit)} id='newPublisher'>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about the Publisher. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('name', { required: true })}
                  />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                IPI Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="ipi-number"
                  id="ipi-number"
                  autoComplete="ipi-number"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('ipi_number')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="pro-affiliation" className="block text-sm font-medium text-gray-700">
                PRO Affiliation
              </label>
              <div className="mt-1">
                  <Controller
                      control={control}
                      name='pro_affiliation'
                      render={(props) => (
                      <Creatable
                          {...props}
                          options={proOptions}
                          onChange={(e) => {
                          setValue('pro_affiliation', e)
                          }}
                      />
                      )}
                  />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="agreement_start_date" className="block text-sm font-medium text-gray-700">
                Sub-Publishing Start Date
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  name="agreement_start_date"
                  id="agreement_start_date"
                  autoComplete="agreement_start_date"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('agreement_start_date')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="royalty-code" className="block text-sm font-medium text-gray-700">
                Client Code
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="royalty-code"
                  id="royalty-code"
                  autoComplete="royalty-code"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('royalty_code')}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name='note'
                  render={(props) => (
                    <ReactQuill
                    {...props}
                    theme='snow'
                    placeholder='Add a note...'
                    onChange={(e) => {
                        setValue('note', e)
                    }}
                    />
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Any additional information for the publisher. (Ex: Agreement with Only Solutions Entertainment.)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Publisher Relations</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              List of related publishers and details of their relation. (* required)
            </p>
          </div>
          <RenderPublishers
            selectedPublishers={selectedPublishers}
            fields={fields}
            append={append}
            remove={remove}
            register={register}
            control={control}
            change={setValue}
          />
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}
export default PublisherForm
