import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'

import InfoBar from 'components/InfoBar'
import ListTable from 'components/ListTable'
import Heading from './components/Heading'

import { fetchAssets } from 'api/asset'

const AssetSearchView = () => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [filteredAssets, setFilteredAssets] = useState()
  const [totalPages, setTotalPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [rownum, setRowNum] = useState(20)

  const hasNext = currPage < totalPages

  const getList = (page, rNum, filter) => {
    setSearchQ(filter)
    fetchAssets(page, rNum, filter).then(res => {
      if (res.status === 200) {
        setFilteredAssets(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch assets')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch assets')
    })
  }

  useEffect(() => {
    if (query.q) {
      setSearchQ(query.q)
      getList(query.page, rownum, query.q)
    } else {
      setCurrPage(1)
      getList(1, rownum, '')
    }

  }, [location.search])

  const _onNext = (page) => {
    getList(page + 1, rownum)
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      getList(page - 1, rownum)
      setCurrPage(page - 1)
    }
  }

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
    }

  const _doSearch = (searchValue) => {
    let query = {
      page: 1,
      q: searchValue
    }
  
    if (searchValue === undefined || searchValue === '') {
      query = { page: 1 }
    }
    history.push({
      pathname: `/augmentor/assets`,
      search: `?${objectToQueryString(query)}`
    })
  }

  if (filteredAssets === undefined || filteredAssets === null) {
    return null
  }

  const assetColumns = [{
    Header: 'Asset ID',
    accessor: 'asset_id'
  }, {
    Header: 'Asset Title',
    accessor: 'asset_title'
  }, {
    Header: 'Report Type',
    accessor: 'report_type'
  }, {
    Header: 'Month',
    accessor: 'month'
  }, {
    Header: 'Year',
    accessor: 'year'
  }, {
    Header: 'Custom ID',
    accessor: 'custom_id'
  }, {
    Header: 'Asset Label',
    accessor: 'asset_label'
  }, {
    Header: 'Writers',
    accessor: 'writers'
  }, {
    Header: 'ISRC',
    accessor: 'isrc'
  }]

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {!searchFirst &&
          (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => history.push('/augmentor/assets')}
            showClose
          />)
        }
        <Heading
          searchFirst={searchFirst}
          handleSearch={_doSearch}
        />
        <div className='py-4'>
          <ListTable
            columns={assetColumns}
            data={filteredAssets}
            getNext={_onNext}
            getPrev={_onPrevious}
            currPage={currPage}
            setCurrPage={setCurrPage}
            pages={totalPages}
            hasNext={hasNext}
            showPagination
          />
        </div>
      </div>
    </div>
  )
}

export default AssetSearchView
