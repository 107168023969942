import React, { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import NoteBox from 'components/NoteBox'
import ActionFeed from 'components/ActionFeed'
import TableCodes from './TableCodes'
import TitleBox from './TitleBox'
import EditRecordingForm from './EditRecordingForm'
import DdexTab from './DdexTab'
import BulkOptions from 'components/BulkOptions'
import ExplorationDetails from 'components/ExplorationDetails'

import moment from 'moment'

import TitleForm from './TitleForm'
import ArtistForm from './ArtistForm'
import ReleaseForm from './ReleaseForm'
import ReleaseTable from './ReleaseTable'
import ArtistDetails from './ArtistDetails'

import { del, edit } from 'api/recordings'
import { getSelectedRecordingsFile as exportSelected } from 'api/download'
import toast from 'react-hot-toast'
import { transformData, checkProperties } from 'views/Recording/utils'
import { AuthContext } from 'stores/auth'

const PLATFORMS = [
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'youtube', label: 'YouTube' }
]

const Detail = ({ propsRecord }) => {
  const { id } = useParams()
  const history = useHistory()
  const authStore = useContext(AuthContext)

  const [showEditForm, setShowEditForm] = useState(false)
  const [showTitleEditForm, setShowTitleEditForm] = useState(false)
  const [toggleExport, setToggleExport] = useState(false)
  const [currNav, setCurrNav] = useState('Details')

  const [record, setRecord] = useState(propsRecord)

  let initialValues = {}
  if (record) {
    let recordCopy = Object.assign({}, record)
    initialValues = transformData(recordCopy)
    if (!initialValues['releases']) {
      initialValues['releases'] = [
        {
          'album_title': '',
          'release_date': '',
          'upc': ''
        }
      ]
    }
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: record ? initialValues : {}
  })

  const formatData = (data) => {
    if (data.related_artists !== undefined) {
      data.related_artists = data.related_artists.map((artist, idx) => {
        return artist.pk
      })
    }

    if (data.percentage === '') {
      data.percentage = null
    }

    if (data['releases']) {
      if (!checkProperties(data['releases'][0])) {
        delete data['releases']
      } else {
        for (var x of data['releases']) {
          if (x['release_date']) {
            let date = x['release_date']
            x['release_date'] = moment(date).format('YYYY-MM-DD')
          }
        }
      }
    }

    if (Array.isArray(data['territory'])) {
      data['territory'] = (data['territory'].map((terr) => { return terr.value })).join(',')
    } else {
      data['territory'] = data['territory']['value']
    }

    edit(data, id).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
        setShowEditForm(false)
        setShowTitleEditForm(false)
        toast.success('Successfully updated recording!')
      } else {
        toast.error('Unable to update recording')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update recording')
    })
  }

  const getExport = (typer) => {
    var data = { selected: [record.pk], typer: typer }
    exportSelected(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const _onDelete = () => {
    del(id).then(res => {
      if (res.status === 204) {
        history.push('/dashboard/')
        toast.success('Successfully deleted recording!')
      } else {
        toast.error('Unable to delete recording')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete recording')
    })
  }

  const _toggleEdit = (form) => {
    if (form === 'recording') {
      setShowEditForm(!showEditForm)
    }
  }

  const _toggleTitleEdit = (form) => {
    if (form === 'recordingTitle') {
      setShowTitleEditForm(!showTitleEditForm)
    }
  }

  if (record === undefined) {
    return null
  }

  var comp = record.composition
  let newArtists = record.related_artists
  let newRecordLabel = record.new_record_label
  let releases = record.releases
  const email = authStore.user.email
  const scope = authStore.scope

  let expPlatforms = []

  if (record.exploration_platforms !== undefined && record.exploration_platforms !== null) {
    record.exploration_platforms.map((platform) => (
      expPlatforms.push(
        (PLATFORMS.find((p) => platform.includes(p.value))).label
      )
    ))
  }

  const bulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: getExport,
      params: 'exprecordings'
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: getExport,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: getExport,
          params: 'scddex'
        }
      ]
    }
  ]

  const navigation = [
    { name: 'Details', href: '#nav-details'},
    { name: 'Notes', href: '#nav-notes' },
    { name: 'Activities', href: '#nav-activities' },
    { name: 'DDEX', href: '#nav-ddex'}
  ]

  return (<div className='flex flex-wrap flex-1 min-h-full'>
    <div className='p-6 flex-1'>
      <FormProvider {...methods}>
        <form className='form py-4' onSubmit={methods.handleSubmit(formatData)}>
          { showTitleEditForm
            ? <TitleForm initialValues={initialValues} />
            : <TitleBox
              record={record}
              comp={comp}
              setToggleExport={setToggleExport}
              toggleExport={toggleExport}
              toggleTitleEdit={_toggleTitleEdit}
              showTitleEditForm={showTitleEditForm}
              email={email}
              onDelete={_onDelete}
              scope={scope}
            />
          }

          {!showTitleEditForm &&
            <ExplorationDetails
              record={record}
              showTitleEditForm={showTitleEditForm}
              expPlatforms={expPlatforms}
            />
          }

          { showTitleEditForm
            ? <ArtistForm
              initialValues={initialValues}
              toggleTitleEdit={_toggleTitleEdit}
            />
            : <ArtistDetails
              record={record}
              newArtists={newArtists}
              newRecordLabel={newRecordLabel}
            />
          }

          <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
            <div className="ml-3">
              {navigation.map((item) => (
                <button
                  type='button'
                  key={item.name}
                  className={item.name === currNav 
                    ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                    : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  }
                  onClick={() => setCurrNav(item.name)}
                  aria-current={item.name === currNav ? 'page' : undefined}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </nav>
          <hr />

          <div className='tab-content' id='nav-tabContent'>
            {currNav === 'Details' &&
              (<div className='tab-pane tab-pad fade' id='nav-details' role='tabpanel' aria-labelledby='nav-details-tab'>
                {showEditForm
                  ? (<ReleaseForm initialValues={initialValues} />)
                  : (<ReleaseTable releases={releases} />)
                }
                { showEditForm
                  ? <EditRecordingForm handleCancel={_toggleEdit} />
                  : <TableCodes
                    _toggleEdit={_toggleEdit}
                    onDelete={_onDelete}
                    record={record}
                  />
                }
              </div>)
            }

            {currNav === 'Notes' &&
              (<div className='tab-pane tab-pad fade' id='nav-notes' role='tabpanel' aria-labelledby='nav-notes-tab'>
                <div>
                  <NoteBox model={'recording'}
                    hideTitle
                    pk={id} />
                </div>
              </div>) 
            }

            {currNav === 'Activities' &&
              (<div className='tab-pane tab-pad fade' id='nav-activities' role='tabpanel' aria-labelledby='nav-activities-tab'>
                <div className='tab-wrapper'>
                  <ActionFeed
                    model={'recording'}
                    pk={id} />
                </div>
              </div>)
            }

            {currNav === 'DDEX' &&
              (<div className='tab-pane tab-pad fade' id='nav-ddex' role='tabpanel' aria-labelledby='nav-ddex-tab'>
                <div className='tab-wrapper'>
                  <DdexTab record={record} />
                </div>
              </div>)
            }
          </div>
        </form>
      </FormProvider>
    </div>
    { toggleExport &&
      (<div className="flex-grow-0 flex-shrink-0">
        <BulkOptions items={bulkItems} onClose={setToggleExport} />
      </div>)
    }
  </div>)
}

export default Detail
