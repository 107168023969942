import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'

import './List.scss'
import InfoBar from 'components/InfoBar'
import ArtistListTable from './ArtistListTable'
import Modal from 'react-modal'
import { getList } from 'api/artist'
import ArtistSearch from './ArtistSearch'
import ArtistForm from './ArtistForm'

const ArtistSelectModal = ({ relatedArtists, status, toggleModal, assignArtist }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()
  const [showForm, setShowForm] = useState(false)
  const [customStyles, setCustomStyles] = useState({
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  })

  const doSearch = (sQ) => {
    if (status) {
      setSearchQ(sQ)
      setCurrPage(1)
      getList({ page: 1, q: sQ }).then(res => {
        if (res.status === 200) {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        } else {
          toast.error('Unable to fetch artists')
        }
      })
    }
  }

  const resetSearch = () => {
    getList({ page: 1, q: '' }).then(res => {
      setList(res.data.payload)
      setTotalPages(res.data.total_pages)
    })
    setSearchQ('')
  }

  const triggerForm = () => {
    setCustomStyles({
      content : {
        width                 : '60%',
        top                   : '53%',
        left                  : '58%',
        right                 : 'auto',
        bottom                : '-43%',
        marginRight           : '-20%',
        transform             : 'translate(-50%, -50%)',
        overflowY             : 'auto',
        maxHeight             : '40vh'
      }
    })
    setShowForm(true)
  }

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      {!showForm && (<div className="mt-5 mb-5 flex justify-end sm:mt-0">
          <button
            type='button'
            onClick={() => assignArtist(null)}
            className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
            Clear Artists
          </button>
          <button
            type='button'
            onClick={() => toggleModal()}
            className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
            Continue
          </button>
        </div>)
      }
      {searchQ === '' ? null : (
        <InfoBar
          text={'Showing search result for ' + "'" + searchQ + "'"}
          onClose={resetSearch}
          showClose
        />
      )}

      <div className='page'>
        {!showForm && (<div className='page-header'>
            <div className='page-title'>
              <span className='main'>Select Artists</span><br />
              <span className='form-help'>Select multiple Artists and click continue to close this window</span>
            </div>
            <ArtistSearch sQ={searchQ} doSearch={doSearch} placeholder={'Search for artist'} />
          </div>)
        }

        {relatedArtists !== undefined && relatedArtists !== null && Array.isArray(relatedArtists)
          ? (<div>{relatedArtists.map((artist, idx) => {
            return (
              <span onClick={() => assignArtist(artist)} key={idx} className='mr-1 text-white text-sm cursor-pointer rounded-md p-1 bg-red-600'>{artist.name}</span>
            )
          })}
          </div>)
          : null
        }

        <div className='page-body no-padding'>
          {!showForm && (<div className="mt-5 flex justify-end sm:mt-0">
              <button
                type='button'
                onClick={() => triggerForm()}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Create New
              </button>
            </div>)
          }
          {showForm
            ? (<ArtistForm
                setShowForm={setShowForm}
                setCustomStyles={setCustomStyles}
                assignArtist={assignArtist}
                toggleModal={toggleModal}
              />)
            : (<ArtistListTable
                propList={list}
                propPages={totalPages}
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                relatedArtists={relatedArtists}
                assignArtist={assignArtist}
            />)
          }
        </div>
      </div>
      {!showForm && (<button type='button'
          className='mb-5 float-right px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'
          onClick={() => toggleModal()}>
          Continue
        </button>)
      }
    </ Modal>
  )
}

export default ArtistSelectModal
