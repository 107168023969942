/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useForm, FormProvider } from 'react-hook-form'
import { TrashIcon, PencilIcon } from '@heroicons/react/outline'

import { deleteClaimSong as deleteClaimSongApi, editClaimSong as editClaimSongApi } from 'api/claim'

import ClaimTitleForm from './ClaimTitleForm'
import ClaimDetailForm from './ClaimDetailForm'
import ClaimIdForm from './ClaimIdForm'

export default function ClaimCard ({ item, getClaim }) {
  const [showEdit, setShowEdit] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: item
  })

  const platforms = {
    'youtube': 'YouTube',
    'soundexchange': 'SoundExchange',
    'mri': 'MRI',
    'mlc': 'MLC',
    'cmrra': 'CMRRA'
  }

  const deleteClaimSong = (id) => {
    deleteClaimSongApi(id).then(res => {
      if (res.status === 204) {
        toast.success('Successfully deleted claim!')
        getClaim()
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to delete claim.')
    })
  }

  const editClaimSong = (data) => {
    editClaimSongApi(item.pk, data).then(res => {
      if (res.status === 200) {
        toast.success('Successfully edited claim!')
        getClaim()
        setShowEdit(false)
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to edit claim.')
    })
  }
  
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(editClaimSong)}>
          <div className="px-4 py-5 sm:px-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                {showEdit
                  ? (<ClaimTitleForm />)
                  : (<div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {item.song_title}
                      <span className="ml-1 text-sm text-gray-500">({item.artist})</span>
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{item.label}</p>
                  </div>)
                }
              </div>
              <div className="mt-5 flex justify-center sm:mt-0">
                {showEdit
                  ? (<>
                    <button
                      type="button"
                      onClick={() => setShowEdit(false)}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                      Save
                    </button>
                  </>)
                  : (<>
                    <button
                      type="button"
                      onClick={() => setShowEdit(true)}
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                    >
                      Edit<PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this claim?')) {
                          deleteClaimSong(item.pk)
                        }
                      }}
                      className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                      Delete Claim
                      <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                    </button>
                  </>)
                }
                
              </div>
            </div>
          </div>
          <hr />
          {showEdit
            ? (<ClaimDetailForm item={item} />)
            : (<dl className="grid grid-cols-1 rounded-lg bg-white overflow-hidden divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Platform</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-red-600">
                    {platforms[item.platform]}
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Song Code</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-red-600">
                    {item.song_code}
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Total</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-red-600">
                    {item.overall_total}
                  </div>
                </dd>
              </div>
            </dl>)
          }
          <hr />
          <h3 className="text-md font-medium px-5 py-3 text-gray-900">
            Video IDs/ISRCs
          </h3>
          {showEdit
            ? (<ClaimIdForm />)
            : ( <div className="px-5 py-3 grid grid-cols-1 gap-4 sm:grid-cols-6">
            {item.claimed_ids.map((claim, idx) => (
              <div
                key={idx}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
              >
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-700">{claim}</p>
                </div>
              </div>
            ))}
          </div>)
          }
        </form>
      </FormProvider>
    </div>
  )
}
