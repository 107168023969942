import PublicAPI from './public'
import PrivateAPI from './private'

export const login = (data) => {
  return PublicAPI.post('/api/auth/', data)
}

export const verifyToken = (data) => {
  return PublicAPI.post('/api/auth/verify/', data)
}

export const getUsers = () => {
  return PrivateAPI.get('/api/users/')
}

export  const createUser = data => {
  return PrivateAPI.post('/api/users/', data)
}
