import React, { useState, useContext } from 'react'
import {
  MenuIcon
} from '@heroicons/react/outline'

import Header from "./components/Header"
import Menu from "./components/Menu"
import Breadcrumb from 'components/Breadcrumb'
import { AuthContext } from "stores/auth";

import { Toaster } from 'react-hot-toast';

const BaseContainer = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const authStore = useContext(AuthContext)

  let user = authStore.user
  let scope  = user?.profile?.scope || authStore.scope

  return (<>
    <div className="h-screen flex overflow-hidden bg-white">
      <Toaster position="top-right" />
      <Menu
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
        propScope={scope}
      />
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
            <a href='/'>
              <img
                className="h-15 w-auto"
                src="/images/new-exploration-logo.png"
                alt="ExplorationData"
              />
            </a>
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto bg-gray-50">
          {/* Start main area*/}
          <div className="absolute inset-0">
            <div className="flex flex-col h-screen h-full">
              <div className="min-w-full">
                <Header />
              </div>
              <div className="min-w-full">
                <Breadcrumb />
              </div>
              <div className="min-w-full">
                {children}
              </div>
            </div>
          </div>
          {/* End main area */}
        </main>
      </div>
    </div>
  </>);
}

export default BaseContainer;
