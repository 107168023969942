import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "mobx-react-lite/batchingForReactDom"
import { GoogleOAuthProvider } from "@react-oauth/google"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import AuthProvider from "stores/auth"
import AssetsProvider from "stores/assets"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"

library.add(fas, far, fab)

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter>
      <AuthProvider>
        <AssetsProvider>
          <App />
        </AssetsProvider>
      </AuthProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
