import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select'
import Creatable from 'react-select/creatable'
import './SelectInput.scss'
import createFilterOptions from 'react-select-fast-filter-options'

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, '')
})

const CreatableComponent = ({ onChange, isMulti, placeholder, disabled, options, ...props }) => {
  const filterOptions = createFilterOptions({
    indexes: ['label', 'value'],
    options
  })

  const [value, setValue] = useState()
  const [selectOptions, setSelectOptions] = useState([])

  const handleChange = (selectedOption) => {
    onChange(selectedOption)
    setValue(selectedOption)
    if (selectedOption) {
      return onChange(selectedOption.value)
    }
  }

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue)
    setValue(newOption)
    setSelectOptions((prevState) => [...prevState, newOption])
  }

  useEffect(() => {
    // if (options && value) {
    //   setValue(options.find(item => item.value === props.value))
    // }
    if (props?.field?.value?.value) {
      setValue({ label: props?.field?.value?.value, value: props?.field?.value?.value })
    }
    setSelectOptions(options)
  }, [])

  return (
    <div className='selectize-div'>
      <Creatable
        isClearable
        onChange={handleChange}
        onCreateOption={(newValue) => {
          handleCreate(newValue)
          onChange(newValue)
        }}
        options={selectOptions}
        onBlur={() => {}}
        value={value}
        placeholder={placeholder}
        isMulti={isMulti}
        disabled={disabled}
        filterOptions={filterOptions}
      />
    </div>
  )
}

// const Creatable = ({ onChange, isMulti, placeholder, disabled, options, ...props }) => {
//   const filterOptions = createFilterOptions({
//     indexes: ['label', 'value'],
//     options
//   })

//   const [value, setValue] = useState()
//   const handleChange = (selectedOption) => {
//     onChange(selectedOption)
//     setValue(selectedOption)
//   }

//   useEffect(() => {
//     if (options && value) {
//       setValue(options.find(item => item.value === props.value))
//     }
//   }, [])

//   return (
//     <div className='selectize-div'>
//       <CreatableSelect
//         {...props}
//         value={value}
//         placeholder={placeholder}
//         onChange={handleChange}
//         options={options}
//         isMulti={isMulti}
//         disabled={disabled}
//         filterOptions={filterOptions}
//       />
//     </div>
//   )
// }

export default CreatableComponent
