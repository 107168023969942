import React, { useState, useRef, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useForm, FormProvider } from 'react-hook-form'
import { PencilIcon } from '@heroicons/react/outline'

import ShareTable from './ShareTable'
import RecordingTable from './RecordingTable'
import NoteBox from 'components/NoteBox'
import ActionFeed from 'components/ActionFeed'
import BulkOptions from 'components/BulkOptions'
import TitleBox from './TitleBox'
import TitleForm from './TitleForm'

import { fetch } from 'api/composition'
import { compositionShareUpdate } from 'api/share'
import { setPrimaryRecording as setPrimaryRecordingApi } from 'api/recordings'
import { getSelectedCompositionsFile as exportSelected } from 'api/download'
import { AuthContext } from 'stores/auth'
import Codes from './Codes'
import MlcInfo from './MlcInfo'

function CompositionDetail ({ record, setRecord }) {
  const { id } = useParams()
  const authStore = useContext(AuthContext)
  let history = useHistory()

  const [showTable, setShowTable] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [showTitleEditForm, setShowTitleEditForm] = useState(false)
  const [toggleExport, setToggleExport] = useState(false)
  const [currNav, setCurrNav] = useState('Shares')
  const [viewAs, setViewAs] = useState('table')
  const [showForm, setShowForm] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')
  const compVal = useRef(null)

  let initialValues = {}

  if (record) {
    initialValues = Object.assign({}, record)
  }

  let codeInitialValues = {}
  if (record) {
    codeInitialValues = record.codes
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: codeInitialValues
  })

  const _toggleTitleEdit = (form) => {
    if (form === 'compTitle') {
      setShowTitleEditForm(!showTitleEditForm)
    }
  }

  const onSubmit = (data) => {
    let payload = Object.assign({}, data)
    payload['composition'] = id
    compositionShareUpdate(payload).then(res => {
      toast.success('Successfully edited code!')
      fetch(id).then((res) => {
        if (res.status === 200) {
          setRecord(res.data)
          setShowForm(false)
        } else {
          toast.error('Unable to fetch composition')
        }
      })
    })
    .catch(err => {
      console.log(err.response)
      toast.error('Error saving code.')
    })
  }

  const getExport = (typer) => {
    var data = { selected: [record.pk], typer: typer }
    exportSelected(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const setExport = () => {
    if (!toggleExport) {
      setShowTable(false)
      setToggleExport(true)
    } else {
      setShowTable(true)
      setToggleExport(false)
    }
  }

  const setPrimaryRecording = (pk) => {
    setPrimaryRecordingApi(pk).then(res => {
      toast.success('Successfully set primary recording!')
      fetch(id).then((res) => {
        if (res.status === 200) {
          setRecord(res.data)
        } else {
          toast.error('Unable to load changes')
        }
      })
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to set as primary recording')
    })
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  if (record === undefined) {
    return null
  }

  var adminshares = record.shares
  var recordings = record.recordings
  var user = authStore.user
  var ownership = parseFloat(record.ownership).toFixed(2)
  var email = user.email
  var scope = authStore.scope
  
  var ownerships = []
  for (var i = 0; i < adminshares.length; i++) {
    for (var j = 0; j < adminshares[i].ownerships.length; j++) {
      ownerships.push(adminshares[i].ownerships[j])
    }
  }

  let codes = record.codes

  const bulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExport,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  const navigation = [
    { name: 'Details', href: '#nav-details'},
    { name: 'Shares', href: '#nav-shares' },
    { name: 'Recordings', href: '#nav-recordings' },
    { name: 'Notes', href: '#nav-notes' },
    { name: 'Activities', href: '#nav-activities' },,
    { name: 'MLC', href: '#nav-mlc' }
  ]

  return (<div className='flex flex-wrap flex-1 min-h-full'>
    <div className='p-6 flex-1'>
      {showTitleEditForm
        ? <TitleForm
          initialValues={initialValues}
          ownership={ownership}
          id={id}
          showTitleEditForm={showTitleEditForm}
          setShowTitleEditForm={setShowTitleEditForm}
          setRecord={setRecord}
        />
      : <TitleBox
          id={id}
          _toggleTitleEdit={_toggleTitleEdit}
          setExport={setExport}
          ownership={ownership}
          record={record}
          email={email}
          scope={scope}
        />
      }

      <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
        <div className="ml-3">
          {navigation.map((item) => (
            <button
              type='button'
              key={item.name}
              className={item.name === currNav 
                ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
              }
              onClick={() => setCurrNav(item.name)}
              aria-current={item.name === currNav ? 'page' : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </nav>
      <hr />
      <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content' id='nav-tabContent'>
        {currNav === 'Details' &&
          (<div className='tab-pane tab-pad fade'
            id='nav-details' role='tabpanel' aria-labelledby='nav-details-tab'>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Codes
                        codes={codes}
                        showForm={showForm}
                    />
                    {showForm && (<div className="px-4 py-5">
                        <button
                            type='submit'
                            className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                            Save
                        </button>
                        <button
                            type='button'
                            className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                            onClick={() => { setShowForm(false) }}>
                            Cancel
                        </button>
                    </div>)
                    }
                </form>
            </FormProvider>
            {!showForm && (<div className="px-4 py-5">
                <button className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'
                  onClick={() => { setShowForm(true) }}>
                  Edit <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
            </div>)
            }
          </div>)
        }

        {currNav === 'Shares' &&
          (<div className='tab-pane tab-pad fade active show' id='nav-shares' role='tabpanel' aria-labelledby='nav-shares-tab'>
            {showTable && (<ShareTable
              record={record}
              adminshares={adminshares}
            />)
            }
          </div>)
        }

        {currNav === 'Recordings' &&
          (<div className='tab-pane tab-pad fade' id='nav-recordings' role='tabpanel' aria-labelledby='nav-recordings-tab'>
            {showTable && (<RecordingTable
                record={record}
                recordings={recordings}
                openModal={openModal}
                setPrimaryRecording={setPrimaryRecording}
              />)
            }
          </div>)
        }  
        {currNav === 'Notes' &&
          (<div className='tab-pane tab-pad fade' id='nav-notes' role='tabpanel' aria-labelledby='nav-notes-tab'>
            <div className='tab-wrapper'>
              <NoteBox model={'composition'} pk={record.external_id} />
            </div>
          </div>)
        }
        {currNav === 'Activities' &&
          ( <div className='tab-pane tab-pad fade' id='nav-activities' role='tabpanel' aria-labelledby='nav-activities-tab'>
            <div className='tab-wrapper'>
              <ActionFeed
                model={'composition'}
                pk={id} />
            </div>
          </div>)
        }
        {currNav === 'MLC' &&
          ( <div className='tab-pane tab-pad fade' id='nav-activities' role='tabpanel' aria-labelledby='nav-activities-tab'>
            <div className='tab-wrapper'>
              <MlcInfo mlcCode={codes['hfa_code']}  />
            </div>
          </div>)
        }
      </div>
    </div>
    { toggleExport &&
      (<div className="flex-grow-0 flex-shrink-0">
        <BulkOptions items={bulkItems} onClose={setExport} />
      </div>)
    }
  </div>)
}

export default CompositionDetail