import React from 'react'
import { useTable } from 'react-table'

import Paginator from 'components/Paginator'

const ListTable = ({ columns, data, getNext, getPrev, currPage, setCurrPage, hasNext, pages, showPaginationBottom, showPagination }) => {
  let _data = data
  if (data === undefined) {
    _data = []
  }

  let tableInit = { columns, data: _data }

  if (showPaginationBottom) {
    tableInit = {
      columns,
      data: _data,
      initialState: { pageIndex: 0, pageSize: 10 }
    }
  }

  // you can get the react table functions by using the hook useTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize }
  } = useTable(tableInit)

  return (<>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 shadow" {...getTableProps()}>
              <thead className="bg-gray-50" id="table-header">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      const {render, getHeaderProps} = column
                      return (
                        <th scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...getHeaderProps()}>
                          {render('Header')}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows.map((row, rowIdx) => {
                    prepareRow(row)
                    return (
                      <tr className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'} {...row.getRowProps()}>
                        {row.cells.map((cell, cellIdx) => {
                          return (
                            <td className={cellIdx === 1 ? 'px-3 py-4 text-sm font-medium break-all' : 'px-3 py-4 text-sm text-gray-500 break-all'}
                              {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ) 
                        })}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {showPagination &&
              <div className="">
                <Paginator
                  getNext={getNext}
                  getPrev={getPrev}
                  currPage={currPage}
                  setCurrPage={setCurrPage}
                  hasNext={hasNext}
                  totalPages={pages}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default ListTable
