const platforms = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'mri', label: 'MRI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'bmi', label: 'BMI' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'sub-publishing', label: 'Sub-Publishing' },
  { value: 'mlc', label: 'MLC' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'lyricfindmerch', label: 'LyricFindMerch' },
  { value: 'dl', label: 'Direct Licensing' },
  { value: 'sync', label: 'Sync' }
]

let platformList = []
platforms.map((platform) => (
  platformList[platform['value']] = platform['label']
))

export const transformData = (payload) => {
  let platformsReady = []
  if (payload['platforms_ready']) {
    payload['platforms_ready'].map((val, idx) => {
      platformsReady.push({
        value: val,
        label: platformList[val]
      })
    })
  }

  payload['ingestType'] = payload['ingest_type']
  payload['isUpdate'] = payload['is_update']
  payload['platformsReady'] = platformsReady
  return payload
}
