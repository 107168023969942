import React from 'react'
import { useForm } from 'react-hook-form'

import toast from 'react-hot-toast'

import { edit } from 'api/recordLabel'

const TitleForm = ({ initialValues, id, setRecord, showTitleEditForm, setShowTitleEditForm }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const onSubmit = (values) => {
    edit(values, id).then(res => {
      if (res.status === 200) {
        toast.success('Record label updated successfully!')
        setRecord(res.data)
      } else {
        toast.error(res.data.error)
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update record label')
    })
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow" onSubmit={handleSubmit(onSubmit)} id='recordLabelDetail'>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('name', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowTitleEditForm(!showTitleEditForm) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default TitleForm
