import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { TrashIcon } from '@heroicons/react/outline'

const ClaimIdForm = () => {
    const { register, control } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'claimed_ids',
        keyName: 'uuid'
    })

    return (<>
        <div className="px-5 py-3 grid grid-cols-1 gap-4 sm:grid-cols-6">
            {fields.map((claimId, idx) => (
                <div
                    key={idx}
                    className="relative rounded-lg border border-gray-300 bg-white px-2 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                >
                    <div className="flex-1 min-w-0">
                        <div className='flex justify-end'>
                            <button
                                className='text-gray-500 mb-2 hover:text-red-500'
                                type='button'
                                onClick={() => remove(idx)}>
                                <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="min-w-full">
                            <input
                                type="text"
                                name="song_code"
                                id="song_code"
                                autoComplete="song_code"
                                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                                {...register(`claimed_ids[${idx}]`, { required: true })}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className="px-5 pt-2 pb-5">
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={() => append()}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                >
                    Add ID
                </button>
            </div>
        </div>
    </>)
}

export default ClaimIdForm