import PrivateAPI from './private'

export const getBillboardCharts = (date) => {
  let payload = { 'date': date }
  return PrivateAPI.get('billboard-charts/', payload)
}

export const sendError = (payload) => {
  return PrivateAPI.post('report-error/', payload)
}

export const getErrors = () => {
  return PrivateAPI.get('report-error/')
}

export const getExpdataErrors = () => {
  return PrivateAPI.get('errors/')
}

export const getAssetIdSearch = (payload) => {
  return PrivateAPI.post('asset-id-search/', payload)
}

export const getMlcApi = (songCode) => {
  let payload = { 'song_code': songCode }
  return PrivateAPI.get('mlc/code-search/', payload)
}

export const getCompMlcData = (pk) => {
  let payload = { 'pk': pk }
  return PrivateAPI.get('mlc/composition-search/', payload)
}

export const undoDelete = (payload) => {
  return PrivateAPI.post('delete/', payload)
}

export const bulkDelete = (payload) => {
  return PrivateAPI.delete('delete/', payload)
}

export const getApiKey = () => {
  return PrivateAPI.get('api-key/')
}

export const runScript = (scriptValue) => {
  var params = {}
  params['script'] = scriptValue
  return PrivateAPI.get('scripts/', params)
}