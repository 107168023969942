import React, { useState } from 'react'
import './List.scss'

import InfoBar from 'layouts/CoreLayout/components/InfoBar'
import PublisherListTable from './PublisherListTable'
import Modal from 'react-modal'
import toast from 'react-hot-toast'
import PublisherSearch from './PublisherSearch'

import { getPublisherSelectList } from 'api/publishers'

const PublisherSelectModal = ({ status, toggleModal, selectedPublishers, assignPublisher }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  const doSearch = (sQ) => {
    if (status) {
      setSearchQ(sQ)
      setCurrPage(1)
      getPublisherSelectList({ page: 1, q: sQ }).then(res => {
        if (res.status === 200) {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        } else {
          toast.error('Unable to fetch publishers')
        }
      })
    }
  }

  const resetSearch = () => {
    getPublisherSelectList({ page: 1, q: '' }).then(res => {
      setList(res.data.payload)
      setTotalPages(res.data.total_pages)
    })
    setSearchQ('')
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    },
    overlay: {
      zIndex                : '10'
    }
  }
  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='float-right py-2 px-4 btn btn-primary btn-sm rounded-md'
        onClick={() => toggleModal()}>
        Continue
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={`Showing search result for '` + searchQ + `'`}
          onClose={resetSearch}
        />
      )}

      <div className='page'>
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Publisher</span><br />
            <span className='form-help'>Select multiple Publishers and click continue to close this window</span>
          </div>

          <PublisherSearch
            sq={searchQ}
            doSearch={doSearch}
          />
        </div>

        {selectedPublishers !== undefined && selectedPublishers !== undefined
          ? (<div>{selectedPublishers.map((pub, idx) => {
            return (
              <span key={idx} className='badge badge-pill badge-secondary'>{pub.pub_name}</span>
            )
          })}
          </div>)
          : null
        }
        <br />
      </div>
      <br />

      <div className='page-body no-padding'>
        <PublisherListTable
          propList={list}
          totalPages={totalPages}
          status={status}
          currPage={currPage}
          searchQ={searchQ}
          selectedPublishers={selectedPublishers}
          assignPublisher={assignPublisher}
        />
      </div>

      <button type='button'
        className='float-right py-2 px-4 btn btn-primary btn-sm rounded-md'
        onClick={() => toggleModal()}>
        Continue
      </button>
    </ Modal>
  )
}

export default PublisherSelectModal
