import PrivateAPI from './private'

export const getAll = () => {
  return PrivateAPI.get('api/v2/publishing/options/')
}

export const getPublishingSelectList = (payload) => {
  var params = {}
  var page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/publishing/options/', params)
}

export const getPublishingSelectPageList = (payload) => {
  var params = {}
  var page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/publishing/options/', params)
}
