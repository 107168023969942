import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ViewListIcon, SearchIcon, UploadIcon } from '@heroicons/react/outline'
import queryString from 'query-string'

import ImportedFiles from './components/ImportedFiles'
import './components/Form.scss'
import UploadForm from './components/UploadForm'
import { AuthContext } from 'stores/auth'

const AugmentorUploadView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [datafeed, setDatafeed] = useState(null)
  const authStore = useContext(AuthContext)

  let user = authStore.user
  let scope  = user?.profile?.scope || authStore.scope

  if (!scope || !user) {
    return null
  }

  return (
    <div className="flex flex-1 min-h-full min-w-full" id="augmentor">
      {/* Upload Form Component */}
      <div className="p-6 flex-1">
        <div className="flex items-center justify-between mb-4">
          {/* Title */}
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Augmentor
          </h2>

          {/* Buttons */}
          <div className="flex space-x-4">
            <a
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
              href="/augmentor/assets/"
            >
              Search Assets
              <SearchIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </a>
            <a
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
              href="/augmentor/dataset/"
            >
              Upload Dataset
              <UploadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="max-w-lg">
          <UploadForm datafeed={datafeed} />
        </div>
      </div>

      {/* Uploaded File List */}
      <div className="p-6 flex-1 bg-gray-50 rounded-md shadow-md">
        <div className="mb-2">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Uploaded Files</h2>
        </div>
        <ImportedFiles propScope={scope} propUser={user} />
      </div>
    </div>
  )
}

export default AugmentorUploadView
