import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import './components/List.scss'
import InfoBar from 'components/InfoBar'
import ListTable from './components/ListTable'
import Heading from './components/Heading'
import AdvancedSearch from 'components/AdvancedSearch'

import { getList } from 'api/artist'
import toast from 'react-hot-toast'

const ListView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  const [currPage, setCurrPage] = useState(parseInt(query.page))
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [filterQ, setFilterQ] = useState(query.f || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)

  useEffect(() => {
    if (query.q === undefined || query.q === '') {
      setCurrPage(parseInt(query.page))
      setSearchQ(query.q || '')
      setFilterQ(query.f || '')
      setRefreshTable(false)
    } else {
      if (query.q !== searchQ) {
        setSearchQ(query.q)
        setRefreshTable(!refreshTable)
      }
      if (query.f !== filterQ) {
        setFilterQ(query.f)
        setRefreshTable(!refreshTable)
      }
    }
  }, [location.search])

  const _listParams = (page, sQ, sort, filter, rownum) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (sQ !== undefined && sQ !== '') {
      queryString['q'] = sQ
    }

    if (rownum) {
      queryString['rownum'] = rownum
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }

    if (filter !== undefined) {
      queryString['f'] = filter
      window.filterParam = queryString['f']
    }

    return queryString
  }

  const getArtistList = (page, sQuery, sort, rownum) => {
    setSearchQ(sQuery)
    getList(_listParams(page, sQuery, sort, filterQ, rownum)).then(res => {
      toast.loading('Getting artists')
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        toast.dismiss()
      } else {
        toast.error('Unable to fetch artists')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch artists')
    })
  }

  const handleSearch = (searchQuery, filterOption, filterValue) => {
    setSearchQ(searchQuery)

    if (filterOption !== '-1' && filterOption !== undefined) {
      let filterQ = filterOption + '|' + filterValue
      getArtistList(currPage, searchQuery, undefined, filterQ)
    } else {
      getArtistList(currPage, searchQuery, undefined, '')
    }
  }

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (<div className="flex flex-1 min-h-full">
    <div className="p-6 flex-1">
      {!searchFirst &&
        (<InfoBar
          text={"Showing search result for '" + searchQ + "'"}
          onClose={() => getArtistList(1, '')}
        />)
      }
      <Heading
        searchFirst={searchFirst}
        showAdvanced={showAdvanced}
        handleSearch={handleSearch}
        toggleAdvanced={toggleAdvanced}
      />
      <div className='py-4'>
        <ListTable
          currentPage={currPage}
          setCurrPage={setCurrPage}
          searchQ={searchQ}
          refreshTable={refreshTable}
          artistList={list}
          pages={totalPages}
          getList={getArtistList}
        />
      </div>
    </div>
  </div>)
}

export default ListView
