import moment from 'moment'
import territories from 'helper/territories'

export const transformDuplicate = (payload) => {
  payload['title'] = payload['title'] + '-Copy'
  return payload
}

export const transformData = (payload) => {
  if (payload['exploration_platforms']) {
    payload['controlled'] = true
    payload['exploration_platforms'].map((val, idx) => {
      payload['platform__' + val] = true
    })
  }

  if (payload['releases']) {
    let releases = []
    for (var release of payload['releases']) {
      if (release['release_date']) {
        let releaseDate = release['release_date']
        release['release_date'] = moment(releaseDate).format('YYYY-MM-DD')
      }
      releases.push(release)
    }
    payload['releases'] = releases
  }

  if (payload['related_artists']) {
    let artistField = ''
    payload['related_artists'].map((val, idx) => {
      if (artistField.length > 0) {
        artistField = artistField + ', ' + val.name
      } else {
        artistField = val.name
      }
    })

    payload['artist_field'] = artistField

    let relatedArtists = []
    payload['related_artists'].map((val, idx) => {
      relatedArtists.push(val)
    })
    payload['related_artists'] = relatedArtists
  }

  if (payload['new_record_label']) {
    let recordLabel = payload['new_record_label']
    payload['label_field'] = recordLabel.name
    payload['new_record_label'] = recordLabel.pk
  }

  if (payload['composition']) {
    let composition = payload['composition']
    payload['composition_field'] = composition._title
    payload['composition'] = composition.pk
  }

  let recordingTerritories = []

  if (payload['territory']) {
    if (payload['territory'].includes(',')) {
      payload['territory'].split(',').map((terr, _idx) => {
        recordingTerritories.push({
          value: terr,
          label: territories[terr]
        })
      })
    } else {
      payload['territory'].split(' ').map((terr, _idx) => {
        recordingTerritories.push({
          value: terr,
          label: territories[terr]
        })
      })
    }
  }

  payload['territory'] = recordingTerritories
  return payload
}

export const checkProperties = (obj) => {
  for (var key in obj) {
    if (obj[key] !== '') {
      return true
    }
  }
  return false
}
