import toast from 'react-hot-toast'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ListTable from 'components/ListTable'
import queryString from 'query-string'

import { getList } from 'api/recordLabel'

const LabelListTable = ({ propList, propPages, searchQ, assignLabel }) => {
    const location = useLocation()
    let query = queryString.parse(location.search)
  
    const [currPage, setCurrPage] = useState(1)
    const [loading, setLoading] = useState(false)
  
    const [list, setList] = useState([])
    const [totalPages, setTotalPages] = useState()
  
    const _listParams = (page, sQ, sort) => {
      if (isNaN(page) || page < 1) {
        page = 1
      }
  
      let qS = { page: page }
  
      if (sQ !== undefined && sQ !== '') {
        query['q'] = sQ
      }
  
      if (sort !== undefined) {
        if (query.sort) {
          qS['sort'] = _updateSortParams(sort).join()
          window.sortParam = qS['sort']
        } else {
          qS['sort'] = sort
          window.sortParam = qS['sort']
        }
      }
      if (window.sortParam !== undefined) {
        qS['sort'] = window.sortParam
      }
      return qS
    }
  
    useEffect(() => {
      setLoading(true)
      if (propList !== undefined && propList.length > 0) {
        setList(propList)
        setTotalPages(propPages)
      } else {
        getList(_listParams(currPage, searchQ)).then(res => {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        })
      }
    }, [propList])
  
    const fetchData = () => {
      if (!loading && list === undefined) {
        setLoading(true)
        getList(this._listParams(currPage, searchQ)).then(res => {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        })
      }
  
      if (list !== undefined) {
        setLoading(false)
      }
    }
  
    const onPageChange = (evt) => {
      setCurrPage(evt.target.value)
  
      if (evt.target.value !== '' && parseInt(evt.target.value) !== currPage) {
        getList(_listParams(evt.target.value, searchQ)).then(res => {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        })
      }
    }
  
    const onSortedChange = (sort) => {
      setLoading(true)
      let newSort = sort[0]['id']
      getList(_listParams(currPage, searchQ, newSort)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
    }
  
    const _onNext = (page) => {
      setLoading(true)
      getList(_listParams(page + 1, searchQ)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
    }
  
    const _onPrevious = (page) => {
      if (page !== 1) {
        setLoading(true)
        getList(_listParams(page - 1, searchQ)).then(res => {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        })
        setCurrPage(page - 1)
      }
    }
  
    const _updateSortParams = (param) => {
      let sortParams = query.sort.split(',')
      let queryParams = []
      let added = false
  
      for (let i = 0; i < sortParams.length; i++) {
        let sortParam = sortParams[i].replace('-', '')
  
        if (param.toLowerCase() === sortParam.toLowerCase()) {
          if (sortParams[i].indexOf('-') === 0) {
            queryParams = [param.toLowerCase()]
          } else {
            queryParams = ['-' + param.toLowerCase()]
          }
          added = true
        } else {
          queryParams = [sortParams[i].toLowerCase()]
        }
      }
  
      if (!added) {
        queryParams = [param.toLowerCase()]
      }
  
      return queryParams
    }
  
    const renderArrow = (columnId, sort) => {
      if (sort === columnId) {
        return '▲'
      } else if (sort === '-' + columnId) {
        return '▼'
      }
      return ''
    }
  
    const hasNext = currPage < totalPages

    const labelColumns = [
        {
        Header: 'ID',
        id: 'pk',
        sortable: false,
        minWidth: 100,
        accessor: item => item['pk']
        }, {
        Header: 'name',
        id: 'name',
        sortable: false,
        maxWidth: 350,
        Cell: ({ row }) => (
            row.original.name
        )
        },{
        id: 'selected',
        maxWidth: 100,
        className: 'select',
        Cell: ({ row }) => (
            <div
            style={{
                textAlign:'right'
            }}
            >
            <button className='py-2 px-4 btn btn-primary btn-sm' type='button'
                onClick={() => assignLabel(row.original)}>Select</button>
            </div>
        ),
        sortable: false
        }
    ]

    return (
        <div className='list-table'>
            <ListTable
              columns={labelColumns}
              data={list}
              getNext={_onNext}
              getPrev={_onPrevious}
              currPage={currPage}
              setCurrPage={setCurrPage}
              hasNext={hasNext}
              pages={totalPages}
              showPagination={searchQ !== ''}
            />
        </div>
    )
}

export default LabelListTable