import React from 'react'
import { Controller } from 'react-hook-form'


import SelectField from 'layouts/components/Select'

const NewPublisher = ({ pub, values, index, saveNewPub, toggleNewPub, change, register, control }) => {
  var fieldStart = pub.split('[')[0]
  var fieldName = ''

  if (pub.includes('publishers')) {
    fieldName = pub + '.owners[0]'
  } else {
    fieldName = pub
  }

  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <>
      <div className="p-6">
        <div className='box box-white'>
          <div className='box-form-header'>New Publisher</div>
          <div className="py-2">
            <div className="col" xs={12} md={12}>
              <div controlId='newPubName'>
                <label className="block text-sm font-medium text-gray-700">Name<sup className='required-icon'>*</sup></label>
                <input
                  name={`${fieldName}.name`}
                  type='text'
                  ref={register({ required: true })} />
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="col" xs={12} md={6}>
              <div controlId='newPubIpi'>
                <label className="block text-sm font-medium text-gray-700">IPI Number</label>
                <input
                  name={`${fieldName}.ipi_number`}
                  type='text'
                  ref={register} />
              </div>
            </div>
            <div className="col" xs={12} md={6}>
              <div controlId='newPubPro'>
                <label className="block text-sm font-medium text-gray-700">Pro Affiliation</label>
                <Controller
                  control={control}
                  name={`${fieldName}.pro_affiliation`}
                  render={() => (
                    <SelectField
                      options={proOptions}
                      onChange={(e) => {
                        change(`${fieldName}.pro_affiliation`, e)
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='box'>
          <div className='row'>
            <div className='col form-actions'>
              {pub.includes('publishers')
                ? <button
                  className='btn btn-primary btn-wide btn-submit btn-flat'
                  type='submit'
                  onClick={() => saveNewPub(values[fieldStart][index]['owners'][0].name)}>Save</button>
                : <button
                  className='btn btn-primary btn-wide btn-submit btn-flat'
                  type='submit'
                  onClick={() => saveNewPub(values[fieldStart][index].name)}>Save</button>
              }
              <button
                className='btn btn-blue-gray btn-wide btn-submit btn-flat'
                type='button'
                onClick={() => toggleNewPub()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPublisher
