import React, { useState, useEffect, useContext } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser from 'react-html-parser'
import toast from 'react-hot-toast'
import moment from 'moment'
import Creatable from 'layouts/components/Creatable'
import ProfileImage from 'components/ProfileImage'

import { fetchNotes, sendNote, editNote } from 'api/composition'
import { AuthContext } from 'stores/auth'

const NoteBox = ({ model, pk, noteType }) => {
  const authStore = useContext(AuthContext)
  const [value, setValue] = useState('')
  const [noteTag, setNoteTag] = useState('')
  const [id, setId] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [notesList, setNotesList] = useState([])
  const [showAll, setShowAll] = useState(false)
  const user = authStore.user

  const getNotes = (noteTyper) => {
    fetchNotes(model, pk, noteTyper, showAll).then(res => {
      setNotesList(res.data)
    })
  }

  useEffect(() => {
    getNotes(noteType)
  }, [showAll])

  const handleSubmit = (evt) => {
    evt.preventDefault()
    const data = {
      [model]: pk,
      note: value.toString('html')
    }

    if (noteTag) {
      data['note_type'] = noteTag
    } else if (noteType) {
      data['note_type'] = noteType
    } else {
      data['note_type'] = 'GN'
    }

    sendNote(data).then(res => {
      toast.success('Note saved successfully!')
      getNotes(noteType)
    }).catch(err => {
      toast.error('Failed saving note')
    })
    setValue('')
    setNoteTag('')
  }

  const handleEdit = (evt) => {
    evt.preventDefault()
    const data = {
      content: value.toString('html')
    }
    if (noteTag) {
      data['note_type'] = noteTag
    } else if (noteType) {
      data['note_type'] = noteType
    } else {
      data['note_type'] = 'GN'
    }
    editNote(id, data).then(res => {
      toast.success('Note edited successfully!')
      getNotes(noteType)
    }).catch(err => {
      toast.error('Failed saving note')
    })
    setValue('')
    setId('')
    setNoteTag('')
  }

  const _triggerEdit = (value, noteId, tag) => {
    setValue(value)
    setId(noteId)
    setNoteTag('CWR')
    setIsEditing(true)
  }

  const cancelEdit = () => {
    setIsEditing(false)
    setId('')
    setValue('')
    setNoteTag('')
  }

  const noteOptions = [
    {value: 'GN', label: 'General Note'},
    {value: 'UN', label: 'Update Note'},
    {value: 'RN', label: 'Relinquishment Note'},
    {value: 'TN', label: 'Territory Restriction Note'},
    {value: 'CWR', label: 'CWR Note'},
    {value: 'CWR Return Note', label: 'CWR Return Note'}
  ]

  notesList.forEach(note => {
    let filteredOption = noteOptions.find(item => item.label === note.note_type|| item.value === note.note_type)
    if (!filteredOption) {
      noteOptions.push({ value: note.note_type, label: note.note_type })
    }
  })

  return (
    <div className='mb-5 px-4 py-4 note'>
      {notesList.length > 0 && 
        (<div>
          <div className='flex px-4'>
            <div className='flex-1'>
              {!noteType &&
                (<Creatable
                  className='form-control'
                  placeholder='Filter notes'
                  options={noteOptions}
                  onChange={(e) => {
                    getNotes(e)
                  }}
                />)
              }
            </div>
          </div>
  
          <ul role="list" className="py-4 divide-gray-200 py-5 px-4">
            {notesList.map((note) => (
              <li className='py-2' key={note.pk}>
                <div className='flex'>
                  <div className='mt-2 mr-2'>
                    <ProfileImage
                      email={note.creator}
                      className="inline-block h-10 w-10 rounded-full"
                    />
                  </div>
                  <div>
                    <div
                      className="z-0 relative bg-gray-100 py-5 px-5 rounded-3xl hover:bg-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-red-600">
                      <div className="flex justify-between space-x-3">
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-gray-900">
                            {note.creator}
                            <span className="px-2 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{note.note_type}</span>
                          </p>
                        </div>
                      </div>
                      <div className="z-10 mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">{ReactHtmlParser(note.content)}</p>
                        {note.note_type && note.note_type === 'RN' || note.note_type === 'Relinquishment Note'
                          && (<div>
                            <p className='mt-2 text-sm text-gray-600'>
                              Collectors:&nbsp;&nbsp;
                              {note.previous_info['collectors']
                                && (note.previous_info['collectors'].map((coll, idx) => {
                                  return (
                                    <div key={'coll-' + idx}>
                                      {coll.name !== undefined ? (
                                        <div>
                                          <a className='text-blue-500 hover:underline' href={`/${coll['set_type']}s/` + `${coll['external_id']}`}>
                                            {coll.name}
                                          </a>
                                          {note.previous_info['collectors'].length === (idx + 1) ? '' : <span>|</span>}
                                        </div>
                                        ) : null
                                      }
                                    </div>
                                  )
                                }))
                              }
                              Owners:&nbsp;&nbsp;
                              {note.previous_info['owners']
                                && (note.previous_info['owners'].map((owner, idx) => {
                                  return (
                                    <div key={'coll-' + idx}>
                                      {owner.name !== undefined ? (
                                        <div>
                                          <a className='text-blue-500 hover:underline' href={`/publishers/` + `${owner['external_id']}`}>
                                            {owner.name}
                                          </a>
                                          {note.previous_info['owners'].length === (idx + 1) ? '' : <span>|</span>}
                                        </div>
                                        ) : null
                                      }
                                    </div>
                                  )
                                }))
                              }
                            </p><br />
                          </div>)
                        }
                      </div>
                    </div>
                    <a href='#hideAll' onClick={() => _triggerEdit(note.content, note.pk, note.note_type)} className='ml-5 text-sm font-medium text-gray-600 display:block hover:underline'>
                      Edit
                    </a>
                    &nbsp;·&nbsp;
                    <time dateTime={note.created} className="flex-shrink-0 text-sm text-gray-500">
                      {moment(note.created).local().fromNow()}
                    </time>
                  </div>
                </div>
              </li>
            ))}
          </ul>
  
          <div className='flex-1 ml-4 mb-4'>
            {showAll
              ? (<a href='#hideAll' onClick={() => setShowAll(false)} className='text-sm font-medium text-gray-600 hover:underline'>
                  Hide other notes
                </a>)
              : (<a href='#showAll' onClick={() => setShowAll(true)} className='text-sm font-medium text-gray-600 hover:underline'>
                View more notes
              </a>)
            }
          </div>
        </div>)
      }    
      <div className='flex'>
        <form className='flex-1'>
          <div className='mx-3'>
            {!noteType &&
              (<Creatable
                placeholder='Add a tag'
                value={noteTag}
                options={noteOptions}
                onChange={(e) => {
                  setNoteTag(e)
                }}
              />)
            }
          </div>
          {model === 'add'
            ? (<ReactQuill className='rte form-control'
              theme='snow'
              name='note'
              placeholder='Add a note...'
              value={value}
              onChange={setValue}
              disabled />)
            : isEditing
            ? (<ReactQuill className='rte form-control'
              theme='snow'
              name='note'
              placeholder='Add a note...'
              value={value}
              onChange={setValue} />)
            : (<ReactQuill className='rte form-control'
              theme='snow'
              name='note'
              placeholder='Add a note...'
              value={value}
              onChange={setValue} />)
          }
          <div className='px-3 py-3'>
            {model === 'add'
              ? (<div>
                <button
                  className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  onClick={handleSubmit}
                  disabled>Submit</button>
                <button
                  onClick={() => { setValue('') }}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                  disabled>Cancel</button>
              </div>)
              : isEditing
              ? (<div>
                <button
                  className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  onClick={handleEdit}>Submit</button>
                <button
                  type='button'
                  onClick={cancelEdit}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>Cancel</button>
              </div>)
              : (<div>
                <button
                  className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  onClick={handleSubmit}>Submit</button>
                <button
                  type='button'
                  onClick={() => { setValue('') }}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>Clear</button>
              </div>)
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default NoteBox
