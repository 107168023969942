import React from 'react'
import { Route, Switch } from 'react-router'
import Form from './routes/Form/index'

export default function AssetReportViews () {
  return (
    <Switch>
      <Route exact path='/asset-report' component={Form} />
    </Switch>
  )
}
