
export const transformData = (payload) => {
  if (payload['related_artists']) {
    let artistField = ''
    payload['related_artists'].map((val, idx) => {
      if (artistField.length > 0) {
        artistField = artistField + ', ' + val.name
      } else {
        artistField = val.name
      }
    })

    payload['artist_field'] = artistField

    let relatedArtists = []
    payload['related_artists'].map((val, idx) => {
      relatedArtists.push(val)
    })
    payload['related_artists'] = relatedArtists
  }

  return payload
}
