import React from 'react'


import RenderReleases from 'views/Recording/components/RenderReleases'

const ReleaseForm = () => {
  return (<>
    <div className='mb-5 px-8 py-4 '>
      <label htmlFor='releases'>Releases</label>
      <div className='form-group col-md-12'>
        <RenderReleases />
      </div>
    </div>
  </>)
}

export default ReleaseForm
