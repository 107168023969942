import React from 'react';
import ReactTooltip from 'react-tooltip';
import './RoleCode.scss';

function RoleCode ({ key, roleCode, asBadge }) {
  let klassName = '';
  if (asBadge) {
    klassName += 'px-2 py-0.5 rounded font-medium bg-red-100 text-red-800';
  }

  let codeKey = key + 'roleCode'

  const option = {};
  option['AD'] = 'Adaptor';
  option['AM'] = 'Administrator';
  option['AR'] = 'Arranger';
  option['PR'] = 'Associated Performer';
  option['A'] = 'Author';
  option['C'] = 'Composer';
  option['CA'] = 'Composer/Author';
  option['E'] = 'Original Publisher';
  option['PA'] = 'Publisher Income Participant';
  option['SR'] = 'Sub-Arranger';
  option['SA'] = 'Sub-Author';
  option['SE'] = 'Sub-Publisher';
  option['ES'] = 'Substitute Publisher';
  option['TR'] = 'Translator';
  option['W'] = 'Writer';
  option['PY'] = 'Payee';

  if (!roleCode || roleCode === '') {
    return null
  }

  return (<>
    <span
      className={'text-xs text-gray-800 form-tip ' + klassName}
      data-tip data-for={codeKey}
    >{roleCode}</span>
    <ReactTooltip id={codeKey} type='dark' place='top'>
      <span>{option[roleCode]}</span>
    </ReactTooltip>
  </>);
};

export default RoleCode;
