import PrivateAPI from './private'

export const fetchConflicts = (page, rownum, term) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/conflicts/', params)
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/conflicts/' + pk + '/')
}

export const create = (payload) => {
  return PrivateAPI.postfile('api/conflicts/', payload)
}

export const del = (pk) => {
  return PrivateAPI.delete('api/conflicts/' + pk + '/')
}

export const onSubmit = (payload) => {
  return PrivateAPI.postfile('api/conflicts/feed/upload/', payload)
}

export const getConflictFeedList = (page, rownum, term, startDate, endDate, conflictStaff) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  if (startDate !== undefined && startDate !== null) {
    params['start_date'] = startDate
  }

  if (endDate !== undefined && endDate !== null) {
    params['end_date'] = endDate
  }

  if (conflictStaff !== undefined && conflictStaff !== null) {
    params['conflict_staff'] = conflictStaff
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/conflicts/feed/upload/', params)
}

export const fetchConflictFeed = (pk ,page, rownum, term) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/conflicts/feed/' + pk + '/', params)
}

export const deleteConflictFeed = (pk) => {
  return PrivateAPI.delete('api/conflicts/feed/' + pk + '/')
}