export const ALLOW_DELETE_ACCESS = [
    'stanley.semilla@exploration.io',
    'raymond.rillo@exploration.io',
    'rosa.catiis@exploration.io',
    'lance.orgil@exploration.io',
    'marby.gerodias@exploration.io',
    'ramon.mondigo@exploration.io',
    'jerrald.barbas@exploration.io',
    'jeff.cvetkovski@exploration.io',
    'cynthia.miolata@exploration.io',
    'rene.merideth@exploration.io',
    'aileen.delrosario@exploration.io',
    'zorayda.cariaso@exploration.io',
    'leizel.juralbal@exploration.io'
]