import React, { useState, useEffect } from 'react'
import ReactTable from 'layouts/components/ReactTable'
import { Link, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import './components/Search.scss'
import { getList } from 'api/search'
import { getSelectedSharesFile as exportSelectedShares,
  getSelectedRecordingsFile as exportSelectedRecordings,
  getSelectedWritersFile as exportSelectedWriters,
  getSelectedPublishersFile as exportSelectedPublishers } from 'api/download'
import { bulkDelete } from 'api/share'
import toast from 'react-hot-toast'

const SearchView = () => {
  const history = useHistory()
  const location = useLocation()
  const [query, setQuery] = useState(queryString.parse(location.search))

  const [currPage, setCurrPage] = useState(parseInt(query.page))
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectAll, setSelectAll] = useState(0)
  const [filtered, setFiltered] = useState([{ id: 'type', value: 'all' }])

  const [results, setResults] = useState()

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const _listParams = (page, searchQ, sort) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (searchQ !== undefined && searchQ !== '') {
      queryString['q'] = searchQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }

    return queryString
  }

  useEffect(() => {
    getList(_listParams(query.page, query.q)).then(res => {
      if (res.status === 200) {
        setResults(res.data.results)
      } else {
        toast.error('Unable to get search results')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get search results')
    })
  }, [query])

  useEffect(() => {
    const q = queryString.parse(location.search)
    setQuery(q)
  }, [location.search])

  const toggleRow = (pk) => {
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => {
      if (newSelected[x] === true) {
        indeterminate = true
      }
    })

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}

    if (selectAll === 0) {
      results.forEach(x => {
        newSelected[x.model_id] = true
      })
    }

    setSelected(newSelected)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const getExport = () => {
    const newSelected = Object.assign({}, selected)
    var newArray = []

    for (var i in newSelected) {
      if (newSelected[i] === true) {
        newArray.push(i)
      }
    }

    var data = { selected: newArray }
    if (filtered[0]['value'] === 'shares') {
      exportSelectedShares(data).then(res => {
        if (res.status === 200) {
          history.push('/download/my-exports/?date=' + res.data.date +'/')
        } else {
          toast.error('Unable to get export')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to get export')
      })
    }

    if (filtered[0]['value'] === 'writers') {
      exportSelectedWriters(data).then(res => {
        if (res.status === 200) {
          history.push('/download/my-exports/?date=' + res.data.date +'/')
        } else {
          toast.error('Unable to get export')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to get export')
      })
    }

    if (filtered[0]['value'] === 'publishers') {
      exportSelectedPublishers(data).then(res => {
        if (res.status === 200) {
          history.push('/download/my-exports/?date=' + res.data.date +'/')
        } else {
          toast.error('Unable to get export')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to get export')
      })
    }
  }

  const deleteAll = () => {
    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { 'share_ids': newArray }
    bulkDelete(data).then(res => {
      if (res.status === 200) {
        history.push('/dashboard/')
        toast.success('Successfully deleted shares!')
      } else {
        toast.error('Unable to delete shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete shares')
    })
  }

  const getShareExport = (typer) => {
    const newSelected = Object.assign({}, selected)
    var newArray = []

    for (var i in newSelected) {
      if (newSelected[i] === true) {
        newArray.push(i)
      }
    }

    var data = { selected: newArray, typer: typer }
    exportSelectedShares(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date +'/')
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getRecordingExport = (typer) => {
    const newSelected = Object.assign({}, selected)
    var newArray = []

    for (var i in newSelected) {
      if (newSelected[i] === true) {
        newArray.push(i)
      }
    }

    var data = { selected: newArray, typer: typer }
    exportSelectedRecordings(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date +'/')
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const searchColumns = [{
    Header: x => {
      return (
        <center>
          <div className='pretty p-default p-curve'>
            <input
              type='checkbox'
              checked={selectAll === 1}
              onChange={() => toggleSelectAll()}
            />
            {selectAll === 2
              ? (<div className='state p-danger'>
                <label />
              </div>)
              : (<div className='state'>
                <label />
              </div>)
            }
          </div>
        </center>
      )
    },
    id: 'selected',
    Cell: ({ row }) => (
      <center>
        <div className='pretty p-default p-curve'>
          <input
            type='checkbox'
            checked={selected[row.original.model_id] === true}
            onChange={() => toggleRow(row.original.model_id)}
          />
          <div className='state'>
            <label />
          </div>
        </div>
      </center>
    ),
    sortable: false,
    show: filtered[0]['value'] !== 'compositions' && filtered[0]['value'] !== 'all'
  }, {
    Header: 'ID',
    id: 'pk',
    sortable: false,
    filterable: false,
    width: 200,
    Cell: ({ row }) => (
      <div>
        <Link to={'/' + row.original.link_type + '/' + row.original.model_id + '/'}>{row.original.model_id}</Link>
      </div>
    )
  }, {
    Header: 'Title/Name',
    id: 'title',
    sortable: false,
    filterable: false,
    width: 300,
    Cell: ({ row }) => (
      <div>
        <Link to={'/' + row.original.link_type + '/' + row.original.model_id + '/'}>{row.original.title}</Link>
      </div>
    )
  }, {
    Header: '',
    id: 'model_info',
    sortable: false,
    filterable: false,
    width: 300,
    Cell: ({ row }) => (
      <div>
        {row.original.link_type === 'compositions'
          ? <ul className='list-ids'>
            <small className='label bg-green'>writers</small>
            <div>{row.original.model_info.writers.map((writer, idx) => {
              return (
                <a className='table-writer' key={writer.pk} href={'/writers/' + writer.pk + '/'}>{writer.name}</a>
              )
            })}
            </div>
            <small className='label bg-green'>collectors</small>
            <div>{row.original.model_info.collectors.map((pub, idx) => {
              return (
                <a className='table-writer' key={pub.pk} href={'/publishers/' + pub.pk + '/'}>{pub.name}</a>
              )
            })}
            </div>
          </ul>
          : row.original.link_type=== 'shares'
          ? <ul className='list-ids'>
            <small className='label bg-green'>writers</small>
            <div>{row.original.model_info.writers.map((writer, idx) => {
              return (
                <a className='table-writer' key={writer.pk} href={'/writers/' + writer.pk + '/'}>{writer.name}</a>
              )
            })}</div>
            <small className='label bg-green'>collectors</small>
            <div>{row.original.model_info.collectors.map((pub, idx) => {
              return (
                <a className='table-writer' key={pub.pk} href={'/publishers/' + pub.pk + '/'}>{pub.name}</a>
              )
            })}
            </div>
          </ul>
          : row.original.link_type === 'recordings'
          ? <ul className='list-ids'>
            <small className='label bg-green'>artists</small>
            {row.original.model_info.artists !== undefined
              ? (<div>{row.original.model_info.artists.map((artist, idx) => {
                return (
                  <a className='table-writer' key={artist.pk} href={'/artists/' + artist.pk + '/'}>{artist.name}</a>
                )
              })}
              </div>)
              : null
            }
          </ul>
          : row.original.link_type === 'writers'
          ? <ul className='list-ids'>
            <small className='label bg-green'>pub affiliation</small>
            <div>{row.original.model_info.publishers.map((pub, idx) => {
              return (
                <a className='table-writer' key={pub.pk} href={'/publishers/' + pub.pk + '/'}>{pub.name}</a>
              )
            })}
            </div>
            <small className='label bg-green'>ipi number</small>
            <li>{row.original.model_info.ipi_number}</li>
          </ul>
          : <ul className='list-ids'>
            <small className='label bg-green'>pro affiliation</small>
            <li>{row.original.model_info.pro_affiliation}</li>
            <small className='label bg-green'>ipi number</small>
            <li>{row.original.model_info.ipi_number}</li>
          </ul>
        }
      </div>
    )
  }, {
    Header: 'Type',
    id: 'type',
    sortable: false,
    filterable: true,
    width: 200,
    Cell: ({ row }) => (
      <div>
        <span>{row.original.model_type}</span>
      </div>
    ),
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true
      }
      if (filter.value === 'compositions') {
        return row.original.link_type === 'compositions'
      }
      if (filter.value === 'shares') {
        return row.original.link_type === 'shares'
      }
      if (filter.value === 'recordings') {
        return row.original.link_type === 'recordings'
      }
      if (filter.value === 'writers') {
        return row.original.link_type === 'writers'
      }
      if (filter.value === 'publishers') {
        return row.original.link_type === 'publishers'
      }
    },
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value='all'>Show All</option>
        <option value='compositions'>Compositions</option>
        <option value='shares'>Shares</option>
        <option value='recordings'>Recordings</option>
        <option value='writers'>Writers</option>
        <option value='publishers'>Publishers</option>
      </select>
  }]

  return (
    <div>
      <div className='box'>
        <div className='row box-header'>
          <div className='col'>
            <span className='box-title'>Search Results</span>
          </div>
          <div className='col-md-auto'>
            <div className='box-tools'>
              { selectAll !== 0 && filtered[0]['value'] === 'shares'
                ? (<a className='dropdown'>
                  <button type='button' className='btn btn-border btn-border-secondary dropdown-toggle' data-toggle='dropdown'>
                    <ion-icon name='cog' />&nbsp;Bulk Actions
                  </button>
                  <div className='dropdown-menu'>
                    <div className='dropdown-header'>Export Formats</div>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('exploration')}>
                      Exploration Format
                    </a>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('youtube')}>
                      YouTube Format
                    </a>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('subpublishing')}>
                      SubPublishing Format
                    </a>
                    <div className='dropdown-submenu'>
                      <a className='dropdown-item dropdown-toggle' data-toggle='dropdown'>BMI Format</a>
                      <div className='dropdown-menu'>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('bmiexploration')}>
                          Exploration Format
                        </a>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('bmiclient')}>
                          Client Format
                        </a>
                      </div>
                    </div>
                    <div className='dropdown-submenu'>
                      <a className='dropdown-item dropdown-toggle' data-toggle='dropdown'>MRI Format</a>
                      <div className='dropdown-menu'>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('mriexploration')}>
                          Exploration Format
                        </a>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('mriclient')}>
                          Client Format
                        </a>
                      </div>
                    </div>
                    <div className='dropdown-submenu'>
                      <a className='dropdown-item dropdown-toggle' data-toggle='dropdown'>HFA Format</a>
                      <div className='dropdown-menu'>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('hfaexploration')}>
                          Exploration Format
                        </a>
                        <a className='dropdown-item' href='#' onClick={() => getShareExport('hfaclient')}>
                          Client Format
                        </a>
                      </div>
                    </div>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('cmrra')}>
                      CMRRA Format
                    </a>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('lyricfind')}>
                      LyricFind Format
                    </a>
                    <a className='dropdown-item' href='#'
                      onClick={() => getShareExport('shellybay')}>
                      Shelly Bay Format
                    </a>
                    <div className='dropdown-header'>Other Actions</div>
                    <a className='dropdown-item' href='#'
                      onClick={(e) => {
                        if (window.confirm('Delete these shares?\nWARNING: ' +
                        'This action cannot be undone.')) {
                          deleteAll()
                        }
                      }}>
                      Delete All
                    </a>
                  </div>
                </a>)
                : selectAll !== 0 && filtered[0]['value'] === 'recordings'
                ? (<a className='dropdown'>
                  <button type='button' className='btn btn-border btn-border-secondary dropdown-toggle' data-toggle='dropdown'>
                    <ion-icon name='cog' />&nbsp;Bulk Actions
                  </button>
                  <div className='dropdown-menu dropdown-menu-right'>
                    <div className='dropdown-submenu'>
                      <a className='dropdown-item dropdown-toggle' data-toggle='dropdown'>SoundExchange Format</a>
                      <div className='dropdown-menu dropdown-menu-right'>
                        <a className='dropdown-item' href='#' onClick={() => getRecordingExport('seexploration')}>
                          Exploration Format
                        </a>
                        <a className='dropdown-item' href='#' onClick={() => getRecordingExport('seclient')}>
                          Client Format
                        </a>
                      </div>
                    </div>
                    <div className='dropdown-submenu dropdown-menu-right'>
                      <a className='dropdown-item dropdown-toggle' data-toggle='dropdown'>DDEX Format</a>
                      <div className='dropdown-menu'>
                        <a className='dropdown-item' href='#' onClick={() => getRecordingExport('ytddex')}>
                          YouTube Format
                        </a>
                        <a className='dropdown-item' href='#' onClick={() => getRecordingExport('scddex')}>
                          Soundcloud Format
                        </a>
                      </div>
                    </div>
                  </div>
                </a>)
                : null }
            </div>
          </div>
        </div>

        <div className='box-body no-padding'>
          <ReactTable
            data={results}
            columns={searchColumns}
            minRows={0}
            defaultPageSize={10}
            filtered={filtered}
            onFilteredChange={filtered => { setFiltered({ filtered: filtered, selected: {} }) }}
            className='table expdata-table'
            loadingText={'Loading search results...'}
            noDataText={'No search results available.'}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchView
