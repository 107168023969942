import React, { useEffect, useState } from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/outline"
import ReactTooltip from 'react-tooltip'

function AssetSearch ({ searchQ, doSearch, placeholder }) {  
    const [searchValue, setSearchValue] = useState()

    useEffect(() => {
        setSearchValue(searchQ)
    }, [searchQ])

    const _keyUp = (event) => {
        if (event.keyCode === 13) {
          doSearch(searchValue)
        }
    }

    let searchPlaceholder = placeholder ? placeholder : 'Search for a song'

    return (<>
    {/* Welcome panel */}
    <section aria-labelledby="profile-overview-title">
        <div className="mt-3 rounded-lg bg-white overflow-hidden">
            <div className="bg-white w-full relative flex items-center justify-between px-2 py-6">
                <div className="flex-1">
                    <div className="px-2 lg:px-6">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>   
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyUp={(event) => _keyUp(event)}
                            className="focus:ring-red-500 focus:border-red-500 disabled:bg-gray-200 block w-full sm:text-sm border-gray-200 rounded-md"
                            placeholder={searchPlaceholder}
                        />
                    </div>
                </div>
                <a data-tip data-for='search-tip' 
                    className="form-tip -ml-2 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                </a>
                <ReactTooltip id='search-tip' type='dark' place='right'>
                    <span>When searching for multiple songs, separate each query with a |. (Ex: Title 1|Title 2)</span>
                </ReactTooltip>
                <button
                    type="button"
                    onClick={() => doSearch(searchValue)}
                    className="inline-flex ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                >
                    Search
                </button>
            </div>
        </div>
    </section>
  </>)
}

export default AssetSearch