import React from 'react'
import { MusicNoteIcon, ExclamationCircleIcon } from '@heroicons/react/outline'

export default function RowDetails({ currentRow }) {
  return (
    <div className="rounded-lg bg-white space-y-4 p-4">
      {/* Title Section */}
      <div className="flex items-center gap-2">
        <MusicNoteIcon className="text-red-500 h-5 w-5" />
        <span className="font-semibold text-xl">{currentRow.asset_title}</span>
      </div>

      {/* Asset Label Section */}
      {currentRow.asset_label && (
        <div>
          <div className="font-bold mb-1">Asset Label:</div>
          <div>{currentRow.asset_label}</div>
        </div>
      )}
      
      {/* Writers Section */}
      <div>
        <div className="font-bold mb-1">Writers:</div>
        <div>{currentRow.writers}</div>
      </div>

      {/* Custom ID Section */}
      {currentRow.custom_id && (
        <div>
          <div className="font-bold mb-1">Custom ID:</div>
          <div>{currentRow.custom_id}</div>
        </div>
      )}

      {/* Missing Fields Warning */}
      {currentRow.missing_fields?.length > 0 && (
        <div className="bg-amber-50 p-3 rounded-md text-sm">
          <div className="flex items-center gap-2 text-amber-800">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span>This song doesn't have the ff:</span>
          </div>
          <ul className="list-disc ml-6 mt-1 text-amber-800">
            {currentRow.missing_fields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
