import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Switch } from '@headlessui/react'

import RenderAlternateTitles from './RenderAlternateTitles'
import SelectField from 'layouts/components/Select.js'
import { AssignToComposition } from 'layouts/components/CompositionModalSelect/AssignToComposition'
import CompositionSelectModal from 'layouts/components/CompositionModalSelect/CompositionSelectModal'
import { AssignToArtist } from 'layouts/components/ArtistModalSelect/AssignToArtist'
import ArtistSelectModal from 'layouts/components/ArtistModalSelect/ArtistSelect'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const GeneralInfo = ({ composition }) => {
  const [selectCompositionModal, setSelectCompositionModal] = useState(false)
  const [selectedComposition, setSelectedComposition] = useState(composition ? composition : null)
  const [selectedCompositionField, setSelectedCompositionField] = useState(composition ? composition._title : '')
  const [selectedArtists, setSelectedArtists] = useState([])
  const [selectedArtistField, setSelectedArtistField] = useState('')
  const [selectArtistModal, setSelectArtistModal] = useState(false)

  useEffect(() => {
    if (composition) {
      setSelectedComposition(composition)
      setSelectedCompositionField(composition._title)
    }
  }, [composition])

  const { register, errors, watch, control, setValue } = useFormContext()

  const compValue = watch('composition')
  const artistValue = watch('related_artists')
  const reviewed = watch('reviewed')
  const arrangement = watch('arrangement')

  const assignComposition = (composition) => {
    if (composition) {
      setValue('composition_field', composition._title)
      setValue('composition', composition)
      setSelectedComposition(composition)
      setSelectedCompositionField(composition._title)
      setSelectCompositionModal(false)
    } else {
      setValue('composition_field', ' ')
      setValue('composition', null)
      setSelectedComposition(null)
      setSelectedCompositionField(' ')
      setSelectCompositionModal(false)
    }
  }

  const assignArtist = (artist) => {
    let artists = selectedArtists
    let artistField = selectedArtistField

    if (artists.includes(artist)) {
      artists.splice(artists.indexOf(artist), 1)

      let artistString = ', ' + artist.name

      if (artistField.includes(artistString)) {
        artistField = artistField.replace(artistString, '')
      } else {
        artistField = artistField.replace(artist.name + ', ', '')
      }
    } else {
      artists.push(artist)

      if (artistField.length > 0) {
        artistField = artistField + ', ' + artist.name
      } else {
        artistField = artist.name
      }
    }

    setValue(`artist_field`, artistField)
    setValue(`related_artists`, artists)

    setSelectedArtistField(artistField)
    setSelectedArtists(artists)
  }

  const toggleSelectArtistModal = () => {
    setSelectArtistModal(!selectArtistModal)
  }

  const toggleSelectCompositionModal = () => {
    setSelectCompositionModal(!selectCompositionModal)
  }

  let reviewSelect = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  return (
    <>
      <CompositionSelectModal
        status={selectCompositionModal}
        toggleModal={toggleSelectCompositionModal}
        assignComposition={assignComposition}
      />
      <ArtistSelectModal
        status={selectArtistModal}
        toggleModal={toggleSelectArtistModal}
        assignArtist={assignArtist}
        relatedArtists={artistValue}
      />
      <div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Basic information about the Shares. (* required)
          </p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('title', { required: true })}
              />
            </div>
            <RenderAlternateTitles />
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Related Composition
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name='composition_field'
                render={() => (
                  <AssignToComposition
                    className='form control appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    value={selectedCompositionField ? selectedCompositionField : ''}
                    toggleSelectCompositionModal={toggleSelectCompositionModal}
                  />
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
              Artists{arrangement && (<sup className='required-icon'>*</sup>)}
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name='artist_field'
                render={(props) => (
                  <AssignToArtist
                    className='form-control'
                    value={selectedArtistField}
                    toggleSelectArtistModal={toggleSelectArtistModal}
                  />
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-1">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Reviewed?
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Has this been reviewed?
                </Switch.Description>
              </span>
              <Switch
                checked={reviewed}
                onChange={(e) => setValue('reviewed', e)}
                className={classNames(
                  reviewed ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    reviewed ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div className="sm:col-span-1">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Arrangement?
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Is this an arrangement?
                </Switch.Description>
              </span>
              <Switch
                checked={arrangement}
                onChange={(e) => setValue('arrangement', e)}
                className={classNames(
                  arrangement ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    arrangement ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          {arrangement && (<div className="sm:col-span-2">
            <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
              ISRC<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('isrc')}
              />
            </div>
          </div>)}
          
          <div className="sm:col-span-2">
            <label htmlFor="custom-id" className="block text-sm font-medium text-gray-700">
              Custom ID<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('custom_id', { required: true })}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="iswc" className="block text-sm font-medium text-gray-700">
              ISWC
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('iswc')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="yt-comp-share-id" className="block text-sm font-medium text-gray-700">
              YouTube Comp. Share ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('yt_comp_share_id')}
              />
            </div>
          </div>
          
          <div className="sm:col-span-2">
            <label htmlFor="ascap-work-id" className="block text-sm font-medium text-gray-700">
              ASCAP Work ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('ascap_work_id')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="bmi-work-id" className="block text-sm font-medium text-gray-700">
              BMI Work ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('bmi_work_id')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="sesac-work-id" className="block text-sm font-medium text-gray-700">
              SESAC Work ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('sesac_work_id')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="cmrra-work-id" className="block text-sm font-medium text-gray-700">
              CMRRA Work ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('cmrra_work_id')}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="hfa-code" className="block text-sm font-medium text-gray-700">
              HFA/MLC Code
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('hfa_code')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="language-code" className="block text-sm font-medium text-gray-700">
              Language Code
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('language_code')}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="client-song-id" className="block text-sm font-medium text-gray-700">
              Client Song ID
            </label>
            <div className="mt-1">
              <input
                type='text'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register('client_song_id')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralInfo
