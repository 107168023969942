import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import { TrashIcon, XIcon } from '@heroicons/react/outline'

import CompositionDetail from './components/CompositionDetail'

import { fetchDuplicate, resolveDuplicate } from 'api/composition'

const DetailView = () => {
  const { id } = useParams()
  const history = useHistory()

  const [duplicate, setDuplicate] = useState()

  useEffect(() => {
    if (duplicate === undefined || duplicate.pk !== id) {
      fetchDuplicate(id).then(res => {
        if (res.status === 200) {
          setDuplicate(res.data)
        } else {
          toast.error('Unable to fetch duplicate')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch duplicate')
      })
    }
  })

  const handleResolveDupe = (value) => {
    const payload = { 'action': value }
    resolveDuplicate(payload, id).then(res => {
      if (res.data === 200) {
        history.push('/duplicates/')
        toast.success('Successfully resolved duplicate!')
      } else {
        toast.error('Unable to resolve duplicate')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to resolve duplicate')
    })
  }
  if (duplicate === undefined) {
    return null
  }

  var compDupe = duplicate.composition
  var potentialDupe = duplicate.duplicate

  return (<>
    <div className='flex flex-wrap flex-1 min-h-full'>
      <div className="p-6 flex-1">

        <div className="mb-2 md:flex md:items-center md:justify-end">
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            <button
              type='button'
              onClick={(e) => {
                if (window.confirm('Delete all?\nWARNING: ' +
                'Deleting both compositions will delete all linked Shares and Recordings')) {
                  handleResolveDupe('all')
                }
              }}
              className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
              Delete All
              <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type='button'
              onClick={() => handleResolveDupe('none')}
              className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
              Not a Duplicate
              <XIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="py-5">
          <CompositionDetail
            divId='duplicate'
            entry={compDupe} />
        </div>

        <hr />

        <div className="py-5">
          <CompositionDetail
            divId='potentialDupe'
            entry={potentialDupe} />
        </div>
      </div>
    </div>
  </>)
}

export default DetailView
