import React from 'react'
import { Route, Switch } from 'react-router'
import List from './routes/List/index'
import Detail from './routes/Detail/index'
import Form from './routes/Form/index'

export default function PublisherViews () {
  return (
    <Switch>
      <Route exact path='/publishers' component={List} />
      <Route path='/publishers/add' component={Form} />
      <Route path='/publishers/:id' component={Detail} />
    </Switch>
  )
}
