import React, { useState } from 'react'
import toast from 'react-hot-toast'

import './List.scss';

import InfoBar from 'components/InfoBar'
import Modal from 'react-modal'
import CompositionSearch from './CompositionSearch'

import { getCompositionSelectList } from 'api/composition'
import CompositionListTable from './CompositionListTable'

const CompositionSelectModal = ({ status, toggleModal, assignComposition }) => {
 const [currPage, setCurrPage] = useState(1)
 const [searchQ, setSearchQ] = useState('')
 const [list, setList] = useState()
 const [totalPages, setTotalPages] = useState()

  const resetSearch = () => {
    getCompositionSelectList({ page: 1, q: '' }).then(res => {
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch compositions')
      }
    })
    setSearchQ('')
  }

  const doSearch = (sQ) => {
    if (status) {
      setSearchQ(sQ)
      setCurrPage(1)
      getCompositionSelectList({ page: 1, q: sQ }).then(res => {
        if (res.status === 200) {
          setList(res.data.results)
          setTotalPages(res.data.total_pages)
        } else {
          toast.error('Unable to fetch compositions')
        }
      })
    }
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    },
    overlay: {
      zIndex                : '10'
    }
  }

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='float-right py-2 px-4 btn btn-primary btn-sm rounded-md'
        onClick={() => assignComposition(null)}>
        Clear Composition
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={`Showing search result for '` + searchQ + "'"}
          onClose={() => resetSearch()}
        />
      )}

      <div className='page'>
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Composition</span>
          </div>
          <CompositionSearch list={list} sQ={searchQ} doSearch={doSearch} />
        </div>
        <div className='page-body no-padding'>
          <CompositionListTable
            propList={list}
            propPages={totalPages}
            status={status}
            currPage={currPage}
            searchQ={searchQ}
            assignComposition={assignComposition}
          />
        </div>
      </div>
    </ Modal>
  )
}

export default CompositionSelectModal
