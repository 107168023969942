import React, { useState } from 'react'
import { UserIcon, ViewGridIcon, ArrowsExpandIcon } from '@heroicons/react/outline'
import ConflictCard from './ConflictCard'

export default function ConflictListItem ({ conflict }) {
  const [showCard, setShowCard] = useState(false)
  
  const platforms = {
    'ascap': 'ASCAP',
    'bmi': 'BMI',
    'cmrra': 'CMRRA',
    'distribution': 'Distribution',
    'hfa': 'HFA',
    'lyricfind': 'LyricFind',
    'mlc': 'MLC',
    'mri': 'MRI',
    'soundexchange': 'SoundExchange',
    'subpublishing': 'Sub-Publishing',
    'youtube': 'YouTube'
  }

  return (
    <>
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-left justify-between">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                    <p className="truncate text-base font-medium text-red-600">{conflict.title}</p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <span className="truncate">{conflict.client_name}</span>
                    </p>
                </div>
                <div className="hidden md:block">
                    <div>
                    <p className="text-base font-medium text-gray-900">
                        Resolved on <time dateTime={conflict.date_resolved}>{conflict.date_resolved}</time>
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                        <ViewGridIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" />
                        {platforms[conflict.platform]}
                    </p>
                    </div>
                </div>
            </div>
            <div>
                <ArrowsExpandIcon onClick={() => setShowCard(!showCard)} className="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true" />
            </div>
        </div> 
      </div>
      {showCard && (<ConflictCard conflict={conflict} />)}
    </>
  )
}
