import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'
import { DownloadIcon, MinusCircleIcon, RefreshIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

import ListTable from 'components/ListTable'
import InfoBar from 'components/InfoBar'
import AssetSearch from 'components/AssetSearch'
import { fetchCompositions } from 'api/publishers'
import { bulkRelinquishShare as bulkRelinquishShareApi, bulkUndoRelinquishShare as bulkUndoRelinquishShareApi } from 'api/share'
import { relinquishShares as relinquishSharesApi, undoRelinquishShares as undoRelinquishSharesApi } from 'api/publishers'

const RelatedAssets = ({ id, isUnknown, relinquished, songCount, setExport, showTable, setShowTable, selectAll, setSelectAll, selected, setSelected }) => {
  const history = useHistory()
  const location = useLocation()

  const query = queryString.parse(location.search)
  const [compositions, setCompositions] = useState([])
  const [pages, setPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [rownum, setRownum] = useState(10)
  const [searchQ, setSearchQ] = useState(query.qf || '')
  const [showAssets, setShowAssets] = useState(Boolean(query.show))

  const getList = (page, rowCount, filter) => {
    fetchCompositions(id, page, rowCount, filter).then(res => {
      if (res.status === 200) {
        setCompositions(res.data.results)
        setPages(res.data.total_pages)
        setShowTable(true)
        toast.dismiss()
      } else {
        toast.error('Unable to fetch assets')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch assets')
    })
  }

  const filterList = (filter) => {
    toast.loading('Loading assets...')
    getList(1, rownum, filter)
  }

  useEffect(() => {
    if (searchQ) {
      filterList(searchQ)
    } else {
      if (query.page && showAssets) {
        toast.loading('Loading assets...')
        let query = {
          page: currPage,
          show: true
        }

        history.replace({
          pathname: `/publishers/${id}`,
          search: `?${objectToQueryString(query)}`
        })
        getList(currPage, rownum)
        setShowAssets(true)
      } else {
        setShowTable(false)
        setShowAssets(false)
      }
    }
  }, [searchQ, currPage])

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
  }

  const _doSearch = (filter) => {
    let query = {
      page: 1,
      qf: filter,
      show: true
    }
  
    if (filter === undefined || filter === '') {
      query = { page: 1, show: true }
    }
    history.replace({
      pathname: `/publishers/${id}`,
      search: `?${objectToQueryString(query)}`
    })
    setSearchQ(filter)
    setCurrPage(1)
  }

  const relinquishSelectedShares = () => {
    toast('Relinquishing shares...')

    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { selected: newArray }
    bulkRelinquishShareApi(data).then(res => {
      if (res.status === 200) {
        toast.success('We are currently processing relinquishment. Once relinquishment has been processed, you will receive a notification via email.')
      } else {
        toast.error('Unable to relinquish shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to relinquish shares')
    })
  }

  const undoRelinquishSelectedShares = () => {
    toast('Undoing relinquishment...')

    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { selected: newArray }
    bulkUndoRelinquishShareApi(data).then(res => {
      if (res.status === 200) {
        toast.success('We are currently undoing relinquishment. Once relinquishment has been undone, you will receive a notification via email.')
      } else {
        toast.error('Unable to undo relinquishment')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to undo relinquishment')
    })
  }

  const relinquishShares = () => {
    toast('Relinquishing shares...')
    relinquishSharesApi(id).then(res => {
      if (res.status === 200) {
        toast.success('We are currently processing relinquishment. Once relinquishment has been processed, you will receive a notification via email.')
      } else {
        toast.error('Unable to relinquish shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to relinquish shares')
    })
  }

  const undoRelinquishShares = () => {
    toast('Undoing relinquishment...')
    undoRelinquishSharesApi(id).then(res => {
      if (res.status === 200) {
        toast.success('We are currently undoing relinquishment. Once relinquishment has been undone, you will receive a notification via email.')
      } else {
        toast.error('Unable to unrelinquish shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to unrelinquish shares')
    })
  }

  const _onNext = (page) => {
    getList(page + 1, rownum)
    setCurrPage(page + 1)
    let query = {
      page: page + 1,
      show: true
    }

    if (searchQ) {
      query['qf'] = searchQ
    }

    history.replace({
      pathname: `/publishers/${id}`,
      search: `?${objectToQueryString(query)}`
    })
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      getList(page - 1, rownum)
      setCurrPage(page - 1)

      let query = {
        page: page - 1,
        show: true
      }
  
      if (searchQ) {
        query['qf'] = searchQ
      }
  
      history.replace({
        pathname: `/publishers/${id}`,
        search: `?${objectToQueryString(query)}`
      })
    }
  }

  const toggleRow = (pk) => {
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => (
      (newSelected[x] === true) && (
        indeterminate = true
      )
    ))

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}

    if (selectAll === 0) {
      compositions.forEach(x => {
        newSelected[x.pk] = true
      })
    }

    setSelected(newSelected)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const resetSearch = () => {
    let query = {
      show: false
    }
    history.replace({
      pathname: `/publishers/${id}`,
      search: `?${objectToQueryString(query)}`
    })

    setSearchQ('')
    setSelected({})
    setSelectAll(0)
  }

  const showAllAssets = () => {
    toast.loading('Loading assets...')
    let query = {
      page: 1,
      show: true
    }

    if (searchQ) {
      query['qf'] = searchQ
    }

    history.replace({
      pathname: `/publishers/${id}`,
      search: `?${objectToQueryString(query)}`
    })

    getList(1, rownum)
    setCurrPage(1)
    setShowAssets(true)
  }

  const hideAllAssets = () => {
    let query = {
      show: false
    }
    history.replace({
      pathname: `/publishers/${id}`,
      search: `?${objectToQueryString(query)}`
    })

    setSearchQ('')
    setSelected({})
    setSelectAll(0)
    setShowTable(false)
    setShowAssets(false)
  }

  const compositionColumns = [{
    Header: x => {
      return (
        <center>
          <div className='pretty p-default p-curve'>
            <input
              type='checkbox'
              checked={selectAll === 1}
              onChange={() => toggleSelectAll()}
            />
            {selectAll === 2
              ? (<div className='state p-danger'>
                <label />
              </div>)
              : (<div className='state'>
                <label />
              </div>)
            }
          </div>
        </center>
      )
    },
    id: 'selected',
    Cell: ({ row }) => (
      <center>
        <div className='pretty p-default p-curve'>
          <input
            type='checkbox'
            checked={selected[row.original.pk] === true}
            onChange={() => toggleRow(row.original.pk)}
          />
          <div className='state'>
            <label />
          </div>
        </div>
      </center>
    ),
    sortable: false
  }, {
    Header: 'ID',
    id: 'pk',
    sortable: false,
    maxWidth: 100,
    accessor: item => (
      <a href={'/compositions/' + item['external_id'] + '/'}>
        <strong>{item['external_id']}</strong>
      </a>)
  }, {
    Header: 'Title',
    id: '_title',
    accessor: item => <Link to={`/compositions/${item.external_id}/`}><strong>{item._title}</strong></Link>
  }, {
    Header: `Writers`,
    id: 'writers',
    sortable: true,
    maxWidth: 300,
    Cell: ({ row }) => (
      <div>{row.original.writers.map((writer, idx) => {
        return (
          <a className='block mb-1 hover:text-blue-500' key={writer['pk']} href={'/writers/' + writer['external_id'] + '/'}>{writer['name']}</a>
        )
      })}</div>
    )
  }, {
    Header: `Collectors`,
    id: 'publishers',
    sortable: true,
    maxWidth: 300,
    Cell: ({ row }) => (
      <div>{row.original.publishers.map((publisher, idx) => {
        return (
          <a className='block mb-1 hover:text-blue-500' key={publisher['pk']} href={`/${publisher['set_type']}s/` + `${publisher['external_id']}`}>{publisher['name']}</a>
        )
      })}</div>
    )
  }, {
    Header: `Owners`,
    id: 'owners',
    sortable: true,
    maxWidth: 300,
    Cell: ({ row }) => (
      <div>{row.original.owners.map((owner, idx) => {
        return (
          <a className='block mb-1 hover:text-blue-500' key={owner['pk']} href={`/${owner['set_type']}s/` + `${owner['external_id']}`}>{owner['name']}</a>
        )
      })}</div>
    )
  }]

  const hasNext = currPage < pages
  const placeholder = songCount > 1 ? 'Search through ' + songCount + ' songs' : 'Search through ' + songCount + ' song'

  return (
    <>
      <div className="px-4 pt-4">
        <div className="md:flex md:items-center md:justify-end">
          <div className="flex-shrink-0 flex md:mt-0 md:ml-4">
            {/* <div>
              <ListSearch
                filterList={filterList}
                placeholder='Find compositions'
              />
            </div> */}
            {showAssets
              ? (<button
                    onClick={() => hideAllAssets()}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                  Hide All Assets<EyeOffIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>)
              : (<button
                    onClick={() => showAllAssets()}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                  Show All Assets<EyeIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              )
            }

            {isUnknown
              ? (<>
                { selectAll !== 0
                  ? (<button
                      onClick={() => {
                        if (window.confirm('Undo relinquishment for selected assets?')) {
                          undoRelinquishSelectedShares()
                        }
                      }}
                      type="button"
                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                    Undo Relinquish Selected<MinusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </button>)
                  : (<button
                      onClick={() => {
                        if (window.confirm('Undo relinquishment for all assets? This action will apply to all related assets listed under this publisher.')) {
                          undoRelinquishShares()
                        }
                      }}
                      type="button"
                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                    Undo All Relinquishment<RefreshIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </button>)
                }
              </>)
              : (<>
                { selectAll !== 0
                  ? (<button
                      onClick={() => {
                        if (window.confirm('Relinquish selected assets?')) {
                          relinquishSelectedShares()
                        }
                      }}
                      type="button"
                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                    Relinquish Selected<MinusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </button>)
                  : (<>
                    {relinquished
                      ? (<button
                          onClick={() => {
                            if (window.confirm('Undo relinquishment for all assets? This action will apply to all related assets listed under this publisher.')) {
                              undoRelinquishShares()
                            }
                          }}
                          type="button"
                          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                        Undo Relinquishment<RefreshIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                      </button>)
                      : (<button
                          onClick={() => {
                            if (window.confirm('Relinquish all assets? This action will apply to all related assets listed under this publisher.')) {
                              relinquishShares()
                            }
                          }}
                          type="button"
                          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                        Relinquish All Assets<MinusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                      </button>)
                    }
                  </>)
                }
              </>)
            }

            { selectAll !== 0
              ? (<button
                  onClick={() => setExport()}
                  type="button"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                Export Selected<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>)
              : (<button
                  onClick={() => setExport()}
                  type="button"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                Export<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>)
            }
          </div>
        </div>
      </div>
      <AssetSearch
        searchQ={searchQ}
        doSearch={_doSearch}
        placeholder={placeholder}
      />
      {showTable && (
        <div className='pb-1'>
          <div className='px-4'>
            {searchQ && (<InfoBar
                text={"Showing search result for '" + searchQ + "'"}
                onClose={() => resetSearch()}
                showClose
              />)
            }
          </div>
          <ListTable
            columns={compositionColumns}
            data={compositions}
            getNext={_onNext}
            getPrev={_onPrevious}
            currPage={currPage}
            setCurrPage={setCurrPage}
            hasNext={hasNext}
            pages={pages}
            showPagination
          />
        </div>)
      }
    </>
  )
}

export default RelatedAssets
