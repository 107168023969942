import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import queryString from 'query-string'

import ExplorationDetails from 'components/ExplorationDetails'
import BulkOptions from 'components/BulkOptions'

import TitleBox from './components/TitleBox'
import GeneralInfo from './components/GeneralInfo'
import RelatedPublisherTable from './components/RelatedPublisherTable'
import EditPublisherForm from './components/EditPublisherForm'
import TitleForm from './components/TitleForm'
import PublisherForm from './components/PublisherForm'

import RelatedAssets from './components/RelatedAssets'
import { getSelectedCompositionsFile as exportSelected, publisherExportAssets as exportAssets } from 'api/download'

import { AuthContext } from 'stores/auth'
import { fetch, del } from 'api/publishers'

const Detail = () => {
  const authStore = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [record, setRecord] = useState()
  const [showEditForm, setShowEditForm] = useState(false)
  const [showTitleEditForm, setShowTitleEditForm] = useState(false)
  const [showPublisherForm, setShowPublisherForm] = useState(false)
  const [currNav, setCurrNav] = useState(query.qf || query.page ? 'Related Assets' : 'Details')
  const [toggleExport, setToggleExport] = useState(false)
  const [selectAll, setSelectAll] = useState(0)
  const [selected, setSelected] = useState({})
  const [showTable, setShowTable] = useState(false)

  const { id } = useParams()

  let initialValues = {}
  if (record) {
    initialValues = Object.assign({}, record)
  }

  useEffect(() => {
    if (record === undefined || record.pk !== id) {
      fetch(id).then(res => {
        if (res.status === 200) {
          setRecord(res.data)
        } else {
          toast.error('Unable to fetch publisher')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch publisher')
      })
    }
  }, [])

  const _toggleEdit = (form) => {
    if (form === 'publisher') {
      setShowEditForm(!showEditForm)
    }
  }

  const _toggleTitleEdit = (form) => {
    if (form === 'publisherTitle') {
      setShowTitleEditForm(!showTitleEditForm)
    }
  }

  const deletePublisher = () => {
    del(id).then(res => {
      if (res.status === 204) {
        history.push('/dashboard/')
        toast.success('Successfully deleted publisher!')
      } else {
        toast.error('Unable to delete publisher')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete publisher')
    })
  }

  const user = authStore.user
  const email = user.email
  const isUnknown = record?.name === 'Unknown Administrator'
  let scope = authStore.scope

  if (record === undefined || initialValues === undefined) {
    return null
  }

  const setExport = () => {
    if (!toggleExport) {
      setToggleExport(true)
    } else {
      setToggleExport(false)
    }
  }

  const getExport = (typer) => {
    if (selectAll !== 0) {
      const newSelected = Object.assign({}, selected)
      var newArray = Object.keys(newSelected)

      var data = { selected: newArray, typer: typer }
      exportSelected(data).then(res => {
        history.push('/download/my-exports/?date=' + res.data.date)
      })
    } else {
      var data = { pk: id, typer: typer, asset_type: 'composition' }
      exportAssets(data).then(res => {
        if (res.status === 200) {
          history.push('/download/my-exports/?date=' + res.data.date + '/')
        } else {
          toast.error('Unable to get export')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to get export')
      })
    }
  }

  const bulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExport,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  if (user?.profile?.scope?.includes('exp-admin')) {
    bulkItems.push(
      {
        name: 'Client Portal Format',
        submenu: [
          {
            name: 'Shares',
            action: getExport,
            params: 'portalpub'
          },
          {
            name: 'Recordings',
            action: getExport,
            params: 'portalsr'
          }
        ]
      }
    )
  }

  const navigation = [
    { name: 'Details', href: '#nav-details'},
    { name: 'Publishing', href: '#nav-publishing' },
    { name: 'Related Assets', href: '#nav-assets' }
  ]
  
  return (<>
    <div className='flex flex-wrap flex-1 min-h-full'>
      <div className='p-6 flex-1'>
        {showTitleEditForm
          ? <TitleForm
            initialValues={initialValues}
            id={id}
            showTitleEditForm={showTitleEditForm}
            setShowTitleEditForm={setShowTitleEditForm}
            setRecord={setRecord}
          />
        : <TitleBox
            _toggleTitleEdit={_toggleTitleEdit}
            record={record}
            onDelete={deletePublisher}
            scope={scope}
          />
        }

        {!showTitleEditForm &&
          <ExplorationDetails
            record={record}
            showTitleEditForm={showTitleEditForm}
            controlled={record.controlled}
          />
        }

        <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
          <div className="ml-3">
            {navigation.map((item) => (
              <button
                type='button'
                key={item.name}
                className={item.name === currNav 
                  ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                  : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                }
                onClick={() => setCurrNav(item.name)}
                aria-current={item.name === currNav ? 'page' : undefined}
              >
                {item.name}
              </button>
            ))}
          </div>
        </nav>
        <hr />
        <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content' id='nav-tabContent'>
          {currNav === 'Details' &&
            (<div className='tab-pane tab-pad fade'
              id='nav-details' role='tabpanel' aria-labelledby='nav-details-tab'>
                {showEditForm
                  ? (<EditPublisherForm
                      id={id}
                      initialValues={initialValues}
                      record={record}
                      setRecord={setRecord}
                      setShowEditForm={setShowEditForm}
                    />)
                  : (<GeneralInfo
                    record={record}
                    scope={scope}
                    _toggleEdit={_toggleEdit}
                  />)
                }
            </div>)       
          }
          {currNav === 'Publishing' &&
            (<div className='tab-pane tab-pad fade' id='nav-publishing' role='tabpanel' aria-labelledby='nav-publishing-tab'>
              {showPublisherForm
              ? (<PublisherForm
                  initialValues={initialValues}
                  setRecord={setRecord}
                  setShowPublisherForm={setShowPublisherForm}
                  showPublisherForm={showPublisherForm}
                />)
              : (<RelatedPublisherTable
                  publishers={record.publishers}
                  setShowPublisherForm={setShowPublisherForm}
                  showPublisherForm={showPublisherForm}
                />)
            }
            </div>)
          }
          {currNav === 'Related Assets' &&
            (<div className='tab-pane tab-pad fade' id='nav-assets' role='tabpanel' aria-labelledby='nav-assets-tab'>
              <RelatedAssets
                id={id}
                isUnknown={isUnknown}
                relinquished={record.relinquished}
                songCount={record.related_songs}
                setExport={setExport}
                showTable={showTable}
                setShowTable={setShowTable}
                selected={selected}
                setSelected={setSelected}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
              />
            </div>)
          }
        </div>
      </div>
      { toggleExport &&
        (<div className="flex-grow-0 flex-shrink-0">
          <BulkOptions items={bulkItems} onClose={setExport} />
        </div>)
      }
    </div>
  </>)
}

export default Detail
