import React from 'react'
import { useFormContext } from 'react-hook-form'

import CollectorSelect from 'views/Share/components/Collectors/CollectorSelect'
import RenderAdministrators from 'views/Share/components/Collectors/RenderAdministrators'
import Ownership from 'views/Share/components/Ownership/Ownership'

const EditCollectorTable = ({ showOwnershipForm, setShowOwnershipForm, userTerritoryPresets }) => {
  const { errors } = useFormContext()

  return (
    <div>
      <div className="py-2">
        <div className="col" xs={12} md={12}>
          <CollectorSelect />
        </div>
      </div>
      <div className="py-2">
        <div className="col" xs={12} md={12}>
          <div>
            <RenderAdministrators />
          </div>
        </div>
      </div>
      <div className='box-body'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-row'>
              <div className='col'>
                <Ownership userTerritoryPresets={userTerritoryPresets} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowOwnershipForm(!showOwnershipForm) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditCollectorTable
