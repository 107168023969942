import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import toast from 'react-hot-toast'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { create } from 'api/recordLabel'

const RecordLabelForm = () => {
  const history = useHistory()

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange'
  })

  const onSubmit = (values) => {
    create(values).then(res => {
      if (res.status === 201) {
        toast.success('Record label created successfully!')
        history.push('/record-labels/' + res.data.external_id + '/')
      } else {
        toast.error('Unable to create record label')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create record label')
    })
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md" onSubmit={handleSubmit(onSubmit)} id='newRecordLabel'>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about the Record Label. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('name', { required: true })}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Client Code
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="royalty_code"
                  id="royalty_code"
                  autoComplete="royalty_code"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('royalty_code')}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name='note'
                  render={(props) => (
                    <ReactQuill
                    {...props}
                    theme='snow'
                    placeholder='Add a note...'
                    onChange={(e) => {
                        setValue('note', e)
                    }}
                    />
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Any additional information for the artist. (Ex: Agreement with Only Solutions Entertainment.)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default RecordLabelForm
