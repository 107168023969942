import React from 'react'
import territories from './territories'

const option = {}
option[''] = '----'
option['AD'] = 'Adaptor'
option['AM'] = 'Administrator'
option['AR'] = 'Arranger'
option['PR'] = 'Associated Performer'
option['A'] = 'Author'
option['C'] = 'Composer'
option['CA'] = 'Composer/Author'
option['E'] = 'Original Publisher'
option['PA'] = 'Publisher Income Participant'
option['SR'] = 'Sub-Arranger'
option['SA'] = 'Sub-Author'
option['SE'] = 'Sub-Publisher'
option['ES'] = 'Substitute Publisher'
option['TR'] = 'Translator'
option['W'] = 'Writer'
option['PY'] = 'Payee'

export const buildName = (payload) => {
  let name = [payload.title, payload.first_name, payload.middle_name, payload.last_name, payload.suffix]
  return name.filter(Boolean).join(' ')
}

export const transformData = (payload) => {
  if (payload['exploration_platforms']) {
    payload['controlled'] = true
    payload['exploration_platforms'].map((val, idx) => {
      payload['platform__' + val] = true
    })
  }

  if (payload['alternate_titles'] === null) {
    delete payload['alternate_titles']
  }

  if (payload['collector_role_code']) {
    payload['collector_role_code'] = { value: payload['collector_role_code'], label: option[payload['collector_role_code']] }
  }

  let composition = payload['composition']
  payload['composition_field'] = composition._title
  payload['composition'] = composition.pk

  let collectorField = ''
  payload['collectors'].map((val, idx) => {
    if (collectorField.length > 0) {
      collectorField = collectorField + ', ' + val.name
    } else {
      collectorField = val.name
    }
  })

  payload['collector_field'] = collectorField

  let collectors = []
  payload['collectors'].map((val, idx) => {
    collectors.push({
      id: val.pk,
      ipi_number: val.ipi_number,
      pk: val.pk,
      pro_affiliation: val.pro_affiliation,
      pub_name: val.name
    })
  })
  payload['collectors'] = collectors

  let administrators = []
  payload['administrators'].map((val, idx) => {
    administrators.push({
      administrator_pk: {
        pk: val.administrator_pk,
        value: val.administrator_pk,
        label: val.administrator
      },
      administrator_field: val.administrator,
      administrator_role_code: {
        value: val.administrator_role_code,
        label: option[val.administrator_role_code]
      }
    })
  })
  payload['administrators'] = administrators

  let publishers = []
  let writers = []

  payload['ownerships'].map((ownership, idx) => {
    let ownershipWriters = []
    let isPub = true
    ownership['ownership_writers'].map((writer, _idx) => {
      ownershipWriters.push({
        owriter_pk: writer.pk,
        writer: {
          value: writer.writer.pk,
          pk: writer.writer.pk,
          id: writer.writer.pk,
          label: buildName(writer.writer),
          isPublisher: false
        },
        writer_field : buildName(writer.writer),
        writer_role_code: {
          value: writer['writer_role_code'],
          label: option[writer['writer_role_code']]
        }
      })
    })

    let owners = []
    ownership['owners'].map((val, _idx) => {
      let noIpi = val.ipi_number === undefined
      let noPro = val.pro_affiliation === undefined

      owners.push({
        pk: val.pk,
        id: val.pk,
        pub_name: val.name,
        ipi_number: noIpi ? null : val.ipi_number,
        pro_affiliation: noPro ? null : val.pro_affiliation
      })
      isPub = val.is_publisher
    })

    let ownerField = ''
    ownership['owners'].map((val, idx) => {
      if (ownerField.length > 0) {
        ownerField = ownerField + ', ' + val.name
      } else {
        ownerField = val.name
      }
    })

    let srTerritories = []
    let mrTerritories = []
    let prTerritories = []
    let lrTerritories = []

    if (ownership['sr_share_territory']) {
      if (ownership['sr_share_territory'].includes(',')) {
        ownership['sr_share_territory'].split(',').map((terr, _idx) => {
          srTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      } else {
        ownership['sr_share_territory'].split(' ').map((terr, _idx) => {
          srTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      }
    }

    if (ownership['mr_share_territory']) {
      if (ownership['mr_share_territory'].includes(',')) {
        ownership['mr_share_territory'].split(',').map((terr, _idx) => {
          mrTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      } else {
        ownership['mr_share_territory'].split(' ').map((terr, _idx) => {
          mrTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      }
    }

    if (ownership['pr_share_territory']){
      if (ownership['pr_share_territory'].includes(',')) {
        ownership['pr_share_territory'].split(',').map((terr, _idx) => {
          prTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      } else {
        ownership['pr_share_territory'].split(' ').map((terr, _idx) => {
          prTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      }
    }

    if (ownership['lr_share_territory']) {
      if (ownership['lr_share_territory'].includes(',')) {
        ownership['lr_share_territory'].split(',').map((terr, _idx) => {
          lrTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      } else {
        ownership['lr_share_territory'].split(' ').map((terr, _idx) => {
          lrTerritories.push({
            value: terr,
            label: territories[terr]
          })
        })
      }
    }

    if (isPub) {
      publishers.push({
        ownership_pk: ownership['pk'],
        owners: owners,
        owner_field: ownerField,
        owner_role_code: {
          value: ownership['owner_role_code'],
          label: option[ownership['owner_role_code']]
        },
        ownership_writers: ownershipWriters,
        sr_share: ownership['sr_share'],
        mr_share: ownership['mr_share'],
        pr_share: ownership['pr_share'],
        lr_share: ownership['lr_share'] || null,

        sr_include_exclude: ownership['sr_include_exclude'],
        mr_include_exclude: ownership['mr_include_exclude'],
        pr_include_exclude: ownership['pr_include_exclude'],
        lr_include_exclude: ownership['lr_include_exclude'] || 'include',

        sr_share_territory: srTerritories,
        mr_share_territory: mrTerritories,
        pr_share_territory: prTerritories,
        lr_share_territory: lrTerritories
      })
    } else {
      writers.push({
        ownership_pk: ownership['pk'],
        owners: owners,
        owner_field: ownerField,
        owner_role_code: {
          value: ownership['owner_role_code'],
          label: option[ownership['owner_role_code']]
        },
        ownership_writers: ownershipWriters,
        sr_share: ownership['sr_share'],
        mr_share: ownership['mr_share'],
        pr_share: ownership['pr_share'],
        lr_share: ownership['lr_share'] || null,

        sr_include_exclude: ownership['sr_include_exclude'],
        mr_include_exclude: ownership['mr_include_exclude'],
        pr_include_exclude: ownership['pr_include_exclude'],
        lr_include_exclude: ownership['lr_include_exclude'] || 'include',


        sr_share_territory: srTerritories,
        mr_share_territory: mrTerritories,
        pr_share_territory: prTerritories,
        lr_share_territory: lrTerritories
      })
    }
  })
  payload['publishers'] = publishers
  payload['writers'] = writers
  payload['share_pk'] = payload['pk']

  return payload
}

export const transformDuplicate = (payload) => {
  if (payload['collector_role_code']) {
    payload['collector_role_code'] = { value: payload['collector_role_code'], label: option[payload['collector_role_code']] }
  }

  payload['composition'] = payload['composition']['_title']
  payload['collector_field'] = payload['collectors'][0]['name']

  let collectors = []
  payload['collectors'].map((val, idx) => {
    collectors.push({
      value: val.pk,
      label: val.name,
      isPublisher: true,
      id: val.pk,
      pk: val.pk
    })
  })
  payload['collectors'] = collectors

  let administrators = []
  payload['administrators'].map((val, idx) => {
    administrators.push({
      administrator: {
        pk: val.administrator_pk,
        value: val.administrator_pk,
        label: val.administrator
      },
      administrator_role_code: {
        value: val.administrator_role_code,
        label: option[val.administrator_role_code]
      },
      administrator_field: payload['administrators'][0]['administrator']
    })
  })
  payload['administrators'] = administrators

  let publishers = []
  let writers = []

  payload['ownerships'].map((ownership, idx) => {
    let ownershipWriters = []
    let isPub = true
    ownership['ownership_writers'].map((writer, _idx) => {
      ownershipWriters.push({
        writer: {
          value: writer.writer.pk,
          pk: writer.writer.pk,
          id: writer.writer.pk,
          label: buildName(writer.writer),
          isPublisher: false
        },
        writer_role_code: {
          value: writer['writer_role_code'] ? writer['writer_role_code'] : '',
          label: writer[writer['writer_role_code'] ? writer['writer_role_code'] : '']
        },
        writer_field: buildName(writer.writer)
      })
    })

    let owners = []
    ownership['owners'].map((owner, _idx) => {
      let noIpi = owner.ipi_number === undefined
      let noPro = owner.pro_affiliation === undefined

      owners.push({
        pk: owner.pk,
        id: owner.pk,
        pub_name: owner.name,
        ipi_number: noIpi ? null : owner.ipi_number,
        pro_affiliation: noPro ? null : owner.pro_affiliation
      })
      isPub = owner.is_publisher
    })

    let srTerritories = []
    ownership['sr_share_territory'].split(',').map((terr, _idx) => {
      srTerritories.push({
        value: terr,
        label: territories[terr]
      })
    })

    let mrTerritories = []
    ownership['mr_share_territory'].split(',').map((terr, _idx) => {
      mrTerritories.push({
        value: terr,
        label: territories[terr]
      })
    })

    let prTerritories = []
    ownership['pr_share_territory'].split(',').map((terr, _idx) => {
      prTerritories.push({
        value: terr,
        label: territories[terr]
      })
    })

    let lrTerritories = []
    ownership['lr_share_territory'].split(',').map((terr, _idx) => {
      lrTerritories.push({
        value: terr,
        label: territories[terr]
      })
    })

    if (isPub) {
      publishers.push({
        owners: owners,
        owner_role_code: {
          value: ownership['owner_role_code'],
          label: option[ownership['owner_role_code']]
        },
        ownership_writers: ownershipWriters,
        sr_share: ownership['sr_share'],
        mr_share: ownership['mr_share'],
        pr_share: ownership['pr_share'],
        lr_share: ownership['lr_share'],

        sr_include_exclude: ownership['sr_include_exclude'],
        mr_include_exclude: ownership['mr_include_exclude'],
        pr_include_exclude: ownership['pr_include_exclude'],
        lr_include_exclude: ownership['lr_include_exclude'],

        sr_share_territory: srTerritories,
        mr_share_territory: mrTerritories,
        pr_share_territory: prTerritories,
        lr_share_territory: lrTerritories,

        owner_field: owners[0]['pub_name']
      })
    } else {
      writers.push({
        owners: owners,
        owner_role_code: {
          value: ownership['owner_role_code'],
          label: option[ownership['owner_role_code']]
        },
        ownership_writers: ownershipWriters,
        sr_share: ownership['sr_share'],
        mr_share: ownership['mr_share'],
        pr_share: ownership['pr_share'],
        lr_share: ownership['lr_share'],

        sr_include_exclude: ownership['sr_include_exclude'],
        mr_include_exclude: ownership['mr_include_exclude'],
        pr_include_exclude: ownership['pr_include_exclude'],
        lr_include_exclude: ownership['lr_include_exclude'],

        sr_share_territory: srTerritories,
        mr_share_territory: mrTerritories,
        pr_share_territory: prTerritories,
        lr_share_territory: lrTerritories
      })
    }
  })
  payload['publishers'] = publishers
  payload['writers'] = writers

  payload['title'] = payload['title'] + '-Copy'
  return payload
}

export const transformPercentage = (payload) => {
  let data = {}

  data['pk'] = payload['pk']
  data['total_sr'] = payload['total_sr']
  data['total_mr'] = payload['total_mr']
  data['total_pr'] = payload['total_pr']
  data['total_lr'] = payload['total_lr']

  let ownerships = []

  payload['ownerships'].map((item, idx) => {
    let ownership = {}
    ownership['ownership_pk'] = item['pk']
    ownership['sr_share'] = item['sr_share']
    ownership['mr_share'] = item['mr_share']
    ownership['pr_share'] = item['pr_share']
    ownership['lr_share'] = item['lr_share']

    ownerships.push(ownership)
  })

  data['ownerships'] = ownerships
  return data
}

export const formatLabel = (option, elem) => {
  if (elem.context === 'menu') {
    return (
      <div className='select-option'>
        <span className='select-title'><ion-icon name={option.isPublisher ? 'contacts' : 'person'} />&nbsp;{option.label}</span>
        <span className='select-muted'>{option.isPublisher ? 'Publisher' : 'Writer'} ID: {option.id ? option.id : 'N/A'}</span>
      </div>
    )
  } else {
    return (
      <div className='select-option'>
        <span className='select-title'><ion-icon name={option.isPublisher ? 'contacts' : 'person'} />&nbsp;{option.label}</span>
      </div>
    )
  }
}
