import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import ToggleSwitch from 'components/ToggleSwitch'
import Checkbox from 'components/Checkbox'

const SHARE_PLATFORMS = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'mri', label: 'MRI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'mlc', label: 'MLC' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'bmi', label: 'BMI' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'sub-publishing', label: 'Sub-Publishing' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'lyricfindmerch', label: 'LyricFindMerch' },
  { value: 'dl', label: 'Direct Licensing' },
  { value: 'sync', label: 'Sync' }
]

const RECORDING_PLATFORMS = [
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'youtube', label: 'YouTube' }
]

const ExplorationControl = ({ initialValues, controlled, type, id }) => {
  const { control, setValue } = useFormContext()

  return (
    <div className='mt-5'>
        <Controller
            control={control}
            name={'controlled'}
            render={() => (
            <ToggleSwitch
                id={id}
                defaultValue={controlled}
                label='Controlled By Exploration'
                onChange={(e) => {
                setValue('controlled', e)
                }}
            />
            )}
        />
        {type === 'share'
        ? (<div className='mt-5'>
            {SHARE_PLATFORMS.map((platform, idx) => {
            let name = 'platform__' + platform.value
            return (
                <Controller
                key={'share' + idx}
                control={control}
                name={name}
                render={() => (
                    <Checkbox
                        defaultValue={initialValues && initialValues[name]}
                        label={platform.label}
                        disabled={!controlled}
                        onChange={(e) => {
                            setValue(name, e)
                        }}
                    />
                )}
                />
            )
            })}
        </div>)
        : (<div className='mt-5'>
            {RECORDING_PLATFORMS.map((platform, idx) => {
            let name = 'platform__' + platform.value
            return (
                <Controller
                key={'recording' + idx}
                control={control}
                name={name}
                render={() => (
                    <Checkbox
                    defaultValue={initialValues && initialValues[name]}
                    label={platform.label}
                    disabled={!controlled}
                    onChange={(e) => {
                        setValue(name, e)
                    }}
                    />
                )}
                />
            )
            })}
        </div>)
        }
    </div>
  )
}

export default ExplorationControl
