import React from 'react'

export const InfoBar = ({ text, onClose }) => (
  <div className='alert alert-warning alert-dismissible fade show' role='alert'>
    <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={onClose}>
      <span aria-hidden='true'>&times;</span>
    </button>
    {text}
  </div>
)

export default InfoBar
