import React, { useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { getMlcApi } from 'api/tools'
import toast from 'react-hot-toast'
import MLCCard from 'views/MLC/components/MLCCard'

const MlcInfo = ({ mlcCode }) => {
    const [songData, setSongData] = useState(null)
    const [searching, setSearching] = useState(false)

    const fetchMlcData = () => {
      setSearching(true)
      getMlcApi(mlcCode).then(res => {
        if (res.status === 200) {
          setSongData(res.data)
        } else {
          toast.error('Song code not found')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Song code not found')
      })
      setSearching(false)
    }
  return (
    <>
      <div className="bg-white overflow-hidden mx-2 my-2">
        <div className="px-4 py-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">MLC Info</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Pull song info from MLC portal using MLC code.</p>
        </div>
        <div className="border-t border-gray-200">
          <div className="px-4 py-5">
            {!songData && !searching && 
              <div> 
                {mlcCode
                  ? (<button
                        type='button'
                        onClick={() => fetchMlcData()}
                        className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                        Pull Info<SearchIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                    </button>)
                  : (<span className='mt-1 max-w-2xl text-sm text-gray-500'>No MLC Code attached to Composition. To perform a search, <a className='text-blue-500 hover:underline' href='/mlc-search'>click here</a>.</span>)
                }
              </div>
            }
            {searching && <p>Pulling information...</p>}
            {songData && <MLCCard songData={songData} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default MlcInfo