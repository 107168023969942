import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import { TrashIcon } from '@heroicons/react/outline'

import { AuthContext } from 'stores/auth'
import { del } from 'api/conflict'

export default function ConflictCard ({ conflict }) {
  const history = useHistory()
  const authStore = useContext(AuthContext)

  let user = authStore.user
  let scope  = user?.profile?.scope || authStore.scope

  const platforms = {
    'ascap': 'ASCAP',
    'bmi': 'BMI',
    'cmrra': 'CMRRA',
    'distribution': 'Distribution',
    'hfa': 'HFA',
    'lyricfind': 'LyricFind',
    'mlc': 'MLC',
    'mri': 'MRI',
    'soundexchange': 'SoundExchange',
    'subpublishing': 'Sub-Publishing',
    'youtube': 'YouTube'
  }

  const assetTypes = {
    'pub': 'Publishing',
    'sr': 'Sound Recording',
    'mv': 'Music Video',
    'web': 'Web',
    'movie': 'Movie',
    'tv': 'TV Episode',
    'Other': 'Other'
  }

  const deleteConflict = () => {
    del(conflict.pk).then(res => {
      toast.success('Conflict deleted successfully!')
      history.go(0)
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to delete conflict')
    })
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="inline-flex text-base font-semibold leading-6 text-gray-900">{conflict.title}</h3>
        <span className="ml-1 max-w-2xl text-sm text-gray-500">({conflict.artist_writer})</span><br />
        <span className="rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
          {assetTypes[conflict.asset_type]}
        </span>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Client Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conflict.client_name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Related Composition</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <a className="font-medium hover:text-blue-600" href={'/compositions/' + conflict?.related_composition?.external_id}>{conflict?.related_composition?._title}</a>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Platform</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{platforms[conflict.platform]}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Asset ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">
                    {conflict.asset_id}
                </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Approx. Daily Views</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conflict.approx_daily_views}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Date Resolved</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conflict.date_resolved}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Team Member</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conflict.team_member}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Resolutions</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 break-words">
              {conflict.resolutions}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 break-words">
              {conflict.notes}
            </dd>
          </div>
        </dl>
      </div>
      {scope.indexOf('exp-conflict') >= 0
        && (<div className="px-4 py-5">
          <button
            type='button'
            onClick={() => {
                if (window.confirm('Are you sure you want to delete this conflict?')) {
                    deleteConflict()
                }
            }}
            className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
            id={conflict.pk}>
            Delete Conflict<TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </button>
        </div>)
      }
    </div>
  )
}
