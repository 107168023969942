import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SaveIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import './PermissionForm.scss'

import { permissionSubmit } from 'api/users'

const SCOPE_OPTIONS = [
  { value: 'read', label: 'Read/View' },
  { value: 'write', label: 'Write/Edit' },
  { value: 'delete', label: 'Delete' },
  { value: 'admin', label: 'Administrator' },
  { value: 'claim', label: 'Claiming' },
  { value: 'claimad', label: 'Claiming Administrator' },
  { value: 'conflict', label: 'Conflict Resolution' }
]

const ROYAPP_SCOPE_OPTIONS = [
  { value: 'read', label: 'Read/View' },
  { value: 'write', label: 'Write/Edit' }
]

const ROYAPPS = ['Contracts', 'Monthly Royalties', 'Invoices', 'Augmentor']

const PermissionForm = ({ record }) => {
  const history = useHistory()
  const { id } = useParams()
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (record.profile !== undefined) {
      let selectedScopes = []
      const scopes = record.profile.scope

      scopes.split(' ').map((scope, idx) => {
        selectedScopes.push(scope)
      })

      setSelected(selectedScopes)
    }
  }, [record])

  const scopes = record.profile.scope
  let initialValues = {
    'exp-read': false,
    'exp-write': false,
    'exp-delete': false,
    'exp-admin': false,
    'exp-claim': false,
    'exp-claimad': false,
    'exp-conflict': false,
    'roy-read': false,
    'roy-write': false,
    'roy-admin': false,
    'contracts-read': false,
    'contracts-write': false,
    'royalties-read': false,
    'royalties-write': false,
    'invoices-read': false,
    'invoices-write': false,
    'augmentor-read': false,
    'augmentor-write': false
  }
  scopes.split(' ').map((scope, idx) => {
    initialValues[scope] = true
  })

  const { handleSubmit, setValue, register } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const formatData = (values) => {
    let payload = values
    payload['pk'] = id
    
    permissionSubmit(payload, id).then((res) => {
      if (res.status === 200) {
        history.go(0)
      } else {
        toast.error('Unable to update permissions')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update permissions')
    })
  }

  const toSlug = (string) => {
    var changeString = string
    if (string === 'Monthly Royalties') {
      changeString = 'royalties'
    }
    var slug = changeString.toLowerCase()
    return slug
  }

  const showRoyalties = record.email.indexOf('@exploration.io') >= 0

  return (
    <>
      <form onSubmit={handleSubmit(formatData)} method='post' className='bg-white mt-5 p-5 space-y-8 rounded-md shadow'>
        <div className='divide-y divide-gray-200 space-y-8 sm:space-y-5'>
          <div className="bg-white overflow-hidden mx-2 my-2">
            <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
              <div className="py-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Permissions</h3>
              </div>
              <div className="ml-4 mt-4 flex-shrink-0">
                <button
                  type="submit"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Apply Changes<SaveIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className='py-3'>
            <div className='text-md leading-6 font-medium text-gray-900'>Exploration Data</div>
            <fieldset>
              <legend className="sr-only">Exploration Data</legend>
              <div className="">
                {SCOPE_OPTIONS.map((option, idx) => {
                  const val = 'exp-' + option.value
                  return (
                    <div className="relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="comments" className="font-medium text-gray-700">
                          {option.label}
                        </label>
                        {/* <p id="comments-description" className="text-gray-500">
                          Get notified when someones posts a comment on a posting.
                        </p> */}
                      </div>
                      <div className="ml-3 flex items-center h-5">
                        <input
                          id={`exp-${option.value}`}
                          aria-describedby={`exp-${option.value}`}
                          name={`exp-${option.value}`}
                          type="checkbox"
                          {...register('exp-' + option.value)}
                          className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </fieldset>
          </div>
          
            {showRoyalties && (<>
              <div className='py-3'>
                <div className='text-md leading-6 font-medium text-gray-900'>Royalties (All Apps)</div>
                <fieldset>
                  <legend className="sr-only">Royalties</legend>
                  <div className="">
                    {SCOPE_OPTIONS.map((option, idx) => {
                      const val = 'roy-' + option.value
                      return (
                        <div className="relative flex items-start py-4">
                          <div className="min-w-0 flex-1 text-sm">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              {option.label}
                            </label>
                            {/* <p id="comments-description" className="text-gray-500">
                              Get notified when someones posts a comment on a posting.
                            </p> */}
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id={`roy-${option.value}`}
                              aria-describedby={`roy-${option.value}`}
                              name={`roy-${option.value}`}
                              type="checkbox"
                              {...register('roy-' + option.value)}
                              className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </fieldset>
              </div>
              {ROYAPPS.map((app, idx) => {
                return (<>
                  <div className='py-3'>
                    <div className='text-md leading-6 font-medium text-gray-900'>{app}</div>
                    <fieldset>
                      <legend className="sr-only">Royalties</legend>
                      <div className="">
                        {ROYAPP_SCOPE_OPTIONS.map((option, idx) => {
                          const val = toSlug(app) + '-' + option.value
                          return (
                            <div className="relative flex items-start py-4">
                              <div className="min-w-0 flex-1 text-sm">
                                <label htmlFor="comments" className="font-medium text-gray-700">
                                  {option.label}
                                </label>
                                {/* <p id="comments-description" className="text-gray-500">
                                  Get notified when someones posts a comment on a posting.
                                </p> */}
                              </div>
                              <div className="ml-3 flex items-center h-5">
                                <input
                                  id={val}
                                  aria-describedby={val}
                                  name={val}
                                  type="checkbox"
                                  {...register(val)}
                                  className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </fieldset>
                  </div>
                </>)
              })}
            </>)
          }
        </div>
      </form>
    </>
  )
}

export default PermissionForm
