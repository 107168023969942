import React, { useState, useEffect } from 'react'
import ListTable from 'components/ListTable'
import toast from 'react-hot-toast'

import { getStats } from 'api/claim'

const StaffTable = ({ month, year }) => {
  const [currPage, setCurrPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const [list, setList] = useState([])
  const [totalPages, setTotalPages] = useState()

  useEffect(() => {
    setLoading(true)
    getStats(null, month, year, 'staff', currPage, 10).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
      setLoading(false)
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to fetch stats')
    })
  }, [month, year])

  const _onNext = (page) => {
    setLoading(true)
    getStats(null, month, year, 'staff', page + 1, 10).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
      setLoading(false)
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to fetch stats')
    })
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getStats(null, month, year, 'staff', page - 1, 10).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        setLoading(false)
      }).catch(err => {
        console.log(err.response)
        toast.error('Unable to fetch stats')
      })
      setCurrPage(page - 1)
    }
  }

  const hasNext = currPage < totalPages

  const staffColumns = [
    {
      Header: 'Staff',
      id: 'staff',
      sortable: false,
      Cell: ({ row }) => (
        <div>
          <a className='hover:text-blue-500' href={`/claiming/stats/${row.original.created_by}`}>{row.original.created_by_email}</a>
        </div>
      )
    }, {
      Header: 'Manual Claims',
      id: 'claims',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.claims}</div>
      )
    }, {
      Header: 'Potential Claims',
      id: 'potential',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.potential_claims}</div>
      )
    }, {
      Header: 'Disputed Claims',
      id: 'disputed',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.disputed_claims}</div>
      )
    }
  ]

  if (loading) {
    return null
  }

  return (
    <div className='list-table'>
      <ListTable
        columns={staffColumns}
        data={list}
        getNext={_onNext}
        getPrev={_onPrevious}
        currPage={currPage}
        setCurrPage={setCurrPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default StaffTable
