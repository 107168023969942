import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import './components/List.scss'
import InfoBar from 'components/InfoBar'
import ListTable from './components/ListTable'
import BulkOptions from 'components/BulkOptions'
import Heading from './components/Heading'

import { getSelectedRecordingsFile as exportSelected } from 'api/download'
import { getList } from 'api/recordings'

const AdminView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const history = useHistory()

  const [currPage, setCurrPage] = useState(parseInt(query.page))
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [filterQ, setFilterQ] = useState(query.f || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [selected, setSelected] = useState({})
  const [selectAll, setSelectAll] = useState(0)
  const [showAdvanced, setShowAdvanced] = useState(false)

  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  useEffect(() => {
    if (query.q === undefined || query.q === '') {
      setCurrPage(parseInt(query.page))
      setSearchQ(query.q || '')
      setFilterQ(query.f || '')
      setRefreshTable(false)
    } else {
      if (query.q !== searchQ) {
        setSearchQ(query.q)
        setRefreshTable(!refreshTable)
      }
      if (query.f !== filterQ) {
        setFilterQ(query.f)
        setRefreshTable(!refreshTable)
      }
    }
  }, [location.search])

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const _listParams = (page, sQ, sort, filter, rownum) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (rownum) {
      queryString['rownum'] = rownum
    }

    if (sQ !== undefined && sQ !== '') {
      queryString['q'] = sQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }

    if (filter !== undefined) {
      queryString['f'] = filter
      window.filterParam = queryString['f']
    }

    return queryString
  }

  const getRecordingList = (page, sQuery, sort, rownum) => {
    setSearchQ(sQuery)
    getList(_listParams(page, sQuery, sort, filterQ, rownum)).then(res => {
      toast.loading('Getting recordings')
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        toast.dismiss()
      } else {
        toast.error('Unable to fetch recordings')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch recordings')
    })
  }

  const closeExport = () => {
    setSelectAll(0)
    setSelected({})
  }

  const toggleRow = (pk) => {
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => (
      (newSelected[x] === true) && (indeterminate = true)
    ))

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}

    if (selectAll === 0) {
      list.forEach(x => {
        newSelected[x.pk] = true
      })
    }

    setSelected(newSelected)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const getExport = (typer) => {
    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { selected: newArray, typer: typer }
    exportSelected(data).then(res => {
      history.push('/download/my-exports/?date=' + res.data.date)
    })
  }

  const handleSearch = (searchQuery, filterOption, filterValue) => {
    setSearchQ(searchQuery)

    if (filterOption !== '-1' && filterOption !== undefined) {
      let filterQ = filterOption + '|' + filterValue
      getRecordingList(currPage, searchQuery, undefined, filterQ)
    } else {
      getRecordingList(currPage, searchQuery, undefined, '')
    }
  }

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  const bulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: getExport,
      params: 'exprecordings'
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: getExport,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: getExport,
          params: 'scddex'
        }
      ]
    }
  ]

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {searchFirst
          ? null
          : (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => getRecordingList(1, '')}
          />)
        }
        <Heading
          searchFirst={searchFirst}
          showAdvanced={showAdvanced}
          handleSearch={handleSearch}
          toggleAdvanced={toggleAdvanced}
        />
        <div className='py-4'>
          <ListTable
            currentPage={currPage}
            setCurrPage={setCurrPage}
            searchQ={searchQ}
            refreshTable={refreshTable}
            sort={query.sort}
            propSelected={selected}
            propSelectAll={selectAll}
            toggleRow={toggleRow}
            toggleSelectAll={toggleSelectAll}
            recordingList={list}
            pages={totalPages}
            getList={getRecordingList}
          />
        </div>
      </div>
      { selectAll !== 0 &&
        (<div className="flex-grow-0 flex-shrink-0">
          <BulkOptions items={bulkItems} onClose={closeExport} />
        </div>)
      }
    </div>
  )
}

export default AdminView
