import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import Creatable from 'layouts/components/Creatable'
import ActionFeed from 'components/ActionFeed'
import NoteBox from 'components/NoteBox'
import { transformData } from 'views/Writer/utils'
import { edit } from 'api/writers'

const EditWriterForm = ({ initialValues, record, setRecord, setShowEditForm, id }) => {
  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const proOptions = [
    { value: '', label: '----' },
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'SOCAN', label: 'SOCAN' },
    { value: 'PRS', label: 'PRS' },
    { value: 'Other', label: 'Other' }
  ]

  const _handleWriterSubmit = (values) => {
    edit(values, id).then(res => {
      if (res.status === 200) {
        toast.success('Successfully updated writer!')
        setRecord(res.data)
      } else {
        toast.error('Unable to update writer')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update writer')
    })
    setShowEditForm(false)
  }

  const formatData = (values) => {
    let data = { ...values }
    let publishers = data.pub_affiliation ? data.pub_affiliation.map(pub => pub.value) : []
    data.pub_affiliation = publishers
    if (data.alternate_names !== undefined) {
      data.alternate_names = data.alternate_names.filter(function (item) {
        return item !== undefined
      })
      for (var i = 0; i < data.alternate_names.length; i++) {
        if (data.alternate_names[i].first_name === undefined) {
          data.alternate_names[i].first_name = ''
        }
        if (data.alternate_names[i].middle_name === undefined) {
          data.alternate_names[i].middle_name = ''
        }
        if (data.alternate_names[i].last_name === undefined) {
          data.alternate_names[i].last_name = ''
        }
      }
    }
    _handleWriterSubmit(data)
  }

  return (<>
    <div className='mb-5 px-4 py-4 table-description'>
      <div className='flex'>
        <div className='flex-1'>
          <div className="mb-5">
            <form className='form' onSubmit={handleSubmit(formatData)}>
              <div className="bg-white shadow sm:rounded-lg mx-2 my-2">
                <div className="px-4 py-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Writer Information</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Related codes and other information.</p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">IPI Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="ipi_number"
                          id="ipi_number"
                          autoComplete="ipi_number"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('ipi_number')}
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Pro Affiliation</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <Controller
                          control={control}
                          name='pro_affiliation'
                          render={(props) => (
                            <Creatable
                              {...props}
                              options={proOptions}
                              onChange={(e) => {
                                setValue('pro_affiliation', e)
                              }}
                            />
                          )}
                        />
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">ISNI</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="isni"
                          id="isni"
                          autoComplete="isni"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('isni')}
                        />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => { setShowEditForm(false) }}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={errors}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form> 
          </div>
          <br />
          <>
            <div className="px-4 py-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Activities</h3>
            </div>
            <ActionFeed
              model={'writer'}
              pk={record.external_id} />
          </>
        </div>
        <div className='flex-1'>
          <div className="px-4 py-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
          </div>
          <NoteBox model={'writer'} pk={record.external_id} />
        </div>
      </div>
    </div>
  </>
  )
}

export default EditWriterForm
