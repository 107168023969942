import React, { useState } from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import WriterRoles from 'views/Share/components/WriterRoles'
import WriterSelectModal from './WriterModalSelect'
import { AssignToWriter } from './AssignToWriter'

const WriterSelect = ({ ownershipWriter, index, ownershipIndex, remove }) => {
  const { control, setValue, getValues, watch, register } = useFormContext()
  let values = getValues()
  const isArrangement = watch('arrangement')

  const [selectedWriter, setSelectedWriter] = useState(ownershipWriter ?  ownershipWriter.writer : null)
  const [selectedWriterField, setSelectedWriterField] = useState(ownershipWriter ? ownershipWriter.writer_field : '')
  const [selectWriterModal, setSelectWriterModal] = useState(false)

  const toggleSelectWriterModal = () => {
    setSelectWriterModal(!selectWriterModal)
  }

  const assignWriter = (writer) => {
    setValue(`publishers[${ownershipIndex}].ownership_writers[${index}].writer_field`, writer.pub_name)
    setValue(`publishers[${ownershipIndex}].ownership_writers[${index}].writer`, writer)
    setSelectedWriter(writer)
    setSelectedWriterField(writer.pub_name)
    setSelectWriterModal(false)
  }

  return (
    <>
      <WriterSelectModal
        status={selectWriterModal}
        setSelectedWriterField={setSelectedWriterField}
        toggleModal={toggleSelectWriterModal}
        assignWriter={assignWriter}
        ownershipWriter={ownershipWriter.writer}
        register={register}
        values={values}
        setValue={setValue}
        index={index}
        ownershipIndex={ownershipIndex}
        selectedWriters={ownershipWriter}
      />
      <div className="bg-white rounded-md">
        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${ownershipIndex}].ownership_writers[${index}].writer`}
                render={() => (
                  <AssignToWriter
                  className='form-control appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    value={selectedWriterField}
                    toggleSelectWriterModal={toggleSelectWriterModal}
                  />
                )}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <div className='mt-1'>
              <WriterRoles
                className='form-control'
                defaultValue={ownershipWriter.writer_role_code}
                name={`publishers[${ownershipIndex}].ownership_writers[${index}].writer_role_code`}
                control={control}
                change={setValue}
                isArrangement={isArrangement}
              />
            </div>
          </div>
        </div>
        {index !== 0 ? <button
          className='btn btn-link btn-remove'
          type='button'
          onClick={() => remove(index)}>
          Remove
        </button> : null }
      </div>
    </>
  )
}

export default WriterSelect
