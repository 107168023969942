import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import moment from 'moment'
import { PencilIcon, DownloadIcon, MinusCircleIcon, TrashIcon } from '@heroicons/react/outline'

import { ALLOW_DELETE_ACCESS } from 'helper/const'
import { adminEdit, adminDel, relinquishShare as relinquishShareApi, undoRelinquishShare as undoRelinquishShareApi } from 'api/share'

const TitleBox = ({ record, setRecord, _toggleTitleEdit, setExport, composition, email, scope }) => {
  const { id } = useParams()
  const history = useHistory()

  const _handleAdminSubmit = (values) => {
    adminEdit(values, id).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
      } else {
        toast.error('Unable to update share')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update share')
    })
  }

  const setBoolean = (value, field) => {
    let data = {
      [field]: value,
      'toggle': true
    }
    _handleAdminSubmit(data)
  }

  const alternateTitles = record.alternate_titles

  if (record === undefined) {
    return null
  }

  const relinquishShare = () => {
    relinquishShareApi(id).then(res => {
      if (res.status === 200) {
        history.go(0)
        toast.success('Successfully relinquished share!')
      } else {
        toast.error('Unable to relinquish share')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to relinquish share')
    })
  }

  const undoRelinquishShare = () => {
    undoRelinquishShareApi(id).then(res => {
      if (res.status === 200) {
        history.go(0)
        toast.success('Successfully undone relinquishment!')
      } else {
        toast.error('Unable to undo relinquishment')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to undo relinquishment')
    })
  }

  const onDelete = () => {
    adminDel(record.external_id).then(res => {
      if (res.status === 204) {
        toast.success('Successfully deleted share!')
        history.push('/dashboard/')
      } else {
        toast.error('Unable to delete share')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete share')
    })
  }

  console.log(record)

  return (
    <>
       <div className="mb-5 rounded-lg bg-white overflow-hidden shadow">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3">{record.title}</h2>
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {record.external_id}
                </p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <button
                type='button'
                onClick={() => { _toggleTitleEdit('adminTitle') }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Edit
                <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              <button className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                type='button'
                onClick={() => setExport()}>
                Export
                <DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              {record.relinquished
                ? (<button
                  type='button'
                  onClick={() => {
                    if (window.confirm('Undo relinquishment for this share?')) {
                      undoRelinquishShare()
                    }
                  }}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
                  Undo Relinquishment
                  <MinusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>)
                : (<button
                  type='button'
                  onClick={() => {
                    if (window.confirm('Relinquish this share?')) {
                      relinquishShare()
                    }
                  }}
                  className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
                  Relinquish
                  <MinusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>)
              }

              <button
                type='button'
                onClick={() => {
                  if (window.confirm('Delete this share?')) {
                    onDelete()
                  }
                }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                disabled={(ALLOW_DELETE_ACCESS.indexOf(email) === -1 && scope?.indexOf('exp-delete') === -1)}
                id={record.pk}>
                <TrashIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-white grid grid-cols-3 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          <div key='alternate-titles' className="px-6 py-5 text-sm text-center">
            <span className="text-gray-600">Alternate Titles:</span>&nbsp;
            <span className="text-gray-900 font-medium">
              {alternateTitles && alternateTitles.length !== 0 &&
                (<span>&nbsp;
                  {alternateTitles.map((alt, index) => index !== 0 ? `, ${alt}` : alt)}
                &nbsp;</span>)
              }
            </span>
          </div>
          <div key='composition' className="px-6 py-5 text-sm text-center">
            <span className="text-gray-600">Related Composition:</span>&nbsp;
            <span className="text-gray-900 font-medium">
              { composition !== undefined && composition !== null &&
                (<a className="font-bold hover:text-blue-500" href={'/compositions/' + composition.external_id + '/'}>{composition._title}</a>)
              }
            </span> 
          </div>
          <div key='modified' className="px-6 py-5 text-sm text-center">
            {record.modified_email === 'Unknown'
              ? (<div>
                <span className="text-gray-600">
                  Last edited at&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
              : (<div>
                <span className="text-gray-600">
                  Last edited by&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {record.modified_email} at {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TitleBox
