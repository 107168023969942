import React from 'react'
import { useFormContext } from 'react-hook-form'

const ExplorationInfo = () => {
  const { register } = useFormContext()

  return (<>
    <div className='py-5'>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Exploration Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Codes related to Exploration. (* required)
        </p>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">          
        <div className="sm:col-span-3">
          <label htmlFor="bmg-code" className="block text-sm font-medium text-gray-700">
            BMG Code
          </label>
          <div className="mt-1">
            <input
              type='text'
              className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
              {...register('bmg_code', { maxLength: 6 })}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label htmlFor="client-code" className="block text-sm font-medium text-gray-700">
            Client Code<sup className='required-icon'>*</sup>
          </label>
          <div className="mt-1">
            <input
              type='text'
              className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
              {...register('royalty_code', { required: true })}
            />
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default ExplorationInfo
