import React from 'react';
import { Route, Switch } from 'react-router';
import Feed from './routes/Feed/index';
import Uploading from './routes/Uploading/index';

export default function AugmentorViews () {
  return (
    <Switch>
      <Route exact path='/ddex/' component={Feed} />
      <Route path='/ddex/upload/:id/' component={Uploading} />
    </Switch>
  )
}
