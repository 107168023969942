import React from 'react'

const WriterCard = ({ writerData }) => {
  return (<>
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Writer Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.writerFirstName}&nbsp;{writerData.writerLastName}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Chain ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.chainId}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Chain Parent ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.chainParentId}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Writer ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.writerId}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Writer IPI</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.writerIPI}</dd>
            </div>

            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Writer Role Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{writerData.writerRoleCode}</dd>
            </div>
        </dl>
    </div>
  </>)
}

export default WriterCard