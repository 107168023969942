import React from 'react'
import MultipleValueField from './components/MultipleValueField'
import SingleValueField from './components/SingleValueField'

const Inline = ({ idx, label, codes, showForm }) => {
  let name = label['key']

  const singleValueFields = ['iswc', 'language_code', 'ascap_work_id', 'bmi_work_id', 'sesac_work_id', 'cmrra_work_id', 'hfa_code', 'bmg_code']

  return (
    <div className={idx % 2 === 0 ? 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6' : 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
      <dt className="text-sm font-medium text-gray-500">
        {label.label}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 min-w-full">
        {singleValueFields.includes(name)
          ? (<SingleValueField
            idx={idx}
            label={label}
            codes={codes}
            showForm={showForm} />)
          : (<MultipleValueField
              idx={idx}
              label={label}
              codes={codes}
              showForm={showForm}
            />
          )
        }
      </dd>
    </div>
  )
}

export default Inline