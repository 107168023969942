import React, { useContext } from 'react'
import { AuthContext } from 'stores/auth'

import ProfileHeader from "./components/ProfileHeader";

import {
  MusicNoteIcon,
  MicrophoneIcon,
  NewspaperIcon,
  CollectionIcon,
  FolderIcon,
  OfficeBuildingIcon
  
} from '@heroicons/react/outline'
import ImportData from './components/ImportData';
import SearchBox from './components/SearchBox';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DashboardView = () => {

  const actions = [
    {
      icon: MusicNoteIcon,
      name: 'Add a Share',
      href: '/shares/add/',
      prompt: 'Add a new Collection Share.'
    },
    {
      icon: FolderIcon,
      name: 'Add a Recording',
      href: '/recordings/add/',
      prompt: 'Add a new Recording.'
    },
    {
      icon: NewspaperIcon,
      name: 'Add a Writer',
      href: '/writers/add/',
      prompt: 'Add a new Writer.'
    },
    {
      icon: CollectionIcon,
      name: 'Add a Publisher',
      href: '/publishers/add/',
      prompt: 'Add a new Publisher.'
    },
    {
      icon: MicrophoneIcon,
      name: 'Add an Artist',
      href: '/artists/add/',
      prompt: 'Add a new Artist.'
    },
    {
      icon: OfficeBuildingIcon,
      name: 'Add a Record Label',
      href: '/record-labels/add/',
      prompt: 'Add a new Record Label.'
    }
  ]

  return (<>
    <div className="min-h-full">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-8">
        <h1 className="sr-only">Dashboard</h1>
        {/* Main 3 column grid */}
        {/* <ProfileHeader /> */}
        <SearchBox />
        <section className='mt-5 rounded-md shadow' aria-labelledby="quick-links-title">
          <ImportData />
        </section>
      </div>
    </div>
  </>)
}

export default DashboardView
