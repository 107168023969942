import React from 'react'
import { StarIcon, PlusCircleIcon, LinkIcon } from '@heroicons/react/outline'

const RecordingTable = ({ record, openModal, recordings, setPrimaryRecording }) => {
  return (<>
    <div className='tab-actions _right'>
      <a href={'/recordings/add?composition=' + record.external_id} className='inline-flex items-center px-4 py-2 border border-transparent
        shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
        Link New Recording<PlusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
      </a>
      {/* <button type='button' onClick={openModal} className='ml-3 inline-flex items-center px-4 py-2 border border-transparent
        shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
        Link Existing Recording<LinkIcon className="ml-2 h-4 w-4" aria-hidden="true" />
      </button> */}
    </div>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Primary</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Artists
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ISRC
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Release Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    UPC
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {recordings.map((item, recordingIdx) => (
                  <tr key={item.external_id} className={recordingIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-3 py-4 text-sm font-medium">
                      {item.is_primary
                        ? (<button
                          type='button'
                          onClick={() => setPrimaryRecording(item.external_id)}
                          className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                          <StarIcon className="h-4 w-4" aria-hidden="true" />
                        </button>)
                        : (<button
                          type='button'
                          onClick={() => setPrimaryRecording(item.external_id)}
                          className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
                          <StarIcon className="h-4 w-4" aria-hidden="true" />
                        </button>)
                      }
                    </td>
                    <td className="px-3 py-4 text-sm font-medium">
                      <a className="hover:text-blue-500" href={'/recordings/' + item.external_id + '/'}>{item.title}</a>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.related_artists && 
                        (<span>
                          {item.related_artists.map((artist, idx) => {
                            return (
                              <span>
                                <a className="block mb-1 hover:text-blue-500" href={'/artists/' + artist.external_id}>{artist.name}</a>
                              </span>
                            )
                          })}
                        </span>)
                      }
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.isrcs}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.release_date}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.upc_list &&
                        (<span>
                          {item.upc_list.map((upc, idx) => {
                            return (
                              <span>
                                {item.upc_list[idx + 1]
                                  ? (<span>{upc}<br /></span>)
                                  : (<span>{upc}</span>)
                                }
                              </span>
                            )
                          })}
                        </span>)
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RecordingTable