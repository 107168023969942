import PrivateAPI from './private'

export const getList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.rownum !== undefined) {
        params['rownum'] = payload.rownum
    }

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    if (payload.f !== undefined) {
        params['f'] = payload.f
    }

    var url = 'api/publishing/artists/exp/'

    return PrivateAPI.get(url, params)
}

export const fetch = (pk) => {
    return PrivateAPI.get('api/publishing/artists/' + pk + '/')
}

export const fetchE = (pk) => {
    return PrivateAPI.get('api/publishing/artists/e/' + pk + '/')
}

export const fetchRecordings = (pk, page, rownum, filter) => {
    var params = {}
    if (filter !== undefined && filter !== '') {
        params['_filter'] = filter
    }
    return PrivateAPI.get('api/publishing/artists/' + pk + '/recordings/' + page + '/' + rownum, params)
}

export const create = (payload) => {
    return PrivateAPI.post('api/publishing/artists/', payload)
}

export const edit = (payload, pk) => {
    return PrivateAPI.put('api/publishing/artists/' + (pk) + '/', payload)
}

export const del = (artistId) => {
    return PrivateAPI.delete('api/publishing/artists/' + artistId + '/')
}

export const fetchRecordLabels = () => {
    return PrivateAPI.get('api/publishing/record-labels/exp/')
}
