import React, { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'

import InfoBar from 'components/InfoBar'
import FileInput from './FileInput'
import Select from 'components/Select'
import SelectField from 'layouts/components/Select'
import { transformData } from 'views/Upload/utils'

import { onSubmit, onEdit } from 'api/upload'

const toggleOptions = [
  { value: 'shares', label: 'Shares' },
  { value: 'writers', label: 'Writers' },
  { value: 'publishers', label: 'Publishers' },
  { value: 'recordings', label: 'Recordings' },
  { value: 'share_recordings', label: 'Shares & Recordings' }
]

const uploadFormats = [
  { value: 'exploration', label: 'Exploration' },
  { value: 'shelly_bay', label: 'Shelly Bay' },
  { value: 'song_delivery', label: 'Song Delivery' }
]

const platforms = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'mri', label: 'MRI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'bmi', label: 'BMI' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'sub-publishing', label: 'Sub-Publishing' },
  { value: 'mlc', label: 'MLC' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'lyricfindmerch', label: 'LyricFindMerch' },
  { value: 'dl', label: 'Direct Licensing' },
  { value: 'sync', label: 'Sync' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UploadForm = ({ showStats, datafeed }) => {
  const [saving, setSaving] = useState(false)
  const [saveFile, setSaveFile] = useState()
  const history = useHistory()

  const [isUpdate, setIsUpdate] = useState(false)
  const [ingestFormat, setIngestFormat] = useState('exploration')

  let initialValues = {}

  useEffect(() => {
    if (datafeed) {
      let datafeedCopy = Object.assign({}, datafeed)
      initialValues = transformData(datafeedCopy)
    }
    reset(initialValues)
  }, [datafeed])

  const { register, handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const submitForm = (formProps) => {
    setSaving(true)
    let formData = Object.assign({}, formProps)

    formData['is_update'] = isUpdate
    if (formProps['platformsReady'] !== undefined) {
      let platformList = []
      formProps['platformsReady'].forEach(platform => platformList.push(platform.value))
      formData['platforms_ready'] = platformList
    }

    if (formProps['pk'] !== undefined && formProps['pk'] !== '') {
      onEdit(formProps['pk'], formData).then(res => {
        if (res.status === 200) {
          history.go(0)
        } else {
          toast.error('Unable to update ingest information.')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to update ingest information')
      })
    } else {
      formData['file'] = saveFile
      onSubmit(formData).then(res => {
        if (res.status === 200) {
          history.push('/upload/' + res.data.pk)
        }
      }).catch(err => {
        if (err.response.data?.error) {
          toast.error(err.response.data?.error)
        } else {
          toast.error('An error occured in processing your file. Please check the headers of the CSV file then try again.')
        }
      })
    }
    setSaving(false)
  }

  const cleanForm = () => {
    reset({
      'ingest_format': 'exploration',
      'ingest_type': 'shares',
      'isUpdate': false,
      'platformsReady': null
    })
  }

  return (<div className='overflow-visible'>
    {/* <Options show={showAdvanced} ingestType={ingestType} /> */}
    {datafeed && <InfoBar
      text={`Editing information for ingest file: ${datafeed.filename}`}
      onClose={cleanForm}
      />}
    <Form className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6" onSubmit={handleSubmit(submitForm)}>
      <Form.Control
        type='hidden'
        {...register('pk')}
      />
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <Controller
              control={control}
              name={`file`}
              render={() => (
                <FileInput saveFile={setSaveFile} />
              )}
            />
          </div>
        </div>

        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">File Information</h3>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="ingestFormat" className="block text-sm font-medium text-gray-700">
                Ingest Format
              </label>
              <div className="mt-1">
                <Select
                  id="ingestFormat"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="ingest_format"
                  onChange={(e) => setIngestFormat(e.value)}
                  options={uploadFormats}
                  register={register}
                />
              </div>
            </div>
            {ingestFormat === 'exploration' && (<div className="sm:col-span-2">
                <label htmlFor="ingestType" className="block text-sm font-medium text-gray-700">
                  Ingest Type
                </label>
                <div className="mt-1">
                  <Select
                    id="ingestType"
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    name="ingest_type"
                    options={toggleOptions}
                    register={register}
                  />
                </div>
              </div>)
            }
            
            <div className="sm:col-span-2">
              <Switch.Group as="div" className="flex items-center justify-between">
                <span className="flex-grow flex flex-col">
                  <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                    Update?
                  </Switch.Label>
                  <Switch.Description as="span" className="text-sm text-gray-500">
                    Update existing assets.
                  </Switch.Description>
                </span>
                <Switch
                  checked={isUpdate}
                  onChange={setIsUpdate}
                  className={classNames(
                    isUpdate ? 'bg-red-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isUpdate ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="platformsReady" className="block text-sm font-medium text-gray-700">
                Platforms Ready For Submission
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name={`platformsReady`}
                  render={(props) => (
                    <SelectField
                      {...props.field}
                      options={platforms}
                      onChange={(e) => props.field.onChange(e)}
                      isMulti
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <p className="text-sm text-gray-500">Additional information regarding this ingest.</p>
              <div className="mt-1">
                <textarea
                  {...register('notes')}
                  id="notes"
                  name="notes"
                  rows={3}
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => cleanForm()}
            disabled={saving}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </Form>
  </div>)
}

export default (UploadForm)