import PrivateAPI from './private'

export const getList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.rownum !== undefined) {
    params['rownum'] = payload.rownum
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  if (payload.f !== undefined) {
    params['f'] = payload.f
  }

  return PrivateAPI.get('api/publishing/writers/exp/', params)
}

export const getWriterSelectList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/publishing/writers/options/', params)
}

export const getWriterSelectPageList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/publishing/writers/options/', params)
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/publishing/writers/' + pk + '/')
}

export const fetchCompositions = (pk, page, rownum, filter) => {
  var params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/publishing/writers/' + pk + '/compositions/' + page + '/' + rownum + '/', params)
}

export const fetchShares = (pk, page) => {
  return PrivateAPI.get('api/publishing/writers/' + pk + '/shares/' + page + '/')
}

export const fetchOwnerships = (pk, page, rownum, filter) => {
  var params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/publishing/writers/' + pk + '/ownerships/' + page + '/' + rownum + '/', params)
}

export const fetchE = (pk) => {
  return PrivateAPI.get('api/publishing/writers/e/' + pk + '/')
}

export const create = (payload) => {
  return PrivateAPI.post('api/publishing/writers/', payload)
}

export const edit = (payload, pk) => {
  return PrivateAPI.put('api/publishing/writers/' + (pk) + '/', payload)
}

export const del = (writerId) => {
  return PrivateAPI.delete('api/publishing/writers/' + writerId + '/')
}

export const fetchPublishers = () => {
  return PrivateAPI.get('api/publishing/publishers/options/')
}
