import PublicAPI from './public'
import PrivateAPI from './private'

export const setup = () => {
  const token = localStorage.getItem('accessToken')
  return PrivateAPI.post('/api/auth/token/verify/', { token: token })
}

export const refresh = () => {
  const token = localStorage.getItem('refreshToken')
  return PrivateAPI.post('/api/auth/token/refresh/', { refresh: token })
}

export const login = (data) => {
  return PublicAPI.post('/api/auth/login/', data)
}

export const register = (data) => {
  return PublicAPI.post('/api/users/', data)
}

export const checkEmail = (email) => {
  return PublicAPI.post('/api/auth/check-email/', {'email': email})
}

export const verifyEmail = (data) => {
  return PublicAPI.post('/api/users/verify/' + data['verify_token'] + '/', {})
}

export const resetPassword = (data) => {
  return PublicAPI.post('/api/auth/passwords/reset_password/', data)
}

export const confirmPassword = (data) => {
  return PublicAPI.post('/api/auth/passwords/confirm/', data)
}

export const changePassword = (data) => {
  return PrivateAPI.post('/api/auth/passwords/change/', data)
}

export const userDetail = () => {
  return PrivateAPI.get('/api/users/me/', {})
}

export const userDetailUpdate = (data) => {
  return PrivateAPI.put(`/api/users/me/`, data)
}

export const userUpdate = (data, pk) => {
  return PrivateAPI.put(`/api/users/${pk}/`, data)
}

export const userChangePhoto = (data) => {
  return PrivateAPI.post('/api/auth/user/change-photo/', data)
}

export const googleLogin = (accessToken) => {
  return PublicAPI.post('/api/users/oauth/', { accessToken: accessToken })
}
