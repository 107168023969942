import React, { useRef, useState } from 'react'
import { getMlcApi } from 'api/tools'
import toast from 'react-hot-toast'

const MLCSearch = ({ setSongData }) => {
  const search = useRef()
  const [searching, setSearching] = useState(false)

  const fetchMlcData = () => {
    setSearching(true)
    getMlcApi(search.current.value).then(res => {
      if (res.status === 200) {
        setSongData(res.data)
      } else {
        toast.error('Song code not found')
      }
    }).catch(err => {
      console.log(err.response)
      toast.error("Song not found. Kindly make sure that the MLC Song Code you entered exists then try again.")
    })
    setSearching(false)
  }

  const _keyUp = (event) => {
    if (event.keyCode === 13) {
      fetchMlcData()
    }
  }

  return (<>
    <div className="mt-1 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs">
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <input
                type="text"
                ref={search}
                className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder='Enter MLC Code'
                onKeyUp={(event) => _keyUp(event)}
            />
        </div>
        <button
            type="button"
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => fetchMlcData()}
        >
            Search
        </button>
    </div>
    {/* <div className='search-form'>
        <div className='input-group search-input-group big-search'>
        <span className='input-group-text' onClick={() => fetchMlcData()}>
            {searching ? (<i className='fa fa-spin fa-spinner' />)
                : (<Icon name='search' />)}
        </span>
        <input
            type='text'
            className='form-control'
            placeholder='Search MLC' ref={search}
            onKeyUp={(event) => _keyUp(event)} />
        <button type='button'
            className='btn btn-search btn-primary'
            onClick={() => fetchMlcData()}
        >Search</button>
        </div>
    </div> */}
  </>)
}

export default MLCSearch