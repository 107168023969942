import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useForm, Controller, FormProvider } from 'react-hook-form'

import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import ExplorationControl from 'components/ExplorationControl'
import SelectField from 'layouts/components/Select.js'
import RenderReleases from 'views/Recording/components/RenderReleases'
import CompositionSelectModal from 'layouts/components/CompositionModalSelect/CompositionSelectModal'
import { AssignToComposition } from 'layouts/components/CompositionModalSelect/AssignToComposition'
import ArtistSelectModal from 'layouts/components/ArtistModalSelect/ArtistSelect'
import { AssignToArtist } from 'layouts/components/ArtistModalSelect/AssignToArtist'
import LabelSelectModal from 'layouts/components/LabelModalSelect/LabelSelectModal'
import { AssignToLabel } from 'layouts/components/LabelModalSelect/AssignToLabel'

import moment from 'moment'

import { checkProperties } from '../../../utils'

import SelectToggle from 'layouts/components/SelectToggle.js'
import { territoryOptions } from 'helper/territories'
import RenderYtSrIds from '../../../components/RenderYtSrIds'

import { create } from 'api/recordings'

const RecordingForm = ({ record, initialValues }) => {
  const history = useHistory()

  const [selectCompositionModal, setSelectCompositionModal] = useState(false)
  const [selectedCompositionField, setSelectedCompositionField] = useState(initialValues?.composition_field || '')
  const [selectedArtists, setSelectedArtists] = useState([])
  const [selectedArtistField, setSelectedArtistField] = useState('')
  const [selectArtistModal, setSelectArtistModal] = useState()
  const [selectLabelModal, setSelectLabelModal] = useState(false)
  const [selectedLabelField, setSelectedLabelField] = useState('')

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  var relatedArtists = methods.watch('related_artists')
  var controlled = methods.watch('controlled')

  const assignComposition = (comp) => {
    if (comp) {
      methods.setValue('composition', comp.pk)
      setSelectedCompositionField(comp._title)
      setSelectCompositionModal(false)
    } else {
      methods.setValue('composition', null)
      setSelectedCompositionField(' ')
      setSelectCompositionModal(false)
    }
  }

  const toggleSelectCompositionModal = () => {
    setSelectCompositionModal(!selectCompositionModal)
  }

  const assignLabel = (label) => {
    if (label) {
      if (label['new']) {
        methods.setValue('new_record_label', label)
        setSelectedLabelField(label['name'])
      } else {
        methods.setValue('new_record_label', label.pk)
        setSelectedLabelField(label.name)
      }
    } else {
      methods.setValue('new_record_label', null)
      setSelectedLabelField(' ')
    }
    setSelectLabelModal(false)
  }

  const toggleSelectLabelModal = () => {
    setSelectLabelModal(!selectLabelModal)
  }

  const assignArtist = (artist) => {
    if (artist) {
      if (artist['new']) {
        if (artist['first_name']) {
          methods.setValue(`artist_field`, artist['first_name'] + ' ' + artist['last_name'])
          setSelectedArtistField(artist['first_name'] + ' ' + artist['last_name'])
        } else {
          methods.setValue(`artist_field`, artist['last_name'])
          setSelectedArtistField(artist['last_name'])
        }
        methods.setValue(`related_artists`, artist)
        setSelectedArtists(artist)
      } else {
        let artists = selectedArtists
        let artistField = selectedArtistField

        if (artists.includes(artist)) {
          artists.splice(artists.indexOf(artist), 1)

          let artistString = ', ' + artist.name

          if (artistField.includes(artistString)) {
            artistField = artistField.replace(artistString, '')
          } else {
            artistField = artistField.replace(artist.name + ', ', '')
          }
        } else {
          artists.push(artist)

          if (artistField.length > 0) {
            artistField = artistField + ', ' + artist.name
          } else {
            artistField = artist.name
          }
        }

        methods.setValue(`artist_field`, artistField)
        methods.setValue(`related_artists`, artists)

        setSelectedArtistField(artistField)
        setSelectedArtists(artists)
      }
    } else {
      methods.setValue('artist_field', '')
      methods.setValue('related_artists', [])
      setSelectedArtistField('')
      setSelectedArtists([])
    }
  }

  const toggleSelectArtistModal = () => {
    setSelectArtistModal(!selectArtistModal)
  }

  const formatData = (values) => {
    let data = values
    if (data.composition_field) {
      delete data.composition_field
    }

    if (data.related_artists !== undefined && Array.isArray(data.related_artists)) {
      data.related_artists = data.related_artists.map((artist, idx) => {
        return artist.pk
      })
    }

    if (!data['percentage']) {
      delete data['percentage']
    }

    if (data['releases']) {
      if (!checkProperties(data['releases'][0])) {
        delete data['releases']
      } else {
        for (var x of data['releases']) {
          if (x['release_date']) {
            let date = x['release_date']
            x['release_date'] = moment(date).format('YYYY-MM-DD')
          }
        }
      }
    }

    if (data['territory']) {
      if (Array.isArray(data['territory'])) {
        data['territory'] = (data['territory'].map((terr) => { return terr.value })).join(',')
      } else {
        data['territory'] = data['territory']['value']
      }
    }
    if (data['reviewed']) {
      data['reviewed'] = data['reviewed'].value
    }
    create(data).then(res => {
      if (res.status === 201) {
        history.push('/recordings/' + res.data.external_id + '/')
        toast.success('Successfully created recording!')
      } else {
        toast.error('Unable to create recording')
      }
    }).catch(err => {
      console.log(err)
      toast.error('Unable to create recording')
    })
  }

  let reviewSelect = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  let includeSelect = [
    { value: 'include', label: 'Include' },
    { value: 'exclude', label: 'Exclude' }
  ]

  return (<FormProvider {...methods}>
    <CompositionSelectModal
      status={selectCompositionModal}
      toggleModal={toggleSelectCompositionModal}
      assignComposition={assignComposition}
    />
    <ArtistSelectModal
      status={selectArtistModal}
      toggleModal={toggleSelectArtistModal}
      assignArtist={assignArtist}
      relatedArtists={relatedArtists}
    />
    <LabelSelectModal
      status={selectLabelModal}
      toggleModal={toggleSelectLabelModal}
      assignLabel={assignLabel}
    />
    <form className="space-y-8" onSubmit={methods.handleSubmit(formatData)} id='newRecording'>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about the Recording. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="title"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('title', { required: true })}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                Assign To Composition
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='composition_field'
                  render={(props) => (
                    <AssignToComposition
                      className='form-control'
                      value={selectedCompositionField}
                      toggleSelectCompositionModal={toggleSelectCompositionModal}
                    />
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                Reviewed?
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='reviewed'
                  render={(props) => (
                    <SelectField
                      {...props}
                      options={reviewSelect}
                      onChange={(e) => {
                        methods.setValue('reviewed', e)
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                Artists
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='artist_field'
                  render={(props) => (
                    <AssignToArtist
                      className='form-control'
                      value={selectedArtistField}
                      toggleSelectArtistModal={toggleSelectArtistModal}
                    />
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="record-label" className="block text-sm font-medium text-gray-700">
                Record Label
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='label_field'
                  render={(props) => (
                    <AssignToLabel
                      className='form-control'
                      value={selectedLabelField}
                      toggleSelectLabelModal={toggleSelectLabelModal}
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="percentage" className="block text-sm font-medium text-gray-700">
                Percentage
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="percentage"
                  id="percentage"
                  autoComplete="percentage"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('percentage')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="percentage" className="block text-sm font-medium text-gray-700">
                Territory
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='territory'
                  render={(props) => (
                    <SelectField
                      {...props}
                      options={territoryOptions}
                      isMulti
                      onChange={(e) => {
                        methods.setValue('territory', e)
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="territory-restriction" className="block text-sm font-medium text-gray-700">
                Restriction
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='territory_restriction'
                  render={() => (
                    <SelectToggle
                      options={includeSelect}
                      onChange={(e) => {
                        methods.setValue('territory_restriction', e)
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="yt-sr-custom-id" className="block text-sm font-medium text-gray-700">
                YouTube SR Custom ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="yt-sr-custom-id"
                  id="yt-sr-custom-id"
                  autoComplete="yt-sr-custom-id"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('yt_sr_custom_id')}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="yt-art-track-custom-id" className="block text-sm font-medium text-gray-700">
                YouTube Art Track Custom ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="yt-art-track-custom-id"
                  id="yt-art-track-custom-id"
                  autoComplete="yt-art-track-custom-id"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('yt_art_track_custom_id')}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="genre" className="block text-sm font-medium text-gray-700">
                Genre
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="genre"
                  id="genre"
                  autoComplete="genre"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('genre')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="isrcs" className="block text-sm font-medium text-gray-700">
                ISRC
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="isrcs"
                  id="isrcs"
                  autoComplete="isrcs"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('isrcs')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="royalty-code" className="block text-sm font-medium text-gray-700">
                Client Code<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="royalty-code"
                  id="royalty-code"
                  autoComplete="royalty-code"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...methods.register('royalty_code', { required: true })}
                />
              </div>
            </div>
            
            <div className="sm:col-span-3">
              <label htmlFor="yt-sr-ids" className="block text-sm text-gray-700">
                <span className="font-medium">YouTube SR IDs</span>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  List of related YouTube Sound Recording IDs. (* required)
                </p>
              </label>
              <div className="mt-1">
                <RenderYtSrIds />
              </div>
            </div>
            
            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1">
                <Controller
                  control={methods.control}
                  name='note'
                  render={(props) => (
                    <ReactQuill
                    {...props}
                    theme='snow'
                    placeholder='Add a note...'
                    onChange={(e) => {
                      methods.setValue('note', e)
                    }}
                    />
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Any additional information for the publisher. (Ex: Agreement with Only Solutions Entertainment.)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Releases</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              List of releases related to the Recording. (* required)
            </p>
          </div>
          <RenderReleases />
        </div>
      </div>

      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Controlled Platforms</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              List of platforms where this Recording is controlled by Exploration.
            </p>
          </div>
          <ExplorationControl
            id={'recordingPlatforms'}
            initialValues={initialValues}
            controlled={controlled}
            type={'recording'}
          />
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </FormProvider>)
}

export default RecordingForm
