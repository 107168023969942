import React from 'react'
import UploadForm from 'views/Upload/routes/Form/components/UploadForm.js'

export default function ImportData () {
  return (
    <div>
      <h2 className="text-xl font-bold text-black sm:text-2xl sm:truncate">
        Import Data
      </h2>
      <div className="sm:block">
        <div className="flex flex-col mt-2">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <UploadForm />
          </div>
        </div>
      </div>
    </div>
  )
}
