import React, { useState } from 'react'

import './List.scss'

import InfoBar from 'components/InfoBar'
import Icon from 'layouts/components/Icon'
import PublisherListTable from './PublisherListTable'
import Modal from 'react-modal'
import PublisherSearch from './PublisherSearch'
import NewPublisher from './NewPublisher'

import { getPublisherSelectList } from 'api/publishers'

const PublisherSelectModal = ({ change, toggleModal, pubValue, assignPublisher, pub, status, index }) => {
  const [currPage, setCurrPage] = useState(1)
  const [searchQ, setSearchQ] = useState('')
  const [refreshTable, setRefreshTable] = useState(false)
  const [newPub, setNewPub] = useState(false)

  const onResetSearch = () => {
    getPublisherSelectList({ page: 1, q: ''})
  }

  const _doSearch = (searchQ) => {
    if (status) {
      setSearchQ(searchQ)
      setCurrPage(1)
      getPublisherSelectList({ page: 1, q: searchQ })
      setRefreshTable(!refreshTable)
    }
  }

  const toggleNewPub = () => {
    setNewPub(!newPub)
  }

  const saveNewPub = (pubName) => {
    setNewPub(!newPub)

    if (pub.includes('publishers')) {
      change(`${pub}.owner_field`, pubName)
    } else {
      change(`${pub}.administrator_field`, pubName)
    }
    change(`${pub}.create`, 'create')
    toggleModal()
  }

  const customStyles = {
    content : {
      width                 : '60%',
      top                   : '53%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : '-43%',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -50%)',
      overflowY             : 'auto',
      maxHeight             : '80vh'
    }
  };

  return (
    <Modal
      isOpen={status}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={toggleModal}
    >
      <button type='button'
        className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
        onClick={() => toggleModal()}>
        Continue
      </button>
      <br /><br /><br />
      {searchQ === '' ? null : (
        <InfoBar
          text={'Showing search result for ' + "'" + searchQ + "'"}
          onClose={onResetSearch}
        />
      )}

      <div className="page">
        <div className='page-header'>
          <div className='page-title'>
            <span className='main'>Select Publisher</span><br />
            <span className='form-help'>Select multiple Publishers and click continue to close this window</span>
          </div>

          <PublisherSearch doSearch={_doSearch} />
        </div>

        {pubValue !== undefined
          ? (<div>{pubValue.map((pub, idx) => {
            return (
              <span onClick={() => assignPublisher(pub)} key={idx} className='mr-1 text-white text-sm cursor-pointer rounded-md p-1 bg-red-600'>{pub.pub_name}</span>
            )
          })}
          </div>)
          : null
        }
        <br />

        {newPub
          ? <NewPublisher
            toggleNewPub={toggleNewPub}
            saveNewPub={saveNewPub}
            pub={pub}
            index={index} />
          : <div>
            <div className='float-right'>
              <button className='btn btn-xs btn-hover btn-hover-primary' type='button'
                onClick={() => toggleNewPub()}>
                <Icon name='add' spaceAfter />Add Publisher
              </button>
            </div>
            <br /><br />
            <div className='page-body no-padding'>
              <PublisherListTable
                status={status}
                currPage={currPage}
                searchQ={searchQ}
                refreshTable={refreshTable}
                pubValue={pubValue}
                assignPublisher={assignPublisher}
              />
            </div>
          </div>
        }
      </div>

      {!newPub
        ? <button type='button'
          className='py-2 px-4 float-right btn btn-primary btn-wide btn-submit btn-flat'
          onClick={() => toggleModal()}>
          Continue
        </button>
        : null
      }
    </ Modal>
  )
}

export default PublisherSelectModal
