import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import Icon from 'layouts/components/Icon'
import AdministratorSelect from './AdministratorSelect'

const RenderAdministrators = () => {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "administrators",
    keyName: "uuid"
  })

  return (<>
    {fields.map((admin, index) =>
      <AdministratorSelect
        key={admin.uuid}
        fields={fields}
        admin={admin}
        index={index}
        remove={remove}
      />
    )}
    <div className="pt-3">
      <div className="flex">
        <button
          type="button"
          onClick={() => append()}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Add Admin
        </button>
      </div>
    </div>
  </>)
}

export default RenderAdministrators
