import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import Creatable from 'layouts/components/Creatable'

import PublisherSelectModal from 'layouts/components/PublisherModalSelect/PublisherSelect'
import { AssignToPublisher } from './AssignToPublisher'

const PublisherSelect = ({ publisher, index, selectedPublishers, change, control, register, remove }) => {
  const [selectedPublisher, setSelectedPublisher] = useState(publisher ? publisher : null)
  const [selectedPublisherField, setSelectedPublisherField] = useState(publisher ? publisher.publisher_field : '')
  const [selectPublisherModal, setSelectPublisherModal] = useState(false)

  const toggleSelectPublisherModal = () => {
    setSelectPublisherModal(!selectPublisherModal)
  }

  const assignPublisher = (pub) => {
    change(`publishers[${index}].publisher_field`, pub.pub_name)
    change(`publishers[${index}].publisher`, pub)
    setSelectedPublisher(pub)
    setSelectedPublisherField(pub.pub_name)
    setSelectPublisherModal(false)
  }

  const pubPlatforms = [
    { value: 'youtube', label: 'YouTube' },
    { value: 'mri', label: 'MRI' },
    { value: 'hfa', label: 'HFA' },
    { value: 'cmrra', label: 'CMRRA' },
    { value: 'ascap', label: 'ASCAP' },
    { value: 'bmi', label: 'BMI' },
    { value: 'sesac', label: 'SESAC' },
    { value: 'soundexchange', label: 'SoundExchange' },
    { value: 'distribution', label: 'Distribution' },
    { value: 'sub-publishing', label: 'Sub-Publishing' }
  ]

  return (
    <>
      <PublisherSelectModal
        status={selectPublisherModal}
        toggleModal={toggleSelectPublisherModal}
        assignPublisher={assignPublisher}
        pub={publisher}
        selectedPublishers={selectedPublishers}
      />
      <div className="bg-white rounded-md">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
              <label htmlFor="publisher" className="block text-sm font-medium text-gray-700">
                Publisher {index + 1}<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name={`publishers[${index}].publisher_field`}
                  render={() => (
                    <AssignToPublisher
                      className='form-control'
                      value={selectedPublisherField}
                      toggleSelectPublisherModal={toggleSelectPublisherModal}
                    />
                  )}
                />
              </div>
          </div>

          <div className="sm:col-span-2">
              <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
                Platform
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name={`publishers[${index}].platform`}
                  render={(props) => (
                    <Creatable
                      options={pubPlatforms}
                      defaultValue={publisher ? publisher.platform : null}
                      onChange={(e) => {
                        change(`publishers[${index}].platform`, e)
                      }}
                    />
                  )}
                />
              </div>
          </div>
          <div className="sm:col-span-1">
              <label htmlFor="account-number" className="block text-sm font-medium text-gray-700">
                Account Number<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="account-number"
                  id="account-number"
                  autoComplete="account-number"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register(`publishers[${index}].account_number`)}
                />
              </div>
          </div>
        </div>
        {index !== null ? <button
          className='btn btn-link btn-remove'
          type='button'
          onClick={() => remove(index)}>
          Remove
        </button> : null }
      </div>
    </>
  )
}

export default PublisherSelect
