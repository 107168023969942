import React, { useState, useEffect } from 'react'

function Checkbox ({ inputClassName, wrapperClassName, label, id, disabled, defaultValue, onChange, ...props }) {
  const [value, setValue] = useState(defaultValue ? defaultValue : false)

  let inputKlassName = ['form-check-input', inputClassName]
  let wrapperKlassName = ['form-check', wrapperClassName]

  let inputId = 'toggleSwitch'
  if (id !== undefined && id !== null && id !== '') {
    inputId = id
  }

  if (disabled) {
    inputKlassName.push('disabled')
    wrapperKlassName.push('disabled')
  }

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [])

  return (
    <div className="inline-flex items-start mr-3">
        <div className="flex items-center h-5">
          <input
              value={value}
              checked={value}
              onChange={() => {
                onChange(!value)
                setValue(!value)
              }}
              id={inputId}
              name={inputId}
              disabled={disabled}
              type="checkbox"
              className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={inputId} className="font-medium text-gray-700">
            {label}
          </label>
        </div>
    </div>
  )
}

export default Checkbox
