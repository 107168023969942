import React from 'react'
import PublisherCard from './PublisherCard'
import WriterCard from './WriterCard'

const MLCCard = ({ songData }) => {
    let akas = songData.akas
    let publishers = songData.publishers
    let writers = songData.writers

    return (<>
        <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{songData.primaryTitle}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{akas.akaTitle}</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">AKA ID</dt>
                        <dd className="mt-1 text-sm text-gray-900">{akas.akaId}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">AKA Title Type Code</dt>
                        <dd className="mt-1 text-sm text-gray-900">{akas.akaTitleTypeCode}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">MLC Song Code</dt>
                        <dd className="mt-1 text-sm text-gray-900">{songData.mlcSongCode}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">ISWC</dt>
                        <dd className="mt-1 text-sm text-gray-900">{songData.iswc}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Member's Song ID</dt>
                        <dd className="mt-1 text-sm text-gray-900">{songData.membersSongId}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Artists</dt>
                        <dd className="mt-1 text-sm text-gray-900">{songData.artists}</dd>
                    </div>
                </dl>
            </div>

            {/* Publishers */}
            <hr />
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Publishers</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Publisher information.</p>
            </div>
            {publishers.map(function (pub, pubIdx) {
                return (<PublisherCard key={'pub-' + pubIdx} pubData={pub} />)
            })}

            {/* Writers */}
            <hr />
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Writers</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Writer information.</p>
            </div>
            {writers.map(function (writer, writerIdx) {
                return (<WriterCard key={'writer-' + writerIdx} writerData={writer} />)
            })}
        </div>
    </>)
}

export default MLCCard