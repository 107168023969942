import React from 'react';
import toast from 'react-hot-toast'

export default function Paginator ({ totalPages, currPage, setCurrPage, hasNext, getPrev, getNext }) {
  const goPrev = (page) => {
    toast.loading('Loading assets...')
    getPrev(parseInt(page))
    var element = document.getElementById("table-header")
    element?.scrollIntoView()
    toast.dismiss()
  }

  const goNext = (page) => {
    toast.loading('Loading assets...')
    getNext(parseInt(page))
    var element = document.getElementById("table-header")
    element?.scrollIntoView()
    toast.dismiss()
  }

  const setPage = (page) => {
    toast.loading('Loading assets...')
    setCurrPage(parseInt(page))
    var element = document.getElementById("table-header")
    element?.scrollIntoView()
    toast.dismiss()
  }

  if (!totalPages) {
    return null
  }

  var PAGES = Array.from({length: totalPages}, (_, index) => index + 1);

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing page
          <select
            id="page"
            name="page"
            className="mt-2 ml-2 mr-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
            defaultValue={1}
            value={currPage}
          >
            {PAGES.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>
          of<span className="ml-1 font-medium">{totalPages}</span>
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {currPage > 1 &&
          <button
            type="button"
            onClick={() => goPrev(currPage)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
        }
        {hasNext &&
          <button
            type="button"
            onClick={() => goNext(currPage)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        }
      </div>
    </nav>
  )
}
