import React, { useState, useEffect } from 'react'

export const AssignToPublisher = (field) => {
  const { toggleSelectPublisherModal, ...custom } = field
  let { className, disabled } = custom
  let klassName = [className]

  if (disabled) {
    klassName.push('is-disabled')
  }

  const [value, setValue] = useState()

  useEffect(() => {
    if (custom.value) {
      setValue(custom.value)
    }
  }, [custom.value])

  return (
    <div className="mt-1 rounded-md shadow-sm flex">
      <button type="button"
        className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm"
        onClick={() => toggleSelectPublisherModal()}>
        Select
      </button>
      <input
        value={value}
        onChange={value => setValue(value)}
        type="text"
        name="publisher"
        id="publisher"
        className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
        placeholder=''
        data-lpignore='true'
        readOnly
      />
    </div>
  )
}

export default AssignToPublisher
