import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { FolderDownloadIcon } from '@heroicons/react/outline'
import Filters from './components/Filters'
import { getClients } from 'api/claim'

const ExportView = () => {
  const [clientOptions, setClientOptions] = useState([])

  useEffect(() => {
    getClients().then(res => {
      if (res.status === 200) {
        setClientOptions(res.data.data)
      } else {
        toast.error('Unable to fetch client list')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch client list')
    })
  }, [])

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Export Claimed Video IDs</h2>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <Link to='/download/my-exports/?filter=claims'>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                >
                  My Downloads<FolderDownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </Link>
            </div>
          </div>

          <div className='mt-4 divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
            {clientOptions && <Filters clientOptions={clientOptions} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExportView
