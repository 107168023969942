import PrivateAPI from './private'

export const getList = (payload) => {
  console.log('Getting list with params', payload)

  console.log(payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.rownum !== undefined) {
    params['rownum'] = payload.rownum
  }

  if (payload.q !== undefined && payload.q !== '') {
    params['q'] = payload.q
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  if (payload.f !== undefined) {
    params['f'] = payload.f
}

  return PrivateAPI.get('api/publishing/record-labels/exp/', params)
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/publishing/record-labels/' + pk + '/')
}

export const fetchE = (pk) => {
  return PrivateAPI.get('api/publishing/record-labels/e/' + pk + '/')
}

export const fetchRecordings = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/publishing/record-labels/' + pk + '/recordings/' + page + '/' + rownum, params)
}

export const create = (payload) => {
  return PrivateAPI.post('api/publishing/record-labels/', payload)
}

export const edit = (payload, pk) => {
  return PrivateAPI.put('api/publishing/record-labels/' + (pk) + '/', payload)
}

export const del = (recordLabelId) => {
  return PrivateAPI.delete('api/publishing/record-labels/' + recordLabelId + '/')
}
