import React from 'react'

const codeLabelList  = [
  {
    key: 'iswc',
    label: 'ISWC'
  },
  {
    key: 'language_code',
    label: 'Language Code'
  },
  {
    key: 'ascap_work_id',
    label: 'ASCAP Work ID'
  },
  {
    key: 'bmi_work_id',
    label: 'BMI Work ID'
  },
  {
    key: 'sesac_work_id',
    label: 'SESAC Work ID'
  },
  {
    key: 'cmrra_work_id',
    label: 'CMRRA Work ID'
  },
  {
    key: 'hfa_code',
    label: 'HFA/MLC Code'
  },
  {
    key: 'bmg_code',
    label: 'BMG Code'
  },
  {
    key: 'yt_comp_share_id',
    label: 'YouTube Comp Share ID'
  },
  {
    key: 'custom_id',
    label: 'Custom ID'
  },
  {
    key: 'client_song_id',
    label: "Client's Song ID"
  },
  {
    key: 'royalty_code',
    label: "Client Code"
  },
  ,
]

const Codes = ({ codes }) => {
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mx-2 my-2">
        <div className="px-4 py-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Related Codes</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Click on the code name to edit.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {codeLabelList.map((label, idx) => (
              <div className={idx % 2 === 0 ? 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6' : 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
                <dt className="text-sm font-medium text-gray-500">
                  {label.label}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span style={{flex: 2 , display: "flex"}}>
                    {codes[label['key']].map((code, idx) => {
                      return (
                        <span key={idx}>
                          <a className="px-3 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" href={`/shares/` + `${code['external_id']}`}>{code[label['key']]}</a>
                        </span>
                      )
                    })}
                  </span>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  )
}

export default Codes