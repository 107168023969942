import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'
import { DownloadIcon, MinusCircleIcon, RefreshIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

import RecordingTable from './RecordingTable'
import InfoBar from 'components/InfoBar'
import AssetSearch from 'components/AssetSearch'
import { fetchRecordings } from 'api/recordLabel'

const RelatedAssets = ({ id, songCount, setExport, showTable, setShowTable, selectAll, setSelectAll, selected, setSelected }) => {
  const history = useHistory()
  const location = useLocation()

  const query = queryString.parse(location.search)

  const [loading, setLoading] = useState(false)
  const [recordings, setRecordings] = useState([])
  const [pages, setPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [rownum, setRownum] = useState(10)
  const [searchQ, setSearchQ] = useState(query.qf || '')
  const [showAssets, setShowAssets] = useState(false)

  const getList = (page, rNum, filter) => {
    fetchRecordings(id, page, rNum, filter).then(res => {
      if (res.status === 200) {
        setRecordings(res.data.results)
        setPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch recordings.')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch recordings')
    })
  }

  const filterList = (recordingFilter) => {
    getList(1, rownum, recordingFilter)
    setRecordings(recordings)
    setShowTable(true)
  }

  useEffect(() => {
    if (searchQ) {
      filterList(searchQ)
    } else {
      if (query.page) {
        getList(currPage, rownum)
        setShowTable(true)
        setShowAssets(true)
      }
    }
  }, [searchQ, currPage])

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
  }

  const _doSearch = (filter) => {
    let query = {
      page: 1,
      qf: filter
    }
  
    if (filter === undefined || filter === '') {
      query = { page: 1 }
    }
    history.replace({
      pathname: `/record-labels/${id}`,
      search: `?${objectToQueryString(query)}`
    })
    setSearchQ(filter)
    setCurrPage(1)
  }

  const onPageChange = (evt) => {
    setCurrPage(evt.target.value)

    if (evt.target.value !== '' && parseInt(evt.target.value) !== currPage) {
      getList(evt.target.value, rownum)
    }
  }

  const _onNext = (page) => {
    getList(page + 1, rownum)
    setCurrPage(page + 1)

    let query = {
      page: page + 1,
    }

    if (searchQ) {
      query['qf'] = searchQ
    }

    history.replace({
      pathname: `/record-labels/${id}`,
      search: `?${objectToQueryString(query)}`
    })
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      getList(page - 1, rownum)
      setCurrPage(page - 1)

      let query = {
        page: page - 1,
      }
  
      if (searchQ) {
        query['qf'] = searchQ
      }
  
      history.replace({
        pathname: `/record-labels/${id}`,
        search: `?${objectToQueryString(query)}`
      })
    }
  }

  const handleRowChange = (event) => {
    setRownum(parseInt(event.target.value))
    getList(currPage, parseInt(event.target.value))
  }

  const toggleRow = (pk) => {
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => (
      (newSelected[x] === true) && (
        indeterminate = true
      )
    ))

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}

    let data = recordings

    if (selectAll === 0) {
      data.forEach(x => {
        newSelected[x.pk] = true
      })
    }

    setSelected(newSelected)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const resetSearch = () => {
    setSearchQ('')
    setSelected({})
    setSelectAll(0)
    setShowTable(false)
    setShowAssets(false)
    _doSearch('')
  }

  const showAllAssets = () => {
    let query = {
      page: 1,
    }

    if (searchQ) {
      query['qf'] = searchQ
    }

    history.replace({
      pathname: `/record-labels/${id}`,
      search: `?${objectToQueryString(query)}`
    })

    getList(1, rownum)
    setShowTable(true)
    setShowAssets(true)
  }

  const hasNext = currPage < pages
  const placeholder = songCount > 1 ? 'Search through ' + songCount + ' songs' : 'Search through ' + songCount + ' song'

  return (
    <>
      <div className="px-4 pt-4">
        <div className="md:flex md:items-center md:justify-end">
          <div className="flex-shrink-0 flex md:mt-0 md:ml-4">
            {/* <div>
              <ListSearch
                filterList={filterList}
                placeholder='Find compositions'
              />
            </div> */}
            {showAssets
              ? (<button
                    onClick={() => resetSearch()}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                  Hide All Assets<EyeOffIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>)
              : (<button
                    onClick={() => showAllAssets()}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                  Show All Assets<EyeIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              )
            }
            { selectAll !== 0
              ? (<button
                  onClick={() => setExport()}
                  type="button"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                Export Selected<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>)
              : (<button
                  onClick={() => setExport()}
                  type="button"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                Export<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>)
            }
            
          </div>
        </div>
      </div>
      <AssetSearch
        searchQ={searchQ}
        doSearch={_doSearch}
        placeholder={placeholder}
      />
      {showTable && (
        <div className='pb-1'>
          <div className='px-4'>
            {searchQ && (<InfoBar
                text={"Showing search result for '" + searchQ + "'"}
                onClose={() => resetSearch()}
                showClose
              />)
            }
          </div>
          <RecordingTable
            recordings={recordings}
            selected={selected}
            selectAll={selectAll}
            toggleRow={toggleRow}
            toggleSelectAll={toggleSelectAll}
          />
        </div>)
      }
    </>
  )
}

export default RelatedAssets
