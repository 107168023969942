import React from 'react'
import { Route, Switch } from 'react-router'
import List from './routes/List/index'
import Detail from './routes/Detail/index'
import Form from './routes/Form/index'

export default function RecordLabelViews () {
  return (
    <Switch>
      <Route exact path='/record-labels' component={List} />
      <Route path='/record-labels/add' component={Form} />
      <Route path='/record-labels/:id' component={Detail} />  
    </Switch>
  )
}
