/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import ListTable from 'components/ListTable'
import { TrashIcon, PencilIcon } from '@heroicons/react/outline'

export default function PotentialClaimCard ({ item }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  console.log(item)

  const status = {
    'claimed': 'Claimed',
    'released': 'Released',
    'misembed': 'MisEmbed',
    'add share': 'Add Share',
    'report for duplicate': 'Report for Duplicate'
  }

  const claimColumns = [
    {
      Header: 'Video Link',
      id: 'video_link',
      sortable: true,
      Cell: ({ row }) => (
        <a className='text-blue-500 hover:text-blue-600' target='_blank' href={row.original.video_link}>{row.original.video_link}</a>
      )
    }, {
      Header: 'Status',
      id: 'status',
      sortable: false,
      Cell: ({ row }) => (
        <div>{status[row.original.status]}</div>
      )
    }
  ]

  const categories = [
    { name: 'Claimed', bgColor: 'bg-red-600', total: item?.totals?.claimed },
    { name: 'Released', bgColor: 'bg-blue-600', total: item?.totals?.released },
    { name: 'MisEmbed', bgColor: 'bg-green-600', total: item?.totals?.misembed },
    { name: 'Add Share', bgColor: 'bg-indigo-600', total: item?.totals?.add_share },
    { name: 'Duplicate', bgColor: 'bg-yellow-600', total: item?.totals?.duplicate }
  ]
  
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
            <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                    <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {item.cms}
                        </h3>
                    </div>
                </div>
            </div>
            <ul role="list" className="mt-5 mb-5 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
                {categories.map((category) => (
                    <li key={category.name} className="col-span-1 flex shadow-sm rounded-md">
                    <div
                        className={classNames(
                            category.bgColor,
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                        )}
                    >
                        {category.total}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                        <div className="flex-1 px-4 py-2 text-sm">
                        {category.name}
                        </div>
                    </div>
                    </li>
                ))}
            </ul>
        </div>
        <hr />
        <h3 className="text-md font-medium px-5 py-3 text-gray-900">
           Potential Claims
        </h3> 
        <ListTable
            columns={claimColumns}
            data={item.claims}
        />
    </div>
  )
}
