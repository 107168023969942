import React from 'react'
import { useHistory } from 'react-router'
import { DownloadIcon } from '@heroicons/react/outline'

import ListSearch from 'components/ListSearch'
import { exportDuplicates } from 'api/download'

export default function Heading (props) {
  const { searchFirst, handleSearch } = props
  const history = useHistory()

  const getExport = () => {
    exportDuplicates().then(res => {
      history.push('/download/my-exports/?date=' + res.data.date)
    })
  }

  return (<>
    <div>
      <div className="mb-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Duplicates</h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <div>
            {searchFirst &&
              (<ListSearch
                filterList={handleSearch}
                placeholder='Find duplicate'
              />)
            }
          </div>
          <button
              onClick={() => getExport()}
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
            Export<DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </>)
}
