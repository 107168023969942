import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'


import CompositionSelectModal from 'layouts/components/CompositionModalSelect/CompositionSelectModal'
import { AssignToComposition } from 'layouts/components/CompositionModalSelect/AssignToComposition'
import ExplorationControl from 'components/ExplorationControl'

const TitleForm = ({ initialValues }) => {
  const { register, errors, setValue, watch } = useFormContext()
  const [selectCompositionModal, setSelectCompositionModal] = useState(false)
  const [selectedCompositionField, setSelectedCompositionField] = useState(initialValues.composition_field)

  var controlled = watch('controlled')

  const assignComposition = (composition) => {
    if (composition) {
      setValue('composition_field', composition._title)
      setValue('composition', composition.pk)
      setSelectedCompositionField(composition._title)
      setSelectCompositionModal(false)
    } else {
      setValue('composition_field', ' ')
      setValue('composition', null)
      setSelectedCompositionField(' ')
      setSelectCompositionModal(false)
    }
  }

  const toggleSelectCompositionModal = () => {
    setSelectCompositionModal(!selectCompositionModal)
  }

  return (<div className="space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow">
    <CompositionSelectModal
      status={selectCompositionModal}
      toggleModal={toggleSelectCompositionModal}
      assignComposition={assignComposition}
    />
    <div className='space-y-8'>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className='sm:col-span-3'>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="title"
              id="title"
              autoComplete="title"
              className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
              {...register('title', { required: true })}
            />
          </div>
        </div>
        <div className="sm:col-span-6">
          <label htmlFor="composition" className="block text-sm font-medium text-gray-700">
            Assign to Composition
          </label>
          <div className="mt-1">
            <AssignToComposition
              className='form-control'
              value={selectedCompositionField}
              toggleSelectCompositionModal={toggleSelectCompositionModal}
            />
          </div>
        </div>
      </div>
      <ExplorationControl
        id={'recordingPlatforms'}
        initialValues={initialValues}
        controlled={controlled}
        type={'recording'} />
    </div>
  </div>)
}

export default TitleForm
