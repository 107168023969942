import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import ReactTooltip from 'react-tooltip'

import PublisherRoles from 'views/Share/components/PublisherRoles'
import Icon from 'layouts/components/Icon'
import WriterOwnerSelectModal from '../WriterOwnerSelect/WriterOwnerSelect'
import { AssignToOwner } from './AssignToOwner'

const WriterOwnerSelect = ({ ownership, ownershipIndex }) => {
  const { control, setValue, watch } = useFormContext()

  const ownerValue = watch('owners')

  const [selectedOwners, setSelectedOwners] = useState([])
  const [selectedOwnerField, setSelectedOwnerField] = useState('')
  const [selectOwnerModal, setSelectOwnerModal] = useState(false)

  const toggleSelectOwnerModal = () => {
    setSelectOwnerModal(!selectOwnerModal)
  }

  const assignOwner = (pub) => {
    let owner = selectedOwners;
    let ownerField = selectedOwnerField;

    if (owner.includes(pub)) {
      owner.splice(owner.indexOf(pub), 1);

      let ownerString = ', ' + pub.pub_name;

      if (ownerField.includes(ownerString)) {
        ownerField = ownerField.replace(ownerString, '');
      } else {
        ownerField = ownerField.replace(pub.pub_name + ', ', '');
      }
    } else {
      owner.push(pub);

      if (ownerField.length > 0) {
        ownerField = ownerField + ', ' + pub.pub_name;
      } else {
        ownerField = pub.pub_name;
      }
    }

    setValue(`writers[${ownershipIndex}].owner_field`, ownerField)
    setValue(`writers[${ownershipIndex}].owners`, owner)

    setSelectedOwnerField(ownerField)
    setSelectedOwners(owner)
  }

  return (
    <>
      <WriterOwnerSelectModal
        status={selectOwnerModal}
        toggleModal={toggleSelectOwnerModal}
        assignWriter={assignOwner}
        selectedWriters={selectedOwners}
        ownerValue={ownership ? ownership.owners : []}
        pub={ownership}
        index={ownershipIndex}
        change={setValue}
      />
      <div className="bg-white rounded-md">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="collectors" className="block text-sm font-medium text-gray-700">
              Owner<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`writers[${ownershipIndex}].owner_field`}
                render={() => (
                  <AssignToOwner
                    className='form-control'
                    value={selectedOwnerField}
                    toggleSelectOwnerModal={toggleSelectOwnerModal}
                  />
                )}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="collector-role-code" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${ownershipIndex}].owner_role_code`}
                render={() => (
                  <PublisherRoles
                    name={`publishers[${ownershipIndex}].owner_role_code`}
                    defaultValue={ownership.owner_role_code}
                    control={control}
                    change={setValue}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WriterOwnerSelect
