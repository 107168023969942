import React, { useState, useEffect, useContext, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthContext } from 'stores/auth'
import Router from 'routes/Router'

import 'styles/index.scss'; // For Tailwind

import 'styles/scss/style.scss'
import 'styles/core.scss'
import 'styles/exploration-data.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

function App () {
  const authStore = useContext(AuthContext)
  const [loading, setLoading] = useState(true)

  const componentIsMounted = useRef(true);

  useEffect(() => {
    if (authStore.hasToken()) {
      authStore.fetchUser().then(() => {
        if (!componentIsMounted.current) return;
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [authStore])

  return (<Router loading={loading} />)
}

export default App
