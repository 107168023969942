import React, { useState, useContext } from 'react'
import { GoogleLogin } from '@react-oauth/google'

import { AuthContext } from 'stores/auth'
import { googleLogin } from 'api/auth'

const GoogleButton = (props) => {
  const authStore = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  const responseGoogleSuccess = async (response) => {
    setLoading(true)
    await googleLogin(response.credential).then(res => {
      authStore.oauthLogin(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err.response)
      setLoading(false)
    })
  }

  const responseGoogleFailure = (response) => {
    console.log('Google Login Error', response)
  }

  return (<div className='center'>
    <GoogleLogin
      shape="pill"
      width="320"
      onSuccess={responseGoogleSuccess}
      onError={responseGoogleFailure}
    />
  </div>)
}

export default GoogleButton
