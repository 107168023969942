import toast from 'react-hot-toast'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ListTable from 'components/ListTable'
import queryString from 'query-string'

import { getCompositionSelectList as getList } from 'api/composition'

const CompositionListTable = ({ propList, propPages, currentPage, searchQ, assignComposition }) => {
  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)
  const [rownum, setRownum] = useState(20)

  const [totalPages, setTotalPages] = useState(0)
  const [list, setList] = useState()

  const location = useLocation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    setLoading(true)
    if (propList !== undefined && propList.length > 0) {
      setList(propList)
      setTotalPages(propPages)
    } else {
      getList(_listParams(currPage, searchQ)).then((res) => {
        toast.loading('Loading compositions...')
        if (res.status === 200) {
          setLoading(false)
          setTotalPages(res.data.total_pages)
          setList(res.data.results)
          toast.dismiss()
        } else {
          setLoading(false)
          setList(undefined)
          toast.error('Unable to load compositions')
        }
      })
    }
  }, [propList])

  const _onNext = (page) => {
    setLoading(true)
    getList(_listParams(parseInt(page) + 1, searchQ)).then((res) => {
      toast.loading('Loading compositions...')
      if (res.status === 200) {
        setLoading(false)
        setTotalPages(res.data.total_pages)
        setList(res.data.results)
        toast.dismiss()
      } else {
        setLoading(false)
        setList(undefined)
        toast.error('Unable to load compositions')
      }
    })
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(_listParams(parseInt(page) - 1, searchQ)).then((res) => {
        toast.loading('Loading compositions...')
        if (res.status === 200) {
          setLoading(false)
          setTotalPages(res.data.total_pages)
          setList(res.data.results)
          toast.dismiss()
        } else {
          setLoading(false)
          setList(undefined)
          toast.error('Unable to load compositions')
        }
      })
      setCurrPage(parseInt(page) - 1)
    }
  }

  const _updateSortParams = useCallback((param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }, [])

  const _listParams = useCallback((page, searchQ, sort, rownum) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let qS = { page: page }

    if (rownum) {
      qS['rownum'] = rownum
    } else {
      qS['rownum'] = rownum
    }

    if (searchQ !== undefined && searchQ !== '') {
      qS['q'] = searchQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        qS['sort'] = _updateSortParams(sort).join()
        window.sortParam = qS['sort']
      } else {
        qS['sort'] = sort
        window.sortParam = qS['sort']
      }
    }
    if (window.sortParam !== undefined) {
      qS['sort'] = window.sortParam
    }

    return qS
  }, [_updateSortParams])

  const renderArrow = (columnId, sort) => {
    if (sort === columnId) {
      return '▲'
    } else if (sort === '-' + columnId) {
      return '▼'
    }
    return ''
  }

  if (list === undefined || list === null) {
    return (<div className='list-table'>
        Loading...
      </div>
    )
  }

  const hasNext = currPage < totalPages

  const compositionColumns = [
    {
      Header: 'ID',
      id: 'pk',
      sortable: false,
      minWidth: 100,
      accessor: item => item['pk']
    }, {
      Header: 'Title',
      id: '_title',
      sortable: false,
      maxWidth: 350,
      Cell: ({ row }) => (
        row.original._title
      )
    }, {
      Header: 'Writer',
      id: 'writers',
      sortable: false,
      maxWidth: 300,
      Cell: ({ row }) => (
        <div>{row.original.writers.map((writer, idx) => {
          if (idx !== 0) {
            return (
              ', ' + writer['name']
            )
          } else {
            return (
              writer['name']
            )
          }
        })}</div>
      )
    }, {
      Header: 'Artists',
      id: 'artists',
      sortable: false,
      maxWidth: 150,
      Cell: ({ row }) => (
        <div>{row.original.artists}</div>
      )
    },
    {
      id: 'selected',
      maxWidth: 100,
      className: 'select',
      Cell: ({ row }) => (
        <div
          style={{
            textAlign:'right'
          }}
        >
          <button className='py-2 px-4 btn btn-blue-gray btn-sm' type='button'
            onClick={() => assignComposition(row.original)}>Select</button>
        </div>
      ),
      sortable: false
    }
  ]

  return (
    <div className='list-table'>
      <ListTable
        columns={compositionColumns}
        data={list}
        getNext={_onNext}
        getPrev={_onPrevious}
        currPage={currPage}
        setCurrPage={setCurrPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination={searchQ !== ''}
      />
    </div>
  )
}

export default CompositionListTable
