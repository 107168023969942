import React, { useEffect, useState } from 'react'
import Paginator from 'components/Paginator'

const ListTable = ({ currentPage, searchQ, refreshTable, sort, propSelected, propSelectAll, toggleRow,
  toggleSelectAll, compositionList, pages, getList }) => {

  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (searchQ) {
      getList(currPage, searchQ)
    }
  }, [refreshTable])

  const _onNext = (page) => {
    setLoading(true)
    getList(parseInt(page) + 1, searchQ)
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(parseInt(page) - 1, searchQ)
      setCurrPage(parseInt(page) - 1)
    }
  }

  if (compositionList === undefined || compositionList === null) {
    return null
  }

  const hasNext = currPage < pages

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md" id="table-header">
      <ul className="divide-y divide-gray-200">
        {compositionList.map((composition) => {
          return (<li key={composition.external_id}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-left justify-between">
                <div>
                  <div className='pretty p-default p-curve'>
                    <input
                      type='checkbox'
                      checked={propSelected[composition.pk] === true}
                      onChange={() => toggleRow(composition.pk)}
                    />
                    <div className='state'>
                      <label />
                    </div>
                  </div>
                  <a href={'/compositions/' + composition.external_id + '/'} className="text-lg font-medium hover:text-blue-500 truncate">{composition._title}</a>
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {composition.external_id}
                  </p>
                </div>
              </div>
              <div className="mt-2 md:grid md:grid-cols-3">
                <div className="sm:flex">
                  <p className="flex items-left text-sm text-gray-500">
                    <div className='table-asset-codes'>
                      <span>Collectors: </span><br />
                      {composition.publishers.map((collector, idx) => {
                        return (
                          <a className='table-collector hover:text-blue-500' key={collector['pk']} href={`/${collector['set_type']}s/` + `${collector['external_id']}`}>{collector['name']}</a>
                        )
                      })}
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="mt-2 flex items-left text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <div className='table-asset-codes'>
                      <span>Owners: </span><br />
                      {composition.owners.map((owner, idx) => {
                        return (
                          <a className='table-writer hover:text-blue-500' key={owner['id']} href={`/${owner['set_type']}s/` + owner['external_id'] + '/'}>{owner['name']}</a>
                        )
                      })}
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="mt-2 flex items-left text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <div className='table-asset-codes'>
                      <span>Writers: </span><br />
                      {composition.writers.map((writer, idx) => {
                        return (
                          <a className='table-writer hover:text-blue-500' key={writer['id']} href={'/writers/' + writer['external_id'] + '/'}>{writer['name']}</a>
                        )
                      })}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </li>)
        })}
      </ul>
      <div className="">
        <Paginator
          getNext={_onNext}
          getPrev={_onPrevious}
          currPage={currPage}
          setCurrPage={setCurrPage}
          hasNext={hasNext}
          totalPages={pages}
        />
      </div>
    </div>
  )
}

export default ListTable
