import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'

import './components/Conflict.scss'
import InfoBar from 'components/InfoBar'
import ListTable from 'components/ListTable'
import Heading from './components/Heading'

import { fetchConflicts } from 'api/composition'

const ConflictView = () => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [filteredConflicts, setFilteredConflicts] = useState()
  const [totalPages, setTotalPages] = useState()
  const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [rownum, setRowNum] = useState(20)

  const hasNext = currPage < totalPages

  const getList = (page, rNum, filter) => {
    setSearchQ(filter)
    fetchConflicts(page, rNum, filter).then(res => {
      if (res.status === 200) {
        setFilteredConflicts(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch conflicts')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch conflicts')
    })
  }

  useEffect(() => {
    if (query.q) {
      setSearchQ(query.q)
      getList(query.page, rownum, query.q)
    } else {
      setCurrPage(1)
      getList(1, rownum, '')
    }

  }, [location.search])

  const _onNext = (page) => {
    getList(page + 1, rownum)
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      getList(page - 1, rownum)
      setCurrPage(page - 1)
    }
  }

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
    }

  const _doSearch = (searchValue) => {
    let query = {
      page: 1,
      q: searchValue
    }
  
    if (searchValue === undefined || searchValue === '') {
      query = { page: 1 }
    }
    history.push({
      pathname: `/conflicts`,
      search: `?${objectToQueryString(query)}`
    })
  }

  if (filteredConflicts === undefined || filteredConflicts === null) {
    return null
  }

  const conflictColumns = [{
    Header: 'Title',
    id: '_title',
    sortable: false,
    width: 350,
    Cell: ({ row }) => (
      <div>{row.original.ownership > 100 ? (
        <Link to={'/compositions/' + row.original.external_id + '/'} style={{ color: 'red' }}>
          <strong>{row.original._title}</strong>
        </Link>
        ) : (
          <Link to={'/compositions/' + row.original.external_id + '/'}><strong>{row.original._title}</strong></Link>
        )
      }</div>
    )
  }, {
    Header: 'Collector',
    id: 'publishers',
    sortable: false,
    width: 300,
    Cell: ({ row }) => (
      <div>{row.original.publishers.map((publisher, idx) => {
        return (
          <div key={'publisher-' + idx + publisher['external_id']} className='div-publisher'>
            <a key={publisher['external_id']} href={'/publishers/' + publisher['external_id'] + '/'}>{publisher['name']}</a>
            {row.original.publishers.length === (idx + 1) ? '' : <span key={'A' + publisher['external_id']}><br /></span>}
          </div>
        )
      })}</div>
    )
  }, {
    Header: 'Owners',
    id: 'owners',
    sortable: false,
    width: 300,
    Cell: ({ row }) => (
      <div>{row.original.owners.map((owner, idx) => {
        return (
          <div key={'owner-' + owner['external_id']} className='div-publisher'>
            <a key={owner['external_id']} href={'/publishers/' + owner['external_id'] + '/'}>{owner['name']}</a>
            {row.original.owners.length === (idx + 1) ? '' : <span key={'A' + owner['external_id']}><br /></span>}
          </div>
        )
      })}</div>
    )
  }, {
    Header: 'Totals',
    id: 'totals',
    sortable: false,
    Cell: ({ row }) => (
      <div className='table-asset-codes'>
        <span><small>Sync:&nbsp;&nbsp;</small>{row.original.sr_total ? row.original.sr_total : '---'}</span><br />
        <span><small>Mech:&nbsp;&nbsp;</small>{row.original.mr_total ? row.original.mr_total : '---'}</span><br />
        <span><small>Perf:&nbsp;&nbsp;</small>{row.original.pr_total ? row.original.pr_total : '---'}</span>
      </div>
    )
  }]

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {!searchFirst &&
          (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => history.push('/conflicts')}
            showClose
          />)
        }
        <Heading
          searchFirst={searchFirst}
          handleSearch={_doSearch}
        />
        <div className='py-4'>
          <ListTable
            columns={conflictColumns}
            data={filteredConflicts}
            getNext={_onNext}
            getPrev={_onPrevious}
            currPage={currPage}
            setCurrPage={setCurrPage}
            pages={totalPages}
            hasNext={hasNext}
            showPagination
          />
        </div>
      </div>
    </div>
  )
}

export default ConflictView
