import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { formatLabel } from 'views/Share/utils'
import PublisherFieldArray from './PublisherFieldArray'
import WriterFieldArray from './WriterFieldArray'

import '../styles/Ownership.scss'

let includeSelect = [
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' }
]

const Ownership = ({ userTerritoryPresets }) => {
  const { control, errors } = useFormContext()

  const {
    fields: publisherFields,
    append: publisherAppend,
    remove: publisherRemove
  } = useFieldArray({
    control,
    name: 'publishers',
    keyName: 'uuid'
  })

  const {
    fields: writerFields,
    append: writerAppend,
    remove: writerRemove
  } = useFieldArray({
    control,
    name: 'writers',
    keyName: 'uuid'
  })

  return (<>
    <div>
      <div className="pb-5 sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Ownership</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Ownership info on the Share. (* required)
          </p>
        </div>

        <div className="mt-3 sm:mt-0 sm:ml-4">
          <button
            type="button"
            onClick={() => publisherAppend({ owners: [] })}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Add Publisher
          </button>
          <button
            type="button"
            onClick={() => writerAppend({ owners: [] })}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Add Writer
          </button>
        </div>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        <PublisherFieldArray
          fields={publisherFields}
          remove={publisherRemove}
          includeSelect={includeSelect}
          formatLabel={formatLabel}
          userTerritoryPresets={userTerritoryPresets}
          errors={errors}
        />
        <WriterFieldArray
          fields={writerFields}
          remove={writerRemove}
          includeSelect={includeSelect}
          formatLabel={formatLabel}
          userTerritoryPresets={userTerritoryPresets}
          errors={errors}
        />
      </div>
    </div>
  </>)
}

export default Ownership
