import React from 'react'
import { useLocation } from 'react-router-dom'

import InfoBar from 'components/InfoBar'
import ErrorList from './components/ErrorList'
import ExportList from './components/ExportList'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ListView = () => {
  const query = useQuery()
  let queryDate = query.get('date')
  
  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          {queryDate && (
            <InfoBar
              text={'We are processing your export. You will get an email once the file is ready.'}
            />
          )}
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">My Downloads</h2>
              <p className='mt-1 text-sm text-gray-500'>All your export files and ingest error files.</p>
            </div>
          </div>
          <div className='mt-4 divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
            <ExportList />
            <ErrorList />
          </div>
        </div>
      </div>
    </>
  )
}

export default ListView