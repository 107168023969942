import { TrashIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import toast from 'react-hot-toast'

import { deleteClaimFeed as deleteClaimFeedApi } from 'api/claim'
import ClaimSheet from './ClaimSheet'

const ClaimDetail = ({ claimFile, getClaim }) => {
  const history = useHistory()

  let firstSheet = claimFile.related_sheets ? claimFile.related_sheets[0] : null
  let secondSheet = claimFile.related_sheets ? claimFile.related_sheets[1] : null
  const [currNav, setCurrNav] = useState(firstSheet?.sheet_name || '')

  let related_sheets = claimFile?.related_sheets
  const navigation = related_sheets?.map(d => d.sheet_name)

  function getSheet() {
    let sheetResult = related_sheets.filter(item => {
      return item.sheet_name === currNav
    })
    if (sheetResult) {
      return sheetResult[0]
    } else {
      return firstSheet
    }
  }

  const deleteClaimFeed = (id) => {
    deleteClaimFeedApi(id).then(res => {
      if (res.status === 204) {
        toast.success('Successfully deleted submission!')
        history.push('/claiming')
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to delete submission.')
    })
  }

  return (<>
    <div className='flex flex-wrap flex-1 min-h-full'>
      <div className="p-6 flex-1">
        <div className="mb-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div>
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3 truncate">{claimFile.filename}</h2>
                  </div>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0">
                  <button
                    type='button'
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this claiming submission?')) {
                        deleteClaimFeed(claimFile.pk)
                      }
                    }}
                    className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                    Delete
                    <TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <dl className="mt-5 mb-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div key='staff' className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">Claiming Staff</dt>
                  <dd className="mt-1 text-3xl tracking-tight font-semibold text-gray-900">{claimFile.claiming_staff}</dd>
                </div>
                <div key='date' className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">Submission Date</dt>
                  <dd className="mt-1 text-3xl tracking-tight font-semibold text-gray-900">{claimFile.submission_date}</dd>
                </div>
              </dl>
            </div>
            {firstSheet
              ? (<>
                <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
                  <div className="ml-3">
                    {navigation.map((item) => (
                      <button
                        type='button'
                        key={item}
                        className={item === currNav 
                          ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                          : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        }
                        onClick={() => setCurrNav(item)}
                        aria-current={item === currNav ? 'page' : undefined}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </nav>
                <hr />
                <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content px-4 py-2' id='nav-tabContent'>
                  <ClaimSheet
                    item={getSheet()}
                    getClaim={getClaim}
                    setCurrNav={setCurrNav}
                    firstSheet={firstSheet}
                    secondSheet={secondSheet}
                  />
                </div>
              </>)
              : (<p className="text-lg font-medium text-gray-900">No claims to display.</p>)
            }
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default ClaimDetail
