import React from 'react'
import { useFormContext } from 'react-hook-form'
import CodeCopy from 'components/CodeCopy'

const SingleValueField = ({ idx, label, codes, showForm }) => {
  const { register } = useFormContext()
  let name = label['key']

  return (<>
    <>
      {!showForm ? (<span key={idx}>
        <CodeCopy copyText={codes[label['key']]} />
      </span>) : <span className='mb-3'>
        <input
          className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
          type='text'
          {...register(`${name}`)}
        />
      </span>}
    </>
  </>)
}

export default SingleValueField