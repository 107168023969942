import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import ReactTooltip from 'react-tooltip'

import PublisherRoles from 'views/Share/components/PublisherRoles'
import Icon from 'layouts/components/Icon'
import PublisherSelectModal from '../PublisherSelect/PublisherSelect'
import { AssignToOwner } from './AssignToOwner'

const OwnerSelect = ({ ownership, index }) => {
  const { control, setValue } = useFormContext()

  const [selectedOwners, setSelectedOwners] = useState(ownership && ownership.owners ? ownership.owners : [])
  const [selectedOwnerField, setSelectedOwnerField] = useState(ownership ? ownership.owner_field : '')
  const [selectOwnerModal, setSelectOwnerModal] = useState(false)

  const toggleSelectOwnerModal = () => {
    setSelectOwnerModal(!selectOwnerModal)
  }

  const assignOwner = (pub) => {
    let owner = selectedOwners
    let ownerField = selectedOwnerField

    if (owner && owner.includes(pub)) {
      owner.splice(owner.indexOf(pub), 1)

      let ownerString = ', ' + pub.pub_name;

      if (ownerField && ownerField.includes(ownerString)) {
        ownerField = ownerField.replace(ownerString, '')
      } else {
        ownerField = ownerField.replace(pub.pub_name + ', ', '')
      }
    } else {
      if (pub) {
        owner.push(pub)
        if (ownerField && ownerField.length > 0) {
          ownerField = ownerField + ', ' + pub.pub_name
        } else {
          ownerField = pub.pub_name
        }
      }
    }

    setValue(`publishers[${index}].owner_field`, ownerField)
    setValue(`publishers[${index}].owners`, owner)

    setSelectedOwnerField(ownerField)
    setSelectedOwners(owner)
  }

  return (
    <>
      <PublisherSelectModal
        status={selectOwnerModal}
        toggleModal={toggleSelectOwnerModal}
        assignPublisher={assignOwner}
        selectedPublishers={selectedOwners}
        pubValue={ownership ? ownership.owners : []}
        pub={ownership}
        change={setValue}
        index={index}
      />
      <div className="bg-white rounded-md">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="collectors" className="block text-sm font-medium text-gray-700">
              Owner<sup className='required-icon'>*</sup>
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${index}].owner_field`}
                render={() => (
                  <AssignToOwner
                  className='form-control'
                    value={selectedOwnerField}
                    toggleSelectOwnerModal={toggleSelectOwnerModal}
                  />
                )}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="collector-role-code" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${index}].owner_role_code`}
                render={() => (
                  <PublisherRoles
                    name={`publishers[${index}].owner_role_code`}
                    defaultValue={ownership.owner_role_code}
                    control={control}
                    change={setValue}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OwnerSelect
