import React, { useState, useEffect, useCallback } from 'react'
import Moment from 'react-moment'
import toast from 'react-hot-toast'
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { DownloadIcon, PencilIcon } from '@heroicons/react/solid'

import ProfileForm from './components/ProfileForm'
import { userDetail, userDetailUpdate } from 'api/auth'
import { getApiKey, runScript as runScriptApi } from 'api/tools';
import DigitalBusinessCard from './components/DigitalBusinessCard'

const ProfileView = () => {
  const [record, setRecord] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [showEdit, setShowEdit] = useState(false)
  const [apiKey, setApiKey] = useState()
  const [scriptValue, setScriptValue] = useState('add_aliases')

  useEffect(() => {
    userDetail().then((res) => {
      if (res.status === 200) {
        let user = res.data.user
        setRecord(user)
        user.position = user?.profile?.position
        user.area_code = user?.profile?.area_code
        user.phone = user?.profile?.phone
        setInitialValues(user)
      } else {
        toast.error('Unable to fetch user')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch user')
    })
  }, [])

  const generateApiKey = () => {
    getApiKey().then((res) => {
      if (res.status === 200) {
        setApiKey(res.data.key)
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to generate API key')
    })
  }

  const runScript = () => {
    runScriptApi(scriptValue).then((res) => {
      if (res.status === 200) {
        toast.success('Script is now running')
      }
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to run script')
    })
  }

  const handleProfileSubmit = (values) => {
    userDetailUpdate(values, record.pk).then((res) => {
      if (res.status === 200) {
        toast.success('Successfully updated profile!')
        let user = res.data
        setRecord(user)
        user.position = user?.profile?.position
        user.area_code = user?.profile?.area_code
        user.phone = user?.profile?.phone
        setInitialValues(user)
        setShowEdit(false)
      } else {
        toast.error('Unable to update profile')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update profile')
    })
  }

  const downloadCard = useCallback(async () => {
    const canvas = await html2canvas(document.getElementById("business-card"));
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'digital-business-card.png', 'image/png');
  }, []);

  const onDownloadCard = () =>{
    toast('Preparing to generate business card...')
    toast.promise(
      downloadCard(),
       {
         loading: 'Generating business card...',
         success: <b>Successfully generated business card!</b>,
         error: <b>Could not generate business card at this time. Please try again later.</b>,
       }
     );
  }

  if (!record) {
    return null
  }

  return (
    <>
      <div className="flex flex-wrap flex-1 min-h-full">
        <div className="p-6 flex-1">
          {/* Page header */}
          <div className="px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="https://picsum.photos/150/?blur"
                    alt="Avatar"
                  />
                  <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {record.first_name || record.last_name
                    ? <React.Fragment>
                        <span>{record.first_name + ' ' + record.last_name}</span>
                      </React.Fragment>
                    : <span>{record.email}</span>
                  }
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Last login:{' '}
                  <span className='text-gray-900'>
                    {record.last_login
                    ? <Moment date={record.last_login} />
                    : 'N/A'}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                onClick={() => setShowEdit(true)}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Edit <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={onDownloadCard}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
              >
                Download Business Card <DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              {showEdit
                ? (<ProfileForm initialValues={initialValues} setShowEdit={setShowEdit} handleProfileSubmit={handleProfileSubmit} />)
              : (<div className='min-w-full'>
                <div className="px-4 py-5 sm:px-6 bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">This information will be used for the Digital Business Card.</p>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Role</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{record.profile?.position}</dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{record.email}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{record.profile?.area_code} {record.profile?.phone}</dd>
                      </div>
                      {record.profile?.scope.includes('exp-admin') && (<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">API Key</dt>
                          {apiKey
                            ? (<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{apiKey}</dd>)
                            : (<button className='px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none' type='button' onClick={() => generateApiKey()}>Generate Key</button>)
                          }
                        </div>)
                      }
                      {record.email === 'tiny.ramirez@exploration.io' && (<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            <input
                              type="text"
                              name="script"
                              id="script"
                              value={scriptValue}
                              className="block w-full rounded-md border-gray-300 pl-16 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                              onChange={(e) => setScriptValue(e.target.value)}
                            />
                          </dt>
                          <button className='px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none' type='button' onClick={() => runScript()}>Run Script</button>
                        </div>)
                      }
                    </dl>
                  </div>
                </div>
              </div>)
              }
            </section>
          </div>

          <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
            <DigitalBusinessCard id='business-card' record={record} />
          </section>
        </div>
      </div>
    </>
  )
}

export default ProfileView
