import React from 'react'
import { PencilIcon } from '@heroicons/react/outline'
import CodeCopy from 'components/CodeCopy'

const TableCodes = ({ record, _toggleEdit }) => {
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mx-2 my-2">
        <div className="px-4 py-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Recording Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Click on the code to copy to clipboard.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ISRC</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <CodeCopy copyText={record.isrcs} />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">YouTube SR ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.yt_sr_id_list && record.yt_sr_id_list.map((ytSrId, idx) => {
                  return (
                    <span className={idx > 0 && 'tab-lt-1'}><CodeCopy copyText={ytSrId} /></span>
                  )
                })}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">YouTube SR Custom ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <CodeCopy copyText={record.yt_sr_custom_id} />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">YouTube Art Track Custom ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <CodeCopy copyText={record.yt_art_track_custom_id} />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Genre</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <CodeCopy copyText={record.genre} />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Client Code</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <CodeCopy copyText={record.royalty_code} />
              </dd>
            </div>
          </dl>
          <div className="px-4 py-5">
            <button className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'
              onClick={() => { _toggleEdit('recording') }}>
              Edit <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>     
    </>
  )
}

export default TableCodes
