import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import queryString from 'query-string'

import TitleBox from './components/TitleBox'
import GeneralInfo from './components/GeneralInfo'
import TitleForm from './components/TitleForm'
import RelatedAssets from './components/RelatedAssets'
import BulkOptions from 'components/BulkOptions'
import ExplorationDetails from 'components/ExplorationDetails'

import { fetch, fetchRecordings, del } from 'api/recordLabel'
import { recordLabelExportRecording as exportRecordings, getSelectedRecordingsFile as exportSelectedRecordings } from 'api/download'
import { AuthContext } from 'stores/auth'
import EditLabelForm from './components/EditLabelForm'

const DetailView = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const authStore = useContext(AuthContext)

  const [record, setRecord] = useState()
  const [currNav, setCurrNav] = useState(query.qf || query.page ? 'Related Assets' : 'Details')
  const [toggleExport, setToggleExport] = useState(false)
  const [showTable, setShowTable] = useState(false)

  const [showEditForm, setShowEditForm] = useState(false)
  const [showTitleEditForm, setShowTitleEditForm] = useState(false)
  const [selectAll, setSelectAll] = useState(0)
  const [selected, setSelected] = useState({})

  useEffect(() => {
    if (record === undefined || record.pk !== id) {
      fetch(id).then(res => {
        setRecord(res.data)
      })
    }
  }, [])

  let initialValues = {}
  if (record) {
    initialValues = Object.assign({}, record)
  }

  const _toggleTitleEdit = (form) => {
    if (form === 'recordLabelTitle') {
      setShowTitleEditForm(!showTitleEditForm)
    }
  }

  const getExport = (typer) => {
    var data = { pk: id, typer: typer }
    exportRecordings(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date + '/')
      }
    })
  }

  const deleteRecordLabel = () => {
    del(id).then(res => {
      if (res.status === 204) {
        toast.success('Record label deleted successfully!')
        history.push('/dashboard/')
      } else {
        toast.error('Unable to delete record label')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete record label')
    })
  }

  const setExport = () => {
    if (!toggleExport) {
      setToggleExport(true)
    } else {
      setToggleExport(false)
    }
  }

  if (record === undefined) {
    return null
  }

  let email = authStore.user.email
  let scope = authStore.scope

  const bulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: getExport,
      params: 'exprecordings'
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: getExport,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: getExport,
          params: 'scddex'
        }
      ]
    }
  ]

  const navigation = [
    { name: 'Details', href: '#nav-details'},
    { name: 'Related Assets', href: '#nav-recordings' }
  ]

  return (
    <>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          {showTitleEditForm
            ? <TitleForm
              initialValues={initialValues}
              id={id}
              showTitleEditForm={showTitleEditForm}
              setShowTitleEditForm={setShowTitleEditForm}
              setRecord={setRecord}
            />
          : <TitleBox
              _toggleTitleEdit={_toggleTitleEdit}
              record={record}
              onDelete={deleteRecordLabel}
              email={email}
              scope={scope}
            />
          }

          {!showTitleEditForm &&
            <ExplorationDetails
              record={record}
              showTitleEditForm={showTitleEditForm}
              controlled={record.controlled}
            />
          }

          <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
            <div className="ml-3">
              {navigation.map((item) => (
                <button
                  type='button'
                  key={item.name}
                  className={item.name === currNav 
                    ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                    : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  }
                  onClick={() => setCurrNav(item.name)}
                  aria-current={item.name === currNav ? 'page' : undefined}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </nav>
          <hr />
          <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content' id='nav-tabContent'>
            {currNav === 'Details' &&
              (<div className='tab-pane tab-pad fade'
                id='nav-details' role='tabpanel' aria-labelledby='nav-details-tab'>
                  {showEditForm
                    ? (<EditLabelForm
                        id={id}
                        initialValues={initialValues}
                        record={record}
                        setRecord={setRecord}
                        showEditForm={showEditForm}
                        setShowEditForm={setShowEditForm}
                      />)
                    : (<GeneralInfo
                      record={record}
                      showEditForm={showEditForm}
                      setShowEditForm={setShowEditForm}
                    />)
                  }
              </div>)       
            }
            {currNav === 'Related Assets' &&
              (<div className='tab-pane tab-pad fade' id='nav-assets' role='tabpanel' aria-labelledby='nav-assets-tab'>
                <RelatedAssets
                  id={id}
                  songCount={record.related_recordings}
                  setExport={setExport}
                  showTable={showTable}
                  setShowTable={setShowTable}
                  selected={selected}
                  setSelected={setSelected}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                />
              </div>)
            }
          </div>
        </div>
        { toggleExport &&
          (<div className="flex-grow-0 flex-shrink-0">
            <BulkOptions items={bulkItems} onClose={setToggleExport} />
          </div>)
        }
      </div>
    </>
  )
}

export default DetailView
