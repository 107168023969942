import React from 'react'
import { Controller } from 'react-hook-form'

import SelectField from 'layouts/components/Select'

const NewPublishing = ({ collectorValue, register, control, change, saveNewPub, toggleNewPub, pubType }) => {
  const proOptions = [
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <>
      <div className="p-6">
        {pubType === 'publisher'
          ? (<div>
            <div className='box box-white'>
              <div className='box-form-header'>New Publisher</div>
              <div className="py-2">
                <div className="col" xs={12} md={12}>
                  <div controlId='newPubName'>
                    <label className="block text-sm font-medium text-gray-700">Name<sup className='required-icon'>*</sup></label>
                    <input
                      name='collectors.publisher.name'
                      type='text'
                      ref={register({ required: true })} />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="col" xs={12} md={6}>
                  <div controlId='newPubIpi'>
                    <label className="block text-sm font-medium text-gray-700">IPI Number</label>
                    <input
                      name='collectors.publisher.ipi_number'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={6}>
                  <div controlId='newPubPro'>
                    <label className="block text-sm font-medium text-gray-700">Pro Affiliation</label>
                    <Controller
                      control={control}
                      name='collectors.publisher.pro_affliation'
                      render={() => (
                        <SelectField
                          options={proOptions}
                          onChange={(e) => {
                            change('collectors.publisher.pro_affliation', e)
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='box'>
              <div className='row'>
                <div className='col form-actions'>
                  <button
                    className='btn btn-primary btn-wide btn-submit btn-flat'
                    type='submit'
                    onClick={() => saveNewPub(collectorValue.publisher.name)}>Save</button>
                  <button
                    className='btn btn-blue-gray btn-wide btn-submit btn-flat'
                    type='button'
                    onClick={() => toggleNewPub('publisher')}>Cancel</button>
                </div>
              </div>
            </div>
          </div>)
          : (<div>
            <div className='box box-white'>
              <div className='box-form-header'>New Writer</div>
              <div className="py-2">
                <div className="col" xs={12} md={2}>
                  <div controlId='newWriterTitle'>
                    <label className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      name='collectors.writer.title'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={3}>
                  <div controlId='newWriterFn'>
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                      name='collectors.writer.first_name'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={2}>
                  <div controlId='newWriterMiddle'>
                    <label className="block text-sm font-medium text-gray-700">Middle</label>
                    <input
                      name='collectors.writer.middle_name'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={3}>
                  <div controlId='newWriterLn'>
                    <label className="block text-sm font-medium text-gray-700">Last Name<sup className='required-icon'>*</sup></label>
                    <input
                      name='collectors.writer.last_name'
                      type='text'
                      ref={register({ required: true })} />
                  </div>
                </div>
                <div className="col" xs={12} md={2}>
                  <div controlId='newWriterSuffix'>
                    <label className="block text-sm font-medium text-gray-700">Suffix</label>
                    <input
                      name='collectors.writer.suffix'
                      type='text'
                      ref={register} />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="col" xs={12} md={4}>
                  <div controlId='newWriterIpi'>
                    <label className="block text-sm font-medium text-gray-700">IPI Number</label>
                    <input
                      name='collectors.writer.ipi_number'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={4}>
                  <div controlId='newWriterIsni'>
                    <label className="block text-sm font-medium text-gray-700">ISNI</label>
                    <input
                      name='collectors.writer.isni'
                      type='text'
                      ref={register} />
                  </div>
                </div>
                <div className="col" xs={12} md={4}>
                  <div controlId='newWriterPro'>
                    <label className="block text-sm font-medium text-gray-700">Pro Affiliation</label>
                    <Controller
                      control={control}
                      name='collectors.writers.pro_affliation'
                      render={() => (
                        <SelectField
                          options={proOptions}
                          onChange={(e) => {
                            change('collectors.writer.pro_affliation', e)
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='box'>
              <div className='row'>
                <div className='col form-actions'>
                  <button
                    className='btn btn-primary btn-wide btn-submit btn-flat'
                    type='submit'
                    onClick={() => saveNewPub(collectorValue.writer.first_name + ' ' + collectorValue.writer.last_name)}>Save</button>
                  <button
                    className='btn btn-blue-gray btn-wide btn-submit btn-flat'
                    type='button'
                    onClick={() => toggleNewPub('publisher')}>Cancel</button>
                </div>
              </div>
            </div>
          </div>)
        }
      </div>
    </>
  )
}

export default NewPublishing
