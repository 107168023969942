import React, { useState } from 'react'
import { CloudUploadIcon, ViewListIcon, ChartBarIcon } from '@heroicons/react/outline'

import ImportedFiles from './components/ImportedFiles'
import './components/Form.scss'
import UploadForm from './components/UploadForm'
import FeedStats from './components/FeedStats'

const FormView = () => {
  const [datafeed, setDatafeed] = useState(null)
  const [showStats, setShowStats] = useState(false)
  const [viewImportedFiles, setViewImportedFiles] =  useState(false)

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Import Data</h2>
              <p className='mt-1 text-sm text-gray-500'>For delivery templates and instructions, please visit&nbsp;
                <a className="hover:text-blue-500" href='https://docs.google.com/spreadsheets/d/1IxijV9B_NccoBV_8OdhoZQM9V6M1IudGzSqAbjBEGlI'
                  target='_blank'>
                  this link
                </a>.
                <br />
                For the complete list of territory codes, you may visit the&nbsp;<a className="hover:text-blue-500" href='/references/'>references</a>&nbsp;page.
              </p>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <button
                className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                onClick={() => {
                  setViewImportedFiles(!viewImportedFiles)
                }}
              >{viewImportedFiles
                ? (<>Show Upload Form<CloudUploadIcon className="ml-2 h-4 w-4" aria-hidden="true" /></>)
                : (<>View Imported Files<ViewListIcon className="ml-2 h-4 w-4" aria-hidden="true" /></>)
              }</button>
              <button
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                onClick={() => {
                  setShowStats(!showStats)
                }}>{showStats
                  ? (<>Hide stats<ChartBarIcon className="ml-2 h-4 w-4" aria-hidden="true" /></>)
                  : (<>Show stats<ChartBarIcon className="ml-2 h-4 w-4" aria-hidden="true" /></>)
              }</button>
            </div>
          </div>
          <div className='mb-5'>
            <FeedStats showStats={showStats} />
          </div>
          <div>
            {!viewImportedFiles && <UploadForm
              showStats={showStats}
              datafeed={datafeed} />
            }
            {viewImportedFiles && <ImportedFiles setDatafeed={setDatafeed} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default FormView
