import React, { useState, useEffect } from 'react'

export const AssignToArtist = (field) => {
  const { toggleSelectArtistModal, ...custom } = field
  let { className, disabled } = custom
  let klassName = [className]

  if (disabled) {
    klassName.push('is-disabled')
  }

  const [value, setValue] = useState()

  useEffect(() => {
    if (custom.value) {
      setValue(custom.value)
    } else {
      setValue('')
    }
  }, [custom.value])

  return (
    <div className="w-full flex rounded-md shadow-sm">
      <button onClick={() => toggleSelectArtistModal()}
        type="button"
        className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
      >
        Select Artist
      </button>
      <input
        value={value}
        onChange={value => setValue(value)}
        style={{ backgroundColor: 'white' }}
        type='text'
        placeholder=''
        data-lpignore='true'
        readOnly
        className="flex-1 block w-full focus:ring-red-500 focus:border-red-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
      />
    </div>
  )
}

export default AssignToArtist
