import React, { useEffect, useState } from 'react'
import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid'
import Paginator from 'components/Paginator';

const ListTable = ({ currentPage, searchQ, refreshTable, sort, propSelected, propSelectAll, toggleRow,
  toggleSelectAll, writerList, pages, getList }) => {

  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (searchQ) {
      getList(currPage, searchQ)
    }
  }, [refreshTable])

  const _onNext = (page) => {
    setLoading(true)
    getList(parseInt(page) + 1, searchQ)
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(parseInt(page) - 1, searchQ)
      setCurrPage(parseInt(page) - 1)
    }
  }

  if (writerList === undefined || writerList === null) {
    return null
  }

  const hasNext = currPage < pages

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {writerList && writerList.map(writer => (
          <li key={writer.external_id}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-left justify-between">
                <div>
                  <a className="text-lg font-medium hover:text-blue-500 truncate" href={'/writers/' + writer.external_id + '/'}>
                    {(writer.title || '') + ' ' + (writer.first_name || '') + ' ' + (writer.middle_name || '') +
                    ' ' + (writer.last_name || '') + ' ' + (writer.suffix || '')}
                  </a>
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {writer.external_id}
                  </p>
                </div>
              </div>
              <div className="mt-2 md:grid md:grid-cols-3">
                <div className="sm:flex">
                  <p className="flex items-left text-sm text-gray-500">
                    <div className='table-asset-codes'>
                      <span>Publisher Affiliation: </span><br />
                      <div>{writer.pub_affiliations && writer.pub_affiliations.map((pub, idx) => {
                        return (
                          <div key={'pub-' + pub.pk + '-' + idx}>
                            <a className="hover:text-blue-500" key={pub['pk']} href={'/publishers/' + pub['external_id']}>
                              {pub.name}
                            </a>
                            {writer.pub_affiliations.length === (idx + 1) ? '' : <span key={'A' + pub['pk']}>&nbsp;|&nbsp;</span>}
                          </div>
                        )
                      })}</div>
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="flex items-left text-sm text-gray-500">
                    <div className='table-asset-codes'>
                      <span>Pro Affiliation: </span><br />
                      {writer.pro_affiliation}
                    </div>
                  </p>
                </div>
                <div className="sm:flex">
                  <p className="mt-2 flex items-left text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <div className='table-asset-codes'>
                      <span>IPI Number: </span><br />
                      {writer.ipi_number}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="">
        <Paginator
          getNext={_onNext}
          getPrev={_onPrevious}
          currPage={currPage}
          setCurrPage={setCurrPage}
          hasNext={hasNext}
          totalPages={pages}
        />
      </div>
    </div>
  )
}

export default ListTable
