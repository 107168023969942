import PrivateAPI from './private'
import { push } from 'connected-react-router'

export const getList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.rownum !== undefined) {
        params['rownum'] = payload.rownum
    }

    push({ pathname: '/compositions', query: payload })

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    if (payload.f !== undefined) {
        params['f'] = payload.f
    }

    return PrivateAPI.get('api/compositions/exp/', params)
}

export const getPageList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    push({ pathname: '/compositions', query: payload })

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    return PrivateAPI.get('api/compositions/', params)
}

export const getCompositionSelectList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    var url = 'api/compositions/exp'
    return PrivateAPI.get(url, params)
}

export const getCompositionSelectPageList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    return PrivateAPI.get('api/compositions/', params)
}

export const fetch = (pk) => {
    return PrivateAPI.get('api/compositions/' + pk + '/')
}

export const del = (pk) => {
    return PrivateAPI.delete('api/compositions/' + pk + '/')
}

export const bulkDelete = (data) => {
    return PrivateAPI.delete('api/compositions/', data)
}

export const sendNote = (payload) => {
    return PrivateAPI.post('api/compositions/notes/', payload)
}

export const editNote = (pk, payload) => {
    return PrivateAPI.put('api/compositions/notes/' + pk + '/', payload)
}

export const deleteNote = (pk) => {
    return PrivateAPI.delete('api/compositions/notes/' + pk + '/')
}

export const fetchNotes = (model, pk, noteType, showAll) => {
    var params = {}
    if (noteType) {
        params['note_type'] = noteType
    }
    if (showAll) {
        params['show_all'] = showAll
    }
    return PrivateAPI.get('api/compositions/notes/' + model + '/' + pk + '/', params)
}

export const getActions = (model, pk) => {
    return PrivateAPI.get('actions/' + model + '/' + pk)
}

export const getNotes = (model, pk) => {
    return PrivateAPI.get('api/compositions/notes/e/' + model + '/' + pk + '/')
}

export const editComposition = (payload, pk) => {
    return PrivateAPI.put('api/compositions/' + (pk) + '/', payload)
}

export const fetchConflicts = (page, rownum, filter) => {
    var params = {}
    if (filter !== undefined && filter !== '') {
      params['_filter'] = filter
    }
    return PrivateAPI.get('api/compositions/conflicts/' + page + '/' + rownum + '/', params)
}

export const fetchDuplicates = (page, rownum, filter) => {
    var params = {}
    if (filter !== undefined && filter !== '') {
      params['_filter'] = filter
    }
    return PrivateAPI.get('api/compositions/duplicates/' + page + '/' + rownum + '/', params)
}

export const fetchDuplicate = (pk) => {
    return PrivateAPI.get('api/compositions/duplicates/' + pk + '/')
}
  
export const resolveDuplicate = (payload, pk) => {
    return PrivateAPI.put('api/compositions/duplicates/' + (pk) + '/', payload)
}