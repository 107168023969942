import PrivateAPI from './private'
// import { AuthContext } from './auth'

// const auth = useContext(AuthContext)
const auth = {}

export const getList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.rownum !== undefined) {
    params['rownum'] = payload.rownum
  }

  if (payload.q !== undefined && payload.q !== '') {
    params['q'] = payload.q
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  if (payload.f !== undefined) {
    params['f'] = payload.f
  }

  return PrivateAPI.get('api/publishing/publishers/exp/', params)
}

export const getPublisherSelectList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/publishing/publishers/options/', params)
}

export const getPublisherSelectPageList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined) {
    if (payload.q !== '') {
      params['q'] = payload.q
    }
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  let url = 'api/publishing/publishers/options/'

  return PrivateAPI.get(url, params)
}

export const fetch = (pk) => {
  let url = 'api/publishing/publishers/' + pk + '/'
  return PrivateAPI.get(url)
}

export const fetchE = (pk) => {
  let url = 'api/publishing/publishers/e/' + pk + '/'
  return PrivateAPI.get(url)
}

export const fetchCompositions = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }

  let url = 'api/publishing/publishers/' + pk + '/compositions/' + page + '/' + rownum + '/'

  return PrivateAPI.get(url, params)
}

export const fetchShares = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }

  let url = 'api/publishing/publishers/' + pk + '/coll-shares/' + page + '/' + rownum + '/'

  return PrivateAPI.get(url, params)
}

export const fetchAdminShares = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }

  let url = 'api/publishing/publishers/' + pk + '/admin-shares/' + page + '/' + rownum + '/'
  return PrivateAPI.get(url, params)
}

export const fetchOwnershipShares = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }

  let url = 'api/publishing/publishers/' + pk + '/ownerships/' + page + '/' + rownum + '/'
  return PrivateAPI.get(url, params)
}

export const fetchWriters = (pk, page, rownum, filter) => {
  let params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }

  let url = 'api/publishing/publishers/' + pk + '/writers/' + page + '/' + rownum + '/'
  return PrivateAPI.get(url, params)
}

export const create = (payload) => {
  let url = 'api/publishing/publishers/'
  return PrivateAPI.post(url, payload)
}

export const edit = (payload, pk) => {
  let url = 'api/publishing/publishers/' + (pk) + '/'
  return PrivateAPI.put(url, payload)
}

export const del = (publisherId) => {
  let url = 'api/publishing/publishers/' + publisherId + '/'
  return PrivateAPI.delete(url)
}

export const createPublisher = (payload) => {
  let url = 'api/publishing/publishers/'
  return PrivateAPI.post(url, payload)
}

export const relinquishShares = (pk) => {
  return PrivateAPI.get('api/publishing/publishers/' + (pk) + '/relinquish/')
}

export const undoRelinquishShares = (pk) => {
  return PrivateAPI.get('api/publishing/publishers/' + (pk) + '/undo-relinquish/')
}
