import React from 'react'
import { Route, Switch } from 'react-router'
import List from './routes/List/index'
import Detail from './routes/Detail/index'
import Form from './routes/Form/index'

export default function WriterViews () {
  return (
    <Switch>
      <Route exact path='/writers' component={List} />
      <Route path='/writers/add' component={Form} />
      <Route path='/writers/:id' component={Detail} />
    </Switch>
  )
}
