import PrivateAPI from './private'


export const getList = (page) => {
  return PrivateAPI.get('api/users/')
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/users/' + pk + '/')
}

const _create = (payload) => {
  return PrivateAPI.post('api/users/list/', payload)
}

const _edit = (payload, pk) => {
  return PrivateAPI.put('api/users/' + (pk) + '/', payload)
}

export const del = (userId) => {
  return PrivateAPI.del('api/users/detail/' + userId + '/')
}

export const permissionSubmit = (payload, pk) => {
  return PrivateAPI.put('api/users/' + pk + '/scopes/', payload)
}

export const getPublishers = (page) => {
  return PrivateAPI.get('api/publishers/form/')
}

export const assignPublisher = (payload) => {
  return PrivateAPI.post('api/users/' + payload.user + '/assign-publisher/', payload)
}

export const assignUserType = (payload) => {
  return PrivateAPI.post('api/users/' + payload.user + '/change-user-type/', payload)
}
