import PrivateAPI from "./private"

export const getPresignedUrl = (payload) => {
  return PrivateAPI.post("api/ddex/presigned-url/", payload)
}

export const notifyUploadComplete = (payload) => {
  return PrivateAPI.post("api/ddex/upload-complete/", payload)
}

export const getDDEXFeedList = (page, rownum) => {
  var params = {}

  params["page"] = page
  params["rownum"] = rownum

  return PrivateAPI.get("api/ddex/", params)
}

export const downloadDDEX = (id) => {
  return PrivateAPI.get(`api/ddex/download/${id}/`)
}
