import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

const RenderAlternateTitles = () => {
  const { register, control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "alternate_titles",
    keyName: "uuid"
  })

  return (<>
    {fields.map((alternateTitle, index) => {
      return (<>
        <div className="bg-white rounded-md" key={alternateTitle}>
          <div className="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="yt-sr-id" className="block text-sm font-medium text-gray-700">
                Alternate Title {index + 1}<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register(`alternate_titles[${index}]`)}
                />
              </div>
            </div>
          </div>
          <button
            className='btn btn-link btn-remove'
            type='button'
            onClick={() => remove(index)}>
            Remove
          </button>
        </div>
       </>)
      }
      )}
      <div className="pt-3">
        <div className="flex">
          <button
            type="button"
            onClick={() => append()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Add Alternate Title
          </button>
        </div>
      </div>
  </>)
}

export default RenderAlternateTitles
