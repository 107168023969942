import React, { useState, useEffect } from 'react'
import InfoBar from 'components/InfoBar'
import ListTable from 'components/ListTable'
import toast from 'react-hot-toast'
import ClientSearch from './ClientSearch'

import { getStats } from 'api/claim'

const DisputedClaimTable = ({ id, month, year }) => {
  const [currPage, setCurrPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [searchQ, setSearchQ] = useState(null)

  const [list, setList] = useState([])
  const [totalPages, setTotalPages] = useState()

  useEffect(() => {
    setLoading(true)
    getStats(id, month, year, 'disputed', currPage, 10, searchQ).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
      setLoading(false)
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to fetch stats')
    })
  }, [month, year, searchQ])

  const _onNext = (page) => {
    setLoading(true)
    getStats(id, month, year, 'disputed', page + 1, 10).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
      setLoading(false)
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to fetch stats')
    })
    setCurrPage(page + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getStats(id, month, year, 'disputed', page - 1, 10).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        setLoading(false)
      }).catch(err => {
        console.log(err.response)
        toast.error('Unable to fetch stats')
      })
      setCurrPage(page - 1)
    }
  }

  const hasNext = currPage < totalPages

  const clientColumns = [
    {
      Header: 'CMS',
      id: 'cms',
      sortable: true,
      Cell: ({ row }) => (
        <div>{row.original.cms}</div>
      )
    }, {
      Header: 'Reinstated',
      id: 'reinstate',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.reinstate}</div>
      )
    }, {
      Header: 'Released',
      id: 'release',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.release}</div>
      )
    }, {
      Header: 'Skipped & Removed',
      id: 'skip_remove',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.skip_remove}</div>
      )
    }, {
      Header: 'Total',
      id: 'claims',
      sortable: false,
      Cell: ({ row }) => (
        <div>{row.original.claims}</div>
      )
    }
  ]

  if (loading) {
    return null
  }

  return (
    <div className='list-table'>
      {searchQ
        ? (<InfoBar
          text={"Showing search result for '" + searchQ + "'"}
          onClose={() => setSearchQ(null)}
          showClose
        />)
        : (<ClientSearch
          searchQ={searchQ}
          setSearchQ={setSearchQ}
        />)
      }
      <ListTable
        columns={clientColumns}
        data={list}
        getNext={_onNext}
        getPrev={_onPrevious}
        currPage={currPage}
        setCurrPage={setCurrPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default DisputedClaimTable
