import React from 'react'
import { Link } from 'react-router-dom'
import { XIcon } from '@heroicons/react/outline'

const BulkOptions = ({ items, onClose }) => {
  return (<aside className="w-96 bg-white border-l border-gray-200 overflow-y-auto h-full">
    <div className="overflow-hidden divide-y divide-gray-200">
      <div className="p-6">
        <div className="relative">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Bulk Options</h3>
              </p>
            </div>
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <button
                type="button"
                onClick={() => onClose()}
                className="flex p-2 rounded-md hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className='py-2'>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
            {items.map((item, idx) => (
              <li key={idx} className="col-span-1 bg-white rounded-lg shadow divide-y divide-white">
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-gray-900 text-sm font-medium truncate">
                        {item.name}
                      </h3>
                    </div>
                  </div>
                </div>
                {item.submenu
                  ? (<div>
                      <div className="-mt-px flex divide-x divide-white">
                        {item.submenu.map((submenu) => {
                          return (
                            <div className="w-0 flex-1 flex bg-red-600 hover:bg-red-700 rounded">
                              <a
                                href="#"
                                onClick={() => submenu.action(submenu.params)}
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-white font-medium border border-transparent rounded-bl-lg"
                              >
                                {submenu.name}
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    </div>)
                  : (<div>
                      <div className="-mt-px flex divide-x divide-white">
                        <div className="w-0 flex-1 flex bg-red-600 hover:bg-red-700 rounded">
                          {item.linkTo
                            ? (<Link to={item.linkTo}
                              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-white font-medium border border-transparent rounded-bl-lg">
                              {item.label}
                            </Link>)
                            : (<a
                              href="#"
                              onClick={() => item.action(item.params)}
                              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-white font-medium border border-transparent rounded-bl-lg"
                            >
                              {item.label}
                            </a>)
                          }
                        </div>
                      </div>
                    </div>)
                }
              </li>
            ))}
          </ul>
        </div>

      </div>
    </div>
  </aside>)

}

export default BulkOptions
