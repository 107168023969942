import React, { useState } from 'react'
import Icon from 'layouts/components/Icon'

import Highlight from 'react-highlight'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const DdexTab = ({ record }) => {
  const [copied, setCopied] = useState(false)

  return (
    <>
      <div className='text-sm px-3 py-3'>
        <Highlight className='xml'>
          {record.ddex_string}
        </Highlight>
      </div>
      <div className='tab-actions'>
        <CopyToClipboard
          text={record.ddex_string}
          onCopy={() => setCopied(true)}
        >
          {copied
          ? (<div className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none disabled'>
            <Icon name='copy' spaceAfter />Copied
          </div>)
          : (<div className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
            <Icon name='copy' spaceAfter />Copy
          </div>)
        }
        </CopyToClipboard>
      </div>
    </>
  )
}

export default DdexTab
