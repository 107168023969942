/* This example requires Tailwind CSS v2.0+ */
import React from 'react'

import ClaimCard from './ClaimCard'

export default function ManualClaims ({ item, getClaim }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const platforms = [
        { name: 'YouTube', bgColor: 'bg-red-600', total: item?.overall_totals?.youtube },
        { name: 'SoundExchange', bgColor: 'bg-blue-600', total: item?.overall_totals?.soundexchange },
        { name: 'MRI', bgColor: 'bg-green-600', total: item?.overall_totals?.mri },
        { name: 'MLC', bgColor: 'bg-indigo-600', total: item?.overall_totals?.mlc },
        { name: 'CMRRA', bgColor: 'bg-yellow-600', total: item?.overall_totals?.cmrra }
    ]
    return (
        <>
            <ul role="list" className="mt-3 mb-10 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
            {platforms.map((platform) => (
                <li key={platform.name} className="col-span-1 flex shadow-sm rounded-md">
                <div
                    className={classNames(
                    platform.bgColor,
                    'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                    )}
                >
                    {platform.total}
                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm">
                    {platform.name}
                    </div>
                </div>
                </li>
            ))}
            </ul>
            {item.related_claims && item.related_claims.map((claim, index) => (
                <div className='py-5'>
                    <ClaimCard key={claim.pk} item={claim} getClaim={getClaim} />
                </div>))
            }
        </>
            
    )
}
