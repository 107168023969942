import React, { useState } from "react"

function ClientSearch ({ searchQ, setSearchQ, placeholder }) {    
    const [filter, setFilter] = useState(searchQ)

    const _keyUp = (event) => {
        if (event.keyCode === 13) {
          setSearchQ(filter)
        }
    }

    let searchPlaceholder = placeholder ? placeholder : 'Search for a client'

    return (<>
    {/* Welcome panel */}
    <section aria-labelledby="profile-overview-title">
        <div className="mt-3 rounded-lg bg-white overflow-hidden">
            <div className="bg-white w-full relative flex items-center justify-between px-4 py-6">
                <div className="flex-1">
                    <div>
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>   
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={searchQ}
                            onChange={(e) => setFilter(e.target.value)}
                            onKeyUp={(event) => _keyUp(event)}
                            className="focus:ring-red-500 focus:border-red-500 disabled:bg-gray-200 block w-full sm:text-sm border-gray-200 rounded-md"
                            placeholder={searchPlaceholder}
                        />
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => setSearchQ(filter)}
                    className="inline-flex ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                >
                    Search
                </button>
            </div>
        </div>
    </section>
  </>)
}

export default ClientSearch