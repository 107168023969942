import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'
import ListTable from 'components/ListTable'

import { getPublisherSelectList } from 'api/publishers'

const PublisherListTable = ({ refreshTable, searchQ, pubValue, assignPublisher }) => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [currPage, setCurrPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  const getList = (payload) => {
    getPublisherSelectList(payload).then((res) => {
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      } else {
        toast.error('Unable to fetch publishers')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch publishers')
    })
  }

  useEffect(() => {
    setLoading(true)
    getList(_listParams(currPage, searchQ))
    setLoading(false)
  }, [refreshTable])

  const fetchData = () => {
    if (!loading && list === undefined) {
      setLoading(true)
      getList(_listParams(currPage, searchQ))
      setLoading(false)
    }

    if (list !== undefined) {
      setLoading(false)
    }
  }

  const onPageChange = (evt) => {
    setCurrPage(evt.target.value)

    if (evt.target.value !== '' && parseInt(evt.target.value) !== currPage) {
      getList(_listParams(evt.target.value, searchQ))
    }
  }

  const onSortedChange = (sort) => {
    setLoading(true)
    let newSort = sort[0]['id']
    getList(_listParams(currPage, searchQ, newSort))
    setLoading(false)
  }

  const _onNext = (page) => {
    setLoading(true)
    getList(_listParams(page + 1, searchQ))
    setCurrPage(page + 1)
    setLoading(false)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(_listParams(page - 1, searchQ))
      setCurrPage(page - 1)
      setLoading(false)
    }
  }

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const _listParams = (page, searchQ, sort) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (searchQ !== undefined && searchQ !== '') {
      queryString['q'] = searchQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }
    return queryString
  }

  const hasNext = currPage < totalPages

  const publisherColumns = [
    {
      Header: 'ID',
      id: 'id',
      sortable: false,
      minWidth: 50,
      accessor: item => item['id']
    }, {
      Header: 'Name',
      id: 'pub_name',
      sortable: false,
      maxWidth: 350,
      Cell: ({ row }) => (
        <div>{row.original.pub_name}</div>
      )
    }, {
      Header: 'IPI Number',
      id: 'ipi_number',
      sortable: false,
      maxWidth: 300,
      Cell: ({ row }) => (
        <div>{row.original.ipi_number}</div>
      )
    }, {
      Header: 'PRO Affiliation',
      id: 'pro_affiliation',
      sortable: false,
      maxWidth: 150,
      Cell: ({ row }) => (
        <div>{row.original.pro_affiliation}</div>
      )
    },
    {
      id: 'selected',
      maxWidth: 150,
      className: 'select',
      Cell: ({ row }) => (<div>
        {pubValue !== undefined && pubValue !== undefined && pubValue.includes(row.original) === true
          ? (<div style={{ textAlign:'left' }}>
            <button className='py-2 px-4 btn btn-primary btn-sm' data-toggle='button'
              type='button' aria-pressed='true'
              onClick={() => assignPublisher(row.original)}>Selected</button>
          </div>)
          : (<div style={{ textAlign:'left' }}>
            <button className='py-2 px-4 btn btn-blue-gray btn-sm' data-toggle='button'
              type='button' aria-pressed='false'
              onClick={() => assignPublisher(row.original)}>Select</button>
          </div>)
        }
      </div>
      ),
      sortable: false
    }
  ]

  return (
    <div className='list-table'>
      <ListTable
        columns={publisherColumns}
        data={list}
        getNext={_onNext}
        getPrev={_onPrevious}
        currPage={currPage}
        setCurrPage={setCurrPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default PublisherListTable
