import React, { useContext } from 'react'

import DuplicateConfirm from './components/DuplicateConfirm'
import { AssetsContext } from 'stores/assets'

const DuplicateFormView = () => {
  const assetsStore = useContext(AssetsContext)

  let originalFormValues = assetsStore.originalFormValues
  let editingFormValues = assetsStore.editingFormValues
  let dupes = assetsStore.potentialDupes

  return (
    <div className="p-6">
      <div className="mb-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Potential Duplicates</h2>
        </div>
      </div>
      <div className='page-body'>
        <DuplicateConfirm
          originalFormValues={originalFormValues}
          editingFormValues={editingFormValues}
          dupes={dupes}
        />
      </div>
    </div>
  )
}

export default DuplicateFormView
