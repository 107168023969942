import React from 'react'
import { useFormContext } from 'react-hook-form'

const ClaimTitleForm = () => {
    const { register } = useFormContext()

    return (<div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
            <label htmlFor="song_title" className="block text-sm font-medium text-gray-700">
                Song Title
            </label>
            <div className="mt-1">
                <input
                type="text"
                name="song_title"
                id="song_title"
                autoComplete="song_title"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register('song_title', { required: true })}
                />
            </div>
        </div>
        <div className='sm:col-span-3'>
            <label htmlFor="artist" className="block text-sm font-medium text-gray-700">
                Artist
            </label>
            <div className="mt-1">
                <input
                type="text"
                name="artist"
                id="artist"
                autoComplete="artist"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register('artist')}
                />
            </div>
        </div>
        <div className='sm:col-span-3'>
            <label htmlFor="label" className="block text-sm font-medium text-gray-700">
                Label
            </label>
            <div className="mt-1">
                <input
                type="text"
                name="label"
                id="label"
                autoComplete="label"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register('label')}
                />
            </div>
        </div>
    </div>)
}

export default ClaimTitleForm