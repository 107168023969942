import React from 'react'
import { useLocalStore } from 'mobx-react'

export const AssetsContext = React.createContext()

const AssetsProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    potentialDupes: undefined,
    originalFormValues: undefined,
    editingFormValues: undefined,

    doSaveDuplicate: (payload) => {
      store.potentialDupes = payload.dupes
      store.originalFormValues = payload.input
      store.editingFormValues = payload.editingValues
    }
  }))

  return <AssetsContext.Provider value={store}>
    {children}
  </AssetsContext.Provider>
}

export default AssetsProvider
