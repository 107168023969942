import React from 'react'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { ALLOW_DELETE_ACCESS } from 'helper/const'

var moment = require('moment')

const TitleBox = ({ record, _toggleTitleEdit, onDelete, email, scope }) => {
  return (
    <>
      <div className="mb-5 rounded-lg bg-white overflow-hidden shadow">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3">
                  {record.name}
                </h2>
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {record.external_id}
                </p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <button
                type='button'
                onClick={() => { _toggleTitleEdit('recordLabelTitle') }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Edit
                <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              <button
                type='button'
                onClick={() => {
                  if (window.confirm('Delete this record label?')) {
                    onDelete()
                  }
                }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                disabled={(ALLOW_DELETE_ACCESS.indexOf(email) === -1 && scope?.indexOf('exp-delete') === -1)}
                id={record.pk}>
                <TrashIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-white grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-1 sm:divide-y-0 sm:divide-x">
          <div key='modified' className="px-6 py-5 text-sm text-center">
            {record.modified_email === 'Unknown'
              ? (<div>
                <span className="text-gray-600">
                  Last edited at&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
              : (<div>
                <span className="text-gray-600">
                  Last edited by&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {record.modified_email} at {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
            }
          </div>
        </div>
      </div>
    </>)
}

export default TitleBox
