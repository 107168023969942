import React, { useState } from 'react';
import { MD5 } from './md5';

const ProfileImage = ({ email, ...props }) => {
  const [error, setError] = useState(false);

  const avatarType = 'identicon';

  return (<img
    {...props}
    src={`//www.gravatar.com/avatar/${MD5(email)}/?d=${avatarType}`}
    onError={() => setError(true)}
    alt={email}
    />
  );
};

export default ProfileImage;
