import React, { useState, useEffect } from 'react'

function CodeCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);
  
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    const handleCopyClick = () => {
      copyTextToClipboard(copyText)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
      <>
        <span
            onClick={handleCopyClick}
            className='px-3 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 cursor-pointer'
        >
            <input type="text" value={copyText} hidden />
            {isCopied ? 'Copied!' : copyText}
        </span>
      </>
    );
  }

export default CodeCopy