import React from 'react'
import { Route, Switch } from 'react-router'
import Form from './routes/Form/index'
import Detail from './routes/Detail/index'
import Stats from './routes/Stats/index'
import Export from './routes/Export/index'

export default function UploadViews () {
  return (
    <Switch>
      <Route exact path='/claiming' component={Form} />
      <Route exact path='/claiming/export' component={Export} />
      <Route exact path='/claiming/stats' component={Stats} />
      <Route path='/claiming/stats/:id' component={Stats} />
      <Route path='/claiming/:id' component={Detail} />
    </Switch>
  )
}
