import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import './components/List.scss'
import InfoBar from 'components/InfoBar'
import ListTable from './components/ListTable'
import Heading from './components/Heading'

import { getList } from 'api/publishers'
import { getSelectedPublishersFile as exportSelected } from 'api/download'
import toast from 'react-hot-toast'

const ListView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  const [currPage, setCurrPage] = useState(parseInt(query.page))
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [filterQ, setFilterQ] = useState(query.f || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [selected, setSelected] = useState({})
  const [selectAll, setSelectAll] = useState(0)
  const [showAdvanced, setShowAdvanced] = useState(false)

  useEffect(() => {
    if (query.q === undefined || query.q === '') {
      setCurrPage(parseInt(query.page))
      setSearchQ(query.q || '')
      setRefreshTable(false)
    } else {
      if (query.q !== searchQ) {
        setSearchQ(query.q)
        setRefreshTable(!refreshTable)
      }
      if (query.f !== filterQ) {
        setFilterQ(query.f)
        setRefreshTable(!refreshTable)
      }
    }
  }, [location.search])

  const _listParams = (page, sQ, sort, filter, rownum) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (sQ !== undefined && sQ !== '') {
      queryString['q'] = sQ
    }

    if (rownum) {
      queryString['rownum'] = rownum
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }

    if (filter !== undefined) {
      queryString['f'] = filter
      window.filterParam = queryString['f']
    }

    return queryString
  }

  const getPublisherList = (page, sQuery, sort, rownum) => {
    setSearchQ(sQuery)
    getList(_listParams(page, sQuery, sort, filterQ, rownum)).then(res => {
      toast.loading('Getting publishers')
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        toast.dismiss()
      } else {
        toast.error('Unable to fetch publishers')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch publishers')
    })
  }

  const toggleRow = (pk) => {
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => (
      (newSelected[x] === true) &&
        (indeterminate = true)
    ))

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}

    if (selectAll === 0) {
      list.forEach(x => {
        newSelected[x.pk] = true
      })
    }

    setSelected(newSelected)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const getExport = () => {
    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { selected: newArray }
    exportSelected(data)
  }

  const handleSearch = (searchQuery, filterOption, filterValue) => {
    setSearchQ(searchQuery)

    if (filterOption !== '-1' && filterOption !== undefined) {
      let filterQ = filterOption + '|' + filterValue
      getPublisherList(currPage, searchQuery, undefined, filterQ)
    } else {
      getPublisherList(currPage, searchQuery, undefined, '')
    }
  }

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  return (<div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        {!searchFirst &&
          (<InfoBar
            text={"Showing search result for '" + searchQ + "'"}
            onClose={() => getPublisherList(1, '')}
          />)
        }
        <Heading
          searchFirst={searchFirst}
          showAdvanced={showAdvanced}
          handleSearch={handleSearch}
          toggleAdvanced={toggleAdvanced}
        />
        <div className='py-4'>
          <ListTable
            currentPage={currPage}
            setCurrPage={setCurrPage}
            searchQ={searchQ}
            refreshTable={refreshTable}
            sort={query.sort}
            propSelected={selected}
            propSelectAll={selectAll}
            toggleRow={toggleRow}
            toggleSelectAll={toggleSelectAll}
            publisherList={list}
            pages={totalPages}
            getList={getPublisherList}
          />
        </div>
      </div>
    </div>
  )
}

export default ListView
