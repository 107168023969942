import React, { useState } from 'react'


import RenderAdministrators from './RenderAdministrators'
import PublisherPanel from '../PublisherPanel'
import CollectorSelect from './CollectorSelect'

const Collectors = ({ publisher, createPublisher }) => {
  const [openPublisherForm, setOpenPublisherForm] = useState(false)
  const [publisherIsSaving, setPublisherIsSaving] = useState(false)
  const [publisherName, setPublisherName] = useState('')

  const hidePublisherForm = () => {
    setOpenPublisherForm(false)
    setPublisherName('')
  }

  const showPublisherForm = (value) => {
    setOpenPublisherForm(true)
    setPublisherName(value)
  }

  const toggleSavingPublisher = () => {
    setPublisherIsSaving(!publisherIsSaving)
  }

  return (<>
    <PublisherPanel
      publisher={publisher}
      publisherName={publisherName}
      isOpen={openPublisherForm}
      hidePublisherForm={hidePublisherForm}
      createPublisher={createPublisher}
      toggleSaving={toggleSavingPublisher}
      isSaving={publisherIsSaving}
    />
    <div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Collectors and Administrators</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Collector and administration info on the Share. (* required)
        </p>
      </div>
      <div className="mt-1 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">          
        <div className="sm:col-span-6">
          <CollectorSelect />
        </div>
        <div className="sm:col-span-6">
          <RenderAdministrators />
        </div>
      </div>
    </div>
  </>)
}

export default Collectors
