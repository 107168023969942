import PrivateAPI from './private'

export const search = (payload) => {
  let query = {
    page: payload.page,
    q: payload.searchTerm
  }

  if (payload.searchTerm === undefined || payload.searchTerm === '') {
    query = { page: payload.page }
  }

  return query
}

export const getList = (payload) => {
  var params = {}
  var page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined && payload.q !== '') {
    params['q'] = payload.q
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  var url = 'search'

  return PrivateAPI.get(url, params)
}
