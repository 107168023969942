import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'
import Moment from 'react-moment'

import ListTable from 'components/ListTable'

import { getErrors, getExpdataErrors } from 'api/tools'

const ErrorView = () => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [errorReports, setErrorReports] = useState()
  const [expdataErrors, setExpdataErrors] = useState()
  // const [totalPages, setTotalPages] = useState()
  // const [currPage, setCurrPage] = useState(parseInt(query.page) || 1)
  // const [searchQ, setSearchQ] = useState(query.q || '')
  // const [rownum, setRowNum] = useState(20)

  // const hasNext = currPage < totalPages

  const getReports = () => {
    // setSearchQ(filter)
    getErrors().then(res => {
      if (res.status === 200) {
        console.log(res)
        setErrorReports(res.data)
      } else {
        toast.error('Unable to fetch errors')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch errors')
    })
  }

  const getCeleryErrors = () => {
    // setSearchQ(filter)
    getExpdataErrors().then(res => {
      if (res.status === 200) {
        setExpdataErrors(res.data)
      } else {
        toast.error('Unable to fetch errors')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch errors')
    })
  }

  useEffect(() => {
    // if (query.q) {
    //   setSearchQ(query.q)
    //   getList(currPage, rownum, query.q)
    // } else {
    //   getList(1, rownum, '')
    // }
    getReports()
    getCeleryErrors()
  }, [])

  // const _onNext = (page) => {
  //   getList(page + 1, rownum)
  //   setCurrPage(page + 1)
  // }

  // const _onPrevious = (page) => {
  //   if (page !== 1) {
  //     getList(page - 1, rownum)
  //     setCurrPage(page - 1)
  //   }
  // }

  if (errorReports === undefined || expdataErrors === null) {
    return null
  }

  const errorReportColumns = [{
    Header: 'ID',
    id: 'id',
    accessor: 'id'
  }, {
    Header: 'User',
    id: 'user',
    accessor: 'user'
  }, {
    Header: 'Message',
    id: 'message',
    accessor: 'message'
  }, {
    Header: 'Created',
    id: 'created',
    Cell: ({ row }) => (
      <Moment format='MM/DD/YYYY HH:mm'>{row.original.created}</Moment>
    )
  }]

  const expdataErrorColumns = [{
    Header: 'ID',
    id: 'id',
    accessor: 'id'
  }, {
    Header: 'Task',
    id: 'task',
    accessor: 'task'
  }, {
    Header: 'Message',
    id: 'message',
    accessor: 'message'
  }, {
    Header: 'Created',
    id: 'created',
    Cell: ({ row }) => (
      <Moment format='MM/DD/YYYY HH:mm'>{row.original.created}</Moment>
    )
  }]

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        <div className='py-4'>
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Error Reports</h2>
            </div>
          </div>
          <ListTable
            columns={errorReportColumns}
            data={errorReports}
          />

          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">App Errors</h2>
            </div>
          </div>
          <ListTable
            columns={expdataErrorColumns}
            data={expdataErrors}
          />
        </div>
      </div>
    </div>
  )
}

export default ErrorView
