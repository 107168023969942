import React from 'react'

const ArtistDetails = ({ record, newArtists, newRecordLabel }) => {
  return (<>
      <div>
        <dl className="mt-5 mb-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Artists</dt>
            <dd className="mt-1 text-xl font-semibold text-gray-900">
              {newArtists &&
                (<div>
                  {newArtists.map((artist, idx) => {
                    return (
                      <a className="block mb-1 hover:text-blue-500" key={idx} href={`/artists/` + `${artist['external_id']}`}>
                        {artist['name']}<br />
                      </a>
                    )
                  })}
                </div>)
              }
            </dd>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Record Label</dt>
            <dd className="mt-1 text-xl font-semibold text-gray-900">
              {newRecordLabel
                ? (<a className="hover:text-blue-500" href={`/record-labels/` + newRecordLabel['external_id']}>
                  {newRecordLabel['name']}
                </a>)
                : null
              }
            </dd>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Percentage & Territory</dt>
            <dd className="mt-1 text-xl font-semibold text-gray-900">
              {record.percentage}&nbsp;{record.territory_final}
            </dd>
          </div>
        </dl>
      </div>
    </>
  )
}


export default ArtistDetails
