import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import Heading from './components/Heading'

import { getAugmentorFeedDetail } from 'api/asset'
import ReviewMatches from './components/ReviewMatches'

const AugmentorStatsView = () => {
  const { id } = useParams()

  const [feedName, setFeedName] = useState()
  const [feedLink, setFeedLink] = useState()
  const [feedStats, setFeedStats] = useState()

  const fetchAugmentorFeed = () => {
    getAugmentorFeedDetail(id).then(res => {
      if (res.status === 200) {
        setFeedName(res.data?.filename)
        setFeedLink(res.data?.output_link)
        setFeedStats(res.data?.stats)
      } else {
        toast.error('Unable to fetch feed details')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch feed details')
    })
  }

  useEffect(() => {
    fetchAugmentorFeed(id)
  }, [])

  if (feedStats === undefined || feedStats === null) {
    return null
  }

  console.log('FeedStats', feedStats)

  return (
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        <Heading
          feedName={feedName}
          feedLink={feedLink}
        />
        <div className='py-4'>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div key='blanks' className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="mb-3 truncate text-xl font-semibold tracking-tight text-gray-900">BLANKS</dt>
              {feedStats?.blank ?
                (<>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Custom ID: {feedStats?.blank?.custom_id ? feedStats?.blank?.custom_id : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Label: {feedStats?.blank?.asset_label ? feedStats?.blank?.asset_label : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Title: {feedStats?.blank?.asset_title ? feedStats?.blank?.asset_title : '0'}</dd>
                </>)
                : (<dd className="mt-1 text-sm font-medium text-gray-500">N/A</dd>)
              }
            </div>
            <div key='filled' className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="mb-3 truncate text-xl font-semibold tracking-tight text-gray-900">FILLED</dt>
              {feedStats?.filled ?
                (<>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Custom ID: {feedStats?.filled?.custom_id ? feedStats?.filled?.custom_id : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Label: {feedStats?.filled?.asset_label ? feedStats?.filled?.asset_label : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Title: {feedStats?.filled?.asset_title ? feedStats?.filled?.asset_title : '0'}</dd>
                </>)
                : (<dd className="mt-1 text-sm font-medium text-gray-500">N/A</dd>)
              }
            </div>
            <div key='overwritten' className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="mb-3 truncate text-xl font-semibold tracking-tight text-gray-900">OVERWRITTEN</dt>
              {feedStats?.overwritten ?
                (<>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Custom ID: {feedStats?.overwritten?.custom_id ? feedStats?.overwritten?.custom_id : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Label: {feedStats?.overwritten?.asset_label ? feedStats?.overwritten?.asset_label : '0'}</dd>
                  <dd className="mt-1 text-sm font-medium text-gray-500">Asset Title: {feedStats?.overwritten?.asset_title ? feedStats?.overwritten?.asset_title : '0'}</dd>
                </>)
                : (<dd className="mt-1 text-sm font-medium text-gray-500">N/A</dd>)
              }
            </div>
          </dl>
        </div>
        <ReviewMatches feedId={id} fetchAugmentorFeed={fetchAugmentorFeed} />
      </div>
    </div>
  )
}

export default AugmentorStatsView
