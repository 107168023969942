import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import { transformData } from 'views/Writer/utils'
import { edit } from 'api/writers'
import AssignToPublisher from 'views/Writer/components/AssignToPublisher'
import PublisherSelectModal from 'views/Writer/components/PublisherSelect/PublisherSelect'

const TitleForm = ({ initialValues, id, showTitleEditForm, setShowTitleEditForm, setRecord }) => {
  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const pubAffiliations = watch('pub_affiliations')
  const publisherField = watch('publisher_field')

  const [selectPublisherModal, setSelectPublisherModal] = useState(false)
  const [selectedPublishers, setSelectedPublishers] = useState(pubAffiliations ? pubAffiliations : [])
  const [selectedPublisherField, setSelectedPublisherField] = useState(publisherField ? publisherField : '')

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'alternate_names',
    keyName: 'uuid'
  })

  const assignPublisher = (pub) => {
    let owner = selectedPublishers
    let ownerField = selectedPublisherField

    if (owner.includes(pub)) {
      owner.splice(owner.indexOf(pub), 1)

      let ownerString = ', ' + pub.pub_name

      if (ownerField.includes(ownerString)) {
        ownerField = ownerField.replace(ownerString, '')
      } else {
        ownerField = ownerField.replace(pub.pub_name + ', ', '')
      }
    } else {
      owner.push(pub)

      if (ownerField.length > 0) {
        ownerField = ownerField + ', ' + pub.pub_name
      } else {
        ownerField = pub.pub_name
      }
    }


    setValue('publisher_field', ownerField)
    setValue('pub_affiliations', owner)

    setSelectedPublisherField(ownerField)
    setSelectedPublishers(owner)
  }

  const toggleSelectPublisherModal = () => {
    setSelectPublisherModal(!selectPublisherModal)
  }

  const _handleWriterSubmit = (values) => {
    edit(values, id).then(res => {
      if (res.status === 200) {
        toast.success('Successfully updated writer!')
        setRecord(res.data)
      } else {
        toast.error('Unable to update writer')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update writer')
    })
    setShowTitleEditForm(false)
  }

  const formatData = (values) => {
    let data = { ...values }
    if (data.alternate_names !== undefined) {
      data.alternate_names = data.alternate_names.filter(function (item) {
        return item !== undefined
      })
      for (var i = 0; i < data.alternate_names.length; i++) {
        if (data.alternate_names[i].first_name === undefined) {
          data.alternate_names[i].first_name = ''
        }
        if (data.alternate_names[i].middle_name === undefined) {
          data.alternate_names[i].middle_name = ''
        }
        if (data.alternate_names[i].last_name === undefined) {
          data.alternate_names[i].last_name = ''
        }
      }
    }
    _handleWriterSubmit(data)
  }

  const RenderAlternateNames = () => {
    return (<div>
      {fields.map((alternateName, index) => {
        return (<div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12' key={alternateName}>
          <div className="sm:col-span-12">
            <label htmlFor="alternate_name" className="block text-sm font-medium text-gray-700">
              Alternate Name # {index+1}
            </label>
          </div>
          <div className="sm:col-span-1">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                autoComplete="title"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register(`${alternateName}.title`)}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first_name"
                id="first_name"
                autoComplete="first_name"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register(`${alternateName}.first_name`)}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
              Middle Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="middle_name"
                id="middle_name"
                autoComplete="middle_name"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register(`${alternateName}.middle_name`)}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="last_name"
                id="last_name"
                autoComplete="last_name"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register(`${alternateName}.last_name`, { required: true })}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="suffix" className="block text-sm font-medium text-gray-700">
              Suffix
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="suffix"
                id="suffix"
                autoComplete="suffix"
                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register(`${alternateName}.suffix`)}
              />
            </div>
          </div>
          <div>
            <button
              className='btn btn-link btn-remove'
              type='button'
              onClick={() => remove(index)}>
              Remove
            </button>
          </div>
        </div>)
        }
      )}
      <div className='pt-5'>
        <div className='flex'>
          <button className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            type='button'
            onClick={() => append()}>
            Add Alternate Name
          </button>
        </div>
      </div>
    </div>)
  }

  return (<form className="space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow" onSubmit={handleSubmit(formatData)} id='writerDetail'>
      <PublisherSelectModal
        status={selectPublisherModal}
        toggleModal={toggleSelectPublisherModal}
        assignPublisher={assignPublisher}
        selectedPublishers={selectedPublishers}
        pubAffiliations={pubAffiliations}
        change={setValue}
      />
      <div className="space-y-8">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-1">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="title"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('title')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="first_name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('first_name')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
                Middle Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  autoComplete="middle_name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('middle_name')}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="last_name"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('last_name', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="suffix" className="block text-sm font-medium text-gray-700">
                Suffix
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="suffix"
                  id="suffix"
                  autoComplete="suffix"
                  className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register('suffix')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <RenderAlternateNames />
        </div>
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Pub Affiliation
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name='publisher_field'
                  render={() => (
                    <AssignToPublisher
                      className='form-control'
                      value={selectedPublisherField}
                      toggleSelectPublisherModal={toggleSelectPublisherModal}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { setShowTitleEditForm(!showTitleEditForm) }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default TitleForm
