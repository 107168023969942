import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import ReactTooltip from 'react-tooltip';

import ComputedShareTable from './ComputedShareTable'
import StaticOwnershipTable from './StaticOwnershipTable'

import { submitPercentage } from 'api/share'
import { transformPercentage, transformData } from 'views/Share/utils'

const CollectorTable = ({ showTable, setRecord, showOwnershipForm, toggleOwnershipEdit, record, territory, administrators, ownerships, expPlatforms }) => {
  const [showPercentageForm, setShowPercentageForm] = useState(false)
  const { getValues, reset } = useFormContext()

  const togglePercentageEdit = () => {
    setShowPercentageForm(!showPercentageForm)
  }

  const submitPercentages = () => {
    let formValues = getValues()
    let data = transformPercentage(formValues)
    submitPercentage(data, record.external_id).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
        let recordCopy = Object.assign({}, res.data)
        reset(transformData(recordCopy))
        setShowPercentageForm(false)
      } else {
        toast.error('Unable to update percentages')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update percentages')
    })
  }

  const option = {}
  option['AD'] = 'Adaptor'
  option['AM'] = 'Administrator'
  option['AR'] = 'Arranger'
  option['PR'] = 'Associated Performer'
  option['A'] = 'Author'
  option['C'] = 'Composer'
  option['CA'] = 'Composer/Author'
  option['E'] = 'Original Publisher'
  option['PA'] = 'Publisher Income Participant'
  option['SR'] = 'Sub-Arranger'
  option['SA'] = 'Sub-Author'
  option['SE'] = 'Sub-Publisher'
  option['ES'] = 'Substitute Publisher'
  option['TR'] = 'Translator'
  option['W'] = 'Writer'
  option['PY'] = 'Payee'

  return (
    <>
      <div className='tab-actions _right'>
        {showPercentageForm
          ? <>
            <button className='inline-flex items-center px-4 py-2 border border-transparent
              shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
              onClick={() => { togglePercentageEdit() }}>
              Cancel
            </button>
            <button
              className='ml-2 inline-flex items-center px-4 py-2 border border-transparent
              shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none'
              type='button'
              onClick={() => submitPercentages()}>
              Save
            </button>
          </>
          : <>
            <button type='button'
              className='inline-flex items-center px-4 py-2 border border-transparent
              shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
              onClick={() => { togglePercentageEdit() }}>
              Edit Percentages</button>
            <button type='button'
              className='ml-2 inline-flex items-center px-4 py-2 border border-transparent
              shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
              onClick={() => { toggleOwnershipEdit() }}>
              Update Ownership</button>
          </>
        }
      </div>
      {showTable && (<div className="flex flex-col">
        <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Collector
                    </th>
                    { administrators.length > 0 &&
                      (<th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Admin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>)
                    }
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Owner&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Writer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sync.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sync. Territory
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mech.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mech. Territory
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Perf.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Perf. Territory
                    </th>
                    {expPlatforms.includes('LyricFind') &&
                      (<th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Lyric
                      </th>)
                    }
                    {expPlatforms.includes('LyricFind') && 
                      (<th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Lyric Territory
                      </th>)
                    }
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr className="bg-gray-50">
                    <ComputedShareTable
                      expPlatforms={expPlatforms}
                      showOwnershipForm={showOwnershipForm}
                      showPercentageForm={showPercentageForm}
                      record={record}
                      territory={territory}
                      withAdmin={administrators.length > 0}
                    />
                  </tr>
                  {administrators.map((admin, index) => {
                    return (
                      <tr className="bg-white" key={index}>
                        <td />
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <a className="hover:text-blue-500"  href={'/publishers/' + admin.administrator.external_id + '/'}>{admin.administrator}</a>
                        </td>
                        <td />
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <span data-tip data-for='adminRoleCode' className='form-tip text-gray-500'>
                            {admin.administrator_role_code}
                          </span>
                          <ReactTooltip id='adminRoleCode' type='dark' place='top'>
                            <span>{option[admin.administrator_role_code]}</span>
                          </ReactTooltip>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        {expPlatforms.includes('LyricFind') && <td />}
                        {expPlatforms.includes('LyricFind') && <td />}
                      </tr>
                    )
                  })}
                  <StaticOwnershipTable
                    expPlatforms={expPlatforms}
                    ownerships={ownerships}
                    territory={territory}
                    withAdmin={administrators.length > 0}
                    showPercentageForm={showPercentageForm}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}

export default CollectorTable
