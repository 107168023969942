import React, { useState } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

function SearchBox () {
    const history = useHistory()
    const location = useLocation()
    const [assetType, setAssetType] = useState('compositions')
    const [searchValue, setSearchValue] = useState()
    const [searchField, setSearchField] = useState()
    const [filterType, setFilterType] = useState()
    const [filterField, setFilterField] = useState()
    const [showAdvanced, setShowAdvanced] = useState(false)
    
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    
    function objectToQueryString(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }
    
    const _doSearch = () => {
        let query = {
          page: 1,
          q: searchValue
        }
      
        if (searchValue === undefined || searchValue === '') {
          query = { page: 1 }
        }
        history.push({
          pathname: `/${assetType}`,
          search: `?${objectToQueryString(query)}`
        })
    }
    
    const _keyUp = (event) => {
        if (event.keyCode === 13) {
          _doSearch()
        }
    }
    
    const handleChange = (e) => {
        setAssetType(e.target.value)
    }
    
    const handleAdvancedSearch = () => {
        let query = {
          page: 1,
          q: searchField
        }
        if (filterType !== null && filterType !== undefined) {
          let filterQ = filterType + '|' + filterField
          query['f'] = filterQ
        }
        history.push({
          pathname: `/${assetType}`,
          search: `?${objectToQueryString(query)}`
        })
    }

    const _keyUpAdvanced = (event) => {
        if (event.keyCode === 13) {
          handleAdvancedSearch()
        }
    }
    
    const resetAdvancedSearch = () => {
        setSearchField('')
        setFilterType('')
        setFilterField('')
    }
    
    const placeHolders = {
        'compositions': 'Search through a list of Compositions',
        'recordings': 'Search through a list of Recordings',
        'writers': 'Search through a list of Writers',
        'publishers': 'Search through a list of Publishers',
        'artists': 'Search through a list of Artists',
        'record-labels': 'Search through a list of Record Labels'
    }
    
    const options = {
        'compositions': {
          'pk': 'ID',
          'writers': 'Writer',
          'owners': 'Owner',
          'publishers': 'Collector',
          'isrcs': 'ISRC'
        },
        'recordings': {
          'pk': 'ID',
          'isrcs': 'ISRC',
          'artists': 'Artist'
        },
        'writers': {
          'pk': 'ID',
          'pro_affiliation': 'Pro Affiliation',
          'pub_affiliations': 'Publisher',
          'ipi_number': 'IPI Number',
          'isni': 'ISNI'
        },
        'publishers': {
          'pk': 'ID',
          'pro_affiliation': 'Pro Affiliation',
          'ipi_number': 'IPI Number'
        },
        'artists': {
          'pk': 'ID',
          'record_label_affiliation': 'Record Label'
        },
        'record-labels': {
          'pk': 'ID',
          'artist': 'Artist'
        }
    }

    return (<>
    {/* Welcome panel */}
    <section aria-labelledby="profile-overview-title">
        <div className="flex items-center justify-between">
            <h2 className="mt-5 text-xl font-bold text-black sm:text-2xl sm:truncate">
                { showAdvanced ? <span>Advanced Search</span> : <span>Search</span> }
            </h2>
            <div className="mt-5 flex-shrink-0 flex">
                <button
                className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                onClick={() => {
                    setShowAdvanced(!showAdvanced)
                }}
                >{showAdvanced
                ? (<>Hide Advanced Search<EyeOffIcon className="ml-2 h-4 w-4"    aria-hidden="true" /></>)
                : (<>Show Advanced Search<EyeIcon className="ml-2 h-4 w-4" aria-hidden="true" /></>)
                }</button>
            </div>
        </div>
        <div className="mt-3 rounded-lg bg-white overflow-hidden shadow">
            {!showAdvanced
                ? (<div className="bg-white w-full relative flex items-center justify-between px-2 py-6">
                    <div className="flex-1">
                        <div className="px-2 lg:px-6">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative rounded-md">
                                <div className="absolute inset-y-0 left-0 flex items-center">
                                    <label htmlFor="assetType" className="sr-only">
                                        Asset Type
                                    </label>
                                    <select
                                        onChange={(e) => handleChange(e)}
                                        id="assetType"
                                        name="assetType"
                                        autoComplete="assetType"
                                        value={assetType}
                                        defaultValue={location.pathname.split('/')[1]}
                                        className="focus:ring-transparent focus:border-red-500 h-full py-0 pl-3 pr-7 border-transparent bg-gray-100 text-gray-500 sm:text-sm rounded-md"
                                    >
                                        <option value="compositions">Composition</option>
                                        <option value="recordings">Recording</option>
                                        <option value="writers">Writer</option>
                                        <option value="publishers">Publisher</option>
                                        <option value="artists">Artist</option>
                                        <option value="record-labels">Record Label</option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyUp={(event) => _keyUp(event)}
                                    className="focus:ring-red-500 focus:border-red-500 disabled:bg-gray-200 block w-full pl-36 sm:text-sm border-gray-200 rounded-md"
                                    placeholder={placeHolders[assetType]}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={() => _doSearch()}
                        className="inline-flex -ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                    >
                        Search
                    </button>
                </div>)
                : (<div className="p-6">
                <div className='py-4'>
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4">
                    <div className="">
                        <label htmlFor="assetType" className="block text-sm font-medium text-gray-700">
                            <div className="inline-flex w-full">
                                <div className="mt-3">
                                    Search through a list of
                                </div>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    id="assetType"
                                    name="assetType"
                                    autoComplete="assetType"
                                    value={assetType}
                                    defaultValue={location.pathname.split('/')[1]}
                                    className="mt-1 ml-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                >
                                    <option value="compositions">Compositions</option>
                                    <option value="recordings">Recordings</option>
                                    <option value="writers">Writers</option>
                                    <option value="publishers">Publishers</option>
                                    <option value="artists">Artists</option>
                                    <option value="record-labels">Record Labels</option>
                                </select>
                            </div>
                        </label>
                        <div className="mt-1">
                            <textarea
                                type='text'
                                name="query"
                                placeholder="Separate each query with a | (ex: Title 1|Title 2)"
                                value={searchField}
                                onChange={(e) => setSearchField(e.target.value)}
                                onKeyUp={(event) => _keyUpAdvanced(event)}
                                rows={3}
                                className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                defaultValue={''}
                            />
                        </div>
                    </div>

                    {options[assetType] && <div className="">
                        <fieldset className="">
                            <legend className="block text-sm font-medium text-gray-700">Filter</legend>
                            <div className="mt-1 rounded-md shadow-sm -space-y-px">
                                <div>
                                <label htmlFor="filter" className="sr-only">
                                    Filter
                                </label>
                                <select
                                    id="filter"
                                    name="filter"
                                    value={filterType}
                                    onChange={(e) => setFilterType(e.target.value)}
                                    className="focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                >
                                    <option value='-1'>Select...</option>
                                    {Object.keys(options[assetType]).map((key, index) => (
                                    <option key={index} value={key}>{options[assetType][key]}</option>
                                    ))}
                                </select>
                                </div>
                                <div>
                                <label htmlFor="filter-field" className="sr-only">
                                    Filter Field
                                </label>
                                <input
                                    type="text"
                                    name="filter-field"
                                    id="postal-code"
                                    value={filterField}
                                    onChange={(e) => setFilterField(e.target.value)}
                                    onKeyUp={(event) => _keyUpAdvanced(event)}
                                    className="focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                    placeholder=""
                                />
                                </div>
                            </div>
                        </fieldset>
                    </div>}

                    <div className="flex justify-end">
                        <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        onClick={() => resetAdvancedSearch()}
                        >
                        Reset
                        </button>
                        <button
                        type="button"
                        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => handleAdvancedSearch()}
                        >
                        Search
                        </button>
                    </div>
                    </div>
                </div>
            </div>)
            }
        </div>
    </section>
  </>)
}

export default SearchBox