import React from 'react'
import { Route, Switch } from 'react-router'
import Form from './routes/Form/index'
import Landing from './routes/Landing/index'

export default function UploadViews () {
  return (
    <Switch>
      <Route exact path='/upload' component={Form} />
      <Route path='/upload/:id' component={Landing} />
    </Switch>
  )
}
