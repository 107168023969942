import React, { useState } from 'react'
import Moment from 'react-moment'
import { DownloadIcon, PencilIcon } from '@heroicons/react/outline'
import ListTable from 'components/ListTable'

const FileTable = ({ fileList, _toggleExport, setDatafeed, getNext, getPrev, currentPage, totalPages, hasNext }) => {
  const getFeedColumns = (setDatafeed) => {
    const columns = [{
      Header: 'Filename',
      accessor: 'filename'
    }, {
      Header: 'Upload Date',
      accessor: 'created',
      Cell: ({ row }) => (
        <Moment format='MM/DD/YYYY HH:mm'>{row.original.created}</Moment>
      )
    }, {
      Header: 'Update?',
      id: 'is_update',
      Cell: ({ row }) => (
        <span>{row.original.is_update ? 'Yes' : 'No'}</span>
      )
    }, {
      Header: 'Platforms (Ready in bold)',
      id: 'platforms',
      Cell: ({ row }) => (<>
        {row.original.all_platforms.map((item, idx) => {
          return (
            <span key={idx}>
              {row.original.platforms_ready && row.original.platforms_ready.includes(item)
                ? <b>{item}</b>
                : item
              }
              {idx < row.original.all_platforms.length - 1
                && <span>,&nbsp;</span>
              }
            </span>
          )
        })}
      </>)
    }, {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }) => (<div className='flex'>
        {row.original.status !== 'invalid'
          && (<button
            type="button"
            onClick={() => setDatafeed(row.original)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
          </button>)
        }
        {row.original.status === 'processed' && (row.original.ingest_type === 'shares' || row.original.ingest_type === 'recordings')
          ? (<button
            type="button"
            onClick={() => _toggleExport(row.original.ingest_type, row.original.model_type, row.original.pk)}
            className="ml-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <DownloadIcon className="h-4 w-4" aria-hidden="true" />
          </button>)
          : row.original.status === 'invalid'
          ? (<label
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              INVALID
            </label>)
          : null
        }
      </div>
      )
    }]
  
    return columns
  }

  const feedColumns = getFeedColumns(setDatafeed)

  return (<>
    <ListTable
      columns={feedColumns}
      data={fileList}
      getNext={getNext}
      getPrev={getPrev}
      currPage={currentPage}
      pages={totalPages}
      hasNext={hasNext}
      showPagination
    />
  </>)
}

export default FileTable