import React, { useState, useEffect } from 'react'

import { getAugmentorFeedList } from 'api/asset'
import StackedTable from './StackedTable'

function ImportedFiles () {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const rownum = 5

  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const isNotEmpty = (obj) => {
    return obj && Object.keys(obj).length > 0;
  }

  const getList = () => {
    setLoading(true)
    getAugmentorFeedList(currentPage, rownum).then(res => {
      setTotalPages(res.data.total_pages)
      setFileList(res.data.results)
      setLoading(false)
    })
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== '' && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  return (
    <div className='divide-y divide-gray-200 bg-white shadow sm:rounded-md'>
      <StackedTable
        data={fileList}
        getNext={onNext}
        getPrev={onPrevious}
        currPage={currentPage}
        setCurrPage={setCurrentPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default ImportedFiles
