import React, { useState } from 'react'

import MLCSearch from './components/MLCSearch'
import MLCCard from './components/MLCCard'

const MLCView = () => {
  const [songData, setSongData] = useState()

  return (<>
    <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
            <div className="mb-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">MLC Search</h2>
                    <p className='mt-1 text-sm text-gray-500'>
                        Enter an MLC Song Code below to fetch data directly from the MLC API.
                    </p>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 bg-white shadow sm:rounded-md p-6">
                        <div className="sm:col-span-6">
                            <MLCSearch setSongData={setSongData} />
                        </div>
                    </div>
                    {songData && <MLCCard songData={songData} />}
                </div>
            </div>
        </div>
    </div>
  </>)
}

export default MLCView