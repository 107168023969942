import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'

import { getPossibleMatches, approveMatch } from 'api/asset'

const getBackgroundColor = (score) => {
  return score >= 90 ? 'bg-green-50' : 'bg-gray-50';
};

export default function PossibleMatches({ rowId, hasNext, currPage, setCurrPage, fetchAugmentorFeed }) {
  const [loading, setLoading] = useState(false)
  const [matches, setMatches] = useState([])

  const acceptMatch = (shareId) => {
    setLoading(true)
    toast.loading('Accepting match...')
    approveMatch(rowId, shareId).then(res => {
      if (res.status === 200) {
        toast.dismiss()
        toast.success('Successfully accepted match and updated data!')
        fetchAugmentorFeed()
        if (hasNext) {
          setCurrPage(currPage + 1)
        }
        setLoading(false)
      } else {
        toast.dismiss()
        toast.error('Unable to accept match.')
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err.response)
      toast.dismiss()
      setLoading(false)
      toast.error('Unable to accept match.')
    })
  }

  const fetchPossibleMatches = () => {
    setLoading(true)
    toast.loading('Fetching possible matches...')
    getPossibleMatches(rowId).then(res => {
      if (res.status === 200) {
        setMatches(res.data.spacy_matches)
        toast.dismiss()
        setLoading(false)
      } else {
        toast.error('Unable to fetch possible matches.')
        toast.dismiss()
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err.response)
      toast.dismiss()
      setLoading(false)
      toast.error('Unable to fetch possible matches.')
    })
}

  useEffect(() => {
    fetchPossibleMatches()
  }, [rowId])

  return (
    <>
      {!loading && matches.length === 0 && <p className="text-sm font-semibold leading-6 text-gray-900">No match found.</p>}
      {matches.map((match, index) => (
        <div key={index} className={`rounded-lg mb-4 ${getBackgroundColor(match.similarity)}`}>
        <div className="flex">
          {/* Score Section */}
          <div className="w-32 flex flex-col items-center justify-center p-6 border-r">
            <div className="text-2xl font-bold">{match.similarity}%</div>
            <div className="text-xs text-gray-600">Match Score</div>
          </div>

          {/* Info Section */}
          <div className="flex-1 p-6">
            <div className="space-y-4">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-sm text-gray-600">Asset Title</div>
                  <div className="font-medium">{match.title}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Custom ID</div>
                  <div className="font-medium">{match.custom_id ? match.custom_id : 'No Custom ID'}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Asset Label</div>
                  <div className="font-medium">{match.asset_label ? match.asset_label : 'No Asset Label'}</div>
                </div>
              </div>

              <div>
                <div className="text-sm text-gray-600">Writers</div>
                <div className="font-medium">{match.writers}</div>
              </div>

              <div className="flex gap-2">
                <button 
                  type='button'
                  onClick={() => acceptMatch(match.external_id)}
                  className="px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700">
                  Accept
                </button>
                <a href={`/shares/${match.external_id}/`}className="px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300">
                  View Asset
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))}
    </>
  )
}
