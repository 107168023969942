import React from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {

  Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout

  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )} />
  )
}

export default PublicRoute
