import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import CompositionDetail from './components/CompositionDetail'

import { fetch } from 'api/composition'
import { AuthContext } from 'stores/auth'

const CompositionDetailView = () => {
  const { id } = useParams()
  const authStore = useContext(AuthContext)
  const [record, setRecord] = useState()

  useEffect(() => {
    if (record === undefined || record.external_id !== id) {
      fetch(id).then((res) => {
        toast.loading('Fetching composition...')
        if (res.status === 200) {
          setRecord(res.data)
          toast.dismiss()
        } else {
          toast.error('Unable to fetch composition')
        }
      })
    }
  }, [])

  if (record === undefined) {
    return null
  }

  return (
    <CompositionDetail
      record={record}
      setRecord={setRecord}
      user={authStore.user}
    />
  )
}

export default CompositionDetailView