import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import SelectField from 'layouts/components/Select'
import RoleCode from 'layouts/components/RoleCode'

const options = [
  { value: '', label: '----' },
  { value: 'AD', label: 'Adaptor' },
  { value: 'AR', label: 'Arranger' },
  { value: 'PR', label: 'Associated Performer' },
  { value: 'A', label: 'Author' },
  { value: 'C', label: 'Composer' },
  { value: 'CA', label: 'Composer/Author' },
  { value: 'SR', label: 'Sub-Arranger' },
  { value: 'SA', label: 'Sub-Author' },
  { value: 'W', label: 'Writer' }
]

const formatLabel = (option, elem) => {
  return (
    <div className='select-option'>
      <span className='select-title'>{option.label}<RoleCode roleCode={option.value} asBadge /></span>
    </div>
  )
}

const WriterRoles = ({ name, control, change, isArrangement, defaultValue }) => {
  var selectName = name === undefined ? 'writer_role_code' : name;
  const [selectValue, setSelectValue] = useState()

  useEffect(() => {
    if (isArrangement) {
      change(selectName, { value: 'AR', label: 'Arranger' })
      setSelectValue({ value: 'AR', label: 'Arranger' })
    }
  }, [isArrangement])

  return (
    <Controller
      control={control}
      name={selectName}
      render={() => (
        <SelectField
          defaultValue={defaultValue}
          value={selectValue}
          options={options}
          formatLabel={formatLabel}
          onChange={(e) => {
            change(selectName, e)
            setSelectValue(e)
          }}
          isDisabled={isArrangement}
        />
      )}
    />
  )
}

export default WriterRoles
