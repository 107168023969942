import React from 'react'
import { ReceiptTaxIcon, StopIcon, ClipboardListIcon } from '@heroicons/react/outline'

const ExportCards = ({ summary, toggleBulkOptions }) => {
    let totalPublishing = summary.publishers.count + summary.writers.count
    const cards = [
        { name: 'Collection Shares', option: 'shares', icon: ReceiptTaxIcon, count: summary.collection_shares.count },
        { name: 'Recordings', option: 'recordings', icon: StopIcon, count: summary.recordings.count },
        { name: 'Writers & Publishers', option: 'publishing', icon: ClipboardListIcon, count: totalPublishing }
    ]

  return (
    <div className="mt-8">
        <div className="max-w-6xl mx-auto">
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {/* Card */}
                {cards.map((card) => (
                    <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">{card.count}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="bg-red-600 px-5 py-3">
                            <div className="text-sm">
                                <button onClick={() => toggleBulkOptions(card.option)} className="font-medium text-white hover:text-white">
                                    Export All
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default ExportCards
