import React from 'react'
import NoteBox from 'components/NoteBox'
import ActionFeed from 'components/ActionFeed'
import { TrashIcon } from '@heroicons/react/outline'

const GeneralInfoTable = ({ record }) => {
  return (
    <>
      <div className='mb-5 px-4 py-4 table-description'>
        <div className='flex'>
          <div className='flex-1'>
            <h3 className='ml-2 font-medium'>Activities</h3>
            <ActionFeed
              model={'artist'}
              pk={record.external_id} />
          </div>
          <div className='flex-1'>
            <h3 className='ml-2 font-medium'>Notes</h3>
            <NoteBox model={'artist'} pk={record.external_id} />
          </div>
        </div>
      </div>      
    </>
  )
}

export default GeneralInfoTable
