import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import { transformDuplicate } from 'views/Share/utils'
import 'views/Share/components/styles/AdminForm.scss'
import { AssetsContext } from 'stores/assets'

import ExplorationControl from 'components/ExplorationControl'

import GeneralInfo from 'views/Share/components/GeneralInfo/GeneralInfo'
import ExplorationInfo from 'views/Share/components/GeneralInfo/ExplorationInfo'
import Collectors from 'views/Share/components/Collectors/Collectors'
import Ownership from 'views/Share/components/Ownership/Ownership'

import { adminFetchE, adminCreate } from 'api/share'
import toast from 'react-hot-toast'

const AdminForm = ({ composition, user, record, setRecord }) => {
  const location = useLocation()
  const history = useHistory()
  const assetsStore = useContext(AssetsContext)

  const [showGuide, setShowGuide] = useState(true)
  const [publisherIsSaving, setPublisherIsSaving] = useState(false)

  const [publisherRecord, setPublisherRecord] = useState()
  let initialValues = {}

  if (localStorage.getItem('review')) {
    initialValues = JSON.parse(localStorage.getItem('review'))
  } else if (record !== undefined) {
    initialValues = transformDuplicate(record)
  } else {
    if (composition !== undefined) {
      let defaultTitle = composition._title
      let defaultComposition = composition.pk
      initialValues = {
        'composition': defaultComposition,
        'composition_field': defaultTitle,
        'title': defaultTitle,
        'publishers': [
          {
            'owners': [],
            'ownership_writers': [],
            'sr_include_exclude': 'include',
            'mr_include_exclude': 'include',
            'pr_include_exclude': 'include',
            'lr_include_exclude': 'include'
          }
        ]
      }
    } else {
      initialValues = {
        'publishers': [
          {
            'owners': [],
            'ownership_writers': [],
            'sr_include_exclude': 'include',
            'mr_include_exclude': 'include',
            'pr_include_exclude': 'include',
            'lr_include_exclude': 'include'
          }
        ]
      }
    }
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  var controlled = methods.watch('controlled')

  useEffect(() => {
    methods.reset(initialValues)
  }, [composition])

  const _duplicating = () => {
    return id !== undefined;
  }

  const { id } = useParams()

  useEffect(() => {
    if (publisherIsSaving && !publisherRecord.isEmpty()) {
      setPublisherIsSaving(false)
    }

    if (_duplicating()) {
      if (record.isEmpty() || record.pk !== id) {
        adminFetchE(id).then(res => {
          if (res.status === 200) {
            setRecord(res.data)
          } else {
            toast.error('Unable to fetch share')
          }
        }).catch(err=>{
          console.log(err.response)
          toast.error('Unable to fetch share')
        })
      }
    } else {
      methods.reset()
    }

    window.$('[data-toggle=\'tooltip\']').tooltip();
    return () => {
      localStorage.removeItem('review')
    }
  }, [])

  const formatData = (values) => {
    let data = Object.assign({}, values)
    const shareTypes = ['sr', 'mr', 'pr', 'lr']
    data.pk = null

    if (data.related_artists) {
      data.related_artists = data.related_artists.map((artist, idx) => {
        return artist.pk
      })
    }

    if (data.collectors !== undefined && data.collectors.create === undefined) {
      data.collectors = data.collectors.map((collector, idx) => {
        return collector.pk
      })
    }

    if (data.collector_role_code !== undefined) {
      data.collector_role_code = data.collector_role_code.value
    }

    if (data.publishers !== undefined) {
      data.publishers = data.publishers.map((publisher) => {
        let pub = Object.assign({}, publisher)
        if (pub.create === undefined) {
          pub.owners = pub.owners.map((owner) => {
            return owner.pk
          })
        }
        // pub.owner_role_code = pub.owner_role_code.value;
        pub.owner_role_code = pub?.owner_role_code?.value || ""

        if (pub.ownership_writers !== undefined) {
          pub.ownership_writers = pub.ownership_writers.map((writer) => {
            if (writer.create === undefined) {
              // if (writer.writer_role_code !== undefined) {
              if (writer.writer_role_code !== undefined && writer.writer) {
                return { writer: writer.writer.pk, writer_role_code: writer.writer_role_code.value }
                // } else {
              } else if (writer.writer) {
                return { writer: writer.writer.pk, writer_role_code: '' }
              }
            } else {
              if (writer.writer_role_code !== undefined) {
                writer.writer_role_code = writer.writer_role_code.value;
                return writer;
              } else {
                writer.writer_role_code = ''
                return writer;
              }
            }
          })
        }

        if (!pub['lr_share']) {
          pub['lr_share'] = 0
        }

        shareTypes.map((type) => {
          if (pub[type + '_share_territory'] !== undefined) {
            if (Array.isArray(pub[type + '_share_territory'])) {
              pub[type + '_share_territory'] = (pub[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
            } else {
              pub[type + '_share_territory'] = pub[type + '_share_territory']['value']
            }
          }
        })
        return pub
      })
    }

    if (data.writers !== undefined) {
      data.writers = data.writers.map((w, idx) => {
        let writer = Object.assign({}, w);
        if (writer.create === undefined) {
          writer.owners = writer.owners.map((owner, idx) => {
            return owner.pk
          })
        }

        if (!writer['lr_share']) {
          writer['lr_share'] = 0
        }

        shareTypes.map((type) => {
          if (writer[type + '_share_territory'] !== undefined) {
            if (Array.isArray(writer[type + '_share_territory'])) {
              writer[type + '_share_territory'] = (writer[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
            } else {
              writer[type + '_share_territory'] = writer[type + '_share_territory']['value']
            }
          }
        })
        return writer
      })
    }

    adminCreate(data, values).then(res => {
      if (res.status === 200 || res.status === 201) {
        history.push('/shares/' + res.data.external_id + '/')
        toast.success('Successfully created share!')
      } else if (res.status === 202) {
        assetsStore.doSaveDuplicate(res.data)
        history.push('/shares/potential-duplicate/')
      } else {
        toast.error('Unable to create share')
      }
    })
      .catch(res => {
        console.log(res.response)
        toast.error('Unable to create share')
      })
  }

  const handleCancel = () => {
    localStorage.removeItem('review')
    history.goBack()
  }

  let userTerritoryPresets = []

  if (user) {
    userTerritoryPresets = user.territory_presets
  }

  return (<FormProvider {...methods}>
    <form className="space-y-8" onSubmit={methods.handleSubmit(formatData)} id='newShare'>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <GeneralInfo composition={composition} />
        <ExplorationInfo />
      </div>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <Collectors />
      </div>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Controlled Platforms</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              List of platforms where this Share is controlled by Exploration.
            </p>
          </div>
          <ExplorationControl
            id={'sharePlatforms'}
            initialValues={initialValues}
            controlled={controlled}
            type={'share'}
          />
        </div>
      </div>
      <div className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md">
        <Ownership userTerritoryPresets={userTerritoryPresets} />
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </FormProvider>)
}

export default AdminForm
