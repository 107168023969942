import PrivateAPI from './private'

export const getList = (page, rownum, term, startDate, endDate, claimStaff) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  if (startDate !== undefined && startDate !== null) {
    params['start_date'] = startDate
  }

  if (endDate !== undefined && endDate !== null) {
    params['end_date'] = endDate
  }

  if (claimStaff !== undefined && claimStaff !== null) {
    params['claim_staff'] = claimStaff
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/claims/upload/', params)
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/claims/' + pk + '/')
}

export const getStats = (userId, month, year, statType, page, rownum, term) => {
  var params = {}

  if (userId) {
    params['user'] = userId
  }

  if (term) {
    params['_filter'] = term
  }

  if (month) {
    params['month'] = month
  }

  if (year) {
    params['year'] = year
  }

  params['statType'] = statType || 'platform'
  params['page'] = page || 1
  params['rownum'] = rownum || 20
  return PrivateAPI.get('api/claims/stats/', params)
}

export const getClients = () => {
  return PrivateAPI.get('api/claims/clients/')
}

export const deleteClaimFeed = (pk) => {
  return PrivateAPI.delete('api/claims/' + pk + '/')
}

export const deleteClaimSheet = (pk) => {
  return PrivateAPI.delete('api/claims/sheet/' + pk + '/')
}

export const editClaimSong = (pk, payload) => {
  return PrivateAPI.put('api/claims/song/' + pk + '/', payload)
}

export const deleteClaimSong = (pk) => {
  return PrivateAPI.delete('api/claims/song/' + pk + '/')
}

export const onSubmit = (payload) => {
  return PrivateAPI.postfile('api/claims/upload/', payload)
}

export const getErrorList = (page, rownum, filter) => {
  var params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/claims/errors/' + page + '/' + rownum + '/', params)
}