import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import SelectField from 'layouts/components/Select'
import { Form } from 'react-bootstrap'

import { exportClaims } from 'api/download'

const DateBox = styled.div`
  margin-top: 10px;
`

const StyledDateRange = styled(DateRange)`
`

function Filters ({ clientOptions }) {
  const history = useHistory()
  const [client, setClient] = useState()
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: null,
      endDate: new Date(""),
      key: 'selection'
    }
  ])

  const handleDateChange = (data) => {
    setDateFilter(data)
  }

  const _handleReset = () => {
    setDateFilter([{
      startDate: null,
      endDate: new Date(""),
      key: 'selection'
    }])
    setClient()
  }

  const _handleExport = () => {  
    const startDate = dateFilter && dateFilter[0].startDate && dateFilter[0].startDate ? format(dateFilter[0].startDate, 'yyyy-MM-dd') : null
    const endDate = dateFilter && dateFilter[0].startDate && dateFilter[0].endDate ? format(dateFilter[0].endDate, 'yyyy-MM-dd') : null

    let payload = {
      'client': client,
      'startDate': startDate,
      'endDate': endDate
    }

    exportClaims(payload).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to generate export file.')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to generate export file')
    })
  }

  return (
    <div className="py-2">
      <Form>
        <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-6'>
          <div className='sm:col-span-3'>
            <DateBox>
              <label htmlFor="uploadDate" className="block text-sm font-medium text-gray-700">
                Date Range
              </label>
              <input type='hidden' name='startDate' />
              <input type='hidden' name='endDate' />
              <StyledDateRange
                editableDateInputs
                onChange={item => handleDateChange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateFilter}
              />
            </DateBox>
          </div>
          <div className='sm:col-span-3 space-y-6 sm:space-y-5'>
            <div className="sm:col-span-3">
              <label htmlFor="client" className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <div className="mt-1">
                <SelectField
                  id="client"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="client"
                  options={clientOptions}
                  onChange={(e) => setClient(e.value)}
                />
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => _handleReset()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Reset
                </button>
                <button
                  type="button"
                  onClick={() => _handleExport()}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
  </div>)
}

export default Filters
