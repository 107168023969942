import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import Creatable from 'layouts/components/Creatable'
import ActionFeed from 'components/ActionFeed'
import NoteBox from 'components/NoteBox'
import { transformData } from 'views/Publisher/utils'
import { edit } from 'api/publishers'

const EditPublisherForm = ({ id, initialValues, record, setRecord, setShowEditForm }) => {
  const proOptions = [
    { value: '', label: '----' },
    { value: 'ASCAP', label: 'ASCAP' },
    { value: 'BMI', label: 'BMI' },
    { value: 'GMR', label: 'GMR' },
    { value: 'PRS', label: 'PRS' },
    { value: 'SESAC', label: 'SESAC' },
    { value: 'SOCAN', label: 'SOCAN' },
    { value: 'Other', label: 'Other' }
  ]

  const { register, handleSubmit, errors, setValue, control } = useForm({
    mode: 'onChange',
    defaultValues: transformData(initialValues)
  })

  const handlePublisherSubmit = (values) => {
    if (values['agreement_start_date'] === "") {
      delete values['agreement_start_date']
    }
    edit(values, id).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
        setShowEditForm(false)
        toast.success('Successfully updated publisher!')
      } else {
        toast.error('Unable to update publisher')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to update publisher')
    })
  }

  return (<>
    <div className='mb-5 px-4 py-4 table-description'>
      <div className='flex'>
        <div className='flex-1'>
          <div className="mb-5">
            <form className='form' onSubmit={handleSubmit(handlePublisherSubmit)}>
              <div className="bg-white shadow sm:rounded-lg mx-2 my-2">
                <div className="px-4 py-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Publisher Information</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Related codes and other information.</p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">CMRRA Account Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="cmrra_account_number"
                          id="cmrra_account_number"
                          autoComplete="cmrra_account_number"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('cmrra_account_number')}
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">IPI Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="ipi_number"
                          id="ipi_number"
                          autoComplete="ipi_number"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('ipi_number')}
                        />
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Pro Affiliation</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <Controller
                          control={control}
                          name='pro_affiliation'
                          render={(props) => (
                            <Creatable
                              {...props}
                              options={proOptions}
                              onChange={(e) => {
                                setValue('pro_affiliation', e)
                              }}
                            />
                          )}
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Sub-Publishing Start Date</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="date"
                          name="agreement_start_date"
                          id="agreement_start_date"
                          autoComplete="agreement_start_date"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('agreement_start_date')}
                        />
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Client Portal UID</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="client_portal_uid"
                          id="client_portal_uid"
                          autoComplete="client_portal_uid"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('client_portal_uid')}
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Client Code</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="royalty_code"
                          id="royalty_code"
                          autoComplete="royalty_code"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('royalty_code')}
                        />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>    
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => { setShowEditForm(false) }}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={errors}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <br />
          <>
            <div className="px-4 py-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Activities</h3>
            </div>
            <ActionFeed
              model={'publisher'}
              pk={record.external_id} />
          </>
        </div>
        <div className='flex-1'>
          <div className="px-4 py-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
          </div>
          <NoteBox model={'publisher'} pk={record.external_id} />
        </div>
      </div>
    </div>
  </>)
}

export default EditPublisherForm
