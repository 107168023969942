import React from 'react'

import Icon from 'layouts/components/Icon'
import PublisherSelect from './PublisherSelect'

const RenderPublishers = ({ selectedPublishers, fields, append, remove, register, control, change }) => {
  return (<div>
    {fields.map((publisher, index) =>
      <PublisherSelect
        key={publisher.uuid}
        fields={fields}
        publisher={publisher}
        index={index}
        change={change}
        selectedPublishers={selectedPublishers}
        control={control}
        register={register}
        remove={remove}
      />
    )}
    <div className="pt-5">
      <div className="flex">
        <button
          type="button"
          onClick={() => { append() }}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Add Publisher
        </button>
      </div>
    </div>
  </div>)
} 

export default RenderPublishers
