import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'
import styled from 'styled-components'

import LoadingApp from "../containers/LoadingApp"
import { AuthContext } from 'stores/auth'
import PublicRoute from './PublicRoute'
import ProtectedRoutes from './ProtectedRoutes'
import Login from 'views/Auth/Login/index'

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

function AuthenticatedRoutes () {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    // if (authStore.loading) {
    //   return (<span>Loading...</span>)
    // }

    if (!authStore.loggedIn) {
      return (<Redirect to='/login' />)
    } else {
      return (<>
        <Switch>
          <Route path="/" component={ProtectedRoutes} />
        </Switch>
      </>)
    }
  })
}

function Router ({ loading }) {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    if (loading) {
      return (<LoadingApp />)
    }

    return (<MainWrapper className='bg-gray-100 h-screen'>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />
        {authStore.loggedIn
          ? <Route path="/" component={AuthenticatedRoutes} />
          : <Route path="/" component={Login} />
        }
      </Switch>
    </MainWrapper>)
  })
}

export default Router
