import React from 'react'
import { Route, Switch } from 'react-router'
import Export from './routes/Exports/index'
import List from './routes/List/index'

export default function Download () {
  return (
    <Switch>
      <Route exact path='/download' component={Export} />
      <Route path='/download/my-exports' component={List} /> 
    </Switch>
  )
}
