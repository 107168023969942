import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import toast from 'react-hot-toast'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Creatable from 'layouts/components/Creatable'
import CompositionSelectModal from 'layouts/components/CompositionModalSelect/CompositionSelectModal'
import { AssignToComposition } from 'layouts/components/CompositionModalSelect/AssignToComposition'

import { create } from 'api/conflict'

const ConflictForm = () => {
  const history = useHistory()

  const [selectCompositionModal, setSelectCompositionModal] = useState(false)
  const [selectedCompositionField, setSelectedCompositionField] = useState('')

  const { register, handleSubmit, control, setValue } = useForm({
    mode: 'onChange'
  })

  const onSubmit = (values) => {
    create(values).then(res => {
      if (res.status === 201) {
        toast.success('Conflict created successfully!')
        history.push('/conflict-resolution/')
      } else {
        toast.error('Unable to create conflict')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create conflict')
    })
  }

  const assignComposition = (comp) => {
    setValue('related_composition', comp.pk)
    setSelectedCompositionField(comp._title)
    setSelectCompositionModal(false)
  }

  const toggleSelectCompositionModal = () => {
    setSelectCompositionModal(!selectCompositionModal)
  }

  const assetTypeOptions = [
    { value: '', label: '----' },
    { value: 'pub', label: 'Publishing' },
    { value: 'sr', label: 'Sound Recording' },
    { value: 'mv', label: 'Music Video' },
    { value: 'web', label: 'Web' },
    { value: 'movie', label: 'Movie' },
    { value: 'tv', label: 'TV Episode' },
    { value: 'Other', label: 'Other' }
  ]

  const platformOptions = [
    { value: '', label: '----' },
    { value: 'ascap', label: 'ASCAP' },
    { value: 'bmi', label: 'BMI' },
    { value: 'cmrra', label: 'CMRRA' },
    { value: 'distribution', label: 'Distribution' },
    { value: 'hfa', label: 'HFA' },
    { value: 'lyricfind', label: 'LyricFind' },
    { value: 'mlc', label: 'MLC' },
    { value: 'mri', label: 'MRI' },
    { value: 'soundexchange', label: 'SoundExchange' },
    { value: 'subpublishing', label: 'Sub-Publishing' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'Other', label: 'Other' }
  ]

  return (<>
    <CompositionSelectModal
      status={selectCompositionModal}
      toggleModal={toggleSelectCompositionModal}
      assignComposition={assignComposition}
    />
    <form className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md" onSubmit={handleSubmit(onSubmit)} id='newRecordLabel'>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about this conflict. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Title<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="title"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('title', { required: true })}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Artist/Writer
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="artist_writer"
                  id="artist_writer"
                  autoComplete="artist_writer"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('artist_writer')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="client" className="block text-sm font-medium text-gray-700">
                Client Name
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="client_name"
                  id="client_name"
                  autoComplete="title"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('client_name')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="ipi-number" className="block text-sm font-medium text-gray-700">
                Assign To Composition
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name='composition_field'
                  render={(props) => (
                    <AssignToComposition
                      className='form-control'
                      value={selectedCompositionField}
                      toggleSelectCompositionModal={toggleSelectCompositionModal}
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Asset Type<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                  <Controller
                    control={control}
                    name='asset_type'
                    render={(props) => (
                      <Creatable
                          {...props}
                          options={assetTypeOptions}
                          onChange={(e) => {
                          setValue('asset_type', e)
                          }}
                      />
                    )}
                  />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Platform<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                  <Controller
                    control={control}
                    name='platform'
                    render={(props) => (
                      <Creatable
                          {...props}
                          options={platformOptions}
                          onChange={(e) => {
                          setValue('platform', e)
                          }}
                      />
                    )}
                  />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Asset ID<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="asset_id"
                  id="asset_id"
                  autoComplete="asset_id"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('asset_id')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="approx_daily_views" className="block text-sm font-medium text-gray-700">
                Approx. Daily Views
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="number"
                  name="approx_daily_views"
                  id="approx_daily_views"
                  autoComplete="approx_daily_views"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('approx_daily_views')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Date Resolved<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="date"
                  name="date_resolved"
                  id="date_resolved"
                  autoComplete="date_resolved"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('date_resolved')}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Resolutions
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <textarea
                  type="text"
                  name="resolutions"
                  id="resolutions"
                  autoComplete="resolutions"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('resolutions')}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <textarea
                  type="text"
                  name="notes"
                  id="notes"
                  autoComplete="notes"
                  className="flex-1 focus:ring-red-500 focus:border-red-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                  {...register('notes')}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Any additional information for this conflict. (Ex: SME removed)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </>)
}

export default ConflictForm
