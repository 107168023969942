import React from 'react'

const RecordingTable = ({ recordings }) => {
  return (<>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Artists
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ISRC
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Release Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    UPC
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {recordings.map((item, recordingIdx) => (
                  <tr key={item.external_id} className={recordingIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-3 py-4 text-sm font-medium">
                      <a className="hover:text-blue-500" href={'/recordings/' + item.external_id + '/'}>{item.title}</a>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.related_artists && 
                        (<span>
                          {item.related_artists.map((artist, idx) => {
                            return (
                              <span>
                                {item.related_artists[idx + 1]
                                  ? (<a className="hover:text-blue-500" href={'/artists/' + artist.external_id}>{artist.name}<br /></a>)
                                  : (<a className="hover:text-blue-500" href={'/artists/' + artist.external_id}>{artist.name}</a>)
                                }
                              </span>
                            )
                          })}
                        </span>)
                      }
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.isrcs}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.release_date}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.upc_list &&
                        (<span>
                          {item.upc_list.map((upc, idx) => {
                            return (
                              <span>
                                {item.upc_list[idx + 1]
                                  ? (<span>{upc}<br /></span>)
                                  : (<span>{upc}</span>)
                                }
                              </span>
                            )
                          })}
                        </span>)
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RecordingTable