import React from "react"
import { Switch, Route, Redirect } from "react-router"
import BaseContainer from "containers/BaseContainer"
import Dashboard from "views/Dashboard/index"
import Composition from "views/Composition/index"
import Writer from "views/Writer/index"
import Publisher from "views/Publisher/index"
import Recording from "views/Recording/index"
import RecordLabel from "views/RecordLabel/index"
import Duplicate from "views/Duplicate/index"
import Share from "views/Share/index"
import Artist from "views/Artist/index"
import Conflict from "views/Conflict/index"
import ConflictResolution from "views/ConflictResolution/index"
import Search from "views/Search/index"
import Upload from "views/Upload/index"
import AssetReport from "views/AssetReport/index"
import Download from "views/Download/index"
import Billboard from "views/Billboard/index"
import Claiming from "views/Claiming/index"
import Augmentor from "views/Augmentor/index"
import User from "views/User/index"
import Delete from "views/Delete/index"
import References from "views/References/index"
import MLC from "views/MLC/index"
import CreateAssets from "views/CreateAssets"
import Error from "views/Error"
import Tools from "views/Tools"
import RoyaltyValidatorView from "views/RoyaltyValidator"
import DDEX from "views/DDEX"

function ProtectedRoutes() {
  return (
    <BaseContainer>
      <Switch>
        {/* <Route exact path="/account/verify" component={EmailConfirmation} />
      <Route exact path="/profile/edit" component={ProfileEdit} /> */}
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/create-assets" component={CreateAssets} />
        <Route exact path="/tools" component={Tools} />
        <Route path="/compositions" component={Composition} />
        <Route path="/record-labels" component={RecordLabel} />
        <Route path="/artists" component={Artist} />
        <Route path="/writers" component={Writer} />
        <Route path="/publishers" component={Publisher} />
        <Route path="/recordings" component={Recording} />
        <Route path="/shares" component={Share} />
        <Route path="/duplicates" component={Duplicate} />
        <Route path="/upload" component={Upload} />
        <Route path="/download" component={Download} />
        <Route path="/claiming" component={Claiming} />
        <Route path="/users" component={User} />
        <Route path="/errors" component={Error} />
        <Route path="/delete" component={Delete} />
        <Route path="/conflict-resolution" component={ConflictResolution} />
        <Route path="/augmentor" component={Augmentor} />
        <Route path="/ddex" component={DDEX} />
        <Route exact path="/asset-report" component={AssetReport} />
        <Route exact path="/references" component={References} />
        <Route exact path="/conflicts" component={Conflict} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/billboard-charts" component={Billboard} />
        <Route exact path="/mlc-search" component={MLC} />
        <Route exact path="/royalty-validator" component={RoyaltyValidatorView} />
        <Redirect push to="/dashboard" />
      </Switch>
    </BaseContainer>
  )
}

export default ProtectedRoutes
