import React from 'react'
import { useLocalStore } from 'mobx-react'

import {
  refresh,
  userDetail,
  userUpdate,
  userChangePhoto,
  login as loginApi,
  changePassword as changePasswordApi
} from 'api/auth'

export const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    loading: false,
    loggedIn: false,
    user: {},
    scope: null,

    hasToken: () => {
      return localStorage.getItem('accessToken')
    },

    isAuthenticated: () => {
      return store.loggedIn
    },

    isAdmin: () => {
      return store.user.scope === 'admin'
    },

    setLoading: (loading) => {
      store.loading = loading
    },

    setLoggedIn: (loggedIn) => {
      store.loggedIn = loggedIn
    },

    setUser: (user) => {
      store.user = user
    },
    reset: () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('scope')

      store.user = {}
      store.scope = null
      store.loggedIn = false
      store.loading = false
    },

    logout: () => store.reset(),

    fetchUser: () => {
      store.loading = true
      return userDetail().then((res) => {
        store.user = res.data?.user
        store.loggedIn = true
        store.loading = false
        return res.data
      }).catch((err) => {
        if (err.response.status === 401) {
          refresh().then((res) => {
            localStorage.removeItem('accessToken')
            localStorage.setItem('accessToken', res.data.access)
            store.user = res.data.user
            store.loggedIn = true
            store.loading = false
            return
          }).catch(() => {
            store.reset()
          })
        } else{
          store.reset()
          throw err
        }
      })
    },

    oauthLogin: (payload) => {
      localStorage.setItem('accessToken', payload.token)
      store.loggedIn = true
      store.user = payload.user
      store.scope = payload.scope
      localStorage.setItem('scope', payload.scope)
      return store.fetchUser()
    },
  }))

  return <AuthContext.Provider value={store}>
    {children}
  </AuthContext.Provider>
}

export default AuthProvider
