import React from "react"
import { Link } from "react-router-dom"
import {
  PresentationChartBarIcon,
  DocumentSearchIcon,
  TableIcon,
  CloudUploadIcon
} from "@heroicons/react/outline"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const ToolsView = () => {
  const actions = [
    {
      icon: TableIcon,
      name: "Augmentor",
      href: "/augmentor/",
      prompt: "A tool to augment multiple documents in one process.",
    },
    {
      icon: PresentationChartBarIcon,
      name: "Billboard Charts",
      href: "/billboard-charts/",
      prompt:
        "A tool that utilizes the Billboard Charts API to fetch the latest hot 100.",
    },
    {
      icon: DocumentSearchIcon,
      name: "MLC Search",
      href: "/mlc-search/",
      prompt: "A tool that utilizes the MLC API to perform an asset search.",
    },
    {
      icon: CloudUploadIcon,
      name: "DDEX Uploader",
      href: "/ddex/",
      prompt: "A tool used to upload submissions to the YouTube CMS in DDEX format.",
    },
    // {
    //   icon: DocumentSearchIcon,
    //   name: "Royalty Validator",
    //   href: "/royalty-validator/",
    //   prompt: "A tool that validates a royalty report",
    // },
  ]

  return (
    <>
      <div className="min-h-full">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-8">
          <div className="mb-10 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
                Explore our Exploration Tools
              </h2>
              <p className="text-gray-400 text-center">
                Find a tool to optimize your task below
              </p>
            </div>
          </div>
          {/* Main 3 column grid */}
          <section
            className="mt-5 rounded-md shadow"
            aria-labelledby="quick-links-title"
          >
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              {actions.map((action, actionIdx) => (
                <div
                  key={actionIdx}
                  className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 text-center"
                >
                  <div>
                    <span className="text-red-700 bg-red-50 rounded-lg inline-flex p-3 ring-4 ring-white">
                      <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-8">
                    <dt className="text-lg font-medium">{action.name}</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      {action.prompt}
                    </dd>
                    <a
                      href={action.href}
                      className="text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300 mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              ))}
            </dl>
          </section>
        </div>
      </div>
    </>
  )
}

export default ToolsView
