import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const reportTypes = {
  'subpublishing': 'subpublishing',
  'mlc': 'mlc',
  'bmi': 'bmi',
  'music reports': 'mri'
}

const FileInput = ({ className, file, setFile, updateReportType }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
    const fileName = acceptedFiles[0].name.toLowerCase()
    const matchedType = Object.keys(reportTypes).find(keyword => fileName.includes(keyword))
    if (matchedType) {
      updateReportType(reportTypes[matchedType])
    }
  }, [])

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    accept: '.csv'})

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <span key={file.path}>
      {file.path} - {file.size} bytes&nbsp;
      <>
        {errors.map(e => (
          <span key={e.code}>({e.message})</span>
        ))}
      </>
    </span>
  ))

  return (
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div className="space-y-1 text-center" {...getRootProps({className: className})}>
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {file === null
          ? (<>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
              >
                <span>Click here to upload a file</span>
                <input id="file-upload" name="file-upload" type="file" className="sr-only" {...getInputProps()} />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500 text-center">accepting CSV format</p>
          </>)
          : file === undefined
          ? (<>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
              >
                <span>File Rejected: </span>
              </label>
              <p className="pl-1">{fileRejectionItems}</p>
            </div>
          </>)
          : (<div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
            >
              <span>{file.name}</span>
            </label>
            <p className="pl-1">{formatBytes(file.size)}</p>
          </div>)
        }
      </div>
    </div>
  )
}

export default FileInput
