import PrivateAPI from './private'

export const getData = () => {
  return PrivateAPI.get('api/importexport/export/')
}

export const getList = (page, rownum, filter) => {
  var params = {}
  if (filter !== undefined && filter !== '') {
    params['_filter'] = filter
  }
  return PrivateAPI.get('api/importexport/export/list/' + page + '/' + rownum + '/', params)
}

export const getFile = (typer) => {
  var payload = {}
  payload['typer'] = typer

  return PrivateAPI.post('api/importexport/export/', payload)
}

export const getFilteredExportFile = (payload) => {
  return PrivateAPI.post('api/importexport/export/filtered/', payload)
}

export const getRecordingsFile = (typer) => {
  var payload = {}
  payload['typer'] = typer

  return PrivateAPI.post('api/importexport/export/recordings/', payload)
}

export const getDistributionFile = () => {
  return PrivateAPI.get('api/importexport/export/recordings/distribution/')
}

export const getSelectedRecordingsFile = (payload) => {
  return PrivateAPI.post('api/importexport/export/selected/recordings/', payload)
}

export const getWritersFile = (typer) => {
  var payload = {}
  payload['typer'] = typer

  return PrivateAPI.post('api/importexport/export/writers/', payload)
}

export const getPublishersFile = () => {
  return PrivateAPI.post('api/importexport/export/publishers/')
}

export const getPublishingFile = () => {
  return PrivateAPI.post('api/importexport/export/publishing/')
}

export const writerExportOwnerships = (data) => {
  return PrivateAPI.post('api/importexport/export/writer/ownership/', data)
}

export const writerExportShares = (data) => {
  return PrivateAPI.post('api/importexport/export/writer/share/', data)
}

export const writerExportAssets = (data) => {
  return PrivateAPI.post('api/importexport/export/writer/assets/', data)
}

export const publisherExportWriter = (data) => {
  return PrivateAPI.post('api/importexport/export/publisher/writer/', data)
}

export const publisherExportAssets = (data) => {
  return PrivateAPI.post('api/importexport/export/publisher/assets/', data)
}

export const artistExportRecording = (data) => {
  return PrivateAPI.post('api/importexport/export/artist/recording/', data)
}

export const recordLabelExportRecording = (data) => {
  return PrivateAPI.post('api/importexport/export/record-label/recording/', data)
}

export const getSelectedCompositionsFile = (selected) => {
  return PrivateAPI.post('api/importexport/export/selected/compositions/', selected)
}

export const getSelectedSharesFile = (selected) => {
  return PrivateAPI.post('api/importexport/export/selected/shares/', selected)
}

export const getSelectedWritersFile = (selected) => {
  return PrivateAPI.post('api/importexport/export/selected/writers/', selected)
}

export const getSelectedPublishersFile = (selected) => {
  return PrivateAPI.post('api/importexport/export/selected/publishers/', selected)
}

export const exportImport = (pk, payload) => {
  return PrivateAPI.post('api/importexport/' + pk + '/export/', payload)
}

export const exportConflicts = () => {
  return PrivateAPI.get('api/importexport/export/conflicts/')
}

export const exportDuplicates = () => {
  return PrivateAPI.get('api/importexport/export/duplicates/')
}

export const exportClaims = (payload) => {
  return PrivateAPI.post('api/importexport/export/claims/', payload)
}

export const uploadAssetReport = (payload) => {
  return PrivateAPI.postfile('api/importexport/export/portal/upload/', payload)
}

export const getAssetReportList = (page, rownum, term, startDate, endDate) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  if (startDate !== undefined && startDate !== null) {
    params['start_date'] = startDate
  }

  if (endDate !== undefined && endDate !== null) {
    params['end_date'] = endDate
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/importexport/export/portal/upload/', params)
}

export const deleteAssetReport = (pk) => {
  return PrivateAPI.delete('api/importexport/export/portal/upload/' + pk + '/')
}
