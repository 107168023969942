import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import StatsContainer from './components/StatsContainer'

import { getStats as getStatsApi } from 'api/claim'
import { fetch } from 'api/users'
import { AuthContext } from 'stores/auth'

const StatsView = () => {
  const [stats, setStats] = useState()
  const [staff, setStaff] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const authStore = useContext(AuthContext)
  const { id } = useParams()

  let user = authStore.user
  let scope  = user?.profile?.scope || authStore.scope

  const getStats = () => {
    getStatsApi(id, month, year).then(res => {
      if (res.status === 200) {
        setStats(res.data)
      }
    })
  }

  useEffect(() => {
    getStats()
    if (id) {
      fetch(id).then(res => {
        if (res.status === 200) {
          setStaff(res.data)
        }
      }).catch(err => {
        console.log(err.response)
      })
    } else {
      if (scope.indexOf('exp-claimad') < 0) {
        setStaff(user)
      }
    }
  }, [month, year])

  if (!stats || !scope || (id && !staff)) {
    return null
  }

  return (<>
    <StatsContainer
      id={id}
      propScope={scope}
      stats={stats}
      staff={staff}
      month={month}
      setMonth={setMonth}
      year={year}
      setYear={setYear}
    />
  </>)
}

export default StatsView
