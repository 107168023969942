import React from 'react'
import Inline from "./Inline"

const codeLabelList  = [
  {
    key: 'iswc',
    label: 'ISWC'
  },
  {
    key: 'language_code',
    label: 'Language Code'
  },
  {
    key: 'ascap_work_id',
    label: 'ASCAP Work ID'
  },
  {
    key: 'bmi_work_id',
    label: 'BMI Work ID'
  },
  {
    key: 'sesac_work_id',
    label: 'SESAC Work ID'
  },
  {
    key: 'cmrra_work_id',
    label: 'CMRRA Work ID'
  },
  {
    key: 'hfa_code',
    label: 'HFA/MLC Code'
  },
  {
    key: 'bmg_code',
    label: 'BMG Code'
  },
  {
    key: 'related_asset_ids',
    label: 'Related Asset ID'
  },
  {
    key: 'custom_id',
    label: 'Custom ID'
  },
  {
    key: 'client_song_id',
    label: "Client's Song ID"
  },
  {
    key: 'royalty_code',
    label: "Client Code"
  },
  ,
]

const Codes = ({ codes, showForm }) => {
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mx-2 my-2">
        <div className="px-4 py-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Related Codes</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Click on the code to copy to clipboard.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {codeLabelList.map((label, idx) => (
              <Inline key={idx} idx={idx} label={label} codes={codes} showForm={showForm} />
            ))}
          </dl>
        </div>
      </div>
    </>
  )
}

export default Codes