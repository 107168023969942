import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import toast from 'react-hot-toast'

import RecordingForm from './components/RecordingForm'
import { transformDuplicate } from '../../utils'

import { fetchE } from 'api/recordings'
import { fetch as fetchComposition } from 'api/composition'

const FormView = () => {
  const { id } = useParams()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [record, setRecord] = useState()
  const [composition, setComposition] = useState()
  const [initialValues, setInitialValues] = useState()

  const _duplicating = () => {
    return id !== undefined
  }

  useEffect(() => {
    if (query.composition) {
      fetchComposition(query.composition).then(res => {
        if (res.status === 200) {
          setComposition(res.data)
          let values = {
            'releases': [
              {
                'album_title': '',
                'release_date': '',
                'upc': ''
              }
            ]
          }
          values['composition_field'] = res.data._title
          values['title'] = res.data._title
          values['composition'] = res.data.pk
          setInitialValues(values)
        } else {
          toast.error('Unable to fetch composition')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch composition')
      })
    }

    if (_duplicating()) {
      if (record.isEmpty() || record.pk !== id) {
        fetchE(id).then(res => {
          if (res.status === 200) {
            setRecord(res.data)
            let values = transformDuplicate(record)
            values['composition_field'] = composition._title
            values['title'] = composition._title
            values['composition'] = composition.pk
            setInitialValues(values)
          } else {
            toast.error('Unable to fetch recording')
          }
        }).catch(err=>{
          console.log(err.response)
          toast.error('Unable to fetch recording')
        })
      }
    }
  }, [])

  if (query.composition) {
    if (!initialValues) {
      return null
    }
  }

  return (
    <>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          <RecordingForm record={record} initialValues={initialValues} />
        </div>
      </div>
    </>
  )
}

export default FormView
