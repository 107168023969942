/* This example requires Tailwind CSS v2.0+ */
import React from 'react'

import PotentialClaimCard from './PotentialClaimCard'

export default function PotentialClaims ({ item }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const categories = [
        { name: 'Claimed', bgColor: 'bg-red-600', total: item?.overall_potential_totals?.claimed },
        { name: 'Released', bgColor: 'bg-blue-600', total: item?.overall_potential_totals?.released },
        { name: 'MisEmbed', bgColor: 'bg-green-600', total: item?.overall_potential_totals?.misembed },
        { name: 'Add Share', bgColor: 'bg-indigo-600', total: item?.overall_potential_totals?.add_share },
        { name: 'Duplicate', bgColor: 'bg-yellow-600', total: item?.overall_potential_totals?.duplicate }
    ]
    return (
        <>
            <h2 className='font-medium'>Totals</h2>
            <ul role="list" className="mt-3 mb-10 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
            {categories.map((category) => (
                <li key={category.name} className="col-span-1 flex shadow-sm rounded-md">
                <div
                    className={classNames(
                        category.bgColor,
                    'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                    )}
                >
                    {category.total}
                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm">
                    {category.name}
                    </div>
                </div>
                </li>
            ))}
            </ul>
            {item.related_potential_claims && item.related_potential_claims.map((claim, index) => (
                <div className='py-5'>
                    <PotentialClaimCard key={claim.pk} item={claim} />
                </div>))
            }
        </>
            
    )
}
