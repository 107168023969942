import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'

import InfoBar from 'components/InfoBar'
import Select from 'components/Select'
import FileInput from './FileInput'
import { transformData } from 'views/Upload/utils'

import { onSubmit } from 'api/claim'

const UploadForm = ({ datafeed }) => {
  const [saving, setSaving] = useState(false)
  const [saveFile, setSaveFile] = useState()
  const history = useHistory()

  let initialValues = {}

  useEffect(() => {
    if (datafeed) {
      let datafeedCopy = Object.assign({}, datafeed)
      initialValues = transformData(datafeedCopy)
    }
    reset(initialValues)
  }, [datafeed])

  const { register, handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const submitForm = (formProps) => {
    setSaving(true)
    let formData = Object.assign({}, formProps)

    formData['file'] = saveFile
    onSubmit(formData).then(res => {
      if (res.status === 200) {
        history.push('/upload/' + res.data.pk)
      } else {
        toast.error('An error occured in processing your file.')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('An error occured in processing your file.')
    })
    setSaving(false)
  }

  const cleanForm = () => {
    reset({
      'submission_date': null,
      'notes': ''
    })
  }

  const typeOptions = [
    { value: 'cmrra', label: 'CMRRA' },
    { value: 'mri', label: 'MRI' },
    { value: 'mlc', label: 'MLC' },
    { value: 'soundexchange', label: 'SoundExchange' },
    { value: 'youtube', label: 'YouTube' }
  ]

  return (<>
    {datafeed && <InfoBar
      text={`Editing information for ingest file: ${datafeed.filename}`}
      onClose={cleanForm}
      />}
    <Form className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6" onSubmit={handleSubmit(submitForm)}>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <Controller
                control={control}
                name={`file`}
                render={() => (
                  <FileInput saveFile={setSaveFile} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Claim Information</h3>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                Date of Submission
              </label>
              <div className="mt-1">
                <input
                  type='date'
                  name='submission_date'
                  {...register('submission_date', { required: true })}
                  className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <p className="text-sm text-gray-500">Additional information regarding this claim submission.</p>
              <div className="mt-1">
                <textarea
                  id="notes"
                  name="notes"
                  rows={3}
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                  {...register('notes')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => cleanForm()}
            disabled={saving}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </Form>
  </>)
}

export default (UploadForm)