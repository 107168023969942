import React, { useRef } from 'react';
import { AdjustmentsIcon, SearchIcon } from '@heroicons/react/outline';

function ListSearch ({ filterList, placeholder, toggleAdvanced }) {
  const search = useRef(null)

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      filterList(search.current.value)
    }
  }

  return (
    <div className="flex">
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="query"
            ref={search}
            className="focus:ring-red-500 focus:border-red-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
            placeholder={placeholder || 'Search'}
            onKeyUp={(evt) => handleKeyUp(evt)}
          />
        </div>
        {toggleAdvanced && <button className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
            onClick={() => toggleAdvanced()}
          >
            <AdjustmentsIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        }
        <button className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-transparent rounded-r-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none"
          onClick={() => filterList(search.current.value)}
        >
          <span>Search</span>
        </button>
      </div>
    </div>
  )
}

export default ListSearch
