import React from 'react'
import { Route, Switch } from 'react-router'
import List from './routes/List/index'
import Detail from './routes/Detail/index'
import Profile from './routes/Profile/index'

export default function UserViews () {
  return (
    <Switch>
      <Route exact path='/users' component={List} />
      <Route exact path='/users/profile' component={Profile} />
      <Route path='/users/:id' component={Detail} />
    </Switch>
  )
}
