import React from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import { DownloadIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { del } from 'api/composition'
import { ALLOW_DELETE_ACCESS } from 'helper/const'

var moment = require('moment')

const TitleBox = ({ id, record, _toggleTitleEdit, setExport, ownership, email, scope }) => {
  let history = useHistory()

  const deleteComposition = () => {
    del(id).then(res => {
      toast.success('Composition deleted successfully!')
      history.push('/dashboard/')
    }).catch(err => {
      console.log(err.response)
      toast.error('Unable to delete composition')
    })
  }

  return (<>
    <div className="mb-5 rounded-lg bg-white overflow-hidden shadow">
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl mb-3">
                  {ownership > 100 ? (
                    <span className="text-red-600">{record._title}</span>
                    ) : (
                      <span>{record._title}</span>
                    )
                  }
                  {record.is_arrangement && (<span className="ml-1 text-sm text-gray-500 font-medium">(Arrangement)</span>)}
                </h2>
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {record.external_id}
                </p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <button
                type='button'
                onClick={() => { _toggleTitleEdit('compTitle') }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                Edit
                <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              <button className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                type='button'
                onClick={() => setExport()}>
                Export
                <DownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
              <button
                type='button'
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this composition?')) {
                    deleteComposition()
                  }
                }}
                className='ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'
                disabled={(ALLOW_DELETE_ACCESS.indexOf(email) === -1 && scope?.indexOf('exp-delete') === -1)}
                id={record.composition}>
                <TrashIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-white grid grid-cols-3 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          <div key='alternate-titles' className="px-6 py-5 text-sm text-center">
            <span className="text-gray-600">Alternate Titles:</span>&nbsp;
            <span className="text-gray-900 font-medium">
              {record.alternate_titles && record.alternate_titles.length !== 0 &&
                (<span>&nbsp;
                  {record.alternate_titles.map((alt, index) => index !== 0 ? `, ${alt}` : alt)}
                &nbsp;</span>)
              }
            </span>
          </div>
          <div key='sync' className="px-6 py-5 text-sm text-center">
            {ownership > 100 ? (
              <div>
                <span className="text-gray-600">
                  Total Synchronization Ownership in the United States is&nbsp;
                </span>
                <span className="text-red-600 font-medium">
                  {ownership.toString().split('.')[1] !== undefined &&
                      ownership.toString().split('.')[1].length > 4
                    ? (parseFloat(ownership).toFixed(2))
                    : ownership
                  }%
                </span>
              </div>
              ) : (
                <div>
                  <span className="text-gray-600">
                    Total Synchronization Ownership in the United States is&nbsp;
                  </span>
                  <span className="text-gray-900 font-medium">
                    {ownership.toString().split('.')[1] !== undefined &&
                        ownership.toString().split('.')[1].length > 4
                      ? (parseFloat(ownership).toFixed(2))
                      : ownership
                    }%
                  </span>
              </div>
              )
            }
          </div>
          <div key='modified' className="px-6 py-5 text-sm text-center">
            {record.modified_email === 'Unknown'
              ? (<div>
                <span className="text-gray-600">
                  Last edited at&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
              : (<div>
                <span className="text-gray-600">
                  Last edited by&nbsp;
                </span>
                <span className="text-gray-900 font-medium">
                  {record.modified_email} at {moment(record.modified).local().format('MM-DD-YYYY hh:mm:ss A')}
                </span>
              </div>)
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TitleBox
