import React from 'react';
import { Route, Switch } from 'react-router';
import List from './routes/List/index';
import Detail from './routes/Detail/index';
import Form from './routes/Form/index';
import Upload from './routes/Upload/index';

export default function ConflictResolutionViews () {
  return (
    <Switch>
      <Route exact path='/conflict-resolution/' component={Upload} />
      <Route exact path='/conflict-resolution/list' component={List} />
      <Route exact path='/conflict-resolution/add' component={Form} />
      <Route path='/conflict-resolution/:id' component={Detail} />
    </Switch>
  )
}