import React from 'react';
import { Route, Switch } from 'react-router';
import List from './routes/List/index';
import Detail from './routes/Detail/index';

export default function CompositionViews () {
  return (
    <Switch>
      <Route exact path='/compositions' component={List} />
      <Route path='/compositions/:id' component={Detail} />
    </Switch>
  )
}