import React from 'react'
import RecordLabelForm from './components/RecordLabelForm'

const FormView = () => {
  return (
    <>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          <RecordLabelForm />
        </div>
      </div>
    </>
  )
}

export default FormView
