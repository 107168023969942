import React from 'react'
import WriterForm from './components/WriterForm'

const FormView = () => {
  return (
    <>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          <WriterForm />
        </div>
      </div>
    </>
  )
}

export default FormView
