import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionFeed from 'components/ActionFeed'
import NoteBox from 'components/NoteBox'
import { edit } from 'api/recordLabel'

const EditLabelForm = ({ initialValues, id, record, setRecord, showEditForm, setShowEditForm  }) => {

    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
        defaultValues: initialValues
    })
    
    const onSubmit = (values) => {
        edit(values, id).then(res => {
          if (res.status === 200) {
            toast.success('Record label updated successfully!')
            setRecord(res.data)
            setShowEditForm(false)
          } else {
            toast.error(res.data.error)
          }
        }).catch(err=>{
          console.log(err.response)
          toast.error('Unable to update record label')
        })
    }

  return (<>
    <div className='mb-5 px-4 py-4 table-description'>
      <div className='flex'>
        <div className='flex-1'>
          <div className="mb-5">
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white shadow sm:rounded-lg mx-2 my-2">
                <div className="px-4 py-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Record Label Information</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Related codes and other information.</p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Client Code</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="royalty_code"
                          id="royalty_code"
                          autoComplete="royalty_code"
                          className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          {...register('royalty_code')}
                        />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>    
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => { setShowEditForm(!showEditForm) }}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={errors}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <br />
          <>
            <div className="px-4 py-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Activities</h3>
            </div>
            <ActionFeed
              model={'record_label'}
              pk={record.external_id} />
          </>
        </div>
        <div className='flex-1'>
          <div className="px-4 py-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
          </div>
          <NoteBox model={'record_label'} pk={record.external_id} />
        </div>
      </div>
    </div>
  </>)
}

export default EditLabelForm
