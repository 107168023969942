import React, { useState, useEffect } from 'react'
import ListTable from 'components/ListTable'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { getList } from 'api/artist'

const ArtistListTable = ({ propList, propPages, searchQ, assignArtist, relatedArtists }) => {
  const location = useLocation()
  let query = queryString.parse(location.search)

  const [currPage, setCurrPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const [list, setList] = useState([])
  const [totalPages, setTotalPages] = useState()

  const _listParams = (page, sQ, sort) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let qS = { page: page }

    if (sQ !== undefined && sQ !== '') {
      query['q'] = sQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        qS['sort'] = _updateSortParams(sort).join()
        window.sortParam = qS['sort']
      } else {
        qS['sort'] = sort
        window.sortParam = qS['sort']
      }
    }
    if (window.sortParam !== undefined) {
      qS['sort'] = window.sortParam
    }
    return qS
  }

  useEffect(() => {
    setLoading(true)
    if (propList !== undefined && propList.length > 0) {
      setList(propList)
      setTotalPages(propPages)
    } else {
      getList(_listParams(currPage, searchQ)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
    }
  }, [propList])

  const fetchData = () => {
    if (!loading && list === undefined) {
      setLoading(true)
      getList(this._listParams(currPage, searchQ)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
    }

    if (list !== undefined) {
      setLoading(false)
    }
  }

  const onPageChange = (evt) => {
    setCurrPage(evt.target.value)

    if (evt.target.value !== '' && parseInt(evt.target.value) !== currPage) {
      getList(_listParams(evt.target.value, searchQ)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
    }
  }

  const onSortedChange = (sort) => {
    setLoading(true)
    let newSort = sort[0]['id']
    getList(_listParams(currPage, searchQ, newSort)).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
    })
  }

  const _onNext = (page) => {
    setLoading(true)
    getList(_listParams(page + 1, searchQ)).then(res => {
      setList(res.data.results)
      setTotalPages(res.data.total_pages)
    })
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(_listParams(page - 1, searchQ)).then(res => {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
      })
      setCurrPage(page - 1)
    }
  }

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const renderArrow = (columnId, sort) => {
    if (sort === columnId) {
      return '▲'
    } else if (sort === '-' + columnId) {
      return '▼'
    }
    return ''
  }

  const hasNext = currPage < totalPages

  const artistColumns = [
    {
      Header: 'ID',
      id: 'id',
      sortable: false,
      minWidth: 50,
      accessor: item => item['pk']
    }, {
      Header: 'Name',
      id: 'name',
      sortable: false,
      maxWidth: 350,
      Cell: ({ row }) => (
        <div>{row.original.name}</div>
      )
    }, {
      Header: 'Record Label',
      id: 'record_Label_affiliation',
      sortable: false,
      maxWidth: 150,
      Cell: ({ row }) => (
        <div>
          {row.original.record_label_affiliation
            ? <span>
              {row.original.record_label_affiliation.name}
            </span>
            : null
          }
        </div>
      )
    }, {
      id: 'selected',
      maxWidth: 150,
      className: 'select',
      Cell: ({ row }) => (<div>
        {relatedArtists && relatedArtists.filter(e => e.pk === row.original.pk).length > 0
          ? (<div style={{ textAlign:'left' }}>
            <button className='py-2 px-4 btn btn-primary btn-sm' data-toggle='button'
              type='button' aria-pressed='true'
              onClick={() => assignArtist(row.original)}>Selected</button>
          </div>)
          : (<div style={{ textAlign:'left' }}>
            <button className='py-2 px-4 btn btn-blue-gray btn-sm' data-toggle='button'
              type='button' aria-pressed='false'
              onClick={() => assignArtist(row.original)}>Select</button>
          </div>)
        }
      </div>
      ),
      sortable: false
    }
  ]

  return (
    <div className='list-table'>
      <ListTable
        columns={artistColumns}
        data={list}
        getNext={_onNext}
        getPrev={_onPrevious}
        currPage={currPage}
        setCurrPage={setCurrPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination={searchQ !== ''}
      />
    </div>
  )
}

export default ArtistListTable
