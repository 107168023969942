import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import SelectField from 'layouts/components/Select'

const ClaimDetailForm = ({ item }) => {
    const { register, control, setValue } = useFormContext()

    const platformOptions = [
        { value: 'youtube', label: 'YouTube' },
        { value: 'soundexchange', label: 'SoundExchange' },
        { value: 'mri', label: 'MRI' },
        { value: 'mlc', label: 'MLC' },
        { value: 'cmrra', label: 'CMRRA' }
    ]

    return (<dl className="grid grid-cols-1 rounded-lg bg-white overflow-hidden divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">Platform</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="mt-1 min-w-full">
                    <Controller
                        control={control}
                        name='platform'
                        render={() => (
                            <SelectField
                                options={platformOptions}
                                defaultValue={platformOptions.find(platform => platform.value === item.platform)}
                                onChange={(e) => {
                                    setValue('platform', e.value)
                                }}
                            />
                        )}
                    />
                </div>
            </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">Song Code</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="mt-1 min-w-full">
                    <input
                        type="text"
                        name="song_code"
                        id="song_code"
                        autoComplete="song_code"
                        className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        {...register('song_code')}
                    />
                </div>
            </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">Total</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-red-600">
                    {item.overall_total}
                </div>
            </dd>
        </div>
    </dl>)
}

export default ClaimDetailForm