import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import AssignToLabel from 'layouts/components/LabelModalSelect/AssignToLabel'
import LabelSelectModal from 'layouts/components/LabelModalSelect/LabelSelectModal'

import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { create } from 'api/artist'

const ArtistForm = () => {
  const history = useHistory()
  const [selectLabelModal, setSelectLabelModal] = useState(false)
  const [selectedLabelField, setSelectedLabelField] = useState('')

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange'
  })

  const assignLabel = (label) => {
    if (label) {
      if (label['new']) {
        setValue('record_label_affiliation', label)
        setSelectedLabelField(label['name'])
      } else {
        setValue('record_label_affiliation', label.pk)
        setSelectedLabelField(label.name)
      }
    } else {
      setValue('record_label_affiliation', null)
      setSelectedLabelField(' ')
    }
    setSelectLabelModal(false)
  }

  const toggleSelectLabelModal = () => {
    setSelectLabelModal(!selectLabelModal)
  }

  const formatData = (values) => {
    let data = { ...values }
    create(data).then(res => {
      if (res.status === 201) {
        history.push('/artists/' + res.data.external_id + '/')
        toast.success('Successfully created artist!')
      } else {
        toast.error('Unable to create artist')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to create artist')
    })
  }

  return (<>
    <LabelSelectModal
      status={selectLabelModal}
      toggleModal={toggleSelectLabelModal}
      assignLabel={assignLabel}
    />
    <form className="space-y-8 divide-y divide-gray-200 bg-white p-6 rounded-md" onSubmit={handleSubmit(formatData)} id='newArtist'>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Basic information about the Artist. (* required)
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First Name
                </label>
                <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      {...register('first_name')}
                    />
                </div>
                </div>

            <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last Name<sup className='required-icon'>*</sup>
                </label>
                <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      {...register('last_name', { required: true })}
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="record-label-affiliation" className="block text-sm font-medium text-gray-700">
                Record Label Affiliation
              </label>
              <div className="mt-1">
                <AssignToLabel
                  className='form-control'
                  value={selectedLabelField}
                  toggleSelectLabelModal={toggleSelectLabelModal}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name='note'
                  render={(props) => (
                    <ReactQuill
                    {...props}
                    theme='snow'
                    placeholder='Add a note...'
                    onChange={(e) => {
                        setValue('note', e)
                    }}
                    />
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Any additional information for the record label. (Ex: Agreement with Only Solutions Entertainment.)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </>)
}

export default ArtistForm
