import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import ArtistSelectModal from 'layouts/components/ArtistModalSelect/ArtistSelect'
import { AssignToArtist } from 'layouts/components/ArtistModalSelect/AssignToArtist'
import LabelSelectModal from 'layouts/components/LabelModalSelect/LabelSelectModal'
import { AssignToLabel } from 'layouts/components/LabelModalSelect/AssignToLabel'

import SelectToggle from 'layouts/components/SelectToggle.js'
import { territoryOptions } from 'helper/territories'
import SelectField from 'layouts/components/Select.js'

const ArtistForm = ({ initialValues, toggleTitleEdit }) => {
  const { register, errors, watch, setValue, control } = useFormContext()

  const [selectedArtists, setSelectedArtists] = useState(initialValues.related_artists || [])
  const [selectedArtistField, setSelectedArtistField] = useState(initialValues.artist_field || '')
  const [selectArtistModal, setSelectArtistModal] = useState()
  const [selectLabelModal, setSelectLabelModal] = useState(false)
  const [selectedLabelField, setSelectedLabelField] = useState(initialValues.label_field || '')

  const territory = watch('territory')
  const territoryRestriction = watch('territory_restriction')
  const relatedArtists = watch('related_artists')

  const assignArtist = (artist) => {
    let artists = selectedArtists
    let artistField = selectedArtistField

    if (artists && artists.includes(artist)) {
      artists.splice(artists.indexOf(artist), 1)

      let artistString = ', ' + artist.name

      if (artistField.includes(artistString)) {
        artistField = artistField.replace(artistString, '')
      } else {
        artistField = artistField.replace(artist.name + ', ', '')
      }
    } else {
      artists.push(artist)

      if (artistField.length > 0) {
        artistField = artistField + ', ' + artist.name
      } else {
        artistField = artist.name
      }
    }

    setValue(`artist_field`, artistField)
    setValue(`related_artists`, artists)

    setSelectedArtistField(artistField)
    setSelectedArtists(artists)
  }

  const toggleSelectArtistModal = () => {
    setSelectArtistModal(!selectArtistModal)
  }

  const assignLabel = (label) => {
    if (label) {
      setValue('new_record_label', label.pk)
      setSelectedLabelField(label.name)
    } else {
      setValue('new_record_label', null)
      setSelectedLabelField(' ')
    }
    setSelectLabelModal(false)
  }
  
  const toggleSelectLabelModal = () => {
    setSelectLabelModal(!selectLabelModal)
  }

  let includeSelect = [
    { value: 'include', label: 'Include' },
    { value: 'exclude', label: 'Exclude' }
  ]

  return (<div className='space-y-8 divide-y divide-gray-200 py-3 px-6 mb-5 bg-white rounded-md shadow'>
    <ArtistSelectModal
      status={selectArtistModal}
      toggleModal={toggleSelectArtistModal}
      assignArtist={assignArtist}
      relatedArtists={relatedArtists}
    />
    <LabelSelectModal
      status={selectLabelModal}
      toggleModal={toggleSelectLabelModal}
      assignLabel={assignLabel}
    />
    <div>
      <div className='box'>
        <div className='box-body'>
          <div className='data-cards'>
            <div className='_card'>
              <label htmlFor="artist" className="block text-sm font-medium text-gray-700">
                Artists
              </label>
              <AssignToArtist
                className='form-control'
                value={selectedArtistField}
                toggleSelectArtistModal={toggleSelectArtistModal}
              />
              {errors && errors.related_artists && (<div className="_form-feedback" type="invalid">
                {errors.related_artists}
              </div>)}
            </div>
            <div className='_card'>
              <label htmlFor="record_label" className="block text-sm font-medium text-gray-700">
                Record Label
              </label>
              <AssignToLabel
                className='form-control'
                value={selectedLabelField}
                toggleSelectLabelModal={toggleSelectLabelModal}
              />
              <br />
              <div className="">
                <div className="col" xs={12} md={4}>
                  <label htmlFor="percentage" className="block text-sm font-medium text-gray-700">
                    Percentage
                  </label>
                  <input
                    type='number'
                    className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
                    {...register('percentage', { min: 0, max: 100 })}
                  />
                  {errors && errors.percentage && (<div className="_form-feedback" type="invalid">
                    {errors.percentage.message}
                  </div>)}
                </div>
                <div className="col" xs={12} md={8}>
                  <label htmlFor="territory" className="mt-5 block text-sm font-medium text-gray-700">
                    Territory
                  </label>
                  <Controller
                    control={control}
                    name='territory'
                    render={() => (
                      <SelectField
                        options={territoryOptions}
                        defaultValue={territory}
                        onChange={(e) => {
                          setValue('territory', e)
                        }}
                        isMulti
                        isTerritories
                      />
                    )}
                  />
                </div>
              </div>
              <br />
              <div className="py-2">
                <div className="col" xs={12} md={4}>
                  <label htmlFor="territory_restriction" className="block text-sm font-medium text-gray-700">
                    Territory Restriction
                  </label>
                  <Controller
                    control={control}
                    name='territory_restriction'
                    render={() => (
                      <SelectToggle
                        options={includeSelect}
                        defaultValue={territoryRestriction}
                        onChange={(e) => {
                          setValue('territory_restriction', e)
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 pb-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => { toggleTitleEdit('recordingTitle') }}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={errors}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>)
}

export default ArtistForm
