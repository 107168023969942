import React, { useState } from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import SelectField from 'layouts/components/Select.js'
import SelectToggle from 'layouts/components/SelectToggle.js'
import { territoryOptions } from './territories'
import '../styles/OwnershipForm.scss'

import Writers from './Writers'
import OwnerSelect from './OwnerSelect'

const PublisherForm = ({ remove, ownership, index, includeSelect, userTerritoryPresets }) => {
  const { register, control, setValue, watch } = useFormContext()

  const [customOwnerForm, setCustomOwnerForm] = useState(0)
  const [territoryChanged, setTerritoryChanged] = useState(false)
  const [useSameTerritory, setUseSameTerritory] = useState(false)
  let srShareTerritory = watch(`publishers[${index}].sr_share_territory`)
  let mrShareTerritory = watch(`publishers[${index}].mr_share_territory`)
  let prShareTerritory = watch(`publishers[${index}].pr_share_territory`)

  let territoryPresets = []
  if (userTerritoryPresets) {
    territoryPresets = userTerritoryPresets.map((preset, index) => {
      return { value: preset.split(','), label: 'Preset ' + (index + 1) + ': ' + preset }
    })
  }
  let newTerritoryOptions = [...territoryPresets, ...territoryOptions]

  const changeCustomOwnerForm = (index) => {
    if (customOwnerForm !== index) {
      setCustomOwnerForm(index)
    }
  }

  const _toggleUseSameTerritory = (evt, index) => {
    setUseSameTerritory(evt.target.checked)
    if (evt.target.checked) {
      setValue(`publishers[${index}].mr_share_territory`, srShareTerritory)
      setValue(`publishers[${index}].pr_share_territory`, srShareTerritory)
    }
  }

  let lyricFind = watch('platform__lyricfind')
  let lyricFindMerch = watch('platform__lyricfindmerch')

  return (<div className='py-5'>
    <div className='inline-flex'>
      <h5 className="text-md leading-6 font-medium text-gray-900">Publisher #{index + 1}</h5>
      <button className='ml-3 btn btn-xs btn-hover btn-hover-danger' onClick={() => remove(index)}>Remove</button>
    </div>

    <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'> 
      <div className='sm:col-span-6'>
        <div controlId='owners'>
          <Controller
            control={control}
            name={`publishers[${index}].owners`}
            render={() => (
              <OwnerSelect
                ownership={ownership}
                customOwnerForm={customOwnerForm}
                index={index}
                changeCustomOwnerForm={changeCustomOwnerForm}
              />
            )}
          />
        </div>
      </div>

      <div className='sm:col-span-6'>
        <div controlId='ownershipWriters'>
          <Writers ownershipIndex={index} />
        </div>
      </div>

      <div className='sm:col-span-1' controlId='syncShare'>
        <label htmlFor="syncShare" className="block text-sm font-medium text-gray-700">
          Sync. Share
        </label>
        <div className="mt-1">
          <input
            type='number'
            step='.01'
            min='0'
            max='100'
            className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
            {...register(`publishers[${index}].sr_share`, { min: 0, max: 100 })}
          />
        </div>
      </div>
      <div className='sm:col-span-3' controlId='syncTerritory'>
        <label htmlFor="syncTerritory" className="block text-sm font-medium text-gray-700">
          Territory
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].sr_share_territory`}
            render={() => (
              <SelectField
                options={newTerritoryOptions}
                key={'sr_share_territory'}
                defaultValue={ownership.sr_share_territory}
                onChange={(e) => {
                  console.log(e)
                  setValue(`publishers[${index}].sr_share_territory`, e)
                }}
                isMulti
                isTerritories
              />
            )}
          />
          <div className='form-check form-check-inline'>
            <input
              className='focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded'
              type='checkbox'
              checked={useSameTerritory}
              onChange={(evt) => _toggleUseSameTerritory(evt, index)}
            />
            <label className='form-check-label help-label text-sm font-medium text-gray-400'>&nbsp;Use the same territory for Mech and Perf</label>
          </div>
        </div>
      </div>
      <div className='sm:col-span-2' controlId='syncRestriction'>
        <label htmlFor="syncRestriction" className="block text-sm font-medium text-gray-700">
          Restriction
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].sr_include_exclude`}
            render={() => (
              <SelectToggle
                options={includeSelect}
                defaultValue={ownership.sr_include_exclude}
                onChange={(e) => {
                  setValue(`publishers[${index}].sr_include_exclude`, e)
                }}
              />
            )}
          />
        </div>
      </div>

      <div className='sm:col-span-1' controlId='mechShare'>
        <label htmlFor="mechShare" className="block text-sm font-medium text-gray-700">
          Mech. Share
        </label>
        <div className="mt-1">
          <input
            type='number'
            step='.01'
            min='0'
            max='100'
            className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
            {...register(`publishers[${index}].mr_share`, { min: 0, max: 100 })}
          />
        </div>
      </div>
      <div className='sm:col-span-3' controlId='mechTerritory'>
        <label htmlFor="mechTerritory" className="block text-sm font-medium text-gray-700">
          Territory
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].mr_share_territory`}
            render={() => (
              <SelectField
                options={newTerritoryOptions}
                key={'mr_share_territory'}
                value={mrShareTerritory}
                onChange={(e) => {
                  setValue(`publishers[${index}].mr_share_territory`, e)
                }}
                isMulti
                isTerritories
              />
            )}
          />
        </div>
      </div>
      <div className='sm:col-span-2' controlId='mechRestriction'>
        <label htmlFor="mechRestriction" className="block text-sm font-medium text-gray-700">
          Restriction
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].mr_include_exclude`}
            render={() => (
              <SelectToggle
                options={includeSelect}
                defaultValue={ownership.mr_include_exclude}
                onChange={(e) => {
                  setValue(`publishers[${index}].mr_include_exclude`, e)
                }}
              />
            )}
          />
        </div>
      </div>

      <div className='sm:col-span-1' controlId='perfShare'>
        <label htmlFor="perfShare" className="block text-sm font-medium text-gray-700">
          Perf. Share
        </label>
        <div className="mt-1">
          <input
            type='number'
            step='.01'
            min='0'
            max='100'
            className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
            {...register(`publishers[${index}].pr_share`, { min: 0, max: 100 })}
          />
        </div>
      </div>
      <div className='sm:col-span-3' controlId='perfTerritory'>
        <label htmlFor="perfTerritory" className="block text-sm font-medium text-gray-700">
          Territory
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].pr_share_territory`}
            render={() => (
              <SelectField
                options={newTerritoryOptions}
                key={'pr_share_territory'}
                value={prShareTerritory}
                onChange={(e) => {
                  setValue(`publishers[${index}].pr_share_territory`, e)
                }}
                isMulti
                isTerritories
              />
            )}
          />
        </div>
      </div>
      <div className='sm:col-span-2' controlId='perfRestriction'>
        <label htmlFor="perfRestriction" className="block text-sm font-medium text-gray-700">
          Restriction
        </label>
        <div className="mt-1">
          <Controller
            control={control}
            name={`publishers[${index}].pr_include_exclude`}
            render={() => (
              <SelectToggle
                options={includeSelect}
                defaultValue={ownership.pr_include_exclude}
                onChange={(e) => {
                  setValue(`publishers[${index}].pr_include_exclude`, e)
                }}
              />
            )}
          />
        </div>
      </div>
      {(lyricFind || lyricFindMerch) &&
        <>
          <div className='sm:col-span-1' controlId='lyricShare'>
            <label htmlFor="lyricShare" className="block text-sm font-medium text-gray-700">
              Lyric Share
            </label>
            <div className="mt-1">
              <input
                type='number'
                step='.01'
                min='0'
                max='100'
                className='appearance-none block w-full text-gray-400 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500'
                {...register(`publishers[${index}].lr_share`, { min: 0, max: 100 })}
              />
            </div>
          </div>
          <div className='sm:col-span-3' controlId='lyricTerritory'>
            <label htmlFor="lyricTerritory" className="block text-sm font-medium text-gray-700">
              Territory
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${index}].lr_share_territory`}
                render={() => (
                  <SelectField
                    options={newTerritoryOptions}
                    key={'lr_share_territory'}
                    defaultValue={ownership.lr_share_territory}
                    onChange={(e) => {
                      setValue(`publishers[${index}].lr_share_territory`, e)
                    }}
                    isMulti
                    isTerritories
                  />
                )}
              />
              {/* <div className='form-check form-check-inline'>
                <input
                  className='focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded'
                  type='checkbox'
                  checked={useSameTerritory}
                  onChange={(evt) => _toggleUseSameTerritory(evt, index)}
                />
                <label className='form-check-label help-label text-sm font-medium text-gray-400'>&nbsp;Use the same territory for Mech and Perf</label>
              </div> */}
            </div>
          </div>
          <div className='sm:col-span-2' controlId='lyricRestriction'>
            <label htmlFor="lyricRestriction" className="block text-sm font-medium text-gray-700">
              Restriction
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                name={`publishers[${index}].lr_include_exclude`}
                render={() => (
                  <SelectToggle
                    options={includeSelect}
                    defaultValue={ownership.sr_include_exclude}
                    onChange={(e) => {
                      setValue(`publishers[${index}].lr_include_exclude`, e)
                    }}
                  />
                )}
              />
            </div>
          </div>
        </>
      }
    </div>
  </div>)
}

export default PublisherForm
