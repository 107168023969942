import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import './components/AdminList.scss'
import InfoBar from 'components/InfoBar'
import ListTable from './components/ListTable'
import Heading from './components/Heading'
import AdvancedSearch from 'components/AdvancedSearch'
import BulkOptions from 'components/BulkOptions'

import { getSelectedSharesFile as exportSelected } from 'api/download'
import { getAdminList as getList, bulkDelete } from 'api/share'

const AdminView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const history = useHistory()

  const [currPage, setCurrPage] = useState(parseInt(query.page))
  const [searchQ, setSearchQ] = useState(query.q || '')
  const [refreshTable, setRefreshTable] = useState(false)
  const [selectedUpdate, setSelectedUpdate] = useState([])
  const [selected, setSelected] = useState({})
  const [selectAll, setSelectAll] = useState(0)
  const [showAdvanced, setShowAdvanced] = useState(false)

  const [list, setList] = useState()
  const [totalPages, setTotalPages] = useState()

  useEffect(() => {
    if (query.q === undefined || query.q === '') {
      setCurrPage(parseInt(query.page))
      setSearchQ(query.q || '')
      setRefreshTable(false)
    } else {
      if (query.q !== searchQ) {
        setSearchQ(query.q)
        setRefreshTable(!refreshTable)
      }
    }
  }, [location.search])

  const _updateSortParams = (param) => {
    let sortParams = query.sort.split(',')
    let queryParams = []
    let added = false

    for (let i = 0; i < sortParams.length; i++) {
      let sortParam = sortParams[i].replace('-', '')

      if (param.toLowerCase() === sortParam.toLowerCase()) {
        if (sortParams[i].indexOf('-') === 0) {
          queryParams = [param.toLowerCase()]
        } else {
          queryParams = ['-' + param.toLowerCase()]
        }
        added = true
      } else {
        queryParams = [sortParams[i].toLowerCase()]
      }
    }

    if (!added) {
      queryParams = [param.toLowerCase()]
    }

    return queryParams
  }

  const _listParams = (page, sQ, sort, filter, rownum) => {
    if (isNaN(page) || page < 1) {
      page = 1
    }

    let queryString = { page: page }

    if (rownum) {
      queryString['rownum'] = rownum
    }

    if (sQ !== undefined && sQ !== '') {
      queryString['q'] = sQ
    }

    if (sort !== undefined) {
      if (query.sort) {
        queryString['sort'] = _updateSortParams(sort).join()
        window.sortParam = queryString['sort']
      } else {
        queryString['sort'] = sort
        window.sortParam = queryString['sort']
      }
    }
    if (window.sortParam !== undefined) {
      queryString['sort'] = window.sortParam
    }

    if (filter !== undefined) {
      queryString['f'] = filter
      window.filterParam = queryString['f']
    }

    return queryString
  }

  const getShareList = (page, sQuery, sort, filter, rownum) => {
    setSearchQ(sQuery)
    getList(_listParams(page, sQuery, sort, filter, rownum)).then(res => {
      toast.loading('Getting shares')
      if (res.status === 200) {
        setList(res.data.results)
        setTotalPages(res.data.total_pages)
        toast.dismiss()
      } else {
        toast.error('Unable to fetch shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch shares')
    })
  }

  const closeExport = () => {
    setSelectAll(0)
    setSelected({})
  }

  const toggleRow = (item) => {
    const pk = item.pk
    const newSelected = Object.assign({}, selected)
    newSelected[pk] = !selected[pk]
    var newSelectedUpdate = selectedUpdate

    if (selectedUpdate.includes(item)) {
      newSelectedUpdate = selectedUpdate.splice(selectedUpdate.indexOf(item), 1)
    } else {
      newSelectedUpdate.push(item)
    }

    var selectAll = 2

    var indeterminate = false
    var newArray = Object.keys(newSelected)

    newArray.map(x => {
      if (newSelected[x] === true) {
        indeterminate = true
      }
    })

    if (!indeterminate) {
      selectAll = 0
    }

    setSelected(newSelected)
    setSelectedUpdate(newSelectedUpdate)
    setSelectAll(selectAll)
  }

  const toggleSelectAll = () => {
    let newSelected = {}
    let newSelectedUpdate = []

    if (selectAll === 0) {
      list.forEach(x => {
        newSelected[x.pk] = true
        newSelectedUpdate.push(x)
      })
    }

    setSelected(newSelected)
    setSelectedUpdate(newSelectedUpdate)
    setSelectAll(selectAll === 0 ? 1 : 0)
  }

  const getExport = (typer) => {
    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { selected: newArray, typer: typer }
    exportSelected(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const deleteAll = () => {
    const newSelected = Object.assign({}, selected)
    var newArray = Object.keys(newSelected)

    var data = { 'share_ids': newArray }
    bulkDelete(data).then((res) => {
      toast.loading('Deleting shares...')
      if (res.status === 204) {
        history.go(0)
        toast.dismiss()
      } else {
        toast.error('Unable to delete shares')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to delete shares')
    })
  }

  const handleSearch = (searchQuery, filterOption, filterValue) => {
    setSearchQ(searchQuery)

    if (filterOption !== '-1' && filterOption !== undefined) {
      let filterQ = filterOption + '|' + filterValue
      getShareList(currPage, searchQuery, undefined, filterQ)
    } else {
      getShareList(currPage, searchQuery, undefined, '')
    }
  }

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  const searchFirst = !(searchQ !== undefined && searchQ !== '')

  const bulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExport,
      params: 'shellybay',
      label: 'Export'
    },
    {
      name:  'Delete All',
      action: deleteAll,
      label: 'Delete',
      icon: 'trash'
    },
    {
      name:  'Update All',
      linkTo: {
        pathname: '/shares/update/',
        state: {
          shares: selectedUpdate,
          shareIds: Object.keys(selected)
        }
      },
      label: 'Update',
      icon: 'pencil'
    }
  ]

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          {!searchFirst &&
            (<InfoBar
              text={"Showing search result for '" + searchQ + "'"}
              onClose={() => getShareList(1, '')}
            />)
          }
          <Heading
            searchFirst={searchFirst}
            showAdvanced={showAdvanced}
            handleSearch={handleSearch}
            toggleAdvanced={toggleAdvanced}
          />
          <div className='py-4'>
            <ListTable
              currentPage={currPage}
              setCurrPage={setCurrPage}
              searchQ={searchQ}
              refreshTable={refreshTable}
              sort={query.sort}
              propSelected={selected}
              propSelectAll={selectAll}
              toggleRow={toggleRow}
              toggleSelectAll={toggleSelectAll}
              shareList={list}
              pages={totalPages}
              getList={getShareList}
            />
          </div>
        </div>
        {searchFirst && showAdvanced &&
          (<div className="flex-grow-0 flex-shrink-0">
            <AdvancedSearch
              filterList={handleSearch}
              placeholder={'Search for multiple shares'}
              toggleAdvancedSearch={toggleAdvanced}
              options={{
                'writers': 'Writer',
                'owners': 'Owner',
                'publishers': 'Collector'
              }}
            />
          </div>)
        }
        { selectAll !== 0 &&
          (<div className="flex-grow-0 flex-shrink-0">
            <BulkOptions items={bulkItems} onClose={closeExport} />
          </div>)
        }
      </div>
    </>
  )
}

export default AdminView
