export const option = {}
option[''] = '----'
option['youtube'] = 'YouTube'
option['mri'] = 'MRI'
option['hfa'] = 'HFA'
option['mlc'] = 'MLC'
option['cmrra'] = 'CMRRA'
option['ascap'] = 'ASCAP'
option['bmi'] = 'BMI'
option['sesac'] = 'SESAC'
option['soundexchange'] = 'SoundExchange'
option['distribution'] = 'Distribution'
option['sub-publishing'] = 'SubPublishing'

export const transformData = (payload) => {
  if (payload['pro_affiliation']) {
    let proAffiliation = { value: payload['pro_affiliation'], label: payload['pro_affiliation'] }
    payload['pro_affiliation'] = proAffiliation
  }

  if (payload['publishers']) {
    let publishers = []
    for (var val of payload['publishers']) {
      publishers.push({
        pk: val.pk,
        publisher: {
          pk: val.publisher.pk,
          value: val.publisher.pk,
          label: val.publisher.name
        },
        publisher_field: val.publisher.name,
        platform: {
          value: val.platform,
          label: option[val.platform]
        },
        account_number: val.account_number
      })
    }
    payload['publishers'] = publishers
  }

  return payload
}
