import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import AdminBulk from './components/AdminBulk'
import toast from 'react-hot-toast'

import { fetch as fetchComposition } from 'api/composition'
import { AuthContext } from 'stores/auth'

const ShareFormView = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const authStore = useContext(AuthContext)
  const user = authStore.user

  const [composition, setComposition] = useState()

  useEffect(() => {
    if (query.composition) {
      fetchComposition(query.composition).then(res => {
        if (res.status === 200) {
          setComposition(res.data)
        } else {
          toast.error('Unable to fetch composition')
        }
      }).catch(err=>{
        console.log(err.response)
        toast.error('Unable to fetch composition')
      })
    }
  }, [])

  const shareUpdates = location.state.shares
  const shareIds = location.state.shareIds

  if (shareUpdates === undefined) {
    return null
  }

  return (
    <AdminBulk
      user={user}
      composition={composition}
      shareUpdates={shareUpdates}
      shareIds={shareIds}
    />
  )
}

export default ShareFormView
