import React from 'react'
import { Link } from 'react-router-dom'
import {
  MusicNoteIcon,
  MicrophoneIcon,
  NewspaperIcon,
  CollectionIcon,
  FolderIcon,
  OfficeBuildingIcon,
  TrashIcon
  
} from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreateAssetsView = () => {

  const actions = [
    {
      icon: MusicNoteIcon,
      name: 'Add a Share',
      href: '/shares/add/',
      prompt: 'Add a new Collection Share.'
    },
    {
      icon: FolderIcon,
      name: 'Add a Recording',
      href: '/recordings/add/',
      prompt: 'Add a new Recording.'
    },
    {
      icon: NewspaperIcon,
      name: 'Add a Writer',
      href: '/writers/add/',
      prompt: 'Add a new Writer.'
    },
    {
      icon: CollectionIcon,
      name: 'Add a Publisher',
      href: '/publishers/add/',
      prompt: 'Add a new Publisher.'
    },
    {
      icon: MicrophoneIcon,
      name: 'Add an Artist',
      href: '/artists/add/',
      prompt: 'Add a new Artist.'
    },
    {
      icon: OfficeBuildingIcon,
      name: 'Add a Record Label',
      href: '/record-labels/add/',
      prompt: 'Add a new Record Label.'
    }
  ]

  return (<>
    <div className="min-h-full">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-8">
        <div className="mb-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Create Assets</h2>
          </div>
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            <Link to='/delete/'>
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete Assets<TrashIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            </Link>
          </div>
        </div>
        {/* Main 3 column grid */}
        <section className='mt-5 rounded-md shadow' aria-labelledby="quick-links-title">
          <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
            <h2 className="sr-only" id="quick-links-title">
              Quick links
            </h2>
            {actions.map((action, actionIdx) => (
              <div
                key={action.name}
                className={classNames(
                  actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                  'relative group bg-white p-6 hover:bg-red-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-red-500'
                )}
              >
                <div>
                  <span
                    className='text-red-700 bg-red-50 rounded-lg inline-flex p-3 ring-4 ring-white'
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <a href={action.href} className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.name}
                    </a>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {action.prompt}
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  </>)
}

export default CreateAssetsView
