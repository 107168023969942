import PrivateAPI from './private'

export const getList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.q !== undefined && payload.q !== '') {
        params['q'] = payload.q
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    return PrivateAPI.get('api/compositions/admin-shares/exp/', params)
}

export const getAdminList = (payload) => {
    var params = {}
    var page = payload.page || 1

    if (page > 0) {
        params['page'] = page
    }

    if (payload.rownum !== undefined) {
        params['rownum'] = payload.rownum
    }

    if (payload.q !== undefined) {
        if (payload.q !== '') {
            params['q'] = payload.q
        }
    }

    if (payload.sort !== undefined) {
        params['sort'] = payload.sort
    }

    if (payload.f !== undefined) {
        params['f'] = payload.f
    }

    return PrivateAPI.get('api/compositions/admin-shares/exp/', params)
}

export const fetch = (pk) => {
    return PrivateAPI.get('api/compositions/shares/' + pk + '/')
}

export const adminFetch = (pk) => {
    return PrivateAPI.get('api/compositions/admin-shares/ownership/' + pk + '/')
}

export const fetchE = (pk) => {
    return PrivateAPI.get('api/compositions/shares/e/' + pk + '/')
}

export const adminFetchE = (pk) => {
    return PrivateAPI.get('api/compositions/admin-shares/' + pk + '/')
}

export const ownershipFetchE = (pk) => {
    return PrivateAPI.get('api/compositions/ownership/e/' + pk + '/')
}

export const _bulkUpdate = (payload) => {
    return PrivateAPI.put('api/compositions/admin-shares/list/', payload)
}

export const _edit = (payload, pk) => {
    return PrivateAPI.put('api/compositions/shares/' + (pk) + '/', payload)
}


export const adminCreate = (payload, editingValues) => {
    if (editingValues) {
        payload['editingValues'] = editingValues
    }
    return PrivateAPI.post('api/compositions/admin-shares/list/', payload)
}

export const adminEdit = (payload, pk) => {
    if (payload.ownerships !== undefined) {
        for (var ownership of payload.ownerships) {
            if (typeof (ownership.publisher) === 'object') {
                ownership.publisher = ownership.publisher.pk
            }
        }
    }
    if (typeof (payload.publisher) === 'object') {
        payload.publisher = payload.publisher.pk
    }
    return PrivateAPI.put('api/compositions/admin-shares/' + (pk) + '/', payload)
}

export const submitPercentage = (payload, pk) => {
    return PrivateAPI.put('api/compositions/admin-shares/percentage/' + (pk) + '/', payload)
}

export const del = (shareId) => {
    return PrivateAPI.delete('api/compositions/shares/' + shareId + '/')
}

export const bulkDelete = (data) => {
    return PrivateAPI.delete('api/compositions/admin-shares/list/', data)
}

export const adminDel = (shareId) => {
    return PrivateAPI.delete('api/compositions/admin-shares/' + shareId + '/')
}

export const ownershipDel = (ownershipId) => {
    return PrivateAPI.delete('api/compositions/ownership/' + ownershipId + '/')
}

export const fetchCompositions = (pk) => {
    return PrivateAPI.get('api/compositions/options/')
}

export const fetchCompositionsSelect = (pk) => {
    return PrivateAPI.get('api/compositions/options/')
}

export const fetchWriters = (pk) => {
    return PrivateAPI.get('api/publishing/writers/options/')
}

export const fetchPublishers = (pk) => {
    return PrivateAPI.get('api/publishing/publishers/options/')
}

export const relinquishShare = (pk) => {
    return PrivateAPI.get('api/compositions/admin-shares/' + (pk) + '/relinquish/')
}

export const undoRelinquishShare = (pk) => {
    return PrivateAPI.get('api/compositions/admin-shares/' + (pk) + '/undo-relinquish/')
}

export const bulkRelinquishShare = (payload) => {
    return PrivateAPI.post('api/compositions/admin-shares/bulk-relinquish/', payload)
}

export const bulkUndoRelinquishShare = (payload) => {
    return PrivateAPI.post('api/compositions/admin-shares/bulk-undo-relinquish/', payload)
}
  
export const compositionShareUpdate = (payload) => {
    return PrivateAPI.put('api/compositions/shares/list/', payload)
}