import React, { useEffect, useState } from 'react'
import { ChevronDoubleUpIcon } from '@heroicons/react/solid'

import { getActions } from 'api/composition'

var moment = require('moment')

const ActionFeed = ({ model, pk }) => {
  const [actionList, setActionList] = useState([])

  useEffect(() => {
    getActions(model, pk).then(res => {
      setActionList(res.data)
    })
  }, [])

  if (actionList === undefined) {
    return null
  }

  return (
    <div className="flow-root mb-5 px-4 py-4">
      <ul role="list" className="-mb-8">
        {actionList.length === 0 && (<span className='text-gray-500 text-sm'>No activities to display.</span>)}
        {actionList.map((action, idx) => (
          <li key={action.pk}>
            <div className="relative pb-8">
              {idx !== actionList.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span className={'bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'}>
                    <ChevronDoubleUpIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {action.user}&nbsp;
                      </span>
                      {action.action}
                    </p>
                  </div>
                  <div className="text-right text-sm text-gray-500">
                    <time dateTime={action.date}>{moment(action.date).local().format('MM-DD-YYYY hh:mm:ss A')}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ActionFeed
