import React from 'react'
import { PhoneIcon, MailIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import QRCode from 'react-qr-code'
import VCard from 'vcard-creator'

function DigitalBusinessCard ({ id, record }) {
  const myVCard = new VCard()
  let phoneNumber = record.profile?.area_code + record.profile?.phone
  myVCard
    // Add personal data
    .addName(record.last_name, record.first_name)
    // Add work data
    .addCompany('Exploration Group LLC')
    .addRole(record.profile?.position ? record.profile?.position : '')
    .addEmail(record.email)
    .addPhoneNumber(phoneNumber ? phoneNumber : '+1-310-739-5482', 'PREF;WORK')
    .addAddress('171 Pier Avenue', 'Suite 251', 'Santa Monica', 'CA', '90405')
    .addURL('https://exploration.io')

  return (<div id={id} className="min-w-full">
    <div className="border bg-white shadow rounded-lg p-6 text-black-100 mb-5">
        <div className="items-center">   
            <img
                className="h-12 w-auto mx-auto mt-5"
                src="/images/new-exploration-logo-black.png"
                alt="Exploration Group LLC"
            />           
            <center className='py-5'><QRCode value={myVCard.toString()} /></center>    
            <div className="w-full">           
                <div className='text-center'>               
                    <h2 className="font-bold text-2xl">{record.first_name + ' ' + record.last_name}</h2>
                    <p className="font-medium text-xl mb-3">{record.profile?.position ? record.profile?.position : ''}</p>   
                    <hr />                                                
                </div>
                <ul role="list" className="mt-6 mb-5 space-y-4 content-center">
                    <li key='email' className="flex space-x-3">
                      <MailIcon className="h-7 w-7 flex-shrink-0 text-red-500" aria-hidden="true" />
                      <span className="text-md font-base">{record.email}</span>
                    </li>
                    <li key='phone' className="flex space-x-3">
                      <PhoneIcon className="h-7 w-7 flex-shrink-0 text-red-500" aria-hidden="true" />
                      <span className="text-md font-base">{phoneNumber ? phoneNumber : '+1-310-739-5482'}</span>
                    </li>
                    <li key='address' className="flex space-x-3">
                      <LocationMarkerIcon className="h-7 w-7 flex-shrink-0 text-red-500" aria-hidden="true" />
                      <span className="text-md font-base">171 Pier Avenue, Suite 251 Santa Monica, CA 90405</span>
                    </li>
                </ul>
            </div>      
        </div>        
    </div>
</div>)
}

export default DigitalBusinessCard