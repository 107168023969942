import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

export const RenderReleases = () => {
  const { control, register, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'releases',
    keyName: 'uuid'
  })

  return (<>
    {fields.map((release, index) => {
      return (<>
        <div className="bg-white rounded-md" key={index}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="album-title" className="block text-sm font-medium text-gray-700">
                Album Title {index + 1}<sup className='required-icon'>*</sup>
              </label>
              <div className="mt-1">
                <input
                  type='text'
                  className='appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  {...register(`releases[${index}].album_title`)}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="release-date" className="block text-sm font-medium text-gray-700">
                Release Date {index + 1}
              </label>
              <div className="mt-1">
                <input
                  type='date'
                  className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                  {...register(`releases[${index}].release_date`)}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="upc" className="block text-sm font-medium text-gray-700">
                UPC {index + 1}
              </label>
              <div className="mt-1">
                <input
                  type='text'
                  className='appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  {...register(`releases[${index}].upc`)}
                />
              </div>
            </div>
          </div>
          <button
            className='btn btn-link btn-remove'
            type='button'
            onClick={() => remove(index)}>
            Remove
          </button>
        </div>
      </>)
    }
    )}
    <div className="pt-5">
      <div className="flex">
        <button
          type="button"
          onClick={() => append()}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Add Release
        </button>
      </div>
    </div>
  </>)
}

export default RenderReleases
