import React, { useEffect, useState } from 'react'
import Paginator from 'components/Paginator'
import ConflictListItem from './ConflictListItem'

const ListTable = ({ currentPage, searchQ, refreshTable, conflictList, pages, getList }) => {

  const [currPage, setCurrPage] = useState(parseInt(currentPage) || 1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (searchQ) {
      getList(currPage, 10, searchQ)
    }
  }, [refreshTable])

  const _onNext = (page) => {
    setLoading(true)
    getList(parseInt(page) + 1, 10, searchQ)
    setCurrPage(parseInt(page) + 1)
  }

  const _onPrevious = (page) => {
    if (page !== 1) {
      setLoading(true)
      getList(parseInt(page) - 1, 10, searchQ)
      setCurrPage(parseInt(page) - 1)
    }
  }

  if (conflictList === undefined || conflictList === null) {
    return null
  }

  const hasNext = currPage < pages

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200" id='table-header'>
        {conflictList.map((conflict) => {
          return (<li key={conflict.pk}>
            <ConflictListItem conflict={conflict} />
          </li>)
        })}
      </ul>
      <div className="">
        <Paginator
          getNext={_onNext}
          getPrev={_onPrevious}
          setCurrPage={setCurrPage}
          currPage={currPage}
          hasNext={hasNext}
          totalPages={pages}
        />
      </div>
    </div>
  )
}

export default ListTable
